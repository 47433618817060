import Cookies from 'js-cookie';
import {Redirect} from "react-router";
import React from "react";
import {DBCode} from "../constants";

const localStorage = require('local-storage');

class Auth {
	static login(data, callback) {
		let token = btoa(JSON.stringify({
			userid: data.userid,
			groupid: data.group.groupid,
			fullname: data.fullname,
			groupnm: data.group.groupname,
			grouptypeid: data.group.grouptypeid,
		}));
		Cookies.set('token', token);

		if(callback !== undefined) {
			if(!this.isLogged()) {
				this.logout()
			}

			callback();
		}
	}

	static logout(callback) {
		Cookies.remove('token');
		localStorage.remove(DBCode.KEY_STORAGE_ACCESSTO);
		localStorage.remove(DBCode.KEY_STORAGE_MENUS);
		localStorage.remove(DBCode.KEY_STORAGE_ORDERS);

		if(callback !== undefined) {
			if(this.isLogged()) {
				return <Redirect to={
					{
						pathname: "/",
					}
				} />
			}

			return callback();
		}
	}

	static isLogged() {
		if(Cookies.get('token') === undefined) {
			this.logout();
			return false;
		}

		return true;
	}

	static get(value) {
		this.isLogged();

		let _token = Cookies.get('token');

		if(_token !== undefined && _token !== '') {
			let token = JSON.parse(atob(_token));
			return token[value];
		}

		return null;
	}
}

export default Auth;
