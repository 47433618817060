import React from "react";

import $ from 'jquery';
$.Datatable = require('datatables.net-bs4');

var tables = [];

class Datatables extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			className: props.className ? props.className : 'display table table-striped table-hover'
		};
	}
	componentDidUpdate(prevProps, prevState, snapshot) {
	}

	componentDidMount() {
		const props = this.props;

		let options = props.options ? props.options : {};

		if(props.serverSide !== undefined) {
			options.serverSide = true;
			options.ajax = props.serverSide;
		}

		options.dom = props.dom ? props.dom : '<\'row\'<\'col-sm-6\'l><\'col-sm-6\'f>r>t<\'row\'<\'col-sm-6\'i><\'col-sm-6\'p>>';
		options.columns = props.columns ? props.columns : [];
		options.columnDefs = props.columnDefs ? props.columnDefs : [];
		options.processing = props.processing ? props.processing : true;
		options.data = props.data ? props.data : [];

		tables.push({
			target: props.target ? props.target : 'table',
			context: this.el,
			options: options,
			data: null,
		});

		$.fn.dataTableExt.oApi.fnStandingRedraw = function (oSettings) {
			oSettings.oApi._fnDraw(oSettings);
		};

		tables.forEach(table => {
			if(!$.fn.dataTable.isDataTable($(table.context))) {
				table.data = $(table.context).DataTable(table.options);
			}
		});
	}
	render() {
		const {className} = this.state;
		const {actions, target, id} = this.props;

		const ajaxData = this.props.datas ? this.props.datas : [];

		tables.forEach(table => {
			if(table !== null && actions !== 'ready' && actions.indexOf('read') !== -1) {
				if(table.options.serverSide !== undefined) {
					if(target === undefined || target === table.target) {
						table.data.ajax.reload(null, false);
					}
				} else {
					table.data.clear().draw();
					if(ajaxData) {
						ajaxData.forEach(data => {
							$('#' + id).dataTable().fnAddData(data);
						});
					}
				}
			}
		});

		return <table id={id} className={className} ref={el => this.el = el} />
	}
}

export default Datatables;
