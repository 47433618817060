import ServiceMasterGroup from "../../../services/master-group";

import $ from 'jquery';

const service = new ServiceMasterGroup();

export const DATATABLES = {
	serverSide: {
		url: service.datatables,
		type: 'post'
	},
	columns: [
		{title: 'No'},
		{title: 'Nama Group', data: 'groupname'},
		{title: 'Tipe Group', data: 'grouptype.typename'},
		{title: 'Aksi'},
	],
	columnDefs: [
		{
			targets: 0,
			width: 20,
			render: (data, type, row, meta) => {
				return meta.row + meta.settings._iDisplayStart + 1;
			},
		},
		{
			targets: 2,
			render: (data, type, row, meta) => {
				return (row.grouptype !== null) ? row.grouptype.typename : '';
			}
		},
		{
			targets: 3,
			width: 180,
			render: (data, type, row, meta) => {
				const $actions = $('<div>');

				const $access = $("<button>");
				$access.addClass("btn btn-warning btn-xs");
				$access.attr("actions", "access");
				$access.attr("data-id", row.groupid);
				$access.append($("<i>", { class: "fa fa-align-justify" }));
				$access.append($("<span>", { class: "ml-1" }).text("Akses Menu"));

				$actions.append($access);

				const $edit = $("<button>");
				$edit.addClass("btn btn-primary btn-xs ml-1");
				$edit.attr("actions", "edit");
				$edit.attr("data-id", row.groupid);
				$edit.append($("<i>", { class: "fa fa-edit" }));

				$actions.append($edit);

				const $delete = $('<button>');
				$delete.addClass("btn btn-danger btn-xs ml-1");
				$delete.attr("actions", "delete");
				$delete.attr("data-id", row.groupid);
				$delete.append($("<i>", { class: "fa fa-trash" }));

				$actions.append($delete);

				return $actions.get(0).outerHTML;
			}
		}
	]
};

export const API_SELECT = {

};
