import {Config} from "../module/core";

class ServiceReportStock {
	constructor() {
		this.api = `${Config.api}/report/stock`;
		this.datatables = `${this.api}/datatables`;
		this.datatablesDetail = `${this.api}/datatablesDetail`;
	}
}

export default ServiceReportStock;
