import React from "react";
import {Button, Modal} from "react-bootstrap";
import AsyncSelect from "react-select/async";
import {API_SELECT, Helpers} from "../../../module/core";
import ServiceMasterProductVariant from "../../../services/master-product-variant";
import {DBMessage} from "../../../module/constants";

class ProductFormVariant extends React.Component {
	constructor(props) {
		super(props);

		const {productid} = props.datas;

		this.state = {
			id: 0,
			disabled: false,
			productid: productid,
			productname: '',
			datas: {
				producttype: null,
				productvar: '',
			}
		};
		this.defaultData = this.state.datas;

		this.service = new ServiceMasterProductVariant(productid);
	}
	onShow() {
		const {actions, datas} = this.props;

		if(actions === 'update-variant') {
			this.setState({disabled: true, productname: datas.productname});
			this.service.fetch(datas.id)
				.then(
					res => {
						if(res.success) {
							this.setState({
								id: datas.id,
								disabled: false,
								datas: res.datas
							});
						} else {
							this.setState({disabled: false});
						}
					}
				);
		} else {
			this.setState({
				datas: this.defaultData,
				actions: actions,
				productname: datas.productname,
			});
		}
	}
	onChangeSelect(option, select) {
		const {datas} = this.state;

		this.setState({
			datas: {
				...datas,
				[select.name]: option,
			}
		});
	}
	onChangeHandler(event) {
		const {datas} = this.state;

		this.setState({
			datas: {
				...datas,
				[event.target.name]: event.target.value,
			}
		});
	}
	onSubmitHandler(event) {
		event.preventDefault();

		const {onHide, actions} = this.props;
		const {id, datas} = this.state;

		this.setState({disabled: true});
		if(actions === 'create-variant') {
			this.service.fetchStore(datas)
				.then(
					res => {
						onHide();
						this.setState({disabled: false});
						if(res.success) {
							Helpers.swalSuccess(DBMessage.FETCH_SUCCESS_TITLE, res.message);
						} else {
							Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, res.message);
						}
					}
				)
		} else if(actions === 'update-variant') {
			this.service.fetchUpdate(datas, id)
				.then(
					res => {
						onHide();
						this.setState({disabled: false});
						if(res.success) {
							Helpers.swalSuccess(DBMessage.FETCH_SUCCESS_TITLE, res.message);
						} else {
							Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, res.message);
						}
					}
				)
		}
	}
	render() {
		const {disabled, productid, productname, datas} = this.state;
		const {show, onHide} = this.props;

		return (
			<Modal show={show} onHide={onHide} onShow={this.onShow.bind(this)}>
				<Modal.Header closeButton>
					<Modal.Title>Form Varian Produk</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form id={"form-varian"} onSubmit={this.onSubmitHandler.bind(this)}>
						<div className={"form-group"}>
							<label htmlFor={"input-nama-produk"}>Nama Produk</label>
							<input
								type={"text"}
								className={"form-control"}
								value={productname}
								disabled={true}
							/>
						</div>
						<div className={"form-group"}>
							<label htmlFor={"input-productype"}>Tipe Produk</label>
							<AsyncSelect
								cacheOptions
								defaultOptions
								name={"producttype"}
								placeholder={'Pilih tipe produk'}
								loadOptions={(inputValue, callback) => {
									API_SELECT.productType(productid, inputValue, callback);
								}}
								onChange={this.onChangeSelect.bind(this)}
								value={datas.producttype}
								isDisabled={disabled}
							/>
						</div>
						<div className={"form-group"}>
							<label htmlFor={"input-productvar"}>Nama Varian</label>
							<input
								type={"text"}
								id={"input-productvar"}
								name={"productvar"}
								placeholder={"Mauskan nama varian"}
								className={"form-control"}
								onChange={this.onChangeHandler.bind(this)}
								value={datas.productvar}
								disabled={disabled}
							/>
						</div>
					</form>
				</Modal.Body>
				<Modal.Footer>
					<Button type={"button"} variant="secondary" size={"sm"} onClick={onHide} disabled={disabled}>
						Batal
					</Button>
					<Button type={"submit"} variant="primary" size={"sm"} form={"form-varian"} disabled={disabled}>
						{!disabled ? 'Simpan' : 'Memproses ...'}
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default ProductFormVariant;
