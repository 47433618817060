import axios from 'axios';
import {Config, Helpers} from "../module/core";
import {DBMessage} from "../module/constants";

import $ from 'jquery';

class ServiceTransactionStock {
	constructor() {
		this.api = `${Config.api}/stock`;
		this.datatables = `${this.api}/datatables`;
		this.datatablesDetail = `${this.api}/datatablesDetail`;
	}
	fetch(id) {
		$('body').addClass('fetching-data');
		return axios.get(`${this.api}/${id}`)
			.then(
				res => {
					$('body').removeClass('fetching-data');
					return res.data;
				},
				error => {
					$('body').removeClass('fetching-data');
					return {success: false, message: error};
				}
			)
			.catch(() => {
				$('body').removeClass('fetching-data');
				Config.swalError(DBMessage.ERROR_NETWORK_TITLE, DBMessage.ERROR_NETWORK_MESSAGE);
			});
	}
	store(datas) {
		$('body').addClass('fetching-data');

		datas.date = new Date(datas.date).toLocaleDateString();
		datas.userid = Helpers.sessUserid();

		return axios.post(`${this.api}`, datas)
			.then(
				res => {
					$('body').removeClass('fetching-data');
					return res.data;
				},
				error => {
					$('body').removeClass('fetching-data');
					return {success: false, message: error};
				}
			)
			.catch(() => {
				$('body').removeClass('fetching-data');
				Config.swalError(DBMessage.ERROR_NETWORK_TITLE, DBMessage.ERROR_NETWORK_MESSAGE);
			});
	}
	update(datas, adjustid) {
		$('body').addClass('fetching-data');

		datas.date = new Date(datas.date).toLocaleDateString();
		datas.userid = Helpers.sessUserid();

		return axios.put(`${this.api}/${adjustid}`, datas)
			.then(
				res => {
					$('body').removeClass('fetching-data');
					return res.data;
				},
				error => {
					$('body').removeClass('fetching-data');
					return {success: false, message: error};
				}
			)
			.catch(() => {
				$('body').removeClass('fetching-data');
				Config.swalError(DBMessage.ERROR_NETWORK_TITLE, DBMessage.ERROR_NETWORK_MESSAGE);
			});
	}
	delete(id) {
		$('body').addClass('fetching-data');
		return axios.delete(`${this.api}/${id}`)
			.then(
				res => {
					$('body').removeClass('fetching-data');
					return res.data;
				},
				error => {
					$('body').removeClass('fetching-data');
					return {success: false, message: error};
				}
			)
			.catch(() => {
				$('body').removeClass('fetching-data');
				Config.swalError(DBMessage.ERROR_NETWORK_TITLE, DBMessage.ERROR_NETWORK_MESSAGE);
			});
	}
	import(datas) {
		$('body').addClass('fetching-data');
		datas.append('userid', Helpers.sessUserid());
		return axios.post(`${this.api}/import`, datas, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
			.then(
				res => {
					$('body').removeClass('fetching-data');
					return res.data;
				},
				error => {
					$('body').removeClass('fetching-data');
					return {success: false, message: error};
				}
			)
			.catch(() => {
				$('body').removeClass('fetching-data');
				Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, DBMessage.FETCH_FAIL_MESSAGE);
			})
	}

	checkCode(code) {
		$('body').addClass('fetching-data');
		return axios.post(`${this.api}/checkNomor`, {code: code}, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
			.then(
				res => {
					$('body').removeClass('fetching-data');
					return res.data;
				},
				error => {
					$('body').removeClass('fetching-data');
					return {success: false, message: error};
				}
			).catch(() => {
				$('body').removeClass('fetching-data');
				Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, DBMessage.FETCH_FAIL_MESSAGE);
			})
	}

}

export default ServiceTransactionStock;
