import ServiceReportOrder from "../../../services/report-order";

import $ from 'jquery';

const service = new ServiceReportOrder();

export const DATATABLES = {
	serverSide: {
		url: service.datatables+'?type=approval',
		type: 'post'
	},
	columns: [
		{title: 'No'},
		{title: 'Informasi Produk'},
		{title: 'Informasi Pemesan'},
		{title: 'Status'},
		{title: 'Aksi'},
	],
	columnDefs: [
		{
			targets: 0,
			width: 20,
			render: (data, type, row, meta) => {
				return meta.row + meta.settings._iDisplayStart + 1;
			},
		},
		{
			targets: 1,
			render: (data, type, row) => {
				const image = row.productvar.images[0]
					? row.productvar.images[0].image
					: row.noImage;

				const styles = {
					'width': '50px',
					'height': '50px',
					'background-image': `url(${image})`,
				};

				let $image = $('<div />', {class: 'image'});
				$image.css(styles);

				let $row = $('<div />', {class: 'row-orders'});
				$row.append($image);

				let $content = $('<div />', {class: 'content'});
				$content.append($('<b />', {class: 'title'}).text(row.ordercd));
				$content.append($('<span />', {class: 'description'}).text(row.product.productname));

				$row.append($content);

				return $row[0].outerHTML;
			}
		},
		{
			targets: 2,
			render: (data, type, row) => {
				let $row = $('<div />', {class: 'row-orders'});
				$row.css('width', '200px');
				let $content = $('<div />', {class: 'content'});
				$content.append($('<b />', {class: 'title'}).text(row.customer ? row.customer.customername : '-'));
				$content.append($('<span />', {class: 'description'}).text(row.customer ? row.customer.address : '-'));
				$content.append($('<span />', {class: 'description'}).text(row.customer ? row.customer.phone : '-'));

				$row.append($content);

				return $row[0].outerHTML;
			}
		},{
			targets: 3,
			render: (data, type, row) => {
				let $row = $('<div />');
				$row.text(row.status ? row.status.statusnm : '-');

				return $row[0].outerHTML;
			}
		},
		{
			width: 280,
			targets: 4,
			render: (data, type, row) => {
				const $actions = $('<div>');

				const $cancel = $("<button />");
				$cancel.addClass("btn btn-danger btn-xs");
				$cancel.attr('actions', 'cancel');
				$cancel.attr('data-id', btoa(row.orderid));
				$cancel.attr('data-productid', btoa(row.productid));
				$cancel.attr('data-statusid', btoa(row.statusid));
				$cancel.append($('<i>', {class: 'fa fa-times-circle'}));
				$cancel.append($('<span>').text('Batalkan Pesanan'));

				$actions.append($cancel);

				const $edit = $("<button>");
				$edit.addClass("btn btn-primary btn-xs ml-1");
				$edit.attr("actions", "detail");
				$edit.attr("data-id", btoa(row.orderid));
				$edit.attr("data-productid", btoa(row.productid));
				$edit.attr("data-statusid", btoa(row.statusid));
				$edit.append($("<i>", { class: "fa fa-info-circle" }));
				$edit.append($('<span />').text('Lihat detail'));

				$actions.append($edit);

				return $actions.get(0).outerHTML;
			}
		}

	]
};
