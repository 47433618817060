import React from "react";
import {DBCode, DBStyles} from "../../../module/constants";

import Header from "../../skin/header";
import Sidebar from "../../skin/sidebar";
import PageHeader from "../../skin/pageheader";

import ServiceAppsProduct from "../../../services/apps-product";
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';
import {Redirect} from "react-router";
import {Helpers} from "../../../module/core";

const localStorage = require('local-storage');

class OrderDetailComponent extends React.Component {
	constructor(props) {
		super(props);

		const {token} = props.match.params;
		const params = JSON.parse(atob(token));
		const productid = params[DBCode.KEY_PARAM_PRODUCTID];

		this.state = {
			refreshing: false,
			title: 'Detail Produk',
			breadcrumbs: [
				{link: '#', menu: 'Orders'},
				{link: '#', menu: 'Detail Produk'},
			],
			menuPositions: ['Orders'],
			info: {
				types: [
					{
						variants: [
							{
								images: []
							}
						]
					}
				]
			},
			datas: {
				typeIndex: 0,
				variantIndex: 0,
				productid: productid,
			},
			options: {
				swiper: {
					pagination: {
						el: '.swiper-pagination',
						clickable: true
					}
				}
			}
		};

		this.products = new ServiceAppsProduct();

		this.fetchDataProduct();
	}
	isValid() {
		const {info, datas} = this.state;
		const type = info.types[datas.typeIndex];

		if(type && type.producttypeid) {
			const variant = type.variants[datas.variantIndex];

			return !(variant && variant.productvarid);
		}

		return true;
	}
	fetchDataProduct() {
		const {datas, breadcrumbs} = this.state;
		const {productid} = datas;

		this.products.fetch(productid)
			.then(
				res => {
					breadcrumbs.push({
						link: '#',
						menu: res.row.productname
					});
					if(res.success) {
						this.setState({
							breadcrumbs: breadcrumbs,
							refreshing: false,
							info: res.row,
						});
					} else {
						this.setState({refrehsing: false});
					}
				}
			)
	}
	onProsesOrder() {
		const {info, datas} = this.state;
		const type = info.types[datas.typeIndex];

		if(type && type.producttypeid) {
			const variant = type.variants[datas.variantIndex];

			if(variant && variant.productvarid) {
				const token = {};
				token[DBCode.KEY_PARAM_USERID] = Helpers.sessUserid();
				token[DBCode.KEY_PARAM_PRODUCTID] = datas.productid;
				token[DBCode.KEY_PARAM_PRODUCTTYPEID] = type.producttypeid;
				token[DBCode.KEY_PARAM_PRODUCTVARID] = variant.productvarid;

				const orders = localStorage.get(DBCode.KEY_STORAGE_ORDERS);
				if(orders) {
					const params = JSON.parse(orders);
					params.datas.estimatedate = new Date(params.datas.estimatedate);

					if(params.datas.productvarid !== variant.productvarid) {
						localStorage.remove(DBCode.KEY_STORAGE_ORDERS);
					}
				}

				this.setState({redirect: `/orders-process/${btoa(JSON.stringify(token))}`});
			}
		}
	}
	render() {
		const {breadcrumbs, menuPositions, title, redirect, info, datas, options} = this.state;
		const {swiper} = options;

		const images = info.types[datas.typeIndex].variants[datas.variantIndex].images.length > 0
			? info.types[datas.typeIndex].variants[datas.variantIndex].images
			: [];

		if(redirect) {
			return <Redirect to={redirect} />;
		}

		return (
			<div className={DBStyles.content.wrapperIcon}>
				<Header />

				<Sidebar menuPosition={menuPositions} />

				<div className={"main-panel apps"}>
					<div className={"content"}>
						<div className={"page-inner"}>
							<PageHeader title={title} breadcrumbs={breadcrumbs} />

							<div className={"apps-content"}>
								<div className={"card"}>
									<div className={"card-body"}>
										<div className={"row card-detail-product"}>
											<div className={"col-md-4"}>
												<Swiper {...swiper} shouldSwiperUpdate={true}>
													{
														images.map((image, img) => {
															return (
																<div key={`image-${img}`} className={"image-product"} style={{backgroundImage: `url(${image})`}} />
															);
														})
													}
												</Swiper>
											</div>
											<div className={"col-md-8 content-product"}>
												<div className={"info-product"}>
													<div className={"product-name"}>{info ? info.productname : null}</div>
													<div className={"product-description"}>
														<p>Ukuran : {info.types[datas.typeIndex].size}</p>
														<p>Jenis Bahan : {info.types[datas.typeIndex].material}</p>
													</div>
												</div>
												<div className={"section-product"}>
													<div className={"section-title"}>Tipe Product</div>
													<div className={"badge-wrapper"}>
														{
															info.types.map((type, i) => {
																if(type.typename) {
																	let className = "badge-item secondary";
																	if(i === datas.typeIndex) className = "badge-item active";

																	return (
																		<button
																			type={'button'}
																			key={`type-${i}`}
																			className={className}
																			onClick={() => {
																				this.setState({
																					datas: {
																						...datas,
																						typeIndex: i,
																						variantIndex: 0,
																					}
																				})
																			}}
																		>
																			{type.typename}
																		</button>
																	);
																} else {
																	return <p>Tidak ada data tipe produk</p>
																}
															})
														}
													</div>
												</div>
												<div className={"section-product"}>
													<div className={"section-title"}>Variant Product</div>
													<div className={"badge-wrapper"}>
														{
															info.types[datas.typeIndex].variants.map((variant, j) => {
																if(variant.productvar) {
																	let className = "badge-item";
																	if(j === datas.variantIndex) className = "badge-item active";

																	return (
																		<button
																			type={'button'}
																			key={`variant-${j}`}
																			className={className}
																			onClick={() => {
																				this.setState({
																					datas: {
																						...datas,
																						variantIndex: j
																					}
																				})
																			}}
																		>
																			{variant.productvar}
																		</button>
																	);
																} else {
																	return <p>Tidak ada variant produk</p>
																}
															})
														}
													</div>
												</div>
												<div className={"action-product"}>
													<button
														className={"btn btn-primary btn-product btn-md"}
														onClick={this.onProsesOrder.bind(this)}
														disabled={this.isValid()}
													>
														Pesan Sekarang
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default OrderDetailComponent;
