import React from "react";
import {Redirect, Route} from "react-router";
import Auth from "../helpers/auth";

export const RouteGuest = ({component: Component, ...rest}) => {
	return (
		<Route
			{...rest}
			render={props => {
				if (!Auth.isLogged()) {
					return <Component {...props} />
				} else {
					return <Redirect to={
						{
							pathname: '/',
							state: {
								from: props.location
							}
						}
					}/>
				}
			}
			}/>
	)
};

export default RouteGuest;
