import React from "react";
import {Button, Modal} from "react-bootstrap";

import $ from "jquery";
import DatePicker from "react-datepicker/es";
import {RENDER_COMPONENTS} from "./_config";
import ServiceTransactionStock from "../../../services/transaction-stock";
import {Helpers} from "../../../module/core";
import {DBMessage} from "../../../module/constants";

class FormImportModal extends React.Component {

    constructor() {
        super();

        this.state = {
            refreshing: false,
            actions: 'read',
            datas: null,
        };
        this.defaultState = this.state;

        this.service = new ServiceTransactionStock();
    }
    onShow() {
        RENDER_COMPONENTS.init();
    }
    onChangeSelect(e) {
        const {datas} = this.state;
        this.setState({
            datas: {
                ...datas,
                [e.target.name]: e.target.value,
            }
        });
    }
    onChangeText(e) {
        const {datas} = this.state;
        this.setState({
            datas: {
                ...datas,
                [e.target.name]: e.target.value,
            }
        });
    }
    onClickUpload() {
        const {datas} = this.state;
        const $uploadEl = $('#file-data');
        $uploadEl.click();
        $uploadEl.change((e) => {
            this.setState({
                datas: {
                    ...datas,
                    files: e.target.files[0],
                }
            })
        })
    }
    onSubmitHandler(e) {
        e.preventDefault();

        const props = this.props;
        const {datas} = this.state;

        const formData = new FormData(e.target);
        formData.append('files', datas.files);
        formData.append('date', datas.date ? datas.date.toLocaleDateString() : new Date().toLocaleDateString());

        this.setState({
            actions: 'importing',
            refreshing: true,
        });
        this.service.import(formData)
            .then(
                res => {
                    this.setState({actions: 'read', refreshing: false});
                    if(res.success) {
                        props.onHide();
                        Helpers.swalSuccess(DBMessage.FETCH_SUCCESS_TITLE, res.message);
                    } else {
                        Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, res.message);
                    }
                }
            )
            .catch(error => {
                this.setState({refreshing: false});
            });
    }
    render() {
        const props = this.props;
        const {refreshing, datas} = this.state;
        return (
            <Modal show={props.show} onHide={props.onHide} onShow={this.onShow.bind(this)}>
                <Modal.Header closeButton>
                    <Modal.Title>Form Import Stock Awal</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form id={'form-import-stock'} onSubmit={this.onSubmitHandler.bind(this)}>
                        <div className={'form-group'}>
                            <label htmlFor={'input-date'}>Tanggal</label>
                            <DatePicker
                                className={'form-control'}
                                placeholderText={'DD / MM / YYYY'}
                                selected={datas && datas.date ? new Date(datas.date) : new Date()}
                                dateFormat={'dd/MM/yyyy'}
                                onChange={date => {
                                    this.onChangeText({
                                        target: {
                                            name: 'date',
                                            value: date,
                                        }
                                    });
                                }}
                                required={true}
                                disabled={refreshing}
                            />
                        </div>
                        <div className={'form-group'}>
                            <label htmlFor={'select-warehouse'}>Warehouse</label>
                            <select
                                id={'select-warehouse-to'}
                                className={'form-control select2-tipe'}
                                name={'whto'}
                                value={datas ? datas.whto : null}
                                onChange={this.onChangeSelect.bind(this)}
                                required={true}
                                data-select-id={datas && datas.warehouse ? datas.warehouse.whto.whid : null}
                                data-select-text={datas && datas.warehouse ? datas.warehouse.whto.whname : null}
                                disabled={refreshing || (datas && datas.state === 'admin')} />
                        </div>
                        <div className={'form-group'}>
                            <label htmlFor={'text-description'}>Deskripsi</label>
                            <textarea
                                className={'form-control'}
                                id={'text-description'}
                                name={'description'}
                                rows={5}
                                value={datas ? datas.description : ''}
                                placeholder={'Masukan deskripsi'}
                            />
                        </div>
                        <div className={'form-group'}>
                            <label>Data Stock</label>
                            <div className={'form-upload'}>
                                <input type={'file'} id={'file-data'} style={{display: 'none'}} accept=".xlsx, .xls, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <Button
                                            variant={"primary"}
                                            size={"sm"}
                                            className={"mr-1"}
                                            onClick={this.onClickUpload.bind(this)}>
                                            <span>Upload Data</span>
                                        </Button>
                                    </div>
                                    <input
                                        type={'text'}
                                        className={'form-control'}
                                        placeholder={'Nama filename'}
                                        disabled={true}
                                        value={datas && datas.files ? datas.files.name : null}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button type={"button"} variant="secondary" size={"sm"} onClick={props.onHide} disabled={refreshing}>
                        Batal
                    </Button>
                    <Button type={"submit"} variant="primary" size={"sm"} form={"form-import-stock"} disabled={refreshing}>
                        {!refreshing ? 'Simpan' : 'Memproses ...'}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default FormImportModal;