import React from "react";
import {Button, Modal} from "react-bootstrap";

import ServiceMasterWareHouse from "../../../services/master-warehouse";

import {Helpers} from "../../../module/core";
import {DBMessage} from "../../../module/constants";

class WareHouseForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			datas: {
				whid: 0,
				whname: '',
				whloc: '',
				whcode: ''
			},
			actions: props.actions ? props.actions : 'ready',
			disabled: false,
		};
		this.defaultDatas = this.state.datas;

		this.service = new ServiceMasterWareHouse();

	}

	onShow() {
		const {actions, datas} = this.props;
		if(actions === 'edit') {
			this.setState({ disabled: true });
			this.service.fetch(datas.id)
				.then(
					res => {
						if(res.success) {
							this.setState({
								datas: res.row,
								actions: 'edit',
								disabled: false,
							});
						} else {
							Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, res.message);
						}
					}
				)
		} else {
			this.setState({
				datas: this.defaultDatas,
				actions: actions,
			});
		}
	}

	onChangeHandler(event) {
		const {datas} = this.state;
		this.setState({
			datas: {
				...datas,
				[event.target.name]: event.target.value,
			}
		});
	}

	onSubmitHandler(event) {
		event.preventDefault();
		const {datas, actions} = this.state;
		const props = this.props;

		this.setState({disabled: true});

		if(actions === 'create') {
			this.service.fetchStore(datas)
				.then(
					res => {
						this.setState({disabled: false});
						if(res.success) {
							props.onHide();
							Helpers.swalSuccess(DBMessage.FETCH_SUCCESS_TITLE, res.message);
						} else {
							Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, res.message);
						}
					}
				)
				.catch(error => {
					this.setState({disabled: false});
				});
		} else if(actions === 'edit') {
			this.service.fetchUpdate(datas, datas.whid)
				.then(
					res => {
						this.setState({disabled: false});
						if(res.success) {
							props.onHide();
							Helpers.swalSuccess(DBMessage.FETCH_SUCCESS_TITLE, res.message);
						} else {
							Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, res.message);
						}
					}
				)
				.catch(error => {
					this.setState({disabled: false});
				});
		}
	}
	render() {
		const props = this.props;
		const {disabled, datas} = this.state;
		return (
			<Modal {...props} onShow={this.onShow.bind(this)} onHide={props.onHide}>
				<Modal.Header closeButton>
					<Modal.Title>Form Warehouse</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form id={"form-group"} onSubmit={this.onSubmitHandler.bind(this)}>
						<div className={"form-group"}>
							<label htmlFor={"input-whcode"}>Kode Warehouse</label>
							<input
								type={"text"}
								id={"input-whcode"}
								className={"form-control"}
								name={"whcode"}
								value={datas.whcode}
								placeholder={"Masukan kode warehouse"}
								onChange={this.onChangeHandler.bind(this)}
								disabled={disabled}
								required={true}/>
						</div>

						<div className={"form-group"}>
							<label htmlFor={"input-whname"}>Nama Warehouse</label>
							<input
								type={"text"}
								id={"input-whname"}
								className={"form-control"}
								name={"whname"}
								value={datas.whname}
								placeholder={"Masukan nama warehouse"}
								onChange={this.onChangeHandler.bind(this)}
								disabled={disabled}
								required={true}/>
						</div>

						<div className={"form-group"}>
							<label htmlFor={"input-whloc"}>Lokasi Warehouse</label>
							<textarea
								id={"input-uomname"}
								className={"form-control"}
								name={"whloc"}
								value={datas.whloc}
								rows={"5"}
								placeholder={"Masukan lokasi warehouse"}
								onChange={this.onChangeHandler.bind(this)}
								disabled={disabled}
								required={true}/>
						</div>
					</form>
				</Modal.Body>
				<Modal.Footer>
					<Button type={"button"} variant="secondary" size={"sm"} onClick={props.onHide} disabled={disabled}>
						Batal
					</Button>
					<Button type={"submit"} variant="primary" size={"sm"} form={"form-group"} disabled={disabled}>
						{!disabled ? 'Simpan' : 'Memproses ...'}
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default WareHouseForm;
