import React from "react";
import {DBCode, DBMessage, DBStyles} from "../../../module/constants";
import Sidebar from "../../skin/sidebar";
import PageHeader from "../../skin/pageheader";
import Header from "../../skin/header";
import {API_SELECT, Helpers} from "../../../module/core";
import DatePicker from "react-datepicker/es";
import Select2 from "../../components/select2";
import {Redirect} from "react-router";
import OrderFormsizes from "../../transaction/order/order-formsizes";
import ServiceReportOrder from "../../../services/report-order";

class AccountEditComponent extends React.Component {
	constructor(props) {
		super(props);

		const {token} = props.match.params;
		const params = JSON.parse(atob(token));
		const userid = atob(params[DBCode.KEY_PARAM_USERID]);
		const orderid = atob(params[DBCode.KEY_PARAM_ORDERID]);
		const productid = atob(params[DBCode.KEY_PARAM_PRODUCTID]);

		this.state = {
			refreshing: true,
			title: 'Data Order',
			breadcrumbs: [
				{link: '#', menu: 'Orders'},
			],

			orderid: orderid,
			order: null,
			orders: null,
			datas: {
				customer: {
					action: 'read',
					data: null
				},
				userid: userid,
				productid: productid,
				productvarsize: {
					show: false,
				},
				productvarsizes: [],
				productvardetail: [],
				productvaracc: []
			},
			options: {
				swiper: {
					pagination: {
						el: '.swiper-pagination',
						clickable: true
					}
				}
			},
			modal: {
				show: false,
			}
		};

		this.serviceOrder = new ServiceReportOrder();

		this.fetchOrder();
	}
	fetchOrder() {
		const {orderid, breadcrumbs} = this.state;
		this.serviceOrder.fetch(orderid)
			.then(
				res => {
					breadcrumbs.push({ link: '#', menu: res.order && res.order.ordercd ? res.order.ordercd : null });
					if(res.success) {
						if(res.order.statusid === DBCode.STATUS_APPROVED) {
							this.setState({redirect: `${window.location.pathname}/view`});
						} else {
							this.setState({
								refreshing: false,
								order: res.order,
								orders: res.orders,
								datas: {
									indexType: res.indexType,
									indexVariant: res.indexVariant,
									orderdate: res.order.orderdate,
									estimatedate: new Date(res.order.estimatedate),
									customer: {
										action: 'read',
										data: res.order.customer,
									},
									orderid: res.order.orderid,
									productid: res.order.product.productid,
									product: res.order.product,
									producttypeid: res.order.producttype.producttypeid,
									producttype: res.order.producttype,
									productvarid: res.order.productvar.productvarid,
									productvar: res.order.productvar,
									productvardetail: res.order.orderDetail,
									productvaracc: res.order.orderAccessoris,
									productvarsize: {
										show: false,
									},
									productvarsizes: res.order.orderSizes,
								}
							})
						}
					} else {
						Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, res.message);
					}
				}
			)
	}
	isValid() {
		const {datas} = this.state;

		if(!datas.estimatedate) return true;

		if(!datas.customer.data) return true;
		else {
			if(!datas.customer.data.customername) return true;

			if(!datas.customer.data.address) return true;
		}

		if(!datas.producttype) return true;

		if(!datas.productvar) return true;

		var _disabled = datas.productvardetail.length === 0;
		datas.productvardetail.forEach((detail) => {
			if(!_disabled && (!detail.groupcolor || !detail.color || !detail.material)) {
				_disabled = true;
			}
		});

		datas.productvaracc.forEach((acc) => {
			if(!_disabled && (!acc.groupcolor || !acc.color)) {
				_disabled = true;
			}
		});

		if(_disabled) return true;

		var countChecked = 0;
		datas.productvarsizes.forEach(size => {
			if(size.checked) countChecked++;
		});

		if(countChecked === 0) return true;
	}
	isValidMaterial() {
		const {datas} = this.state;

		var _disabled = false;
		datas.productvardetail.forEach((detail) => {
			if(!_disabled && (!detail.groupcolor || !detail.color || !detail.material)) {
				_disabled = true;
			}
		});

		datas.productvaracc.forEach((acc) => {
			if(!_disabled && (!acc.groupcolor || !acc.color)) {
				_disabled = true;
			}
		});

		if(_disabled) return true;
	}
	onClickNewCustomer() {
		const {datas} = this.state;
		const {customer} = datas;
		this.setState({
			datas: {
				...datas,
				customer: {
					...customer,
					action: customer.action === 'read' ? 'create' : 'read',
					data: null
				}
			}
		})
	}
	onClickOpenFormsize() {
		const {datas} = this.state;
		const {productvarsize} = datas;
		this.setState({
			datas: {
				...datas,
				productvarsize: {
					...productvarsize,
					show: true,
				}
			}
		})
	}
	onClickCloseFormsize(params) {
		const {datas} = this.state;
		const {productvarsize} = datas;

		let state = {
			...datas,
			productvarsize: {
				...productvarsize,
				show: false,
			},
		};
		if(params !== null) state.productvarsizes = params;

		this.setState({
			datas: state
		});
	}
	onClickProcess() {
		const {datas, orderid} = this.state;
		datas._estimatedate = datas.estimatedate.toLocaleDateString();
		datas.userid = Helpers.sessUserid();

		this.setState({refreshing: true});
		this.serviceOrder.fetchEditProcess(orderid, datas)
			.then(
				res => {
					if(res.success) {
						Helpers.swalSuccess(DBMessage.FETCH_SUCCESS_TITLE, res.message)
							.then(
								() => {
									this.setState({
										refreshing: false,
										redirect: '/account/history',
									});
								}
							)
					} else {
						Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, res.message)
							.then(() => {
								this.setState({refreshing: false});
							})
					}
				}
			)
	}
	onClickRemoveSize(i) {
		const {refreshing, datas} = this.state;
		if(!refreshing) {
			const {productvarsizes} = datas;
			productvarsizes[i].checked = false;
			productvarsizes[i].qty = 0;

			this.setState({
				datas: {
					...datas,
					productvarsizes: productvarsizes
				}
			});
		}
	}
	onChangeData(name, value) {
		const {datas} = this.state;

		this.setState({
			datas: {
				...datas,
				[name]: value
			}
		});
	}
	onChangeSelectDetail(i, name, value) {
		const {datas} = this.state;
		const productvardetail = datas.productvardetail;
		if(productvardetail[i] === undefined) productvardetail[i] = {};

		productvardetail[i][name] = value;

		this.setState({
			datas: {
				...datas,
				productvardetail: productvardetail,
			}
		});
	}
	onChangeSelectAcc(i, name, value) {
		const {datas} = this.state;
		const productvaracc = datas.productvaracc;
		if(productvaracc[i] === undefined) productvaracc[i] = {};

		productvaracc[i][name] = value;

		this.setState({
			datas: {
				...datas,
				productvaracc: productvaracc,
			}
		});
	}
	renderReadCustomer() {
		const {refreshing, datas} = this.state;
		return (
			<div className={'form-group'}>
				<label htmlFor={'select-customer'}>Nama Customer</label>
				<Select2
					value={datas.customer.data}
					loadData={API_SELECT.customer}
					searchable={true}
					renderOption={item => {
						return (
							<div>
								<div><b>{item.customername}</b></div>
								<div><small>{item.phone}</small></div>
								<div><small>{item.email}</small></div>
								<div><small>{item.address}</small></div>
							</div>
						);
					}}
					renderField={item => {
						return (
							<div>
								<div><b>{item.customername}</b></div>
								<div><small>{item.phone}</small></div>
								<div><small>{item.email}</small></div>
								<div><small>{item.address}</small></div>
							</div>
						);
					}}
					onSelectedItem={item => {
						const {datas} = this.state;
						const {customer} = datas;
						this.setState({
							datas: {
								...datas,
								customer: {
									...customer,
									data: item
								}
							}
						})
					}}
					disabled={refreshing}
				/>
			</div>
		);
	}
	renderCreateCustomer() {
		const {refreshing, datas} = this.state;
		const {customer} = datas;
		const {data} = customer;
		return [
			<div className={'form-group'} key={'form-customername'}>
				<label htmlFor={'input-customer'}>Nama Customer</label>
				<input
					name={'customername'}
					type={'text'}
					className={'form-control'}
					placeholder={'Masukan nama customer'}
					onChange={this.onChangeFormCustomer.bind(this)}
					value={data ? data.customername : ''}
					disabled={refreshing}
				/>
			</div>,
			<div className={'form-group'} key={'form-phone'}>
				<label htmlFor={'input-phone'}>Telp Customer</label>
				<input
					name={'phone'}
					type={'text'}
					className={'form-control'}
					placeholder={'Masukan phone customer'}
					onChange={this.onChangeFormCustomer.bind(this)}
					value={data ? data.phone : ''}
					disabled={refreshing}
				/>
			</div>,
			<div className={'form-group'} key={'form-email'}>
				<label htmlFor={'input-email'}>Email Customer</label>
				<input
					name={'email'}
					type={'text'}
					className={'form-control'}
					placeholder={'Masukan email customer'}
					onChange={this.onChangeFormCustomer.bind(this)}
					value={data ? data.email : ''}
					disabled={refreshing}
				/>
			</div>,
			<div className={'form-group'} key={'form-address'}>
				<label htmlFor={'input-address'}>Alamat Customer</label>
				<textarea
					name={'address'}
					rows={5}
					className={'form-control'}
					placeholder={'Masukan alamat customer'}
					onChange={this.onChangeFormCustomer.bind(this)}
					value={data ? data.address : ''}
					disabled={refreshing}
				/>
			</div>,
			<div className={'form-group'} key={'form-npwp'}>
				<label htmlFor={'input-npwp'}>NPWP Customer</label>
				<input
					name={'npwp'}
					type={'text'}
					className={'form-control'}
					placeholder={'Masukan npwp customer'}
					onChange={this.onChangeFormCustomer.bind(this)}
					value={data ? data.npwp : ''}
					disabled={refreshing}
				/>
			</div>,
		];
	}
	__renderDetail(data, i) {
		const {refreshing, datas} = this.state;
		return (
			<div className={'form-group'} key={`partname-${i}`}>
				<label>{data.partname}</label>
				<div className={'row'} id={'select-color'}>
					<div className={'col'}>
						<Select2
							searchable={true}
							loadData={API_SELECT.material}
							getLabel={item => item.label}
							onSelectedItem={item => {
								this.onChangeSelectDetail(i, 'material', item);
							}}
							value={data ? data.material : null}
							disabled={refreshing}
						/>
					</div>
					<div className={'col'}>
						<Select2
							loadData={API_SELECT.groupColor}
							getLabel={item => item.label}
							onSelectedItem={item => {
								this.onChangeSelectDetail(i, 'groupcolor', item);
								this.onChangeSelectDetail(i, 'color', null);
							}}
							value={data.groupcolor !== undefined
								? data.groupcolor
								: {
									value: data._color.groupcolorid.typeid,
									label: data._color.groupcolorid.typename
								}
							}
							disabled={refreshing}
						/>
					</div>
					<div className={'col'}>
						<Select2
							searchable={true}
							maxWidth={'lg'}
							loadData={(inputValue, callback) => {
								let detail = datas.productvardetail[i];
								API_SELECT.colorByGroup(
									detail.groupcolor !== undefined
										? detail.groupcolor.value
										: detail._color.groupcolorid.typeid
									, inputValue, callback);
							}}
							getLabel={item => item.label}
							onSelectedItem={item => {
								this.onChangeSelectDetail(i, 'color', item);
							}}
							value={data && data.color ? data.color : null}
							setWrapperStyle={{
								marginLeft: 0,
								marginRight: 0,
							}}
							setWrapperClass={'row'}
							setItemStyle={{
								padding: 0
							}}
							setItemClass={'col-sm-6'}
							renderOption={(data, getLabel) => {
								return (
									<div className={'thumb-color'} style={{
										backgroundImage: `url(${data.file && data.file[0] ? data.file[0].file : ''})`,
										backgroundRepeat: 'no-repeat',
										backgroundPosition: 'center',
										backgroundSize: 'cover',
										backgroundColor: '#f1f1f1',
									}}  >
										<span className={'thumb-title'}>{getLabel(data)}</span>
										<div className={'color'}  style={{backgroundColor: data.colorcode}}/>
									</div>
								);
							}}
							renderField={(selectedItem, getLabel) => {
								return (
									<div className={'d-flex'}>
										<div className={'thumb-color sm mr-1'} style={{backgroundColor: selectedItem.colorcode}} />
										{getLabel(selectedItem)}
									</div>
								);
							}}
							disabled={refreshing}
						/>
					</div>
				</div>
			</div>
		)
	}
	renderDetail() {
		const {datas} = this.state;
		return (datas.productvardetail.length > 0)
			?  [
				<div className={'form-header'} key={'title-detail'}>
					<div className={'form-title'}>Detail Produk</div>
				</div>,
				<div className={'form-body'} style={{height: 'auto'}} key={'form-detail'}>
					{datas.productvardetail.map((detail, i) => this.__renderDetail(detail, i))}
				</div>
			]: null
	}
	__renderAccessoris(accessoris, i) {
		const {refreshing, datas} = this.state;
		return (
			<div className={'form-group'} key={`partname-${i}`}>
				<label htmlFor={'select-color'}>{accessoris.material.type.typename} - {accessoris.material.materialname}</label>
				<div className={'row'} id={'select-color'}>
					<div className={'col'}>
						<Select2
							searchable={true}
							loadData={API_SELECT.groupColor}
							getLabel={item => item.label}
							onSelectedItem={item => {
								this.onChangeSelectAcc(i, 'groupcolor', item);
								this.onChangeSelectAcc(i, 'color', null);
							}}
							value={accessoris.groupcolor !== undefined
								? accessoris.groupcolor
								: {
									value: accessoris._color.groupcolorid.typeid,
									label: accessoris._color.groupcolorid.typename
								}
							}
							disabled={refreshing}
						/>
					</div>
					<div className={'col'}>
						<Select2
							searchable={true}
							maxWidth={'lg'}
							loadData={(inputValue, callback) => {
								let accessoris = datas.productvaracc[i];
								API_SELECT.colorByGroup(
									accessoris.groupcolor !== undefined
										? accessoris.groupcolor.value
										: accessoris._color.groupcolorid.typeid
									, inputValue, callback);
							}}
							getLabel={item => item.label}
							onSelectedItem={item => {
								this.onChangeSelectAcc(i, 'color', item);
							}}
							value={accessoris && accessoris.color ? accessoris.color : null}
							setWrapperStyle={{
								marginLeft: 0,
								marginRight: 0,
							}}
							setWrapperClass={'row'}
							setItemStyle={{
								padding: 0
							}}
							setItemClass={'col-sm-6'}
							renderOption={(data, getLabel) => {
								return (
									<div className={'thumb-color'} style={{
										backgroundImage: `url(${data.file && data.file[0] ? data.file[0].file : ''})`,
										backgroundRepeat: 'no-repeat',
										backgroundPosition: 'center',
										backgroundSize: 'cover',
										backgroundColor: '#f1f1f1',
									}}  >
										<span className={'thumb-title'}>{getLabel(data)}</span>
										<div className={'color'}  style={{backgroundColor: data.colorcode}}/>
									</div>
								);
							}}
							renderField={(selectedItem, getLabel) => {
								return (
									<div className={'d-flex'}>
										<div className={'thumb-color sm mr-1'} style={{backgroundColor: selectedItem.colorcode}} />
										{getLabel(selectedItem)}
									</div>
								);
							}}
							disabled={refreshing}
						/>
					</div>
				</div>
			</div>
		);
	}
	renderAccessoris() {
		const {datas} = this.state;
		return (datas.productvaracc.length > 0)
			? [
				<div className={'form-header'} key={'title-accessoris'}>
					<div className={'form-title'}>Aksesoris Produk</div>
				</div>,
				<div className={'form-body'} style={{height: 'auto'}} key={'form-accessoris'}>
					{datas.productvaracc.map((accessoris, i) => this.__renderAccessoris(accessoris, i))}
				</div>,
			]
			: null
	}
	renderVarsize() {
		const {datas} = this.state;
		return [
			<div className={'form-group'} key={'variant-sizes'}>
				<label htmlFor={'list-productsize'}>List ukuran yang sudah ditambahkan :</label>
				<div className={'badge-wrapper'}>
					{
						datas.productvarsizes.map((size, i) => {
							if(size.checked) {
								return (
									<div className={'badge-item'} onClick={() => this.onClickRemoveSize(i)}>
										Ukuran: {size.sizename} ({size.qty} Pcs)
										<span className={'remove'}>&times;</span>
									</div>
								);
							}

							return null;
						})
					}
				</div>
			</div>
		];
	}
	render() {
		const {refreshing, redirect, title, breadcrumbs, menuPositions, datas, orders} = this.state;

		if(redirect) {
			return <Redirect to={redirect} />
		}

		let count = 0;
		datas.productvarsizes.forEach(size => {
			if(size.checked) count++;
		});

		return (
			<div className={DBStyles.content.wrapperIcon}>
				<Header />

				<Sidebar menuPosition={menuPositions} />

				<div className={'main-panel apps'}>
					<div className={'content'}>
						<div className={'page-inner'}>
							<PageHeader title={title} breadcrumbs={breadcrumbs} />

							<div className={'card'}>
								<div className={'card-body'}>
									<div className={'row'}>
										<div className={'col-md-8'}>
											<div className={'form-header'}>
												<div className={'form-title'}>Informasi Pemesanan</div>
											</div>
											<div className={'form-body'}>
												<div className={'form-body-title'}>Pesanan</div>
												<div className={'form-group'}>
													<div className={'row'}>
														<div className={'col-md-6'}>
															<label htmlFor={'select-date'}>Tanggal Pesanan</label>
															<input
																id={'select-orderdate'}
																type={'text'}
																className={'form-control'}
																placeholder={'DD / MM / YYYY'}
																disabled={true}
																value={datas.orderdate}
															/>
														</div>
														<div className={'col-md-6'}>
															<label htmlFor={'select-orderdate'}>Estimasi Pesanan</label>
															<DatePicker
																className={'form-control'}
																placeholderText={'DD / MM / YYYY'}
																selected={datas.estimatedate}
																dateFormat={'dd/MM/yyyy'}
																onChange={date => {
																	this.onChangeData('estimatedate', date);
																}}
																disabled={refreshing}
															/>
														</div>
													</div>
												</div>
												<div className={'form-body-title'}>
													Customer
													<div className={'form-action'}>
														<button
															className={`btn ${datas.customer.action === 'read' ? 'btn-primary' : 'btn-danger'} btn-xs`}
															onClick={this.onClickNewCustomer.bind(this)}
															disabled={refreshing}>
															{
																datas.customer.action === 'read'
																	? [
																		<i className={'fa fa-plus'} key={'icon-add-customer'} />,
																		<span key={'text-add-customer'}>Buat Baru</span>
																	]
																	: [
																		<i className={'fa fa-times'} key={'icon-add-customer'} />,
																		<span key={'text-add-customer'}>Batal</span>,
																	]
															}
														</button>
													</div>
												</div>
												{
													datas.customer.action === 'read'
														? this.renderReadCustomer()
														: this.renderCreateCustomer()
												}
												<div className={'form-body-title'}>Produk</div>
												<div className={'form-group'}>
													<label htmlFor={'select-producttype'}>Tipe</label>
													<Select2
														items={orders ? orders.types : []}
														getLabel={item => item.type.typename}
														value={datas.producttype ? datas.producttype : null}
														onSelectedItem={item => {

															const {datas} = this.state;
															this.setState({
																datas: {
																	...datas,
																	indexType: item.indexType,
																	indexVariant: null,
																	producttype: item,
																	producttypeid: item.producttypeid,
																	productmaterialid: 0,
																	productmaterial: null,
																	productvarid: 0,
																	productvar: null,
																	productvardetail: [],
																	productsizes: [],
																}
															});
														}}
														disabled={refreshing}
													/>
												</div>
												<div className={'form-group'}>
													<label htmlFor={'select-variant'}>Variant</label>
													<Select2
														items={orders && datas.indexType !== null ? orders.types[datas.indexType].variant : []}
														getLabel={item => item.productvar}
														onSelectedItem={item => {
															const {datas} = this.state;
															this.setState({
																productvarid: item.productvarid,
																datas: {
																	...datas,
																	indexVariant: item.indexVariant,
																	productvarid: item.productvarid,
																	productvar: item,
																	productvardetail: [],
																	productsizes: item.detail,
																}
															})
														}}
														value={datas.productvar}
														disabled={refreshing}
													/>
												</div>
												{
													datas.producttype && datas.productvar
														? this.renderDetail()
														: null
												}
												{
													datas.producttype && datas.productvar
														? this.renderAccessoris()
														: null
												}
											</div>
										</div>
										<div className={'col-md-4'}>
											<div className={'section-form'}>
												<div className={'form-header'}>
													<div className={'form-title'}>Informasi Produk</div>
												</div>
												<div className={'form-body'}>
													<div className={'info-product process-product'}>
														<div className={'tumb-image'} style={{backgroundImage: `url(${
																datas.productvar
																	? datas.productvar.image
																	: orders ? orders.noImage : ''
															})`}} />
														<div className={'info-product-content'}>
															<div className={'product-title'}>
																{orders ? orders.productname + ' ' : null}
																({
																datas.producttype
																	? datas.producttype.type.typename
																	: null
															})
															</div>
															<div className={'product-text'}>
																{
																	datas.producttype
																		? 'Tipe Ukuran : ' + datas.producttype.groupsize.typename
																		:  	[
																			<span key={'sizegroup-label'} className={'product-text'}>Size Group : </span>,
																			<span key={'sizegroup-value'} className={'product-text secondary'}>Belum Dipilih</span>,
																		]
																}
															</div>
															<div className={'product-text'}>
																{
																	datas.productvar
																		? 'Varian Produk : ' + datas.productvar.productvar
																		:  	[
																			<span key={'variant-label'} className={'product-text'}>Variant Produk : </span>,
																			<span key={'variant-value'} className={'product-text secondary'}>Belum Dipilih</span>,
																		]
																}
															</div>
														</div>
													</div>
													<button
														className={'btn btn-secondary btn-block mb-2'}
														onClick={this.onClickOpenFormsize.bind(this)}
														disabled={this.isValidMaterial() || refreshing}>
														Pilih ukuran produk ({count})
													</button>
													{
														datas.productvarsizes.length > 0
															? this.renderVarsize()
															: null
													}
													<OrderFormsizes
														show={datas.productvarsize.show}
														datas={datas}
														onClose={productvarsizes => this.onClickCloseFormsize(productvarsizes)}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className={'card-footer apps'}>
									<button
										className={'btn btn-primary btn-block'}
										onClick={this.onClickProcess.bind(this)}
										disabled={this.isValid() || refreshing}
									>
										<i className={'fas fa-save'} />
										<span>{refreshing ? 'Sedang Memproses ...' : 'Simpan Pesanan'}</span>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default AccountEditComponent;
