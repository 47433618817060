import React from "react";
import {DBStyles, DBText} from "../../../module/constants";
import {Button, Modal} from "react-bootstrap";
import AsyncSelect from "react-select/async";

import ServiceMasterVariantDetail from "../../../services/master-variant-detail";
import {API_SELECT} from "../../../module/core";

class VariantFormDetail extends React.Component {
	constructor(props) {
		super(props);

		const {productid} = props.datas;

		this.state = {
			productvarsizeid: 0,
			productid: productid,
			disabled: false,
			datas: {
				productvar: null,
				color: null,
				size: null,
				partname: '',
				kg: '',
				yard: '',
				meter: '',
			}
		};
		this.defaultDatas = this.state.datas;

		this.service = new ServiceMasterVariantDetail(productid);
	}
	onShow() {

	}
	onChangeSelect(option, select) {
		const {datas} = this.state;
		this.setState({
			datas: {
				...datas,
				[select.name]: option
			}
		});
	}
	render() {
		const {productid, disabled, datas} = this.state;
		const {show, onHide} = this.props;
		return (
			<Modal show={show} onShow={this.onShow.bind(this)} onHide={onHide}>
				<Modal.Header closeButton>
					<Modal.Title>Form Variant Detail</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form>
						<div className={"form-group"}>
							<label htmlFor={"select-variant"}>Varian Produk</label>
							<AsyncSelect
								cacheOptions
								defaultOptions
								name={"producttype"}
								placeholder={'Pilih tipe produk'}
								loadOptions={(inputValue, callback) => {
									API_SELECT.productVariant(productid, inputValue, callback);
								}}
								onChange={this.onChangeSelect.bind(this)}
								value={datas.producttype}
								isDisabled={disabled}
							/>
						</div>
					</form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant={"secondary"} size={"sm"} onClick={onHide}>
						<i className={DBStyles.icon.cancel} />
						<span>{DBText.button.cancel}</span>
					</Button>
					<Button variant={"primary"} size={"sm"}>
						<i className={DBStyles.icon.save} />
						<span>{DBText.button.save}</span>
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default VariantFormDetail;
