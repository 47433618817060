import React from "react";
import {RouteAuth} from "../module/routes/route-auth";
import AccountComponent from "../app/account/home/account";
import AccountHistoryComponent from "../app/account/home/account-history";
import AccountHistoryDetailComponent from "../app/account/home/account-history-detail";
import AccountEditComponent from "../app/account/home/account-edit";

const routes_account = [
	<RouteAuth exact={true} path={'/account'} component={AccountHistoryComponent} key={'account'} />,
	<RouteAuth path={'/account/history/:token'} component={AccountHistoryDetailComponent} key={'account-history-detail'} />,
	<RouteAuth path={'/account/history'} component={AccountHistoryComponent} key={'account-history'} />,
	<RouteAuth path={'/account/edit/:token'} component={AccountEditComponent} key={'account-eidt'} />,
];

export default routes_account;
