import React from "react";
import {DBCode, DBMessage, DBStyles} from "../../../module/constants";
import Header from "../../skin/header";
import Sidebar from "../../skin/sidebar";
import PageHeader from "../../skin/pageheader";
import ServiceTransactionOrder from "../../../services/transaction-order";
import {API_SELECT, Helpers} from "../../../module/core";
import Select2 from "../../components/select2";
import OrderFormsizes from "./order-formsizes";
import {Redirect} from "react-router";
import DatePicker from "react-datepicker/es";

import "react-datepicker/dist/react-datepicker.css";

const localStorage = require('local-storage');

class OrderProcessComponent extends React.Component {
	constructor(props) {
		super(props);

		const {token} = props.match.params;
		const params = JSON.parse(atob(token));

		const userid = params[DBCode.KEY_PARAM_USERID];
		const productid = params[DBCode.KEY_PARAM_PRODUCTID];
		const producttypeid = params[DBCode.KEY_PARAM_PRODUCTTYPEID];
		const productvarid = params[DBCode.KEY_PARAM_PRODUCTVARID];

		this.state = {
			refreshing: true,
			title: 'Detail Product',
			breadcrumbs: [
				{link: '#', menu: 'Orders'},
			],
			menuPositions: ['Orders'],
			orders: {},
			datas: {
				customer: {
					action: 'read',
					data: null
				},
				indexType: null,
				indexVariant: null,
				orderdate: null,
				estimatedate: '',
				userid: userid,
				productid: productid,
				product: {productid: productid},
				producttypeid: producttypeid,
				producttype: null,
				productmaterialid: null,
				productmaterial: null,
				productvarid: productvarid,
				productvar: null,
				productvardetail: [],
				productvaracc: [],
				productvarsize: {
					show: false,
				},
				productvarsizes: [],
			},
			selects: {
				groupcolors: [],
				customers: [],
			},
		};

		this.serviceOrder = new ServiceTransactionOrder();

		const orders = localStorage.get(DBCode.KEY_STORAGE_ORDERS);
		if(orders) {
			const params = JSON.parse(orders);
			params.datas.estimatedate = new Date(params.datas.estimatedate);

			this.state = params;
		} else {
			this.fetchDataOrder();
		}
	}
	fetchDataOrder() {
		const {datas} = this.state;

		this.serviceOrder.fetch(datas)
			.then(
				res => {
					if(res.success) {
						this.setState({
							refreshing: false,
							orders: res.order,
							datas: {
								...datas,
								indexType: res.indexType,
								indexVariant: res.indexVariant,
								orderdate: res.orderdate,
								estimatedate: res.estimatedate,
								producttype: res.producttype,
								productmaterialid: res.producttype.material ? res.producttype.material.material.materialid : null,
								productmaterial: res.producttype.material
									? {label: res.producttype.material.material.materialname, value: res.producttype.material.material.materialid}
									: null,
								productvar: res.productvar,
								productvardetail: res.productvar.detail,
								productvaracc: res.productvar.accessoris,
								productvarsizes: res.productvarsizes
							},
							selects: res.selects
						})
					}
				}
			)
	}
	updateStock() {
		const {datas} = this.state;
		const {productvarsizes} = datas;

		productvarsizes.forEach((size, i) => {
			for(let j = 0; j < size.count; j++) {
				const data = size['data'+j];
				data.used = size.qty * data.need;

				if(i > 0) {
					const last = productvarsizes[i-1]['data'+j];
					const newavailable = last.avaialable - last.used;
					data.avaialable = newavailable;
					data.estimate = Math.floor(newavailable/data.need);

					const sizelast = productvarsizes[i - 1];
					size.args.lastqty = sizelast.qty ? sizelast.qty : 0;
				}
			}

			let qty = null;
			for(let j = 0; j < size.count; j++) {
				const data = size['data' + j];
				qty = ((data.estimate > 0 && data.estimate < qty) || qty === null) ? data.estimate : qty;
			}
			size.args.need = qty > 0 ? qty : 0;
		});
	}
	isValid() {
		const {datas} = this.state;

		if(!datas.estimatedate) return true;

		if(!datas.customer.data) return true;
		else {
			if(!datas.customer.data.customername) return true;

			if(!datas.customer.data.address) return true;
		}

		if(!datas.producttype) return true;

		if(!datas.productvar) return true;

		var _disabled = datas.productvardetail.length === 0;
		datas.productvardetail.forEach((detail) => {
			if(!_disabled && (!detail.groupcolor || !detail.color || !detail.material)) {
				_disabled = true;
			}
		});

		datas.productvaracc.forEach((acc) => {
			if(!_disabled && (!acc.groupcolor || !acc.color)) {
				_disabled = true;
			}
		});

		if(_disabled) return true;

		var countChecked = 0;
		datas.productvarsizes.forEach(size => {
			if(size.checked) countChecked++;
		});

		if(countChecked === 0) return true;
	}
	isValidMaterial() {
		const {datas} = this.state;

		var _disabled = false;
		datas.productvardetail.forEach((detail) => {
			if(!_disabled && (!detail.groupcolor || !detail.color || !detail.material)) {
				_disabled = true;
			}
		});

		datas.productvaracc.forEach((acc) => {
			if(!_disabled && (!acc.groupcolor || !acc.color)) {
				_disabled = true;
			}
		});

		if(_disabled) return true;
	}
	onChangeData(name, value) {
		const {datas} = this.state;

		this.setState({
			datas: {
				...datas,
				[name]: value
			}
		});
	}
	onChangeSelectDetail(i, name, value) {
		const {datas} = this.state;
		const productvardetail = datas.productvardetail;
		if(productvardetail[i] === undefined) productvardetail[i] = {};

		productvardetail[i][name] = value;

		this.setState({
			datas: {
				...datas,
				productvardetail: productvardetail,
			}
		});
	}
	onChangeSelectAcc(i, name, value) {
		const {datas} = this.state;
		const productvaracc = datas.productvaracc;
		if(productvaracc[i] === undefined) productvaracc[i] = {};

		productvaracc[i][name] = value;

		this.setState({
			datas: {
				...datas,
				productvaracc: productvaracc,
			}
		});
	}
	onClickNewCustomer() {
		const {datas} = this.state;
		const {customer} = datas;
		this.setState({
			datas: {
				...datas,
				customer: {
					...customer,
					action: customer.action === 'read' ? 'create' : 'read',
					data: null
				}
			}
		})
	}
	onChangeFormCustomer(event) {
		const {datas} = this.state;
		const {customer} = datas;
		const {data} = customer;

		this.setState({
			datas: {
				...datas,
				customer: {
					...customer,
					data: {
						...data,
						[event.target.name]: event.target.value,
					}
				}
			}
		});
	}
	onClickOpenFormsize() {
		const {datas} = this.state;
		const {productvarsize} = datas;
		this.setState({
			datas: {
				...datas,
				productvarsize: {
					...productvarsize,
					show: true,
				}
			}
		})
	}
	onClickCloseFormsize(params) {
		const {datas} = this.state;
		const {productvarsize} = datas;

		let state = {
			...datas,
			productvarsize: {
				...productvarsize,
				show: false,
			},
		};
		if(params !== null) state.productvarsizes = params;

		this.setState({
			datas: state
		});
	}
	onClickRemoveSize(i) {
		const {refreshing, datas} = this.state;
		if(!refreshing) {
			const {productvarsizes} = datas;
			productvarsizes[i].checked = false;
			productvarsizes[i].qty = 0;

			this.setState({
				datas: {
					...datas,
					productvarsizes: productvarsizes
				}
			});
		}
	}
	onClickProcess(e) {
		e.preventDefault();
		const {datas} = this.state;
		datas._estimatedate = new Date(datas.estimatedate).toLocaleDateString();

		localStorage.set(DBCode.KEY_STORAGE_ORDERS, JSON.stringify(this.state));

		this.setState({refreshing: true});
		this.serviceOrder.fetchProcess(datas)
			.then(
				res => {
					if(res.success) {
						const token = {};
						token[DBCode.KEY_PARAM_ORDERID] = res.orderid;
						localStorage.remove(DBCode.KEY_STORAGE_ORDERS);
						this.setState({redirect: `/orders-complete/${btoa(JSON.stringify(token))}`});
					} else {
						Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, res.message)
							.then(() => {
								this.setState({refreshing: false});
							})
					}
				}
			)
	}
	__renderDetail(detail, i) {
		const {refreshing} = this.state;
		return (
			<div className={'form-group'} key={`partname-${i}`}>
				<label htmlFor={'select-color'} className={'label-required'}>{detail.partname}</label>
				<div className={'row'} id={'select-color'}>
					<div className={'col'}>
						<Select2
							searchable={true}
							loadData={API_SELECT.material}
							getLabel={item => item.label}
							onSelectedItem={item => {
								this.onChangeSelectDetail(i, 'material', item);
							}}
							value={detail ? detail.material : null}
							disabled={refreshing}
						/>
					</div>
					<div className={'col'}>
						<Select2
							searchable={true}
							loadData={API_SELECT.groupColor}
							getLabel={item => item.label}
							onSelectedItem={item => {
								this.onChangeSelectDetail(i, 'groupcolor', item);
								this.onChangeSelectDetail(i, 'color', null);
							}}
							value={detail ? detail.groupcolor : null}
							disabled={refreshing}
						/>
					</div>
					<div className={'col'}>
						<Select2
							searchable={true}
							maxWidth={'lg'}
							loadData={(inputValue, callback) => {
								const {datas} = this.state;
								const detail = datas.productvardetail[i];
								API_SELECT.colorByGroupDetail(
									'detail',
									detail.productvarid,
									detail.partname,
									detail && detail.material ? detail.material.value : 0,
									detail && detail.groupcolor ? detail.groupcolor.value : 0,
									inputValue,
									callback
								);
							}}
							getLabel={item => item.label}
							onSelectedItem={item => {
								this.onChangeSelectDetail(i, 'color', item);
							}}
							value={detail ? detail.color : null}
							setWrapperStyle={{
								paddingLeft: 10,
								paddingRight: 5,
							}}
							setWrapperClass={'row'}
							setItemStyle={{
								padding: 0
							}}
							setItemClass={'col-sm-6'}
							renderOption={(data, getLabel) => {
								return (
									<div className={'thumb-color'} style={{
										backgroundImage: `url(${data.file && data.file[0] ? data.file[0].file : ''})`,
										backgroundRepeat: 'no-repeat',
										backgroundPosition: 'center',
										backgroundSize: 'cover',
										backgroundColor: '#f1f1f1',
									}}  >
										<span className={'thumb-title'}>{getLabel(data)}</span>
										<div className={'color'}  style={{backgroundColor: data.colorcode}}/>
									</div>
								);
							}}
							renderField={(selectedItem, getLabel) => {
								return (
									<div className={'d-flex'}>
										<div className={'thumb-color sm mr-1'} style={{backgroundColor: selectedItem.colorcode}} />
										{getLabel(selectedItem)}
									</div>
								);
							}}
							disabled={refreshing}
						/>
					</div>
				</div>
			</div>
		)
	}
	renderDetail() {
		const {datas} = this.state;
		return [
			<div className={'form-body-title'} key={'card-detail-title'}>Detail Produk</div>,
			datas.productvardetail.map((detail, i) => this.__renderDetail(detail, i)),
			datas.productvardetail.length === 0
				? (
					<i key={'detail-not-found'}>Tidak ditemukan detail product</i>
				)
				: null
		];
	}
	__renderAccessoris(accessoris, i) {
		const {refreshing} = this.state;
		return (
			<div className={'form-group'} key={'accessoris'}>
				<div className={'form-group'} key={`partname-${i}`}>
					<label htmlFor={'select-color'} className={'label-required'}>{accessoris.material.type.typename} - {accessoris.material.materialname}</label>
					<div className={'row'} id={'select-color'}>
						<div className={'col'}>
							<Select2
								searchable={true}
								loadData={API_SELECT.groupColor}
								getLabel={item => item.label}
								onSelectedItem={item => {
									this.onChangeSelectAcc(i, 'groupcolor', item);
									this.onChangeSelectAcc(i, 'color', null);
								}}
								value={accessoris ? accessoris.groupcolor : null}
								disabled={refreshing}
							/>
						</div>
						<div className={'col'}>
							<Select2
								searchable={true}
								maxWidth={'lg'}
								loadData={(inputValue, callback) => {
									const {datas} = this.state;
									const detail = datas.productvaracc[i];
									console.log(detail);
									API_SELECT.colorByGroupDetail(
										'accessoris',
										detail.productvarid,
										detail.partname,
										detail && detail.material ? detail.materialid : 0,
										detail && detail.groupcolor ? detail.groupcolor.value : 0,
										inputValue,
										callback
									);
								}}
								getLabel={item => item.label}
								onSelectedItem={item => {
									this.onChangeSelectAcc(i, 'color', item);
								}}
								value={accessoris ? accessoris.color : null}
								setWrapperStyle={{
									paddingLeft: 10,
									paddingRight: 5,
								}}
								setWrapperClass={'row'}
								setItemStyle={{
									padding: 0
								}}
								setItemClass={'col-sm-6'}
								renderOption={(data, getLabel) => {
									return (
										<div className={'thumb-color'} style={{
											backgroundImage: `url(${data.file && data.file[0] ? data.file[0].file : ''})`,
											backgroundRepeat: 'no-repeat',
											backgroundPosition: 'center',
											backgroundSize: 'cover',
											backgroundColor: '#f1f1f1',
										}}  >
											<span className={'thumb-title'}>{getLabel(data)}</span>
											<div className={'color'}  style={{backgroundColor: data.colorcode}}/>
										</div>
									);
								}}
								renderField={(selectedItem, getLabel) => {
									return (
										<div className={'d-flex'}>
											<div className={'thumb-color sm mr-1'} style={{backgroundColor: selectedItem.colorcode}} />
											{getLabel(selectedItem)}
										</div>
									);
								}}
								disabled={refreshing}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
	renderAccessoris() {
		const {datas} = this.state;
		return (
			datas.productvaracc.length <= 0 ? null : [
				<div className={'form-body-title'} key={'card-accessoris-title'}>Aksesoris Produk</div>,
				datas.productvaracc.map((accessoris, i) => this.__renderAccessoris(accessoris, i)),
			]
		)
	}
	renderVarsize() {
		const {datas} = this.state;
		return [
			<div className={'form-group'} key={'variant-sizes'}>
				<label htmlFor={'list-productsize'}>List ukuran yang sudah ditambahkan :</label>
				<div className={'badge-wrapper'}>
					{
						datas.productvarsizes.map((size, i) => {
							if(size.checked) {
								return (
									<div className={'badge-item'} onClick={() => this.onClickRemoveSize(i)}>
										Ukuran: {size.sizename} ({size.qty} Pcs)
										<span className={'remove'}>&times;</span>
									</div>
								);
							}

							return null;
						})
					}
				</div>
			</div>
		];
	}
	renderReadCustomer() {
		const {refreshing, datas} = this.state;
		return (
			<div className={'form-group'}>
				<label htmlFor={'select-customer'} className={'label-required'}>Nama Customer</label>
				<Select2
					value={datas.customer.data}
					loadData={API_SELECT.customer}
					searchable={true}
					renderOption={item => {
						return (
							<div>
								<div><b>{item.customername}</b></div>
								<div><small>{item.phone}</small></div>
								<div><small>{item.email}</small></div>
								<div><small>{item.address}</small></div>
							</div>
						);
					}}
					renderField={item => {
						return (
							<div>
								<div><b>{item.customername}</b></div>
								<div><small>{item.phone}</small></div>
								<div><small>{item.email}</small></div>
								<div><small>{item.address}</small></div>
							</div>
						);
					}}
					onSelectedItem={item => {
						const {datas} = this.state;
						const {customer} = datas;
						this.setState({
							datas: {
								...datas,
								customer: {
									...customer,
									data: item
								}
							}
						})
					}}
					disabled={refreshing}
				/>
			</div>
		);
	}
	renderCreateCustomer() {
		const {refreshing, datas} = this.state;
		const {customer} = datas;
		const {data} = customer;
		return [
			<div className={'form-group'} key={'form-customername'}>
				<label htmlFor={'input-customer'} className={'label-required'}>Nama Customer</label>
				<input
					name={'customername'}
					type={'text'}
					className={'form-control'}
					placeholder={'Masukan nama customer'}
					onChange={this.onChangeFormCustomer.bind(this)}
					value={data ? data.customername : ''}
					disabled={refreshing}
				/>
			</div>,
			<div className={'form-group'} key={'form-phone'}>
				<label htmlFor={'input-phone'}>Telp Customer</label>
				<input
					name={'phone'}
					type={'text'}
					className={'form-control'}
					placeholder={'Masukan phone customer (optional)'}
					onChange={this.onChangeFormCustomer.bind(this)}
					value={data ? data.phone : ''}
					disabled={refreshing}
				/>
			</div>,
			<div className={'form-group'} key={'form-email'}>
				<label htmlFor={'input-email'}>Email Customer</label>
				<input
					name={'email'}
					type={'text'}
					className={'form-control'}
					placeholder={'Masukan email customer (optional)'}
					onChange={this.onChangeFormCustomer.bind(this)}
					value={data ? data.email : ''}
					disabled={refreshing}
				/>
			</div>,
			<div className={'form-group'} key={'form-address'}>
				<label htmlFor={'input-address'} className={'label-required'}>Alamat Customer</label>
				<textarea
					name={'address'}
					rows={5}
					className={'form-control'}
					placeholder={'Masukan alamat customer'}
					onChange={this.onChangeFormCustomer.bind(this)}
					value={data ? data.address : ''}
					disabled={refreshing}
				/>
			</div>,
			<div className={'form-group'} key={'form-npwp'}>
				<label htmlFor={'input-npwp'}>NPWP Customer</label>
				<input
					name={'npwp'}
					type={'text'}
					className={'form-control'}
					placeholder={'Masukan npwp customer (optional)'}
					onChange={this.onChangeFormCustomer.bind(this)}
					value={data ? data.npwp : ''}
					disabled={refreshing}
				/>
			</div>,
		];
	}
	render() {
		const {refreshing, title, redirect, breadcrumbs, menuPositions, orders, datas} = this.state;

		if(redirect) {
			return <Redirect to={redirect} />
		}

		let count = 0;
		datas.productvarsizes.forEach(size => {
			if(size.checked) count++;
		});
		return (
			<div className={DBStyles.content.wrapperIcon}>
				<Header />

				<Sidebar menuPosition={menuPositions} />

				<div className={'main-panel apps'}>
					<div className={'content'}>
						<div className={'page-inner'}>

							<PageHeader title={title} breadcrumbs={breadcrumbs} />

							<form onSubmit={this.onClickProcess.bind(this)}>
								<div className={'card'}>
									<div className={'card-body'}>
										<div className={'row'}>
											<div className={'col-md-8'}>
												<div className={'section-form'}>
													<div className={'form-header'}>
														<div className={'form-title'}>Informasi Pemesanan</div>
													</div>
													<div className={'form-body'}>
														<div className={'form-body-title'}>Pesanan</div>
														<div className={'form-group'}>
															<div className={'row'}>
																<div className={'col-md-6'}>
																	<label htmlFor={'select-date'} className={'label-required'}>Tanggal Pesanan</label>
																	<input
																		id={'select-orderdate'}
																		type={'text'}
																		className={'form-control'}
																		placeholder={'DD / MM / YYYY'}
																		disabled={true}
																		value={datas.orderdate}
																	/>
																</div>
																<div className={'col-md-6'}>
																	<label htmlFor={'select-orderdate'} className={'label-required'}>Estimasi Pesanan</label>
																	<DatePicker
																		className={'form-control'}
																		placeholderText={'DD / MM / YYYY'}
																		selected={datas.estimatedate}
																		dateFormat={'dd/MM/yyyy'}
																		onChange={date => {
																			this.onChangeData('estimatedate', date);
																		}}
																		required={true}
																		disabled={refreshing}
																	/>
																</div>
															</div>
														</div>
														<div className={'form-body-title'}>
															Customer
															<div className={'form-action'}>
																<button
																	type={'button'}
																	className={`btn ${datas.customer.action === 'read' ? 'btn-primary' : 'btn-danger'} btn-xs`}
																	onClick={this.onClickNewCustomer.bind(this)}
																	disabled={refreshing}
																>
																	{
																		datas.customer.action === 'read'
																			? [
																				<i className={'fa fa-plus'} key={'icon-add-customer'} />,
																				<span key={'text-add-customer'}>Buat Baru</span>
																			]
																			: [
																				<i className={'fa fa-times'} key={'icon-add-customer'} />,
																				<span key={'text-add-customer'}>Batal</span>,
																			]
																	}
																</button>
															</div>
														</div>
														{
															datas.customer.action === 'read'
																? this.renderReadCustomer()
																: this.renderCreateCustomer()
														}
														<div className={'form-body-title'}>Produk</div>
														<div className={'form-group'}>
															<label htmlFor={'select-producttype'} className={'label-required'}>Tipe</label>
															<Select2
																searchable={true}
																items={orders ? orders.types : []}
																getLabel={item => item.type.typename}
																value={datas.producttype ? datas.producttype : null}
																onSelectedItem={item => {

																	const {datas} = this.state;
																	this.setState({
																		datas: {
																			...datas,
																			indexType: item.indexType,
																			indexVariant: null,
																			producttype: item,
																			producttypeid: item.producttypeid,
																			productmaterialid: 0,
																			productmaterial: null,
																			productvarid: 0,
																			productvar: null,
																			productvardetail: [],
																			productsizes: [],
																		}
																	});
																}}
																disabled={refreshing}
															/>
														</div>
														<div className={'form-group'}>
															<label htmlFor={'select-productvariant'} className={'label-required'}>Variant</label>
															<Select2
																searchable={true}
																items={orders && datas.indexType !== null ? orders.types[datas.indexType].variant : []}
																getLabel={item => item.productvar}
																onSelectedItem={item => {
																	const {datas} = this.state;
																	this.setState({
																		productvarid: item.productvarid,
																		datas: {
																			...datas,
																			indexVariant: item.indexVariant,
																			productvarid: item.productvarid,
																			productvar: item,
																			productvardetail: item.detail,
																			productvaracc: item.accessoris,
																			productsizes: [],
																		}
																	})
																}}
																value={datas.productvar}
																disabled={refreshing}
															/>
														</div>
														{ this.renderDetail() }
														{ this.renderAccessoris() }
													</div>
												</div>
											</div>
											<div className={'col-md-4'}>
												<div className={'section-form'}>
													<div className={'form-header'}>
														<div className={'form-title'}>Informasi Produk</div>
													</div>
													<div className={'form-body'}>
														<div className={'info-product process-product'}>
															<div className={'tumb-image'} style={{backgroundImage: `url(${
																	datas.productvar
																		? datas.productvar.image
																		: orders ? orders.noImage : ''
																})`}} />
															<div className={'info-product-content'}>
																<div className={'product-title'}>
																	{orders ? orders.productname + ' ' : null}
																	({
																	datas.producttype
																		? datas.producttype.type.typename
																		: null
																})
																</div>
																<div className={'product-text'}>
																	{
																		datas.producttype
																			? 'Tipe Ukuran : ' + datas.producttype.groupsize.typename
																			:  	[
																				<span key={'sizegroup-label'} className={'product-text'}>Size Group : </span>,
																				<span key={'sizegroup-value'} className={'product-text secondary'}>Belum Dipilih</span>,
																			]
																	}
																</div>
																<div className={'product-text'}>
																	{
																		datas.productmaterial
																			? 'Jenis Bahan : ' + datas.productmaterial.label
																			:  	[
																				<span key={'material-label'} className={'product-text'}>Jenis Bahan : </span>,
																				<span key={'material-value'} className={'product-text secondary'}>Belum Dipilih</span>,
																			]
																	}
																</div>
																<div className={'product-text'}>
																	{
																		datas.productvar
																			? 'Varian Produk : ' + datas.productvar.productvar
																			:  	[
																				<span key={'variant-label'} className={'product-text'}>Variant Produk : </span>,
																				<span key={'variant-value'} className={'product-text secondary'}>Belum Dipilih</span>,
																			]
																	}
																</div>
															</div>
														</div>
														<button
															type={'button'}
															className={'btn btn-secondary btn-block mb-2'}
															onClick={this.onClickOpenFormsize.bind(this)}
															disabled={this.isValidMaterial() || refreshing}
														>
															Pilih ukuran produk ({count})
														</button>
														{
															datas.productvarsizes.length > 0
																? this.renderVarsize()
																: null
														}
														<OrderFormsizes
															show={datas.productvarsize.show}
															datas={datas}
															onClose={productvarsizes => this.onClickCloseFormsize(productvarsizes)}
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className={'card-footer apps'}>
										<button
											type={'submit'}
											className={'btn btn-primary btn-block'}
											disabled={this.isValid() || refreshing}>
											<i className={'fas fa-shopping-cart'} />
											<span>{refreshing ? 'Sedang Memproses ...' : 'Proses Pemesanan'}</span>
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default OrderProcessComponent;
