import React from "react";
import {DBCode, DBStyles} from "../../../module/constants";
import Header from "../../skin/header";
import Sidebar from "../../skin/sidebar";
import PageHeader from "../../skin/pageheader";
import Datatables from "../../components/datatables";
import {DATATABLES} from "./_config";
import $ from "jquery";
import {Redirect} from "react-router";
import {Helpers} from "../../../module/core";
import OrdersCanceled from "./orders-canceled";

class ReportOrdersComponent extends React.Component {
	constructor() {
		super();

		this.state = {
			actions: 'ready',
			title: 'Orders',
			breadcrumbs: [
				{link: '#', menu: 'Report'},
				{link: '#', menu: 'Orders'}
			],
			datatables: {
				fnRowCallback: (nRow, aData, iDisplayIndex, iDisplayIndexFull) => {
					const $row = $(nRow);
					const $detail = $row.find('[actions=detail]');
					$detail.click(this.onClickDetail.bind(this));
					const $canceled = $row.find('[actions=canceled]');
					$canceled.click(this.onClickCanceled.bind(this));
				}
			},
			modal: {
				show: false,
				datas: {}
			}
		};
	}
	onClickDetail(event) {
		const $clicked = $(event.currentTarget);
		const orderid = $clicked.data('id');
		const productid = $clicked.data('productid');
		const statusid = $clicked.data('statusid');

		const token = {};
		token[DBCode.KEY_PARAM_USERID] = btoa(Helpers.sessUserid());
		token[DBCode.KEY_PARAM_ORDERID] = orderid;
		token[DBCode.KEY_PARAM_PRODUCTID] = productid;

		if([DBCode.STATUS_APPROVED, DBCode.STATUS_CANCELED].includes(parseInt(atob(statusid)))) {
			this.setState({redirect: `/report/orders/${btoa(JSON.stringify(token))}/view`});
		} else {

			this.setState({redirect: `/report/orders/${btoa(JSON.stringify(token))}`});
		}
	}
	onClickCanceled(event) {
		const $clicked = $(event.currentTarget);
		const orderid = atob($clicked.data('id'));
		this.setState({
			modal: {
				show: true,
				datas: {
					orderid: orderid
				}
			},
			actions: 'cancel'
		});
	}
	onCloseCanceled() {
		this.setState({
			modal: {
				show: false,
				datas: {},
			}
		});
	}
	render() {
		const {redirect, actions, title, breadcrumbs, datatables, modal} = this.state;

		if(redirect) return <Redirect to={redirect} />;

		return (
			<div className={DBStyles.content.wrapperIcon}>

				<Header />

				<Sidebar />

				<div className={'main-panel apps'}>
					<div className={'content'}>
						<div className={'page-inner'}>
							<PageHeader title={title} breadcrumbs={breadcrumbs} />

							<div className={'card'}>
								<div className={'card-header'}>
									<div className={"card-title"}>Data {title}</div>
								</div>
								<div className={'card-body'}>
									<div className={'table-responsive'}>
										<Datatables
											actions={actions}
											columns={DATATABLES.columns}
											columnDefs={DATATABLES.columnDefs}
											serverSide={DATATABLES.serverSide}
											options={datatables}
										/>
									</div>
								</div>
							</div>

							<OrdersCanceled
								show={modal.show}
								datas={modal.datas}
								actions={actions}
								onHide={this.onClickCanceled.bind(this)}/>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default ReportOrdersComponent;
