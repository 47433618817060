import React from "react";
import {RouteAuth} from "../module/routes/route-auth";
import ReportOrdersComponent from "../app/report/orders/orders";
import ReportOrderDetailComponent from "../app/report/orders/orders-detail";
import ReportOrdersViewComponent from "../app/report/orders/orders-view";

const routes_report_orders = [
	<RouteAuth exact={true} path={'/report/orders'} component={ReportOrdersComponent} key={'report-orders'} />,
	<RouteAuth path={'/report/orders/:token/view'} component={ReportOrdersViewComponent} key={'report-orders-view'} />,
	<RouteAuth path={'/report/orders/:token'} component={ReportOrderDetailComponent} key={'report-orders-detail'} />,
];

export default routes_report_orders;
