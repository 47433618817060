import React from "react";
import {Button, Modal} from "react-bootstrap";
import {Helpers} from "../../../module/core";
import ServiceReportOrder from "../../../services/report-order";
import {DBMessage} from "../../../module/constants";
import {Redirect} from "react-router";

var moment = require('moment');

class OrdersConfirm extends React.Component {
	constructor() {
		super();

		this.state = {
			disabled: false,
			datas: null
		};

		this.orderService = new ServiceReportOrder();
	}
	onShow() {
		this.setState({
			datas: this.props.datas
		});
	}
	onChangeDetailUOM(name, value, key, kuom) {
		const {datas} = this.state;
		datas.productmaterials[key].uoms[kuom][name] = value;

		this.setState({datas: datas});
	}
	onChangeAccUOM(name, value, i, uomid) {
		const {datas} = this.state;
		datas.productvaracc[i].uoms[uomid][name] = value;

		this.setState({datas: datas});
	}
	onClickProcess() {
		const {datas} = this.state;
		datas.userid = Helpers.sessUserid();
		if(datas.estimatedate) datas._estimatedate = datas.estimatedate.toLocaleDateString();

		this.setState({refreshing: true});
		this.orderService.processApprove(datas.orderid, datas)
			.then(
				res => {
					if(res.success) {
						Helpers.swalSuccess(DBMessage.FETCH_SUCCESS_TITLE, res.message)
							.then(res => {
								if(res.value) {
									this.setState({
										refreshing: false,
										redirect: `${window.location.pathname}/view`
									});
								} else {
									Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, res.message)
										.then(() => {
											this.setState({
												refreshing: false,
											})
										});
								}
							})
					}
				}
			);
	}
	renderColors(datas) {
		return [
			'Warna Produk : ',
			datas.map((color, i) => {
				return i === 0 ? color.color.colorname : ',' + color.color.colorname;
			})
		]
	}
	renderTableDetail(datas) {
		return datas.map((detail, i) => {
			return [
				<tr key={`color-${i}`}>
					<td>{ i + 1}</td>
					<td>{detail.partname}</td>
					<td>{detail.material.materialname}</td>
					<td>{detail.groupcolor !== undefined ? detail.groupcolor.label : detail._color.groupcolorid.typename}</td>
					<td>{detail.color ? detail.color.label : detail._color.colorname}</td>
					<td>
						<div style={{backgroundColor: detail.color ? detail.color.colorcode : detail._color.colorcode, width: 30, height: 30}} />
					</td>
				</tr>,
			]
		});
	}
	renderDetail() {
		const {datas} = this.state;
		return (
			<div className={'col-md-12'}>
				<div className={'section-form h-auto'}>
					<div className={'form-header mb-1'}>
						<div className={'form-title mb-1'}>Detail</div>
					</div>
					<div className={'form-body h-auto'}>
						<table className={'table table-striped table-bordered small-padding'}>
							<thead>
							<tr>
								<th width={80}>No</th>
								<th>Partname</th>
								<th>Material</th>
								<th>Group Color</th>
								<th>Color</th>
								<th>Preview</th>
							</tr>
							</thead>
							<tbody>
							{
								datas && datas.productvardetail
									? this.renderTableDetail(datas.productvardetail)
									: null
							}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		);
	}
	renderTableAccessoris(datas) {
		return datas.map((acc, i) => {
			return [
				<tr key={`acc-${i}`}>
					<td>{ i + 1}</td>
					<td>{`${acc.material.type.typename} - ${acc.material.materialname}`}</td>
					<td>{acc.groupcolor !== undefined ? acc.groupcolor.label : acc._color.groupcolorid.typename}</td>
					<td>{acc.color ? acc.color.label : acc._color.colorname}</td>
					<td>
						<div style={{backgroundColor: acc.color ? acc.color.colorcode : acc._color.colorcode, width: 30, height: 30}} />
					</td>
				</tr>,
			]
		});
	}
	renderAccessoris() {
		const {datas} = this.state;
		return (
			<div className={'col-md-12'}>
				<div className={'section-form h-auto'}>
					<div className={'form-header mb-1'}>
						<div className={'form-title mb-1'}>Accessoris</div>
					</div>
					<div className={'form-body h-auto'}>
						<table className={'table table-striped table-bordered small-padding'}>
							<thead>
							<tr>
								<th width={80}>No</th>
								<th>Accessoris</th>
								<th>Group Color</th>
								<th>Color</th>
								<th>Preview</th>
							</tr>
							</thead>
							<tbody>
							{
								datas && datas.productvaracc
									? this.renderTableAccessoris(datas.productvaracc)
									: null
							}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		);
	}
	renderTableSize(productvarsizes) {
		const {datas} = this.state;
		return [
			productvarsizes.map((size, i) => {
				return (size.checked)
					? (
						<tr key={`size-${i}`}>
							<td>{ i + 1}</td>
							<td>{size.sizename}</td>
							<td>{size.qty}</td>
							<td>{size.newqty}</td>
							<td>
								<div className={'row'} style={{width: 400}}>
									{
										size.details.map((detail) => {
											return (
												<div className={'col-sm-6 mb-3'}>
													<b>{detail.partname} - {detail.material.materialname}</b>
													<br />
													{
														detail.uoms.map(uom => {
															let needTotal = parseFloat((uom.perItem * size.qtyapproved).toFixed(3));
															return (
																<div>
																	<p style={{lineHeight: 1.2,marginTop: 5}} className={'mb-0'}>
																		{uom.perItem} x {size.qtyapproved} = {needTotal} {uom.materialdt.uom.uomname}
																		<small style={{fontSize: 10}} className={'d-block mb-0'}><i>{uom.perItem} {uom.materialdt.uom.uomname.toLocaleLowerCase()}/item</i></small>
																	</p>
																</div>
															)
														})
													}
												</div>
											)
										})
									}
									{
										size.accessoris.map((acc) => {
											return (
												<div className={'col-sm-6 mb-3'}>
													<b>{acc.material.type.typename} - {acc.material.materialname}</b>
													<br />
													{
														acc.uoms.map(uom => {
															let needTotal = parseFloat((uom.perItem * size.qtyapproved).toFixed(3));
															return (
																<div>
																	<p style={{lineHeight: 1.2,marginTop: 5}} className={'mb-0'}>
																		{uom.perItem} x {size.qtyapproved} = {needTotal} {uom.materialdt.uom.uomname}
																		<small style={{fontSize: 10}} className={'d-block mb-0'}><i>{uom.perItem} {uom.materialdt.uom.uomname.toLocaleLowerCase()}/item</i></small>
																	</p>
																</div>
															)
														})
													}
												</div>
											)
										})
									}
								</div>
							</td>
						</tr>
					) : null;
			}),
			(
				<tr>
					<td colSpan={4}><b>Total</b></td>
					<td>
						<div className={'row'}>
							{
								datas.productvardetail.map((detail) => {
									return (
										<div className={'col-sm-6 mb-3'}>
											<b>{detail.partname} - {detail.material.materialname}</b>
											<br />
											{
												Object.keys(detail.uoms).map(kuom => {
													const uom = detail.uoms[kuom];
													return (
														<div>
															<p style={{lineHeight: 1.2,marginTop: 5}} className={'mb-0'}>
																Total : {parseFloat(uom.needTotal.toFixed(3))} {uom.uom.uomname}</p>
														</div>
													)
												})
											}
										</div>
									)
								})
							}
							{
								datas.productvaracc.map((acc) => {
									return (
										<div className={'col-sm-6 mb-3'}>
											<b>{acc.material.type.typename} - {acc.material.materialname}</b>
											<br />
											{
												Object.keys(acc.uoms).map(kuom => {
													const uom = acc.uoms[kuom];
													return (
														<div>
															<p style={{lineHeight: 1.2,marginTop: 5}} className={'mb-0'}>
																Total : {parseFloat(uom.needTotal.toFixed(3))} {uom.uom.uomname}</p>
														</div>
													)
												})
											}
										</div>
									)
								})
							}
						</div>
					</td>
				</tr>
			)
		];
	}
	renderSizes() {
		const {datas} = this.state;
		return (
			<div className={'col-md-12'}>
				<div className={'section-form h-auto'}>
					<div className={'form-header mb-1'}>
						<div className={'form-title mb-1'}>Data Ukuran</div>
					</div>
					<div className={'form-body h-auto'}>
						<div className={'table-responsive'}>
							<table className={'table table-striped table-bordered small-padding'}>
								<thead>
								<tr>
									<th width={80}>No</th>
									<th>Ukuran</th>
									<th width={80}>Jumlah Pesanan</th>
									<th width={80}>Jumlah Disetujui</th>
									<th width={150}>Perhitungan</th>
								</tr>
								</thead>
								<tbody>
								{
									datas && datas.productvarsizes
										? this.renderTableSize(datas.productvarsizes)
										: null
								}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		);
	}
	renderMaterials(datas) {
		const {refreshing} = this.state;
		return (
			<div className={'col-md-12'}>
				<div className={'section-form h-auto'}>
					<div className={'form-body h-auto'}>
						<div className={'table-responsive'}>
							<table className={'table table-striped table-bordered small-padding'}>
								<thead>
								<tr>
									<th width={80}>Stock Information</th>
								</tr>
								</thead>
								<tbody>
								{
									Object.keys(datas).map(key => {
										let data = datas[key];
										return (
											<tr>
												<td>
													<div>
														<h6 className={'text-bold'}>{data.material.materialname} - {data.color.colorname}</h6>
														<div className={'row'}>
															{
																Object.keys(data.uoms).map(kuom => {
																	let uom = data.uoms[kuom];
																	return (
																		<div className={'col-sm-4'}>
																			<label>{uom.uomname}</label>
																			<input
																				type={'text'}
																				className={'form-control'}
																				value={uom.usedTotal}
																				onChange={event => this.onChangeDetailUOM('usedTotal', event.target.value, key, kuom)}
																				disabled={refreshing}
																			/>
																			<small>Stock tersedia {parseFloat(uom.stockAvaiable.toFixed(3))} {uom.uomname}</small>
																		</div>
																	);
																})
															}
														</div>
													</div>
												</td>
											</tr>
										);
									})
								}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		);
	}
	render() {
		const {show, onHide} = this.props;
		const {refreshing, redirect, datas, disabled} = this.state;

		if(redirect) return <Redirect to={redirect} />;

		const images = datas && datas.productvar && datas.productvar.images[0]
			? datas.productvar.images[0].image
			: null;

		return (
			<Modal show={show} onHide={onHide} size={"lg"} onShow={this.onShow.bind(this)}>
				<Modal.Header closeButton>
					<Modal.Title>Konfirmasi Pesanan</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className={'row'}>
						<div className={'col-md-6'}>
							<div className={'section-form h-auto'}>
								<div className={'form-header mb-1'}>
									<div className={'form-title mb-1'}>Informasi Pesanan</div>
								</div>
								<div className={'form-body h-auto'}>
									<div className={'form-label'}>
										<b>Tanggal Pesanan : {datas ? datas.orderdate : null}</b>
										<br />
										<b>Estimate Pesanan : {datas ? moment(datas.estimatedate).format('DD/MM/YYYY') : null}</b>
									</div>
									{
										datas && datas.customer && datas.customer.data ?
											(
												<div className={'form-label'}>
													<b>{datas.customer.data.customername}</b>
													<p>{datas.customer.data.address}</p>
													<p>{datas.customer.data.phone}</p>
												</div>
											) : null
									}
								</div>
							</div>
						</div>
						<div className={'col-md-6'}>
							<div className={'section-form h-auto'}>
								<div className={'form-header mb-1'}>
									<div className={'form-title mb-1'}>Informasi Produk</div>
								</div>
								<div className={'form-body'}>
									<div className={'form-body'}>
										<div className={'info-product process-product'}>
											<div className={'tumb-image'} style={{backgroundImage: `url(${images})`}} />
											<div className={'info-product-content'}>
												<div className={'product-title'}>
													{datas && datas.product ? datas.product.productname + ' ' : null}
													({
														datas && datas.producttype
															? datas.producttype.type.typename
															: null
													})
												</div>
												<div className={'product-text'}>
													{
														datas && datas.producttype
															? 'Tipe Ukuran : ' + datas.producttype.groupsize.typename
															:  	[
																<span key={'sizegroup-label'} className={'product-text'}>Size Group : </span>,
																<span key={'sizegroup-value'} className={'product-text secondary'}>Belum Dipilih</span>,
															]
													}
												</div>
												<div className={'product-text'}>
													{
														datas && datas.productmaterial
															? 'Jenis Bahan : ' + datas.productmaterial.label
															:  	[
																<span key={'material-label'} className={'product-text'}>Jenis Bahan : </span>,
																<span key={'material-value'} className={'product-text secondary'}>Belum Dipilih</span>,
															]
													}
												</div>
												<div className={'product-text'}>
													{
														datas && datas.productvar
															? 'Varian Produk : ' + datas.productvar.productvar
															:  	[
																<span key={'variant-label'} className={'product-text'}>Variant Produk : </span>,
																<span key={'variant-value'} className={'product-text secondary'}>Belum Dipilih</span>,
															]
													}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						{
							datas && datas.productvardetail && datas.productvardetail.length > 0
								? this.renderDetail()
								: null
						}
						{
							datas && datas.productvaracc && datas.productvaracc.length > 0
								? this.renderAccessoris()
								: null
						}
						{
							datas && datas.productvarsizes && datas.productvarsizes.length > 0
								? this.renderSizes()
								: null
						}
						{
							datas && datas.productmaterials
								? this.renderMaterials(datas.productmaterials)
								: null
						}
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button type={"button"} variant="secondary" size={"sm"} onClick={onHide} disabled={refreshing}>
						Batal
					</Button>
					<Button type={"submit"} onClick={this.onClickProcess.bind(this)} variant="primary" size={"sm"} form={"form-product-type"} disabled={disabled}>
						{!refreshing ? 'Process' : 'Memproses ...'}
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default OrdersConfirm;
