import React from "react";
import {RouteAuth} from "../module/routes/route-auth";
import StockComponent from "../app/inventory/stock/stock";
import StockFormComponent from "../app/inventory/stock/stock-form";
import StockEditComponent from "../app/inventory/stock/stock-edit";

const routes_inventory_stock = [
	<RouteAuth exact={true} path={'/inventory/stock'} component={StockComponent} key={'inventory-stock'} />,
	<RouteAuth path={'/inventory/stock/create'} component={StockFormComponent} key={'inventory-stock-create'} />,
	<RouteAuth path={'/inventory/stock/edit/:token'} component={StockEditComponent} key={'invantory-stock-edit'} />,
];

export default routes_inventory_stock;
