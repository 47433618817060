import axios from 'axios';
import {Config, Helpers} from "../module/core";
import {DBMessage} from "../module/constants";

import $ from 'jquery';

class ServiceMasterColor {
	constructor() {
		this.api = `${Config.api}/colors`;
		this.datatables = `${this.api}/datatables`;
		this.fileupload = `${this.api}/upload`;
		this.select2Data = `${this.api}/select2Data`;
	}
	select(searchValue) {
		return axios.get(`${this.api}/select2Api`)
			.then(
				res => {
					return res.data;
				},
				error => {
					return {success: false, message: error};
				}
			)
			.catch(() => {
				Config.swalError(DBMessage.ERROR_NETWORK_TITLE, DBMessage.ERROR_NETWORK_MESSAGE);
			});
	}
	byGroup(groupid, searchValue) {
		return axios.get(`${this.api}/select2ByGroup`, {
			params: {
				searchValue: searchValue,
				groupid: groupid
			}
		})
			.then(
				res => {
					return res.data
				},
				error => {
					return {success: false, message: error};
				}
			)
			.catch(() => {
				Helpers.swalError(DBMessage.ERROR_NETWORK_TITLE, DBMessage.ERROR_NETWORK_MESSAGE);
			});
	}
	byGroupStock(from, productvarid, partname, materialid, groupid, searchValue) {
		return axios.get(`${this.api}/select2ByGroup`, {
			params: {
				from: from,
				searchValue: searchValue,
				groupid: groupid,
				productvarid: productvarid,
				partname: partname,
				materialid: materialid,
			}
		})
			.then(
				res => {
					return res.data
				},
				error => {
					return {success: false, message: error};
				}
			)
			.catch(() => {
				Helpers.swalError(DBMessage.ERROR_NETWORK_TITLE, DBMessage.ERROR_NETWORK_MESSAGE);
			});
	}
	fetch(id) {
		$('body').addClass('fetching-data');
		return axios.get(`${this.api}/${id}`)
			.then(
				res => {
					$('body').removeClass('fetching-data');
					return res.data;
				},
				error => {
					$('body').removeClass('fetching-data');
					return {success: false, message: error};
				}
			)
			.catch(() => {
				$('body').removeClass('fetching-data');
				Config.swalError(DBMessage.ERROR_NETWORK_TITLE, DBMessage.ERROR_NETWORK_MESSAGE);
			});
	}
	fetchStore(formData) {
		$('body').addClass('fetching-data');

		formData.append('userid', Helpers.sessUserid());

		return axios.post(`${this.api}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
			.then(
				res => {
					$('body').removeClass('fetching-data');
					return res.data;
				},
				error => {
					$('body').removeClass('fetching-data');
					return {success: false, message: error};
				}
			)
			.catch(() => {
				$('body').removeClass('fetching-data');
				Config.swalError(DBMessage.ERROR_NETWORK_TITLE, DBMessage.ERROR_NETWORK_MESSAGE);
			});
	}
	fetchUpdate(formDatas, id) {
		$('body').addClass('fetching-data');

		formDatas.append('userid', Helpers.sessUserid());

		return axios.post(`${this.api}/${id}`, formDatas, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
			.then(
				res => {
					$('body').removeClass('fetching-data');
					return res.data;
				},
				error => {
					$('body').removeClass('fetching-data');
					return {success: false, message: error};
				}
			)
			.catch(() => {
				$('body').removeClass('fetching-data');
				Config.swalError(DBMessage.ERROR_NETWORK_TITLE, DBMessage.ERROR_NETWORK_MESSAGE);
			});
	}
	fetchDelete(id) {
		$('body').addClass('fetching-data');
		return axios.delete(`${this.api}/${id}`)
			.then(
				res => {
					$('body').removeClass('fetching-data');
					return res.data;
				},
				error => {
					$('body').removeClass('fetching-data');
					return {success: false, message: error};
				}
			)
			.catch(() => {
				$('body').removeClass('fetching-data');
				Config.swalError(DBMessage.ERROR_NETWORK_TITLE, DBMessage.ERROR_NETWORK_MESSAGE);
			});
	}
	import(formData) {
		formData.append('userid', Helpers.sessUserid());
		return axios.post(`${this.api}/import`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
			.then(
				res => {
					return res.data;
				},
				error => {
					return {success: false, message: error}
				},
			)
			.catch(() => {
				$('body').removeClass('fetching-data');
				Config.swalError(DBMessage.ERROR_NETWORK_TITLE, DBMessage.ERROR_NETWORK_MESSAGE);
			});
	}
	export() {
		window.open(`${this.api}/export`, '_blank');
	}
}

export default ServiceMasterColor;
