import React from "react";
import {Button, Modal} from "react-bootstrap";

import ServiceMasterCustomer from "../../../services/master-customer";

import {Helpers} from "../../../module/core";
import {DBMessage} from "../../../module/constants";

class CustomerForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			datas: {
				customerid: 0,
				customername: '',
				address: '',
				phone: '',
				npwp: ''
			},
			actions: props.actions ? props.actions : 'ready',
			disabled: false,
		};
		this.defaultDatas = this.state.datas;

		this.service = new ServiceMasterCustomer();

	}

	onShow() {
		const {actions, datas} = this.props;
		if(actions === 'edit') {
			this.setState({ disabled: true });
			this.service.fetch(datas.id)
				.then(
					res => {
						if(res.success) {
							this.setState({
								datas: res.row,
								actions: 'edit',
								disabled: false,
							});
						} else {
							Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, res.message);
						}
					}
				)
		} else {
			this.setState({
				datas: this.defaultDatas,
				actions: actions,
			});
		}
	}

	onChangeHandler(event) {
		const {datas} = this.state;
		this.setState({
			datas: {
				...datas,
				[event.target.name]: event.target.value,
			}
		});
	}
	
	onSubmitHandler(event) {
		event.preventDefault();
		const {datas, actions} = this.state;
		const props = this.props;

		this.setState({disabled: true});

		if(actions === 'create') {
			this.service.fetchStore(datas)
				.then(
					res => {
						this.setState({disabled: false});
						if(res.success) {
							props.onHide();
							Helpers.swalSuccess(DBMessage.FETCH_SUCCESS_TITLE, res.message);
						} else {
							Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, res.message);
						}
					}
				)
				.catch(error => {
					this.setState({disabled: false});
				});
		} else if(actions === 'edit') {
			this.service.fetchUpdate(datas, datas.customerid)
				.then(
					res => {
						this.setState({disabled: false});
						if(res.success) {
							props.onHide();
							Helpers.swalSuccess(DBMessage.FETCH_SUCCESS_TITLE, res.message);
						} else {
							Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, res.message);
						}
					}
				)
				.catch(error => {
					this.setState({disabled: false});
				});
		}
	}
	render() {
		const props = this.props;
		const {disabled, datas} = this.state;
		return (
			<Modal {...props} onShow={this.onShow.bind(this)} onHide={props.onHide}>
				<Modal.Header closeButton>
					<Modal.Title>Form Customer</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form id={"form-group"} onSubmit={this.onSubmitHandler.bind(this)}>
						<div className={"form-group"}>
							<label htmlFor={"input-customername"}>Nama Customer</label>
							<input
								type={"text"}
								id={"input-customername"}
								className={"form-control"}
								name={"customername"}
								value={datas.customername}
								placeholder={"Masukan nama customer"}
								onChange={this.onChangeHandler.bind(this)}
								disabled={disabled}
								required={true}/>
						</div>
						<div className={"form-group"}>
							<label htmlFor={"input-npwp"}>No NPWP</label>
							<input
								type={"text"}
								id={"input-npwp"}
								className={"form-control"}
								name={"npwp"}
								value={datas.npwp}
								placeholder={"Masukan no npwp"}
								onChange={this.onChangeHandler.bind(this)}
								disabled={disabled}
								required={true}/>
						</div>
						<div className={"form-group"}>
							<label htmlFor={"input-phone"}>No Handphone</label>
							<input
								type={"number"}
								id={"input-phone"}
								className={"form-control"}
								name={"phone"}
								value={datas.phone}
								placeholder={"Masukan no handphone"}
								onChange={this.onChangeHandler.bind(this)}
								disabled={disabled}
								required={true}/>
						</div>
						<div className={"form-group"}>
							<label htmlFor={"input-address"}>Alamat</label>
							<textarea
								id={"input-address"}
								rows={"5"}
								className={"form-control"}
								name={"address"}
								value={datas.address}
								placeholder={"Masukan alamat"}
								onChange={this.onChangeHandler.bind(this)}
								disabled={disabled}
								required={true}/>
						</div>
					</form>
				</Modal.Body>
				<Modal.Footer>
					<Button type={"button"} variant="secondary" size={"sm"} onClick={props.onHide} disabled={disabled}>
						Batal
					</Button>
					<Button type={"submit"} variant="primary" size={"sm"} form={"form-group"} disabled={disabled}>
						{!disabled ? 'Simpan' : 'Memproses ...'}
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default CustomerForm;
