import React from "react";
import {DBCode, DBMessage, DBStyles} from "../../../module/constants";
import Header from "../../skin/header";
import Sidebar from "../../skin/sidebar";
import PageHeader from "../../skin/pageheader";
import {DATATABLES, RENDER_COMPONENTS, RENDER_PRODUCT} from "./_config";
import Datatables from "../../components/datatables";

import $ from 'jquery';
import ChooseColor from "./choose-color";
import {Button} from "react-bootstrap";
import {Link, Redirect} from "react-router-dom";
import DatePicker from "react-datepicker/es";
import ServiceTransactionStock from "../../../services/transaction-stock";
import {Helpers} from "../../../module/core";
import CancelOrders from "./cancel-orders";

class StockFormComponent extends  React.Component {
	componentDidMount() {
		RENDER_COMPONENTS.init();

		$('.select2-tipe').each((i, item) => {
			$(item).change(this.onChangeSelect.bind(this));
		});
		$('#select-order').change(this.onChangeOrder.bind(this));
	}
	componentDidUpdate(prevProps, prevState, snapshot) {
		RENDER_COMPONENTS.init();
	}

	constructor(props) {
		super(props);

		const params = new URLSearchParams(props.location.search);

		const order = params.get('orderid')
			? JSON.parse(atob(params.get('orderid')))
			: null;

		this.state = {
			actions: order ? 'cancel-order' : 'ready',
			refreshing: false,
			title: 'Form Stok',
			breadcrumbs: [
				{link: '#', menu: 'Inventory'},
				{link: '#', menu: 'Stok'},
				{link: '#', menu: 'Stok Baru'}
			],
			datas: {
				state: order ? 'admin' : 'superadmin',
				date: new Date(),
				type: order ? DBCode.TYPE_CANCELED_ORDER : 0,
				_type: order ? order.type : null,
				whfrom: order ? order.warehouse.whto.whid : null,
				whto: order ? order.warehouse.whfrom.whid : null,
				refno: order ? order.orderid : null,
				order: order,
				description: null,
				warehouse: order ? order.warehouse : null,
			},
			modal: {
				show: !!order,
				datas: {
					orderid: order ? order.orderid : null,
				}
			},
			datatables: {
				data: RENDER_PRODUCT.get(),
				initComplete: () => {
					let $wrapperFilter = $('#datatableform_products_filter');

					let $ButtonAdd = $('<button>', {
						type: 'button',
						class: 'btn btn-primary btn-sm ml-1'
					});
					$ButtonAdd.append($('<i>', {class: 'fa fa-plus'}));
					$ButtonAdd.append($('<span>').text('Tambah Produk'));
					$ButtonAdd.click(this.onClickAddData.bind(this));

					$wrapperFilter.append($ButtonAdd);
					$wrapperFilter.addClass('d-flex justify-content-end align-items-center');
				},
				fnRowCallback: (nRow, aData, iDisplayIndex, iDisplayIndexFull) => {
					let $row = $(nRow);
					let $TogglePress = $row.find('[data-toggle=keypress]');
					$TogglePress.on('keypress, keydown, keyup', this.onChangeInput.bind(this));
					let $ChooseColor = $row.find('[data-toggle=choose-color]');
					RENDER_PRODUCT.renderUpdate(nRow, RENDER_PRODUCT.get($ChooseColor.data('index')));
				},
			}
		};

		this.serviceStock = new ServiceTransactionStock();
	}
	onClickAddData(e) {
		RENDER_PRODUCT.add();
	}
	onChangeInput(e) {
		let $element = $(e.currentTarget);
		let index = parseInt($element.data('index'));
		let data = RENDER_PRODUCT.get(index);

		$element.get(0).onblur = function() {
			data[$element.data('name')] = $element.val();
			RENDER_PRODUCT.set(index, data);
		};
	}
	onChangeSelect(e) {
		const {datas} = this.state;
		this.setState({
			datas: {
				...datas,
				[e.target.name]: e.target.value,
			}
		});
	}
	onChangeText(e) {
		const {datas} = this.state;
		this.setState({
			datas: {
				...datas,
				[e.target.name]: e.target.value,
			}
		});
	}
	onChangeOrder(e) {
		const {datas} = this.state;
		if($.trim(e.target.value)) {
			this.setState({
				datas: {
					...datas,
					refno: e.target.value,
				},
				modal: {
					show: true,
					datas: {
						orderid: e.target.value,
					}
				},
				actions: 'cancel-order'
			})
		} else {
			this.setState({
				datas: {
					refno: null,
				},
			});
		}
	}
	onCloseCancelOrder(params) {
		const {datas} = this.state;

		if(params !== null) {
			datas.warehouse = params.warehouse;
			datas.refno = params.orderid;
			datas.whfrom = params.warehouse.whfrom.whid;
			datas.whto = params.warehouse.whto.whid;
			datas.productvarsizes = params.productvarsizes;

			RENDER_PRODUCT.datas = params.materials;
			RENDER_PRODUCT.render();
		}

		this.setState({
			modal: {
				show: false,
				datas: {},
			},
			actions: 'ready',
			datas: datas,
		});
	}
	onSubmitHandler(e) {
		e.preventDefault();

		RENDER_PRODUCT.setDisabled(true);
		this.setState({refreshing: true});

		const {datas} = this.state;
		datas.products = RENDER_PRODUCT.get();

		this.serviceStock.store(datas)
			.then(
				res => {
					if(res.success) {
						Helpers.swalSuccess(DBMessage.FETCH_SUCCESS_TITLE, res.message)
							.then(() => {
								this.setState({
									refreshing: false,
									redirect: '/inventory/stock'
								});
								RENDER_PRODUCT.setDisabled(false);
							});
					} else {
						Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, res.message)
							.then(() => {
								this.setState({
									refreshing: false,
								})
							});
					}
				}
			);
	}
	render() {
		const {redirect, refreshing, actions, title, breadcrumbs, datas, modal, datatables} = this.state;

		if(redirect) return <Redirect to={redirect} />;

		const typesWhto = [0, DBCode.TYPE_ADJUSTMENT_IN, DBCode.TYPE_PRODUCTION, DBCode.TYPE_CANCELED_ORDER, DBCode.TYPE_SALDO_AWAL];
		const typesWhfrom = [0, DBCode.TYPE_ADJUSTMENT_OUT, DBCode.TYPE_PRODUCTION, DBCode.TYPE_CANCELED_ORDER];

		return (
			<div className={DBStyles.content.wrapperIcon}>
				<Header />

				<Sidebar />

				<div className={'main-panel'}>
					<div className={'content'}>
						<div className={'page-inner'}>
							<PageHeader title={title} breadcrumbs={breadcrumbs} />

							<form onSubmit={this.onSubmitHandler.bind(this)}>
								<div className={'card'}>
									<div className={'card-header'}>
										<div className={'card-title'}>Informasi Stock</div>
										<div className={'card-actions'}>
											<Link className={'btn btn-danger btn-sm'} to={'/inventory/stock'} disabled={refreshing}>
												<i className={DBStyles.icon.back} />
												<span>Kembali</span>
											</Link>
											<Button className={'ml-1'} type={"submit"} variant={"primary"} size={"sm"} disabled={refreshing}>
												<i className={"fa fa-save"} />
												<span>Simpan</span>
											</Button>
										</div>
									</div>
									<div className={'card-body'}>
										<div className={'row'}>
											<div className={'col-md-6'}>
												<div className={'form-group'}>
													<label htmlFor={'input-nomor'}>Nomor</label>
													<input
														type={'text'}
														id={'input-nomor'}
														className={'form-control'}
														placeholder={'Nomor akan diisi otomatis oleh sistem'}
														required={true}
														disabled={true} />
												</div>
												<div className={'form-group'}>
													<label htmlFor={'select-tipe'}>Tipe</label>
													<select
														id={'select-tipe'}
														className={'form-control select2-tipe'}
														required={true}
														name={'type'}
														data-select-id={datas._type ? datas._type.typeid : null}
														data-select-text={datas._type ? datas._type.typename : null}
														disabled={refreshing || datas.state === 'admin'} />
												</div>
												<div className={`form-group ${([DBCode.TYPE_CANCELED_ORDER].includes(parseInt(datas.type))) ? 'd-none' : ''}`}>
													<label htmlFor={'input-refno'}>Refno</label>
													<input
														type={'text'}
														className={'form-control'}
														placeholder={'Refno'}
														name={'refno'}
														value={datas.refno}
														onChange={this.onChangeText.bind(this)}
														disabled={refreshing || datas.state === 'admin'} />
												</div>
												<div className={`form-group ${(![DBCode.TYPE_CANCELED_ORDER].includes(parseInt(datas.type))) ? 'd-none' : ''}`}>
													<label htmlFor={'input-refno'}>Nomor Order</label>
													<div className={'d-flex'}>
														<select
															id={'select-order'}
															className={'form-control select2-order'}
															data-select-id={datas.refno}
															data-select-text={datas.order ? datas.order.orderdate + ' - ' + datas.order.ordercd : null}
															required={([DBCode.TYPE_CANCELED_ORDER].includes(parseInt(datas.type)))}
															disabled={refreshing || datas.state === 'admin'} />
														<button
															type={'button'}
															className={'btn btn-primary btn-sm ml-1'}
															disabled={!datas.refno}
															onClick={() => this.onChangeOrder(
																{
																	target: {
																		value: datas.refno,
																	}
																})}>
															Tampilkan
														</button>
													</div>
												</div>
											</div>
											<div className={'col-md-6'}>
												<div className={'form-group'}>
													<label htmlFor={'input-date'}>Tanggal</label>
													<DatePicker
														className={'form-control'}
														placeholderText={'DD / MM / YYYY'}
														selected={new Date(datas.date)}
														dateFormat={'dd/MM/yyyy'}
														onChange={date => {
															this.onChangeText({
																target: {
																	name: 'date',
																	value: date,
																}
															});
														}}
														required={true}
														disabled={refreshing}
													/>
												</div>
												<div className={'form-group'}>
													<div className={'row'}>
														<div className={`col ${(!typesWhfrom.includes(parseInt(datas.type))) ? 'd-none' : ''}`}>
															<label htmlFor={'select-warehouse-from'}>Warehouse From</label>
															<select
																id={'select-warehouse-from'}
																className={'form-control select2-tipe'}
																name={'whfrom'}
																value={datas.whfrom}
																required={(typesWhfrom.includes(parseInt(datas.type)))}
																onChange={this.onChangeSelect.bind(this)}
																data-select-id={datas.warehouse ? datas.warehouse.whfrom.whid : null}
																data-select-text={datas.warehouse ? datas.warehouse.whfrom.whname : null}
																disabled={refreshing || datas.state === 'admin'} />
														</div>
														<div className={`col ${(!typesWhto.includes(parseInt(datas.type))) ? 'd-none' : ''}`}>
															<label htmlFor={'select-warehouse-to'}>Warehouse To</label>
															<select
																id={'select-warehouse-to'}
																className={'form-control select2-tipe'}
																name={'whto'}
																value={datas.whto}
																onChange={this.onChangeSelect.bind(this)}
																required={(typesWhto.includes(parseInt(datas.type)))}
																data-select-id={datas.warehouse ? datas.warehouse.whto.whid : null}
																data-select-text={datas.warehouse ? datas.warehouse.whto.whname : null}
																disabled={refreshing || datas.state === 'admin'} />
														</div>
													</div>
												</div>
												<div className={'form-group'}>
													<label htmlFor={'input-description'}>Deskripsi</label>
													<textarea
														className={'form-control'}
														rows={5}
														placeholder={'Deskripsi'}
														name={'description'}
														value={datas.description}
														onChange={this.onChangeText.bind(this)}
														disabled={refreshing} />
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className={'card'}>
									<div className={'card-header'}>
										<div className={'card-title'}>Data Product</div>
									</div>
									<div className={'card-body'}>
										<Datatables
											id={'datatableform_products'}
											actions={actions}
											columns={DATATABLES.columns}
											columnDefs={DATATABLES.columnDefs}
											options={datatables}/>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>

				<CancelOrders
					show={['cancel-order'].includes(actions) && modal.show}
					datas={modal.datas}
					onHide={this.onCloseCancelOrder.bind(this)} />
			</div>
		);
	}
}

export default StockFormComponent;
