import React from 'react';
import {DBStyles} from "../../module/constants";
import Header from "../skin/header";
import Sidebar from "../skin/sidebar";
import {Link} from "react-router-dom";

class PageNotFound extends React.Component {
	render() {
		return (
			<div className={DBStyles.content.wrapperIcon}>

				<Header />

				<Sidebar />

				<div className={'main-panel apps'}>
					<div className={'content'}>
						<div className={'page-inner'}>
							<div className={'page-not-found'}>
								<div className={'title-not-found'}>404</div>
								<div className={'subtitle-not-found'}>Halaman yang anda cari tidak ditemukan</div>
								<Link to={'/'}>
									<button className={'btn btn-primary btn-md'}>
										<i className={'fa fa-home'} />
										<span>Kembali ke halaman utama</span>
									</button>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default PageNotFound;
