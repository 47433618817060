import React from "react";
import {Button, Modal} from "react-bootstrap";
import AsyncSelect from 'react-select/async';

import ServiceMasterSize from "../../../services/master-size";

import {API_SELECT, Helpers} from "../../../module/core";
import {DBMessage} from "../../../module/constants";

class SizeForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			datas: {
				sizeid: 0,
				sizename: '',
				sizecode: '',
				sizegroupid: null,
			},
			actions: props.actions ? props.actions : 'ready',
			disabled: false,
		};
		this.defaultDatas = this.state.datas;

		this.service = new ServiceMasterSize();

	}
	onShow() {
		const {actions, datas} = this.props;
		if(actions === 'edit') {
			this.setState({ disabled: true });
			this.service.fetch(datas.id)
				.then(
					res => {
						if(res.success) {
							this.setState({
								datas: res.row,
								actions: 'edit',
								disabled: false,
							});
						}
					}
				)
		} else {
			this.setState({
				datas: this.defaultDatas,
				actions: actions,
			});
		}
	}
	onChangeHandler(event) {
		const {datas} = this.state;
		this.setState({
			datas: {
				...datas,
				[event.target.name]: event.target.value,
			}
		});
	}
	onChangeSelect(option, select) {
		const {datas} = this.state;
		this.setState({
			datas: {
				...datas,
				[select.name]: option
			}
		});
	}
	onSubmitHandler(event) {
		event.preventDefault();
		const {datas, actions} = this.state;
		const props = this.props;

		this.setState({disabled: true});

		if(actions === 'create') {
			this.service.fetchStore(datas)
				.then(
					res => {
						this.setState({disabled: false});
						if(res.success) {
							props.onHide();
							Helpers.swalSuccess(DBMessage.FETCH_SUCCESS_TITLE, res.message);
						} else {
							Helpers.swalSuccess(DBMessage.FETCH_FAIL_TITLE, res.message);
						}
					}
				)
				.catch(error => {
					this.setState({disabled: false});
				});
		} else if(actions === 'edit') {
			this.service.fetchUpdate(datas, datas.sizeid)
				.then(
					res => {
						this.setState({disabled: false});
						if(res.success) {
							props.onHide();
							Helpers.swalSuccess(DBMessage.FETCH_SUCCESS_TITLE, res.message);
						} else {
							Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, res.message);
						}
					}
				)
				.catch(error => {
					this.setState({disabled: false});
				});
		}
	}
	render() {
		const props = this.props;
		const {disabled, datas} = this.state;
		return (
			<Modal {...props} onShow={this.onShow.bind(this)} onHide={props.onHide}>
				<Modal.Header closeButton>
					<Modal.Title>Form Size</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form id={"form-group"} onSubmit={this.onSubmitHandler.bind(this)}>
						<div className={"form-group"}>
							<label htmlFor={"input-sizecode"}>Kode Size</label>
							<input
								type={"text"}
								id={"input-sizecode"}
								className={"form-control"}
								name={"sizecode"}
								value={datas.sizecode}
								placeholder={"Masukan kode size"}
								onChange={this.onChangeHandler.bind(this)}
								disabled={disabled}
								required={true}/>
						</div>
						
						<div className={"form-group"}>
							<label htmlFor={"input-sizename"}>Nama Size</label>
							<input
								type={"text"}
								id={"input-sizename"}
								className={"form-control"}
								name={"sizename"}
								value={datas.sizename}
								placeholder={"Masukan nama size"}
								onChange={this.onChangeHandler.bind(this)}
								disabled={disabled}
								required={true}/>
						</div>
						<div className={"form-group"}>
							<label htmlFor={"input-groupsize"}>Size Group</label>
							<AsyncSelect
								value={datas.sizegroupid}
								cacheOptions
								loadOptions={API_SELECT.groupSize}
								defaultOptions
								placeholder={'Pilih tipe group'}
								name={"sizegroupid"}
								onChange={this.onChangeSelect.bind(this)}
							/>
						</div>
					</form>
				</Modal.Body>
				<Modal.Footer>
					<Button type={"button"} variant="secondary" size={"sm"} onClick={props.onHide} disabled={disabled}>
						Batal
					</Button>
					<Button type={"submit"} variant="primary" size={"sm"} form={"form-group"} disabled={disabled}>
						{!disabled ? 'Simpan' : 'Memproses ...'}
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default SizeForm;
