import React from "react";
import {DBMessage, DBStyles} from "../../../module/constants";
import Header from "../../skin/header";
import Sidebar from "../../skin/sidebar";
import PageHeader from "../../skin/pageheader";
import {Button} from "react-bootstrap";
import {DATATABLES_DATA} from "./_config";
import Datatables from "../../components/datatables";

import $ from 'jquery';
import {Helpers} from "../../../module/core";
import ServiceTransactionStock from "../../../services/transaction-stock";
import FormImportModal from "./form-import";

class StockComponent extends React.Component {
	constructor() {
		super();

		this.state = {
			refreshing: false,
			actions: 'ready',
			title: 'Stock',
			breadcrumbs: [
				{link: '#', menu: 'Inventory'},
				{link: '#', menu: 'Stock'}
			],
			datatables: {
				fnRowCallback: (nRow) => {
					let $row = $(nRow);
					let $edit = $row.find('[actions=edit]');
					$edit.click(this.onClickEdit.bind(this));
					let $delete = $row.find('[actions=delete]');
					$delete.click(this.onClickDelete.bind(this));
				}
			},
			modal: {
				show: false,
				datas: null,
			}
		};

		this.service = new ServiceTransactionStock();
	}
	onClickImportStockAwal() {
		this.setState({
			actions: 'import-stock-awal',
			modal: {
				show: true,
			}
		});
	}
	onClickCreate() {
		this.setState({
			redirect: '/inventory/stock/create'
		});
	}
	onClickEdit(e) {
		let $clicked = $(e.currentTarget);
		let id = $clicked.data('id');

		let token = {
			id: id
		};

		this.setState({
			redirect: '/inventory/stock/edit/' + btoa(JSON.stringify(token))
		});
	}
	onClickDelete(event) {
		let $clicked = $(event.currentTarget);
		Helpers.swalConfirm(DBMessage.CONFIRM_DELETE_TITLE, DBMessage.CONFIRM_DELETE_MESSAGE)
			.then(
				result => {
					if(result.value) {
						this.service.delete($clicked.data('id'))
							.then(
								res => {
									if(res.success) {
										this.setState({
											actions: 'read'
										});
										Helpers.swalSuccess(DBMessage.FETCH_SUCCESS_TITLE, res.message);
									} else {
										Helpers.notifyError(DBMessage.FETCH_FAIL_TITLE, res.message)
									}
								}
							);
					}
				}
			);
	}
	render() {
		const {actions, title, breadcrumbs, redirect, datatables, modal} = this.state;

		if(redirect) window.location.href = redirect;

		return (
			<div className={DBStyles.content.wrapperIcon}>
				<Header />

				<Sidebar />

				<div className={'main-panel'}>
					<div className={'content'}>
						<div className={'page-inner'}>
							<PageHeader title={title} breadcrumbs={breadcrumbs} />

							<div className={'card'}>
								<div className={'card-header'}>
									<div className={'card-title'}>Data {title}</div>
									<Button className={'mr-1'} variant={"success"} size={"sm"} onClick={this.onClickImportStockAwal.bind(this)}>
										<i className={"fa fa-upload"} />
										<span>Import Stock Awal</span>
									</Button>
									<Button variant={"primary"} size={"sm"} onClick={this.onClickCreate.bind(this)}>
										<i className={"fa fa-plus"} />
										<span>Buat Baru</span>
									</Button>
								</div>
								<div className={'card-body'}>
									<div className={'table-responsive'}>
										<Datatables
											actions={actions}
											columns={DATATABLES_DATA.columns}
											columnDefs={DATATABLES_DATA.columnDefs}
											serverSide={DATATABLES_DATA.serverSide}
											options={datatables}/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<FormImportModal
					show={modal.show && ['import-stock-awal'].includes(actions)}
					datas={modal.datas}
					actions={actions}
					onHide={() => {
						this.setState({
							actions: 'read',
							modal: {
								show: false
							}
						});
					}}
				/>
			</div>
		);
	}
}

export default StockComponent;
