import ServiceMasterCategory from "../../../services/master-category";

import $ from 'jquery';

const service = new ServiceMasterCategory();

export const DATATABLES = {
	serverSide: {
		url: service.datatables,
		type: 'post'
	},
	columns: [
		{title: 'No'},
		{title: 'Nama Category', data: 'catname'},
		{title: 'Aksi'},
	],
	columnDefs: [
		{
			targets: 0,
			width: 20,
			render: (data, type, row, meta) => {
				return meta.row + meta.settings._iDisplayStart + 1;
			},
		},
		
		{
			targets: 2,
			width: 80,
			render: (data, type, row, meta) => {
				const $actions = $('<div>');

				const $edit = $("<button>");
				$edit.addClass("btn btn-primary btn-xs");
				$edit.attr("actions", "edit");
				$edit.attr("data-id", row.catid);
				$edit.append($("<i>", { class: "fa fa-edit" }));

				$actions.append($edit);

				const $delete = $('<button>');
				$delete.addClass("btn btn-danger btn-xs ml-1");
				$delete.attr("actions", "delete");
				$delete.attr("data-id", row.catid);
				$delete.append($("<i>", { class: "fa fa-trash" }));

				$actions.append($delete);

				return $actions.get(0).outerHTML;
			}
		}
	]
};
