import React from "react";
import {DBCode, DBStyles} from "../../../module/constants";
import Header from "../../skin/header";
import Sidebar from "../../skin/sidebar";
import PageHeader from "../../skin/pageheader";
import AccountMenu from "./account-menu";
import ServiceTransactionOrder from "../../../services/transaction-order";
import {Helpers} from "../../../module/core";
import {Redirect} from "react-router";

class AccountHistoryComponent extends React.Component {
	constructor() {
		super();

		this.state = {
			refreshing: false,
			title: 'Daftar Pesanan',
			breadcrumbs: [
				{link: '#', menu: 'Profil'},
				{link: '#', menu: 'Daftar Pesanan'}
			],
			orders: [],
			status: [],
			datas: {
				userid: Helpers.sessUserid(),
				statusid: 0,
			}
		};

		this.serviceOrder = new ServiceTransactionOrder();

		this.fetchHistory(this.state.datas);
	}
	fetchHistory(datas) {

		this.serviceOrder.fetchHistoryOrder(datas)
			.then(
				res => {
					this.setState({
						orders: res.orders,
						status: res.status,
						datas: {
							...datas,
							statusid: res.statusid
						}
					});
				}
			)
	}
	onClickFilterStatus(event, statusid) {
		event.preventDefault();
		const {datas} = this.state;
		this.fetchHistory({
			userid: datas.userid,
			statusid: statusid,
		});
	}
	onClickDetail(orderid) {
		const token = {};
		token[DBCode.KEY_PARAM_ORDERID] = btoa(orderid);

		this.setState({ redirect: `/account/history/${btoa(JSON.stringify(token))}`});
	}
	onClickEdit(orderid, productid) {
		const token = {};
		token[DBCode.KEY_PARAM_ORDERID] = btoa(orderid);
		token[DBCode.KEY_PARAM_PRODUCTID] = btoa(productid);
		token[DBCode.KEY_PARAM_USERID] = btoa(Helpers.sessUserid());

		this.setState({ redirect: `/account/edit/${btoa(JSON.stringify(token))}`});
	}
	renderStatus(status) {
		const {datas} = this.state;
		return (
			<li key={`status-${status.statusid}`} className={status.statusid === datas.statusid ? 'active' : ''}>
				<span onClick={(event) => this.onClickFilterStatus(event, status.statusid)} className={'nav-link'}>{status.statusnm}</span>
			</li>
		);
	}
	renderOrder(order) {
		return (
			<div className={'card-vertical'} key={`order-${order.orderid}`}>
				<div className={'card-header'}>
					<div className={'card-text'}>{order.ordercd}</div>
					<div className={'card-text'}>{order.orderdate}</div>
				</div>
				<div className={'card-content'}>
					<div className={'image'} style={{backgroundImage: `url(${order.images})`}} />
					<div className={'information'}>
						<div className={'information-title'}>{order.product.productname}</div>
						<div className={'information-item'}>{order.producttype.type.typename}, {order.producttype.groupsize.typename}</div>
						<div className={'information-item'}>{order.varmaterial}</div>
						<div className={'information-footer'}>
							<div className={'footer-item'}>{order.varcolor}</div>
							<div className={'footer-item'}>1x</div>
						</div>
					</div>
				</div>
				<div className={'card-footer'}>
					<span className={'footer-status'}>{order.status.statusnm}</span>
					<div className={'d-flex'}>
						{
							(parseInt(order.status.statusid) === 1) ?
								(
									<button onClick={() => this.onClickEdit(order.orderid, order.product.productid)} className={'btn btn-secondary btn-sm btn-style-1 mr-1'}>
										Edit Order
									</button>
								) : null
						}
						<button onClick={() => this.onClickDetail(order.orderid)} className={'btn btn-primary btn-sm btn-style-1'}>
							Lihat Detail
						</button>
					</div>
				</div>
			</div>
		);
	}
	renderNoOrder() {
		return (
			<div>Tidak ada pesanan</div>
		);
	}
	render() {
		const {redirect, title, breadcrumbs, orders, datas, status} = this.state;

		if(redirect) {
			return <Redirect to={redirect} />;
		}

		return (
			<div className={DBStyles.content.wrapperIcon}>
				<Header />

				<Sidebar />

				<div className={'main-panel apps'}>
					<div className={'content'}>
						<div className={'page-inner'}>
							<PageHeader title={title} breadcrumbs={breadcrumbs} />

							<div className={'row'}>
								<div className={'col-md-3'}>
									<AccountMenu active={'account-history'} />
								</div>
								<div className={'col-md-9'}>
									<div className={'card card-tabs'}>
										<div className={'card-body'}>
											<ul className={'nav nav-tabs-card'}>
												<li className={datas.statusid === 0 ? 'active' : null}>
													<span onClick={event => this.onClickFilterStatus(event, null)} className={'nav-link'}>Semua</span>
												</li>
												{
													status.map(st => this.renderStatus(st))
												}
											</ul>
										</div>
									</div>
									{
										orders.length > 0
											? orders.map(order => this.renderOrder(order))
											: this.renderNoOrder()
									}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default AccountHistoryComponent;
