import React from "react";
import {DBStyles} from "../../module/constants";
import Header from "../skin/header";
import Sidebar from "../skin/sidebar";

class IndexComponent extends React.Component {
	render() {
		return (
			<div className={DBStyles.content.wrapper}>
				<Header />

				<Sidebar />
			</div>
		);
	}
}

export default IndexComponent;
