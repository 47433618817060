import $ from 'jquery';
import ServiceMasterProduct from "../../../services/master-product";

var service = new ServiceMasterProduct();

export const DATATABLES = {
	serverSide: {
		url: service.datatables,
		type: 'post'
	},
	columns: [
		{title: 'No'},
		{title: 'Kode Produk', data: 'productcode'},
		{title: 'Kategori Produk', data: 'category.typename'},
		{title: 'Nama Produk', data: 'productname'},
		{title: 'Aksi'}
	],
	columnDefs: [
		{
			targets: 0,
			width: 20,
			render: (data, type, row, meta) => {
				return meta.row + meta.settings._iDisplayStart + 1;
			}
		},
		{
			targets: 2,
			render: (data, type, row, meta) => {
				return row.category !== null ? row.category.typename : '-';
			}
		},
		{
			targets: 4,
			width: 80,
			render: (data, type, row, meta) => {
				const $actions = $('<div>');

				const $edit = $("<button>");
				$edit.addClass("btn btn-primary btn-xs");
				$edit.attr("actions", "edit");
				$edit.attr("data-id", row.productid);
				$edit.append($("<i>", { class: "fa fa-edit" }));

				$actions.append($edit);

				const $delete = $('<button>');
				$delete.addClass("btn btn-danger btn-xs ml-1");
				$delete.attr("actions", "delete");
				$delete.attr("data-id", row.productid);
				$delete.append($("<i>", { class: "fa fa-trash" }));

				$actions.append($delete);

				return $actions.get(0).outerHTML;
			}
		}
	]
};

export const DATATABLES_TIPE = {
	serverSide: function(productid) {
		return {
			url: `${service.datatablesType(productid)}`,
			type: 'post',
		};
	},
	columns: [
		{title: 'No'},
		{title: 'Tipe Produk', data: 'type.typename'},
		{title: 'Size Group', data: 'groupsize.typename'},
		{title: 'Aksi'},
	],
	columnDefs: [
		{
			targets: 0,
			width: 20,
			render: (data, type, row, meta) => {
				return meta.row + meta.settings._iDisplayStart + 1;
			}
		},
		{
			targets: 1,
			render: (data, type, row, meta) => {
				return row.type.typename;
			}
		},
		{
			targets: 2,
			render: (data, type, row, meta) => {
				return row.groupsize.typename;
			}
		},
		{
			targets: 3,
			width: 80,
			render: (data, type, row, meta) => {
				const $actions = $('<div>');

				const $edit = $("<button>");
				$edit.addClass("btn btn-primary btn-xs");
				$edit.attr("actions", "edit");
				$edit.attr("data-id", row.producttypeid);
				$edit.append($("<i>", { class: "fa fa-edit" }));

				$actions.append($edit);

				const $delete = $('<button>');
				$delete.addClass("btn btn-danger btn-xs ml-1");
				$delete.attr("actions", "delete");
				$delete.attr("data-id", row.producttypeid);
				$delete.append($("<i>", { class: "fa fa-trash" }));

				$actions.append($delete);

				return $actions.get(0).outerHTML;
			}
		},
	]
};

export const DATATABLES_MATERIAL = {
	columns: [
		{title: 'No'},
		{title: 'Tipe Produk', data: 'type.typename'},
		{title: 'Material', data: 'groupsize.typename'},
		{title: 'Aksi'},
	],
	columnDefs: [
		{
			targets: 0,
			width: 20,
			render: (data, type, row, meta) => {
				return meta.row + meta.settings._iDisplayStart + 1;
			}
		},
		{
			targets: 1,
			render: (data, type, row, meta) => {
				return row.producttype.type.typename + ' - ' + row.producttype.groupsize.typename;
			}
		},
		{
			targets: 2,
			render: (data, type, row, meta) => {
				return row.material.materialname;
			}
		},
		{
			targets: 3,
			width: 80,
			render: (data, type, row, meta) => {
				const $actions = $('<div>');

				const $edit = $("<button>");
				$edit.addClass("btn btn-primary btn-xs");
				$edit.attr("actions", "edit");
				$edit.attr("data-id", row.productmaterialid);
				$edit.append($("<i>", { class: "fa fa-edit" }));

				$actions.append($edit);

				const $delete = $('<button>');
				$delete.addClass("btn btn-danger btn-xs ml-1");
				$delete.attr("actions", "delete");
				$delete.attr("data-id", row.productmaterialid);
				$delete.append($("<i>", { class: "fa fa-trash" }));

				$actions.append($delete);

				return $actions.get(0).outerHTML;
			}
		},
	]
};

export const DATATABLES_VARIANT = {
	columns: [
		{title: 'No'},
		{title: 'Tipe Produk', data: 'producttype'},
		{title: 'Nama Varian', data: 'productvar'},
		{title: 'Aksi'},
	],
	columnDefs: [
		{
			targets: 0,
			width: 20,
			render: (data, type, row, meta) => {
				return meta.row + meta.settings._iDisplayStart + 1;
			}
		},
		{
			targets: 1,
			render: (data, type, row, meta) => {
				return row.producttype.type.typename + ' - ' + row.producttype.groupsize.typename;
			}
		},
		{
			targets: 3,
			width: 80,
			render: (data, type, row, meta) => {
				const $actions = $('<div>');

				const $detail = $("<button>");
				$detail.addClass("btn btn-primary btn-xs");
				$detail.attr("data-value", row.productvarid);
				$detail.attr("data-label", row.productvar + "(" + row.producttype.type.typename + "," + row.producttype.groupsize.typename + ")");
				$detail.attr("actions", "variant");
				$detail.append($("<i>", { class: "fa fa-plus" }));
				$detail.append($('<span>').text("Input Detail"));

				$actions.append($detail);

				const $galery = $("<button>");
				$galery.addClass("btn btn-primary btn-xs ml-1");
				$galery.attr("actions", "galery");
				$galery.attr("data-id", row.productvarid);
				$galery.append($("<i>", { class: "fa fa-image" }));

				$actions.append($galery);

				const $edit = $("<button>");
				$edit.addClass("btn btn-primary btn-xs ml-1");
				$edit.attr("actions", "edit");
				$edit.attr("data-id", row.productvarid);
				$edit.append($("<i>", { class: "fa fa-edit" }));

				$actions.append($edit);


				const $delete = $('<button>');
				$delete.addClass("btn btn-danger btn-xs ml-1");
				$delete.attr("actions", "delete");
				$delete.attr("data-id", row.productvarid);
				$delete.append($("<i>", { class: "fa fa-trash" }));

				$actions.append($delete);

				return $actions.get(0).outerHTML;
			}
		}
	]
};

export const DATATABLES_VARDETAIL = {
	columns: [
		{title: 'No'},
		{title: 'Varian Produk'},
		{title: 'Detail Varian'},
		{title: 'Aksi'},
	],
	columnDefs: [
		{
			targets: 0,
			width: 20,
			render: (data, type, row, meta) => {
				return meta.row + meta.settings._iDisplayStart + 1;
			}
		},
		{
			targets: 3,
			width: 80,
			render: (data, type, row, meta) => {
				const $actions = $('<div>');

				const $edit = $("<button>");
				$edit.addClass("btn btn-primary btn-xs");
				$edit.attr("actions", "edit");
				$edit.attr("data-id", row.productvarid);
				$edit.append($("<i>", { class: "fa fa-edit" }));

				$actions.append($edit);

				const $delete = $('<button>');
				$delete.addClass("btn btn-danger btn-xs ml-1");
				$delete.attr("actions", "delete");
				$delete.attr("data-id", row.productvarid);
				$delete.append($("<i>", { class: "fa fa-trash" }));

				$actions.append($delete);

				return $actions.get(0).outerHTML;
			}
		}
	]
};
