import React from "react";
import {DBCode, DBStyles} from "../../../module/constants";
import Header from "../../skin/header";
import Sidebar from "../../skin/sidebar";
import PageHeader from "../../skin/pageheader";
import ServiceTransactionOrder from "../../../services/transaction-order";
import ServiceAppsProduct from "../../../services/apps-product";
import {Link} from "react-router-dom";

class OrderComponent extends React.Component {
	constructor() {
		super();

		this.state = {
			refreshing: true,
			title: 'Orders',
			breadcrumbs: [
				{link: '#', menu: 'Orders'},
				{link: '#', menu: 'Data Product'},
			],
			menuPosition: ['Orders'],
			datas: {
				products: [],
			},
		};

		this.order = new ServiceTransactionOrder();
		this.products = new ServiceAppsProduct();

		this.fetchDataProduct();
	}
	fetchDataProduct() {
		this.products.fetchLimitProduct()
			.then(
				res => {
					const {datas} = this.state;
					if(res.success) {
						this.setState({
							refreshing: false,
							datas: {
								...datas,
								products: res.datas
							}
						});
					} else {
						this.setState({refreshing: false});
					}
				}
			)
			.catch(() => {
				this.setState({refrehsing: false});
			})
	}
	renderLoading() {
		return (
			<div className={"loading-page"}>
				<p className={"loading-title"}>Loading</p>
			</div>
		);
	}
	renderProductItem(product, i) {
		const token = {};
		token[DBCode.KEY_PARAM_PRODUCTID] = product.productid;
		let image = product.source === null
			? product.no_image
			: product.source;
		return (
			<Link to={`/orders/${btoa(JSON.stringify(token))}`} className={"col-6 col-md-3 nav-link"} key={`item-${i}`}>
				<div className={"card-product style-1"}>
					<div className={"part-image "+image} style={{backgroundImage: `url(${image})`}} /><div className={"part-content"}>
						<div className={"content-title"}>{product.productname}</div>
						<div className={"content-description"}>
							<p>Ukuran : {product.sizes}</p>
							<p>Jenis Bahan : {product.materials}</p>
						</div>
					</div>
				</div>
			</Link>
		);
	}
	render() {
		const {refreshing, title, breadcrumbs, menuPosition, datas} = this.state;
		return (
			<div className={DBStyles.content.wrapperIcon}>
				<Header />

				<Sidebar menuPosition={menuPosition} />

				<div className={'main-panel apps'}>
					<div className={'content'}>
						<div className={'page-inner'}>
							<PageHeader title={title} breadcrumbs={breadcrumbs} />

							{
								refreshing ? this.renderLoading() : null
							}
							<div className={'row apps-gutter'}>
								{
									datas.products.map((product, i) => {
										return this.renderProductItem(product, i);
									})
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default OrderComponent;
