import React from "react";
import {DBCode, DBStyles} from "../../../module/constants";
import Header from "../../skin/header";
import Sidebar from "../../skin/sidebar";
import ServiceTransactionOrder from "../../../services/transaction-order";
import {Redirect} from "react-router";

class OrderCompleteComponent extends React.Component {
	constructor(props) {
		super(props);

		const {token} = props.match.params;
		const param = JSON.parse(atob(token));
		const orderid = param[DBCode.KEY_PARAM_ORDERID];

		this.state = {
			refreshing: false,
			orderid: orderid,
			title: 'Pesanan Berhasil',
			breadcrumbs: [],
			order: null,
		};

		this.service = new ServiceTransactionOrder();

		this.fetchOrder();
	}
	fetchOrder() {
		const {orderid} = this.state;
		this.service.fetchDetail(orderid)
			.then(
				res => {
					if(res.success) {
						this.setState({
							refreshing: false,
							order: res.order,
						});
					}
				}
			)
	}
	onClickDetail(orderid) {
		const token = {};
		token[DBCode.KEY_PARAM_ORDERID] = btoa(orderid);

		this.setState({ redirect: `/account/history/${btoa(JSON.stringify(token))}`});
	}
	render() {
		const {redirect, orderid, order} = this.state;

		if(redirect) return <Redirect to={redirect} />;

		return (
			<div className={DBStyles.content.wrapperIcon}>
				<Header />

				<Sidebar />

				<div className={'main-panel apps'}>
					<div className={'content'}>
						<div className={'page-inner'}>
							{/*<PageHeader title={title} breadcrumbs={breadcrumbs} />*/}

							<div className={'content-center'}>
								<div className={'image-center'} style={{backgroundImage: 'url(http://192.168.43.133/garment/api/public/assets/images/order-complete.png)'}} />
								<div className={'description'}>
									<div className={'description-title'}>Proses Telah Berhasil</div>
									<div className={'description-text'}>Pembuat pesanan telah berhasil dengan nomor pesanan <b>{order ? order.ordercd : null}</b>. Untuk melihat status pesanan tekan tombol lihat detail pesanan</div>
								</div>
								<div className={'action'}>
									<button className={'btn btn-primary btn-md'} onClick={() => this.onClickDetail(orderid)}>
										<i className={'fa fa-info-circle'} />
										<span>Lihat Detail Pesanan</span>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default OrderCompleteComponent;
