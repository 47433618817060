import React from "react";
import {Button, Modal} from "react-bootstrap";
import ServiceReportOrder from "../../../services/report-order";
import {DBMessage} from "../../../module/constants";
import {Helpers} from "../../../module/core";

class OrdersCanceled extends React.Component {
	constructor() {
		super();

		this.state = {
			disabled: false,
			datas: {
				orderid: '',
				qty: '',
				reason: '',
			}
		};

		this.service = new ServiceReportOrder();
	}
	onShowHandler() {
		const {datas} = this.props;
		this.setState({
			datas: {
				...datas,
				orderid: datas.orderid
			}
		});
	}
	onChangeHandler(event) {
		const {datas} = this.state;
		this.setState({
			datas: {
				...datas,
				[event.target.name]: event.target.value,
			}
		})
	}
	onSubmitHandler(e) {
		e.preventDefault();

		const {onHide} = this.props;
		const {datas, orderid} = this.state;

		this.setState({disabled: true});
		this.service.processCancel(orderid, datas)
			.then(
				res => {
					if(res.success) {
						Helpers.swalSuccess(DBMessage.FETCH_SUCCESS_TITLE, res.message)
							.then(() => onHide());
					} else {
						this.setState({disabled: false})
					}
				},
				error => this.setState({disabled: false})
			)
			.catch(() => this.setState({disabled: false}));
	}
	render() {
		const props = this.props;
		const {disabled, datas} = this.state;

		return (
			<Modal {...props} onHide={props.onHide} onShow={this.onShowHandler.bind(this)}>
				<Modal.Header closeButton>
					<Modal.Title>Form Pembatalan</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form id={'form-canceled'} onSubmit={this.onSubmitHandler.bind(this)}>
						<div className={'form-group'}>
							<label htmlFor={'input-qty'}>Qty (Pcs)</label>
							<input
								type={'number'}
								className={'form-control'}
								placeholder={'0 Pcs'}
								name={'qty'}
								onChange={this.onChangeHandler.bind(this)}
								value={datas.qty}
								required={true}
								disabled={disabled} />
						</div>
						<div className={'form-group'}>
							<label htmlFor={'input-reason'}>Alasan Pembatalan</label>
							<textarea
								className={'form-control'}
								rows={5}
								placeholder={'Masukan alasan pembatalan'}
								name={'reason'}
								onChange={this.onChangeHandler.bind(this)}
								value={datas.reason}
								required={true}
								disabled={disabled} />
						</div>
					</form>
				</Modal.Body>
				<Modal.Footer>
					<Button type={"button"} variant="secondary" size={"sm"} onClick={props.onHide} disabled={disabled}>
						Batal
					</Button>
					<Button type={"submit"} variant="primary" size={"sm"} form={"form-canceled"} disabled={disabled}>
						{!disabled ? 'Process' : 'Memproses ...'}
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default OrdersCanceled;
