import React from 'react';
import {Link} from "react-router-dom";

class PageHeader extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			title: props.title ? props.title : 'Default Page',
			breadcrumbs: props.breadcrumbs ? props.breadcrumbs : [],
			withHome: props.withHome === undefined ? true : props.withHome,
		}
	}
	render() {
		const {title, breadcrumbs, withHome} = this.state;

		let items = [];
		if(withHome) {
			items.push((
				<li className={"nav-home"} key={"breadcrumb-home"}>
					<Link to={`${process.env.PUBLIC_URL}`}>
						<i className={"flaticon-home"} />
					</Link>
				</li>
			));
		}

		breadcrumbs.forEach((item, i) => {
			if(i === 0) {
				if(withHome) {
					items.push((
						<li className={"separator"} key={`breadcrumb-separator-${item.menu}`}>
							<i className={"flaticon-right-arrow"} />
						</li>
					));
				}
			} else {
				items.push((
					<li className={"separator"} key={`breadcrumb-separator-${item.menu}`}>
						<i className={"flaticon-right-arrow"} />
					</li>
				));
			}

			items.push((
				<li className={"nav-item"} key={`breadcrumb-item-${item.menu}`}>
					<Link to={item.link}>{item.menu}</Link>
				</li>
			));
		});

		return (
			<div className={"page-header"}>
				<div className={"page-title"}>{title}</div>
				<ul className={"breadcrumbs"}>{items}</ul>
			</div>
		);
	}
}

export default PageHeader;
