import axios from 'axios';
import {Config, Helpers} from "../module/core";
import {DBMessage} from "../module/constants";

import $ from "jquery";

class ServiceTransactionOrder {
	constructor() {
		this.api = `${Config.apiApps}/orders`;
	}
	fetch(datas) {
		return axios.get(this.api, {
			params: datas
		})
			.then(
				res => {
					$('body').removeClass('fetching-data');
					return res.data;
				},
				error => {
					$('body').removeClass('fetching-data');
					return {success: false, message: error};
				}
			)
			.catch(() => {
				$('body').removeClass('fetching-data');
				Helpers.swalError(DBMessage.ERROR_NETWORK_TITLE, DBMessage.ERROR_NETWORK_MESSAGE);
			});
	}
	fetchProcess(datas) {
		datas.userid = Helpers.sessUserid();
		return axios.post(this.api, datas)
			.then(
				res => {
					$('body').removeClass('fetching-data');
					return res.data;
				},
				error => {
					$('body').removeClass('fetching-data');
					return {success: false, message: error};
				}
			)
			.catch(() => {
				$('body').removeClass('fetching-data');
				Helpers.swalError(DBMessage.ERROR_NETWORK_TITLE, DBMessage.ERROR_NETWORK_MESSAGE);
			});
	}
	fetchHistoryOrder(datas) {
		return axios.get(`${this.api}/history`, {
			params: datas
		})
			.then(
				res => {
					return res.data;
				},
				error => {
					$('body').removeClass('fetching-data');
					return {success: false, message: error};
				}
			)
			.catch(() => {
				$('body').removeClass('fetching-data');
				Helpers.swalError(DBMessage.ERROR_NETWORK_TITLE, DBMessage.ERROR_NETWORK_MESSAGE);
			});
	}
	fetchDetail(orderid) {
		return axios.get(`${this.api}/history/${orderid}`)
			.then(
				res => {
					return res.data;
				},
				error => {
					$('body').removeClass('fetching-data');
					return {success: false, message: error};
				}
			)
			.catch(() => {
				$('body').removeClass('fetching-data');
				Helpers.swalError(DBMessage.ERROR_NETWORK_TITLE, DBMessage.ERROR_NETWORK_MESSAGE);
			});
	}
	fetchStock(datas) {
		datas.userid = Helpers.sessUserid();
		return axios.post(`${this.api}/check`, datas)
			.then(
				res => {
					return res.data;
				},
				error => {
					$('body').removeClass('fetching-data');
					return {success: false, message: error};
				}
			)
			.catch(() => {
				$('body').removeClass('fetching-data');
				Helpers.swalError(DBMessage.ERROR_NETWORK_TITLE, DBMessage.ERROR_NETWORK_MESSAGE);
			});
	}
}

export default ServiceTransactionOrder;
