import axios from 'axios';
import {Config, Helpers} from "../module/core";
import $ from "jquery";
import {DBMessage} from "../module/constants";

class ServiceMasterMenu {
	constructor() {
		this.api = `${Config.api}/menus`;
		this.datatables = `${this.api}/datatables`;
	}
	select(searchValue) {
		return axios.get(`${this.api}/select2Api`, {
			params: {
				searchValue: searchValue
			}
		})
			.then(
				res => {
					return res.data;
				},
				error => {
					return {success: false, message: error};
				}
			)
			.catch(() => {
				Config.notifyError(DBMessage.ERROR_NETWORK_TITLE, DBMessage.ERROR_NETWORK_MESSAGE);
			});
	}
	fetch(id) {
		$('body').addClass('fetching-data');
		return axios.get(`${this.api}/${id}`)
			.then(
				res => {
					$('body').removeClass('fetching-data');
					return res.data;
				},
				error => {
					$('body').removeClass('fetching-data');
					return {success: false, message: error};
				}
			)
			.catch(() => {
				$('body').removeClass('fetching-data');
				Config.notifyError(DBMessage.ERROR_NETWORK_TITLE, DBMessage.ERROR_NETWORK_MESSAGE);
			});
	}
	fetchStore(datas) {
		$('body').addClass('fetching-data');

		datas.userid = Helpers.sessUserid();

		return axios.post(`${this.api}`, datas)
			.then(
				res => {
					$('body').removeClass('fetching-data');
					return res.data;
				},
				error => {
					$('body').removeClass('fetching-data');
					return {success: false, message: error};
				}
			)
			.catch(() => {
				$('body').removeClass('fetching-data');
				Config.notifyError(DBMessage.ERROR_NETWORK_TITLE, DBMessage.ERROR_NETWORK_MESSAGE);
			});
	}
	fetchUpdate(datas, id) {
		$('body').addClass('fetching-data');

		datas.userid = Helpers.sessUserid();

		return axios.put(`${this.api}/${id}`, datas)
			.then(
				res => {
					$('body').removeClass('fetching-data');
					return res.data;
				},
				error => {
					$('body').removeClass('fetching-data');
					return {success: false, message: error};
				}
			)
			.catch(() => {
				$('body').removeClass('fetching-data');
				Config.notifyError(DBMessage.ERROR_NETWORK_TITLE, DBMessage.ERROR_NETWORK_MESSAGE);
			});
	}
	fetchDelete(id) {
		$('body').addClass('fetching-data');
		return axios.delete(`${this.api}/${id}`)
			.then(
				res => {
					$('body').removeClass('fetching-data');
					return res.data;
				},
				error => {
					$('body').removeClass('fetching-data');
					return {success: false, message: error};
				}
			)
			.catch(() => {
				$('body').removeClass('fetching-data');
				Config.notifyError(DBMessage.ERROR_NETWORK_TITLE, DBMessage.ERROR_NETWORK_MESSAGE);
			});
	}
	fetchGroupMenu(groupid) {
		$('body').addClass('fetching-data');
		return axios.get(`${this.api}/groupmenu`, {
			params: {
				groupid: groupid,
			}
		})
			.then(
				res => {
					$('body').removeClass('fetching-data');
					return res.data;
				},
				error => {
					$('body').removeClass('fetching-data');
					return {success: false, message: error};
				}
			)
			.catch(error => {
				$('body').removeClass('fetching-data');
				Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, error);
			})
	}
}

export default ServiceMasterMenu;
