import React from "react";
import {RouteAuth} from "../module/routes/route-auth";
import OrderComponent from "../app/transaction/order/order";
import OrderDetailComponent from "../app/transaction/order/order-detail";
import OrderProcessComponent from "../app/transaction/order/order-process";
import OrderCompleteComponent from "../app/transaction/order/order-complete";

export const routes_transaction_order = [
	<RouteAuth exact={true} path={'/orders'} component={OrderComponent} key={'order'} />,
	<RouteAuth path={'/orders/:token'} component={OrderDetailComponent} key={'order-detail'} />,
	<RouteAuth path={'/orders-process/:token'} component={OrderProcessComponent} key={'order-process'} />,
	<RouteAuth path={'/orders-complete/:token'} component={OrderCompleteComponent} key={'order-complete'} />,
];
