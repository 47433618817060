import React from "react";
import {DBCode, DBMessage, DBStyles} from "../../../module/constants";
import Header from "../../skin/header";
import Sidebar from "../../skin/sidebar";
import PageHeader from "../../skin/pageheader";
import ServiceTransactionStock from "../../../services/transaction-stock";
import {Link} from "react-router-dom";
import {Button} from "react-bootstrap";
import {DATATABLES, DATATABLES_PARAMS, RENDER_COMPONENTS, RENDER_PRODUCT} from "./_config";
import DatePicker from "react-datepicker/es";
import Datatables from "../../components/datatables";

import $ from 'jquery';
import ChooseColor from "./choose-color";
import {Helpers} from "../../../module/core";
import CancelOrders from "./cancel-orders";

class StockEditComponent extends React.Component {
	componentDidMount() {
		RENDER_PRODUCT.$table = $('#datatableform_products');
	}

	constructor(props) {
		super(props);

		let {token} = props.match.params;
		let params = JSON.parse(atob(token));
		let id = params.id;

		DATATABLES_PARAMS.params.id = id;

		this.state = {
			actions: 'ready',
			refreshing: false,
			title: 'Edit Stok',
			breadcrumbs: [
				{link: '#', menu: 'Inventory'},
				{link: '#', menu: 'Stock'},
			],
			adjustid: id,
			modal: {
				show: false,
				datas: {}
			},
			datas: {
				adjustdate: new Date(),
				type: {
					typeid: null,
					typename: null
				},
			},
			datatables: {
				fnRowCallback: (nRow, aData, iDisplayIndex, iDisplayIndexFull) => {
					let $row = $(nRow);
					let $TogglePress = $row.find('[data-toggle=keypress]');
					$TogglePress.on('keypress, keydown, keyup', this.onChangeInput.bind(this));
					let $ChooseColor = $row.find('[data-toggle=choose-color]');
					RENDER_PRODUCT.renderUpdate(nRow, RENDER_PRODUCT.get($ChooseColor.data('index')));
				},
			}
		};

		this.service = new ServiceTransactionStock();

		this.fetch();
	}

	fetch() {
		const {adjustid} = this.state;

		this.service.fetch(adjustid)
			.then(res => {
				if(res.success) {
					this.setState({
						actions: 'read',
						datas: res.row
					});
					RENDER_PRODUCT.datas = res.products;
					RENDER_COMPONENTS.init();
				}
			})
	}
	onChangeInput(e) {
		let $element = $(e.currentTarget);
		let index = parseInt($element.data('index'));
		let data = RENDER_PRODUCT.get(index);

		$element.get(0).onblur = function() {
			data[$element.data('name')] = $element.val();
			RENDER_PRODUCT.set(index, data);
		};
	}
	onChangeText(e) {
		const {datas} = this.state;
		this.setState({
			datas: {
				...datas,
				[e.target.name]: e.target.value,
			}
		});
	}
	onChangeSelect(e) {
		const {datas} = this.state;
		this.setState({
			datas: {
				...datas,
				[e.target.name]: e.target.value,
			}
		});
	}
	onChangeOrder(e) {
		const {datas} = this.state;
		if($.trim(e.target.value)) {
			this.setState({
				datas: {
					...datas,
					refno: e.target.value,
				},
				modal: {
					show: true,
					datas: {
						orderid: e.target.value,
					}
				},
				actions: 'cancel-order'
			})
		} else {
			this.setState({
				datas: {
					refno: null,
				},
			});
		}
	}
	onCloseCancelOrder(params) {
		const {datas} = this.state;

		if(params !== null) {
			datas.warehouse = params.warehouse;
			datas.refno = params.orderid;
			datas.whfrom = params.warehouse.whfrom.whid;
			datas.whto = params.warehouse.whto.whid;
			datas.productvarsizes = params.productvarsizes;

			RENDER_PRODUCT.datas = params.materials;
			RENDER_PRODUCT.render();
		}

		this.setState({
			modal: {
				show: false,
				datas: {},
			},
			actions: 'ready',
			datas: datas,
		});
	}
	onSubmitHandler(e) {
		e.preventDefault();

		RENDER_PRODUCT.setDisabled(true);
		this.setState({refreshing: true});

		const {datas, adjustid} = this.state;
		datas.products = RENDER_PRODUCT.get();

		this.service.update(datas, adjustid)
			.then(
				res => {
					if(res.success) {
						Helpers.swalSuccess(DBMessage.FETCH_SUCCESS_TITLE, res.message)
							.then(() => {
								this.setState({
									refreshing: false,
									redirect: '/inventory/stock'
								});
								RENDER_PRODUCT.setDisabled(false);
								window.location.reload();
							});
					} else {
						Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, res.message)
							.then(() => {
								this.setState({
									refreshing: false,
								})
							});
					}
				}
			);
	}
	render() {
		const {actions, refreshing, title, breadcrumbs, modal, datas, datatables} = this.state;

		const typesWhto = [0, DBCode.TYPE_ADJUSTMENT_IN, DBCode.TYPE_PRODUCTION, DBCode.TYPE_CANCELED_ORDER, DBCode.TYPE_SALDO_AWAL];
		const typesWhfrom = [0, DBCode.TYPE_ADJUSTMENT_OUT, DBCode.TYPE_PRODUCTION, DBCode.TYPE_CANCELED_ORDER];

		return (
			<div className={DBStyles.content.wrapperIcon}>

				<Header />

				<Sidebar />

				<div className={'main-panel'}>
					<div className={'content'}>
						<div className={'page-inner'}>
							<PageHeader title={title} breadcrumbs={breadcrumbs} />

							<form onSubmit={this.onSubmitHandler.bind(this)}>
								<div className={'card'}>
									<div className={'card-header'}>
										<div className={'card-title'}>Form Stock</div>
										<div className={'card-actions'}>
											<Link className={'btn btn-danger btn-sm'} to={'/inventory/stock'} disabled={refreshing}>
												<i className={DBStyles.icon.back} />
												<span>Kembali</span>
											</Link>
											<Button className={'ml-1'} type={"submit"} variant={"primary"} size={"sm"} disabled={refreshing}>
												<i className={"fa fa-save"} />
												<span>Simpan</span>
											</Button>
										</div>
									</div>
									<div className={'card-body'}>
										<div className={'row'}>
											<div className={'col-md-6'}>
												<div className={'form-group'}>
													<label htmlFor={'input-nomor'}>Nomor</label>
													<input
														type={'text'}
														className={'form-control'}
														placeholder={'Nomor akan diisi otomatis oleh sistem'}
														disabled={true}
														value={datas.adjustcd}
														onChange={() => {}}/>
												</div>
												<div className={'form-group'}>
													<label htmlFor={'select-tipe'}>Tipe</label>
													<select
														id={'select-tipe'}
														className={'form-control select2-tipe'}
														required={true}
														name={'type'}
														onChange={() => {}}
														data-select-id={datas.type ? datas.type.typeid : null}
														data-select-text={datas.type ? datas.type.typename : null}
														disabled={refreshing} />
												</div>
												<div className={`form-group ${(datas.type && [DBCode.TYPE_CANCELED_ORDER].includes(parseInt(datas.type.typeid))) ? 'd-none' : ''}`}>
													<label htmlFor={'input-refno'}>Refno</label>
													<input
														type={'text'}
														className={'form-control'}
														placeholder={'Refno'}
														name={'refno'}
														value={datas.refno ? datas.refno : ''}
														onChange={this.onChangeText.bind(this)}
														disabled={refreshing} />
												</div>
												<div className={`form-group ${(datas.type && ![DBCode.TYPE_CANCELED_ORDER].includes(parseInt(datas.type.typeid))) ? 'd-none' : ''}`}>
													<label htmlFor={'input-refno'}>Nomor Order</label>
													<div className={'d-flex'}>
														<select
															id={'select-order'}
															className={'form-control select2-order'}
															required={([DBCode.TYPE_CANCELED_ORDER].includes(parseInt(datas.type)))}
															disabled={refreshing}
															data-select-id={datas ? datas.refno : null}
															data-select-text={datas.orders ? datas.orders.orderdate + ' - ' + datas.orders.ordercd : null}
														/>
														<button
															type={'button'}
															className={'btn btn-primary btn-sm ml-1'}
															disabled={!datas.refno}
															onClick={() => this.onChangeOrder(
																{
																	target: {
																		value: datas.refno,
																	}
																})}>
															Tampilkan
														</button>
													</div>
												</div>
											</div>
											<div className={'col-md-6'}>
												<div className={'form-group'}>
													<label htmlFor={'input-date'}>Tanggal</label>
													<DatePicker
														className={'form-control'}
														placeholderText={'DD / MM / YYYY'}
														selected={datas ? new Date(datas.adjustdate) : new Date()}
														dateFormat={'dd/MM/yyyy'}
														onChange={date => {
															this.onChangeText({
																target: {
																	name: 'adjustdate',
																	value: date,
																}
															});
														}}
														required={true}
														disabled={refreshing}
													/>
												</div>
												<div className={'form-group'}>
													<div className={'row'}>
														<div className={`col ${(!typesWhfrom.includes(parseInt(datas.type.typeid))) ? 'd-none' : ''}`}>
															<label htmlFor={'select-warehouse-from'}>Warehouse From</label>
															<select
																id={'select-warehouse-from'}
																className={'form-control select2-tipe'}
																name={'whfrom'}
																value={datas.whfrom}
																required={(typesWhfrom.includes(parseInt(datas.type.typeid)))}
																onChange={this.onChangeSelect.bind(this)}
																data-select-id={datas.warehousefrom ? datas.warehousefrom.whid : null}
																data-select-text={datas.warehousefrom ? datas.warehousefrom.whname : null}
																disabled={refreshing} />
														</div>
														<div className={`col ${(!typesWhto.includes(parseInt(datas.type.typeid))) ? 'd-none' : ''}`}>
															<label htmlFor={'select-warehouse-to'}>Warehouse To</label>
															<select
																id={'select-warehouse-to'}
																className={'form-control select2-tipe'}
																name={'whto'}
																value={datas.whto}
																onChange={this.onChangeSelect.bind(this)}
																required={(typesWhto.includes(parseInt(datas.type.typeid)))}
																data-select-id={datas.warehouseto ? datas.warehouseto.whid : null}
																data-select-text={datas.warehouseto ? datas.warehouseto.whname : null}
																disabled={refreshing} />
														</div>
													</div>
												</div>
												<div className={'form-group'}>
													<label htmlFor={'input-description'}>Deskripsi</label>
													<textarea
														className={'form-control'}
														rows={5}
														placeholder={'Deskripsi'}
														name={'description'}
														value={datas.description}
														onChange={this.onChangeText.bind(this)}
														disabled={refreshing} />
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className={'card'}>
									<div className={'card-header'}>
										<div className={'card-title'}>Data Product</div>
									</div>
									<div className={'card-body'}>
										<div className={'table-responsive'}>
											<Datatables
												id={'datatableform_products'}
												actions={actions}
												serverSide={DATATABLES.serverSide}
												columns={DATATABLES.columns}
												columnDefs={DATATABLES.columnDefs}
												options={datatables}/>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>

				<CancelOrders
					show={['cancel-order'].includes(actions) && modal.show}
					datas={modal.datas}
					onHide={this.onCloseCancelOrder.bind(this)} />
			</div>
		);
	}
}

export default StockEditComponent;
