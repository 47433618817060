import React from "react";
import {Button, Modal} from "react-bootstrap";
import AsyncSelect from 'react-select/async';

import ServiceMasterMenu from "../../../services/master-menu";
import {API_SELECT, Helpers} from "../../../module/core";
import {DBMessage} from "../../../module/constants";

class MenuForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			datas: {
				menuid: 0,
				menuname: '',
				link: '',
				icon: '',
				seq: '',
				master: null
			},
			actions: props.actions ? props.actions : 'ready',
			disabled: false,
		};
		this.defaultDatas = this.state.datas;

		this.service = new ServiceMasterMenu();
	}
	onShow() {
		const {actions, datas} = this.props;

		this.setState({
			actions: actions
		});

		if(actions === 'edit') {
			this.setState({ disabled: false });
			this.service.fetch(datas.id)
				.then(
					res => {
						this.setState({ disabled: false });
						if(res.success) {
							this.setState({
								datas: res.row,
							});
						} else {
							Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, res.message);
						}
					}
				)
		} else {
			this.setState({
				datas: this.defaultDatas,
			})
		}
	}
	onChangeHandler(event) {
		const {datas} = this.state;

		this.setState({
			datas: {
				...datas,
				[event.target.name]: event.target.value,
			}
		});
	}
	onChangeSelect(option, select) {
		const {datas} = this.state;

		this.setState({
			datas: {
				...datas,
				[select.name]: option
			}
		});
	}
	onSubmitHandler(event) {
		event.preventDefault();

		const {datas, actions} = this.state;
		const {onHide} = this.props;

		this.setState({ disabled: true });

		if(actions === 'create') {
			this.service.fetchStore(datas)
				.then(
					res => {
						this.setState({ disabled: false });
						onHide();
						if(res.success) {
							Helpers.swalSuccess(DBMessage.FETCH_SUCCESS_TITLE, res.message);
						} else {
							Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, res.message);
						}
					}
				)
		} else if(actions === 'edit') {
			this.service.fetchUpdate(datas, datas.menuid)
				.then(
					res => {
						this.setState({ disabled: false });
						onHide();

						if(res.success) {
							Helpers.swalSuccess(DBMessage.FETCH_SUCCESS_TITLE, res.message);
						} else {
							Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, res.message);
						}
					}
				)
		}
	}
	render() {
		const props = this.props;
		const {disabled, datas} = this.state;
		return (
			<Modal {...props} onShow={this.onShow.bind(this)} onHide={props.onHide}>
				<Modal.Header closeButton>
					<Modal.Title>Form Group</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form id={"form-group"} onSubmit={this.onSubmitHandler.bind(this)}>
						<div className={"form-group"}>
							<label htmlFor={"input-master"}>Parent Menu</label>
							<AsyncSelect
								cacheOptions
								defaultOptions
								name={"master"}
								placeholder={"Pilih parent menu"}
								loadOptions={API_SELECT.menu}
								onChange={this.onChangeSelect.bind(this)}
								value={datas.master}
								isDisabled={disabled} />
						</div>
						<div className={"form-group"}>
							<label htmlFor={"input-menuname"}>Nama Menu</label>
							<input
								type={"text"}
								id={"input-menuname"}
								className={"form-control"}
								name={"menuname"}
								value={datas.menuname}
								placeholder={"Masukan nama menu"}
								onChange={this.onChangeHandler.bind(this)}
								disabled={disabled}
								required={true}/>
						</div>
						<div className={"form-group"}>
							<label htmlFor={"input-link"}>Link Menu</label>
							<input
								type={"text"}
								id={"input-link"}
								className={"form-control"}
								name={"link"}
								value={datas.link}
								placeholder={"Masukan link menu"}
								onChange={this.onChangeHandler.bind(this)}
								disabled={disabled}
								required={true} />
						</div>
						<div className={"form-group"}>
							<label htmlFor={"input-icon"}>Icon Menu</label>
							<input
								type={"text"}
								id={"input-icon"}
								className={"form-control"}
								name={"icon"}
								value={datas.icon}
								placeholder={"Masukan icon menu"}
								onChange={this.onChangeHandler.bind(this)}
								disabled={disabled}
								required={true} />
						</div>
						<div className={"form-group"}>
							<label htmlFor={"input-seq"}>Sequence Menu</label>
							<input
								type={"number"}
								id={"input-seq"}
								className={"form-control"}
								name={"seq"}
								value={datas.seq}
								placeholder={"Masukan sequence menu"}
								onChange={this.onChangeHandler.bind(this)}
								disabled={disabled}
								required={true} />
						</div>
					</form>
				</Modal.Body>
				<Modal.Footer>
					<Button type={"button"} variant="secondary" size={"sm"} onClick={props.onHide} disabled={disabled}>
						Batal
					</Button>
					<Button type={"submit"} variant="primary" size={"sm"} form={"form-group"} disabled={disabled}>
						{!disabled ? 'Simpan' : 'Memproses ...'}
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default MenuForm;
