import axios from 'axios';
import {Config, Helpers} from "../module/core";
import $ from "jquery";
import {DBCode, DBMessage} from "../module/constants";

class ServiceMasterMaterialDetail {

    constructor() {
        this.api = `${Config.api}/materials/detail`;
        this.datatables = `${this.api}/datatables`;
        this.select2Api = `${this.api}/select2Api`;
    }
    fetch(id) {
        $('body').addClass('fetching-data');

        return axios.get(`${this.api}/${id}`, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(
                res => {
                    $('body').removeClass('fetching-data');
                    return res.data;
                },
                error => {
                    $('body').removeClass('fetching-data');
                    return {success: false, message: error};
                }
            )
            .catch(() => {
                $('body').removeClass('fetching-data');
                Config.swalError(DBMessage.ERROR_NETWORK_TITLE, DBMessage.ERROR_NETWORK_MESSAGE);
            });
    }
    fetchAll() {

    }
    store(datas) {
        $('body').addClass('fetching-data');

        datas.append('userid', Helpers.sessUserid());

        return axios.post(`${this.api}`, datas, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(
                res => {
                    $('body').removeClass('fetching-data');
                    return res.data;
                },
                error => {
                    $('body').removeClass('fetching-data');
                    return {success: false, message: error};
                }
            )
            .catch(() => {
                $('body').removeClass('fetching-data');
                Config.swalError(DBMessage.ERROR_NETWORK_TITLE, DBMessage.ERROR_NETWORK_MESSAGE);
            });
    }
    show(id) {

    }
    update(datas, id) {
        $('body').addClass('fetching-data');

        datas.append('userid', Helpers.sessUserid());

        return axios.post(`${this.api}/${id}`, datas, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(
                res => {
                    $('body').removeClass('fetching-data');
                    return res.data;
                },
                error => {
                    $('body').removeClass('fetching-data');
                    return {success: false, message: error};
                }
            )
            .catch(() => {
                $('body').removeClass('fetching-data');
                Config.swalError(DBMessage.ERROR_NETWORK_TITLE, DBMessage.ERROR_NETWORK_MESSAGE);
            });
    }
    delete(id) {
        $('body').addClass('fetching-data');


        return axios.delete(`${this.api}/${id}`)
            .then(
                res => {
                    $('body').removeClass('fetching-data');
                    return res.data;
                },
                error => {
                    $('body').removeClass('fetching-data');
                    return {success: false, message: error};
                }
            )
            .catch(() => {
                $('body').removeClass('fetching-data');
                Config.swalError(DBMessage.ERROR_NETWORK_TITLE, DBMessage.ERROR_NETWORK_MESSAGE);
            });
    }
}

export default ServiceMasterMaterialDetail;