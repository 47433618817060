import React from "react";
import {Button, Modal} from "react-bootstrap";
import AsyncSelect from 'react-select/async';
import { SketchPicker } from 'react-color';

import ServiceMasterColor from "../../../services/master-color";

import {Helpers,API_SELECT} from "../../../module/core";
import {DBMessage} from "../../../module/constants";

import $ from "jquery";

class ColorForm extends React.Component {
	state = {
		displayColorPicker: false,
	};

	handleClick = () => {
		this.setState({ displayColorPicker: !this.state.displayColorPicker })
	};

	handleClose = () => {
		this.setState({ displayColorPicker: false })
	};

	constructor(props) {
		super(props);
		this.state = {
			datas: {
				colorid: 0,
				colorals: '',
				colorcode: '',
				colorname: '',
				groupcolorid: null,
				files: null
			},
			actions: props.actions ? props.actions : 'ready',
			disabled: false,
		};
		this.defaultDatas = this.state.datas;

		this.service = new ServiceMasterColor();

	}
	onClickUpload() {
		const {datas} = this.state;
		const $uploadEl = $('#file-color');
		$uploadEl.click();
		$uploadEl.change((e) => {
			this.setState({
				datas: {
					...datas,
					files: e.target.files[0],
				}
			})
		})
	}
	onShow() {
		const {actions, datas} = this.props;
		if(actions === 'edit') {
			this.setState({ disabled: true });
			this.service.fetch(datas.id)
				.then(
					res => {
						if(res.success) {
							this.setState({
								datas: res.row,
								actions: 'edit',
								disabled: false,
							});
						}
					}
				)
		} else {
			this.setState({
				datas: this.defaultDatas,
				actions: actions,
			});
		}
	}

	handleChange (color){
  		const {datas} = this.state;
		this.setState({
			datas: {
				...datas,
				colorcode: color.hex
			}
		});
	}
	onClickFile(name, file) {
		const {datas} = this.state;
		this.setState({
			datas: {
				...datas,
				[name]: file
			}
		});
	}
	onChangeHandler(event) {
		const {datas} = this.state;
		this.setState({
			datas: {
				...datas,
				[event.target.name]: event.target.value,
			}
		});
	}
	onChangeSelect(option, select) {
		const {datas} = this.state;
		this.setState({
			datas: {
				...datas,
				[select.name]: option
			}
		});
	}
	onSubmitHandler(event) {
		event.preventDefault();
		const {datas, actions} = this.state;
		const props = this.props;

		this.setState({disabled: true});

		const formData = new FormData(event.target);
		formData.append('files', datas.files);
		if(actions === 'create') {
			this.service.fetchStore(formData)
				.then(
					res => {
						this.setState({disabled: false});
						if(res.success) {
							props.onHide();
							Helpers.swalSuccess(DBMessage.FETCH_SUCCESS_TITLE, res.message);
						} else {
							Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, res.message);
						}
					}
				)
				.catch(error => {
					this.setState({disabled: false});
				});
		} else if(actions === 'edit') {
			this.service.fetchUpdate(formData, datas.colorid)
				.then(
					res => {
						this.setState({disabled: false});
						if(res.success) {
							props.onHide();
							Helpers.swalSuccess(DBMessage.FETCH_SUCCESS_TITLE, res.message);
						} else {
							Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, res.message);
						}
					}
				)
				.catch(error => {
					this.setState({disabled: false});
				});
		}
	}
	renderImage() {
		const {datas} = this.state;

		let $image = $('#image-preview');
		$image.css({
			width: '100%',
			height: 500,
			backgroundPosition: 'center',
			backgroundSize: 'contain',
			backgroundColor: '#000',
			backgroundRepeat: 'no-repeat',
		});

		if(datas.files) {
			if(typeof datas.files !== 'string') {
				const Reader = new FileReader();
				Reader.readAsDataURL(datas.files);
				Reader.onloadend = function() {
					$image.css({backgroundImage: `url(${this.result})`});
				};
			} else {
				$image.css({backgroundImage: `url(${datas.files})`});
			}
		} else {
			$image.removeAttr('style');
		}
	}
	render() {
		const props = this.props;
		const {disabled, datas} = this.state;
		const popover = {
	      position: 'absolute',
	      zIndex: '2',
    	};
	    const cover = {
	      position: 'fixed',
	      top: '0px',
	      right: '0px',
	      bottom: '0px',
	      left: '0px',
	    };

		return (
			<Modal {...props} onShow={this.onShow.bind(this)} onHide={props.onHide}>
				<Modal.Header closeButton>
					<Modal.Title>Form Color</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form id={"form-group"} onSubmit={this.onSubmitHandler.bind(this)}>
						<div className={'row'}>
							<div className={'col-sm-8'}>
								<div className={"form-group"}>
									<label htmlFor={"input-colorcode"}>Kode Warna</label>
									<input
										onClick={ this.handleClick }
										type={"text"}
										className={"form-control"}
										value={datas.colorcode}
										name={"colorcode"}
										placeholder={"Masukan kode warna"}
										disabled={disabled}
										required={true}
										autoComplete={'off'}
										onChange={this.onChangeHandler.bind(this)}/>

									{ this.state.displayColorPicker ? <div style={ popover }>
										<div style={ cover } onClick={ this.handleClose } />
										<SketchPicker color={{hex: datas.colorcode}} onChange={ this.handleChange.bind(this) } />
									</div> : null }
								</div>
							</div>
							<div className={'col-sm-4'}>
								<div style={{
									backgroundColor: datas.colorcode,
									width: '100%',
									height: '100%',
									border: 1,
									borderRadius: 5,
									borderStyle: 'solid',
									borderColor: '#f1f1f1',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									fontSize: 10,
									color: '#b3b3b3',
								}}>{!datas.colorcode ? 'Preview' : null}</div>
							</div>
						</div>

						<div className={"form-group"}>
							<label htmlFor={"input-colorname"}>Nama Warna</label>
							<input
								type={"text"}
								id={"input-colorname"}
								className={"form-control"}
								name={"colorname"}
								value={datas.colorname}
								placeholder={"Masukan nama warna"}
								onChange={this.onChangeHandler.bind(this)}
								disabled={disabled}
								required={true}/>
						</div>
						<div className={"form-group"}>
							<label htmlFor={"input-colorals"}>Warna Als</label>
							<input
								type={"text"}
								id={"input-colorals"}
								className={"form-control"}
								name={"colorals"}
								value={datas.colorals}
								placeholder={"Masukan warna als"}
								onChange={this.onChangeHandler.bind(this)}
								disabled={disabled}
								required={true}/>
						</div>
						<div className={"form-group"}>
							<label htmlFor={"input-groupcolorid"}>Group Color</label>
							<AsyncSelect
								value={datas.groupcolorid}
								cacheOptions
								loadOptions={API_SELECT.groupColor}
								defaultOptions
								placeholder={'Pilih group color id'}
								name={"groupcolorid"}
								onChange={this.onChangeSelect.bind(this)}
							/>
						</div>
						<div className={'form-group'}>
							<label>Gambar</label>
							<div className={'form-upload'}>
								<input type={'file'} id={'file-color'} style={{display: 'none'}} />
								<div className="input-group mb-3">
									<div className="input-group-prepend">
										<Button
											variant={"primary"}
											size={"sm"}
											className={"mr-1"}
											onClick={this.onClickUpload.bind(this)}>
											<span>Upload Gambar</span>
										</Button>
									</div>
									<input
										type={'text'}
										className={'form-control'}
										placeholder={'Nama filename'}
										disabled={true}
										value={datas.files ? datas.files.name : null}
									/>
								</div>
								<div id={'image-preview'}>
									{ this.renderImage() }
								</div>
							</div>
						</div>
					</form>
				</Modal.Body>
				<Modal.Footer>
					<Button type={"button"} variant="secondary" size={"sm"} onClick={props.onHide} disabled={disabled}>
						Batal
					</Button>
					<Button type={"submit"} variant="primary" size={"sm"} form={"form-group"} disabled={disabled}>
						{!disabled ? 'Simpan' : 'Memproses ...'}
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default ColorForm;
