import React from "react";
import {DBCode, DBStyles} from "../../../module/constants";
import Header from "../../skin/header";
import Sidebar from "../../skin/sidebar";
import PageHeader from "../../skin/pageheader";
import ServiceTransactionOrder from "../../../services/transaction-order";
import {Link, Redirect} from "react-router-dom";

const moment = require('moment');

class ReportOrdersViewComponent extends React.Component {
	constructor(props) {
		super(props);

		const {token} = props.match.params;
		const params = JSON.parse(atob(token));
		const userid = atob(params[DBCode.KEY_PARAM_USERID]);
		const orderid = atob(params[DBCode.KEY_PARAM_ORDERID]);
		const productid = atob(params[DBCode.KEY_PARAM_PRODUCTID]);

		this.state = {
			refreshing: false,
			title: 'Data Order',
			breadcrumbs: [
				{link: '#', menu: 'Orders'}
			],
			orderid: orderid,
			userid: userid,
			productid: productid,
			order: null,
		};

		this.service = new ServiceTransactionOrder();

		this.fetchDetail();
	}
	onClickCancelOrder() {
		const {orderid, order} = this.state;
		const params = {
			orderid: orderid,
			ordercd: order.ordercd,
			orderdate: order.orderdate.substr(0, 10),
			type: order.typecancelorder,
			warehouse: {
				whfrom: order.whhq,
				whto: order.whprd,
			}
		};

		this.setState({
			redirect: '/inventory/stock/create?orderid=' + btoa(JSON.stringify(params))
		});
	}
	fetchDetail() {
		const {orderid} = this.state;
		this.service.fetchDetail(orderid)
			.then(
				res => {
					if(res.success) {
						this.setState({
							refreshing: false,
							order: res.order,
						});
					}
				}
			)
	}
	renderStatus() {
		const {order} = this.state;
		return [
			order.statuses.map((status, i) => {
				return (
					<div className={'row'} key={`status-${i}`}>
						<b className={'col-sm-4'}>{status.statusdate}</b>
						<p className={'col-sm-8 text-left'}><b>({status.status.statusnm})</b> {status.descriptions}</p>
					</div>
				);
			}),
			<div className={'row'} key={`status-created`}>
				<b className={'col-sm-4'}>{order.orderdate}</b>
				<p className={'col-sm-8 text-left'}><b>(Pesanan telah dibuat)</b> Pesanan telah berhasil dibuat pada tanggal {order.orderdate}</p>
			</div>
		]
	}
	renderVarsize() {
		const {order} = this.state;
		return (order.sizes.length > 0)
			? [
				<div className={'section-form'} style={{height: 'auto'}}>
					<div className={'form-body'}>
						<div className={'form-body-title'}>
							Daftar Ukuran
						</div>
						<div className={'form-label'}>
							<table className={'table table-striped table-hovered'}>
								<thead>
								<tr>
									<th>Ukuran</th>
									<th>QTY</th>
								</tr>
								</thead>
								<tbody>
								{
									order.sizes.map((size, i) => {
										return (
											<tr>
												<td>{size.size.sizename}</td>
												<td>{size.qty} Pcs</td>
											</tr>
										)
									})
								}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			] : null;
	}
	renderDetail(datas) {
		return (datas.length > 0)
			? [
				<div className={'form-body-title'} key={'title-detail'}>
					Detail Pesanan
				</div>,
				<table className={'table table-striped table-hovered'} key={'table-detail'}>
					<thead>
					<tr>
						<th>No</th>
						<th>Partname</th>
						<th>Bahan</th>
						<th>Warna</th>
					</tr>
					</thead>
					<tbody>
					{
						datas.map((data, i) => {
							return (
								<tr>
									<td>{i + 1}</td>
									<td>{data.partname}</td>
									<td>{data.material.materialname}</td>
									<td>{data.color.colorname}</td>
								</tr>
							);
						})
					}
					</tbody>
				</table>
			] : null;
	}
	renderAccessoris(datas) {
		return (datas.length > 0)
			? [
				<div className={'form-body-title'} key={'title-accessoris'}>
					Accessoris Pesanan
				</div>,
				<table className={'table table-striped table-hovered'} key={'table-accessoris'}>
					<thead>
					<tr>
						<th>No</th>
						<th>Aksessoris</th>
						<th>Warna</th>
					</tr>
					</thead>
					<tbody>
					{
						datas.map((data, i) => {
							return (
								<tr>
									<td>{i + 1}</td>
									<td>{i + 1}</td>
									<td>{i + 1}</td>
								</tr>
							)
						})
					}
					</tbody>
				</table>
			] : null
	}
	render() {
		const {redirect, order, title, breadcrumbs} = this.state;

		if(redirect) return <Redirect to={redirect} />;

		return (
			<div className={DBStyles.content.wrapper}>
				<Header />

				<Sidebar />

				<div className={'main-panel apps'}>
					<div className={'content'}>
						<div className={'page-inner'}>
							<PageHeader title={title} breadcrumbs={breadcrumbs} />

							<div className={'card'}>
								<div className={'card-body'}>
									<div className={'row'}>
										<div className={'col-md-8'}>
											<div className={'section-form'}>
												<div className={'form-header'}>
													<div className={'form-title'}>Informasi Pemesanan</div>
												</div>
												<div className={'form-body'}>
													{
														order && order.customer ?
															(
																<div className={'form-label'}>
																	<b>{order.customer.customername}</b>
																	<p>{order.customer.address}</p>
																	<p>{order.customer.phone}</p>
																</div>
															) : null
													}
													<div className={'form-body-title'}>
														Status Pesanan
													</div>
													<div className={'form-label tracking-order custom-scroll-1'}>
														{
															order && order.statuses
																? this.renderStatus()
																: null
														}
													</div>
													<div className={'form-label'}>
														{
															order && order.productvardetail
																? this.renderDetail(order.productvardetail)
																: null
														}
													</div>
													<div className={'form-label'}>
														{
															order && order.productvaracc
																? this.renderAccessoris(order.productvaracc)
																: null
														}
													</div>
												</div>
											</div>
										</div>
										<div className={'col-md-4'}>
											<div className={'section-form'}>
												<div className={'form-header'}>
													<div className={'form-title'}>Informasi Produk</div>
												</div>
												<div className={'form-body'}>
													<div className={'form-body'}>
														<div className={'info-product process-product'}>
															<div className={'tumb-image'} style={{backgroundImage: `url(${order && order.images ? order.images : null})`}} />
															<div className={'info-product-content'}>
																<div className={'product-title'}>
																	{order && order.product ? order.product.productname + ' ' : null}
																	({
																	order && order.producttype
																		? order.producttype.type.typename
																		: null
																})
																</div>
																<div className={'product-text'}>
																	{
																		order && order.producttype
																			? 'Tipe Ukuran : ' + order.producttype.groupsize.typename
																			:  	[
																				<span key={'sizegroup-label'} className={'product-text'}>Size Group : </span>,
																				<span key={'sizegroup-value'} className={'product-text secondary'}>Belum Dipilih</span>,
																			]
																	}
																</div>
																<div className={'product-text'}>
																	{
																		order && order.productvar
																			? 'Varian Produk : ' + order.productvar.productvar
																			:  	[
																				<span key={'variant-label'} className={'product-text'}>Variant Produk : </span>,
																				<span key={'variant-value'} className={'product-text secondary'}>Belum Dipilih</span>,
																			]
																	}
																</div>
															</div>
														</div>
														{
															order && order.sizes.length > 0
																? this.renderVarsize()
																: null
														}
														<div className={'d-flex mb-2'}>
															<button
																type={'button'}
																className={'col-sm-6 btn btn-danger mr-1'}
																onClick={this.onClickCancelOrder.bind(this)}
															>
																Batalkan Pesanan
															</button>
															<Link
																to={'/report/orders'}
																className={'col-sm-6 btn btn-primary'}
															>
																Kembali
															</Link>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default ReportOrdersViewComponent;
