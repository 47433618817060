import $ from "jquery";
import ServiceMasterUsers from "../../../services/master-users";

const service = new ServiceMasterUsers();

export const DATATABLES = {
	serverSide: {
		url: service.datatables,
		type: 'post'
	},
	columns: [
		{title: 'No'},
		{title: 'Nama Lengkap', data: 'fullname'},
		{title: 'Nama Pengguna', data: 'username'},
		{title: 'Group', data: 'group.groupname'},
		{title: 'Aksi'},
	],
	columnDefs: [
		{
			targets: 0,
			width: 20,
			render: (data, type, row, meta) => {
				return meta.row + meta.settings._iDisplayStart + 1;
			},
		},
		{
			targets: 3,
			render: (data, type, row, meta) => {
				return row.group.groupname;
			}
		},
		{
			targets: 4,
			width: 80,
			render: (data, type, row, meta) => {
				const $actions = $('<div>');

				const $edit = $("<button>");
				$edit.addClass("btn btn-primary btn-xs");
				$edit.attr("actions", "edit");
				$edit.attr("data-id", row.userid);
				$edit.append($("<i>", { class: "fa fa-edit" }));

				$actions.append($edit);

				const $delete = $('<button>');
				$delete.addClass("btn btn-danger btn-xs ml-1");
				$delete.attr("actions", "delete");
				$delete.attr("data-id", row.userid);
				$delete.append($("<i>", { class: "fa fa-trash" }));

				$actions.append($delete);

				return $actions.get(0).outerHTML;
			}
		}
	]
};
