import React from "react";
import {Modal} from "react-bootstrap";
import {FormControlLabel} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import ServiceReportOrder from "../../../services/report-order";
import {DBCode, DBMessage} from "../../../module/constants";
import {Redirect} from "react-router";

const sprintf = require('sprintf-js').sprintf;

class CancelOrders extends React.Component {
	constructor() {
		super();

		this.state = {
			refreshing: false,
			datas: {
				orderid: null,
				whhq: null,
				whprd: null,
				ordercd: null,
				productvardetail: [],
				productvaracc: [],
				productvarsizes: [],
				productvarmaterials: [],
			},
			stocks: {},
		};

		this.serviceOrder = new ServiceReportOrder();
	}
	onShow() {
		const {datas} = this.props;

		this.serviceOrder.fetch(datas.orderid, DBCode.WAREHOUSE_PRD, 'qtycanceled')
			.then(
				res => {
					if(res.success) {
						this.setState({
							datas: {
								ordercd: res.order.ordercd,
								orderid: res.order.orderid,
								whhq: res.order.whhq,
								whprd: res.order.whprd,
								productvaracc: res.order.orderAccessoris,
								productvardetail: res.order.orderDetail,
								productvarsizes: res.order.orderSizes,
								productvarmaterials: res.order.orderMaterials,
							},
							stocks: res.stocks
						});
					} else {
						this.setState({
							redirect: '/inventory/stock',
						});
					}
				}
			)
	}
	onClose() {
		const {onHide} = this.props;
		const {datas} = this.state;

		let materials = [];
		Object.keys(datas.productvarmaterials).forEach(materialid => {
			const material = datas.productvarmaterials[materialid];
			Object.keys(material.uoms).forEach(uomid => {
				const uom = material.uoms[uomid];
				if(uom.usedTotal > 0) {
					materials.push({
						id: 0,
						materialtype: {
							id: material.material.type.category.catid,
							text: material.material.type.category.catname,
						},
						type: {
							id: material.material.type.typeid,
							text: material.material.type.typename,
						},
						material: {
							id: material.material.materialid,
							text: material.material.materialname
						},
						materialcolor: {
							groupcolor: {
								value: material.color.groupcolorid.typeid,
								label: material.color.groupcolorid.typename,
							},
							color: material.color
						},
						materialuom: {
							id: uom.uomid,
							text: uom.uomname,
						},
						qty: parseFloat(uom.usedTotal.toFixed(3)),
						deleted: false,
					})
				}
			})
		});

		onHide({
			orderid: datas.orderid,
			warehouse: {
				whto: datas.whhq,
				whfrom: datas.whprd,
			},
			materials: materials,
			productvarsizes: datas.productvarsizes,
		});
	}
	onChangeSize(name, value, i) {
		const {datas} = this.state;
		const {productvarsizes} = datas;
		productvarsizes[i][name] = value;

		this.setState({
			datas: {
				...datas,
				productvarsizes: productvarsizes
			}
		});
	}
	onChangeDetailUOM(name, value, i, uomid) {
		const {datas} = this.state;
		datas.productvarmaterials[i].uoms[uomid][name] = value;

		this.setState({datas: datas});
	}
	onChangeAccUOM(name, value, i, uomid) {
		const {datas} = this.state;
		datas.productvaracc[i].uoms[uomid][name] = value;

		this.setState({datas: datas});
	}
	isValid() {
		const {datas} = this.state;
		let isValid = datas.productvarsizes.length > 0;
		datas.productvarsizes.forEach(data => {
			if (data.info.isError) {
				isValid = false;
				return true;
			}
		});

		return !isValid;
	}
	calculateStock(value, i) {
		const {datas, stocks} = this.state;

		if(isNaN(value)) value = 1;

		Object.keys(stocks).forEach((kStock) => {
			stocks[kStock].stockAvaiable = stocks[kStock].qtyavailable !== null
				? parseFloat(parseFloat(stocks[kStock].qtyavailable).toFixed(3))
				: 0;
			stocks[kStock].stockUsed = 0;
		});

		datas.productvardetail.forEach((detail, i) => {
			datas.productvardetail[i].needQty = 0;
			datas.productvardetail[i].usedQty = 0;
			Object.keys(datas.productvardetail[i].uoms).forEach((uom) => {
				datas.productvardetail[i].uoms[uom].needTotal = 0;
				datas.productvardetail[i].uoms[uom].usedTotal = 0;
			});
		});

		datas.productvaracc.forEach((acc, i) => {
			datas.productvaracc[i].needQty = 0;
			datas.productvaracc[i].usedQty = 0;
			Object.keys(datas.productvaracc[i].uoms).forEach((uom) => {
				datas.productvaracc[i].uoms[uom].needTotal = 0;
				datas.productvaracc[i].uoms[uom].usedTotal = 0;
			});
		});

		Object.keys(datas.productvarmaterials).forEach(materialid => {
			let material = datas.productvarmaterials[materialid];
			Object.keys(material.uoms).forEach(kuom => {
				datas.productvarmaterials[materialid].uoms[kuom].usedTotal = 0;
			});
		});

		let needQty = 0;

		datas.productvarsizes[i].qtycanceled = value;
		datas.productvarsizes.forEach((size) => {
			size.estimateQty = null;

			size.details.forEach((detail, j) => {
				needQty = size.qtycanceled;
				detail.stockEstimateQty = 0;

				detail.uoms.forEach((uom) => {

					const token = btoa(sprintf('%(materialdtid)s.%(uomid)s.%(warehouseid)s.%(stocktypeid)s', {
						materialdtid: uom.materialdt.materialdtid,
						uomid: uom.materialdt.uom.uomid,
						warehouseid: uom.stock.whid,
						stocktypeid: uom.stock.stocktypeid,
					}));

					uom.token = token;
					uom.stockAvaiable = stocks[token].stockAvaiable;

					let estimateQty = Math.floor(uom.stockAvaiable/uom.perItem);
					detail.stockEstimateQty += estimateQty;

					let needTotal = parseFloat((uom.perItem * needQty).toFixed(3));
					uom.needTotal = needTotal;

					let diffTotal = needTotal - uom.stockAvaiable;

					let calcEstimateQty = 0;
					if(diffTotal > 0) calcEstimateQty = Math.floor((needTotal - diffTotal)/uom.perItem);
					else calcEstimateQty = needQty;

					uom.calcEstimateQty = calcEstimateQty;

					let usedQty = 0;
					if(needTotal >= uom.stockAvaiable) usedQty = calcEstimateQty;
					else if(uom.stockAvaiable > needTotal) usedQty = needQty;

					uom.usedQty = usedQty;

					uom.usedTotal = uom.perItem * usedQty;

					uom.needQty = needQty;

					needQty -= usedQty;

					datas.productvardetail[j].usedQty += usedQty;
					datas.productvardetail[j].uoms['key_'+uom.materialdt.uom.uomid].usedTotal += uom.usedTotal;
					datas.productvardetail[j].uoms['key_'+uom.materialdt.uom.uomid].needTotal += size.qtycanceled * uom.perItem;

					stocks[token].stockAvaiable -= uom.usedTotal;
					stocks[token].stockUsed += usedQty;
				});

				datas.productvardetail[j].needQty += size.qtycanceled;

				if(size.estimateQty == null || size.estimateQty > detail.stockEstimateQty) {
					size.estimateQty = detail.stockEstimateQty;
					size.info.args.estimate = detail.stockEstimateQty;
				}

				if(detail.stockEstimateQty === 0) {
					size.info.args = {
						materialname: detail.material.materialname,
						colorname: detail.color.label,
					};
					size.info.message = DBMessage.PARAMS_STOCK_INSUFFICIENT;
				}
			});

			size.accessoris.forEach((acc, j) => {
				needQty = size.qtycanceled;
				acc.stockEstimateQty = 0;

				acc.uoms.forEach((uom) => {
					const token = btoa(sprintf('%(materialdtid)s.%(uomid)s.%(warehouseid)s.%(stocktypeid)s', {
						materialdtid: uom.materialdt.materialdtid,
						uomid: uom.materialdt.uom.uomid,
						warehouseid: uom.stock.whid,
						stocktypeid: uom.stock.stocktypeid,
					}));

					uom.token = token;
					uom.stockAvaiable = stocks[token].stockAvaiable;

					let estimateQty = Math.floor(uom.stockAvaiable/uom.perItem);
					acc.stockEstimateQty += estimateQty;

					let needTotal = parseFloat((uom.perItem * needQty).toFixed(3));
					uom.needTotal = needTotal;

					let diffTotal = needTotal - uom.stockAvaiable;

					let calcEstimateQty = 0;
					if(diffTotal > 0) calcEstimateQty = Math.floor((needTotal - diffTotal)/uom.perItem);
					else calcEstimateQty = needQty;

					uom.calcEstimateQty = calcEstimateQty;

					let usedQty = 0;
					if(needTotal >= uom.stockAvaiable) usedQty = calcEstimateQty;
					else if(uom.stockAvaiable > needTotal) usedQty = needQty;

					uom.usedQty = usedQty;

					uom.usedTotal = parseFloat((uom.perItem * calcEstimateQty).toFixed(3));

					uom.needQty = needQty;
					needQty -= usedQty;

					datas.productvaracc[j].usedQty += usedQty;
					datas.productvaracc[j].uoms['key_'+uom.materialdt.uom.uomid].usedTotal += uom.usedTotal;
					datas.productvaracc[j].uoms['key_'+uom.materialdt.uom.uomid].needTotal += size.qtycanceled * uom.perItem;

					stocks[token].stockAvaiable -= uom.usedTotal;
					stocks[token].stockUsed += usedQty;
				});

				datas.productvaracc[j].needQty += size.qtycanceled;

				if(size.estimateQty == null || size.estimateQty > acc.stockEstimateQty) {
					size.estimateQty = acc.stockEstimateQty;
					size.info.args.estimate = acc.stockEstimateQty;
				}

				if(acc.stockEstimateQty === 0) {
					size.info.args = {
						materialname: acc.material.materialname,
						colorname: acc.color.label,
					};
					size.info.message = DBMessage.PARAMS_STOCK_INSUFFICIENT;
				}
			});

			if(size.estimateQty > 0) {
				size.info.args = {
					estimate: size.estimateQty,
					sizename: size.sizename,
				};
				size.info.message = DBMessage.PARAMS_STOCK_EXIST;
			}
		});

		datas.productvarsizes.forEach(size => {
			size.details.forEach(detail => {
				let needQty = size.qtycanceled;
				let key = detail.material.materialid + '.' + detail.color.value;
				Object.keys(detail.uoms).forEach(kuom => {
					let uom = detail.uoms[kuom];

					let total = uom.perItem * needQty;
					let estimate = 0;
					if(uom.perItem && uom.perItem > 0) {
						estimate = Math.floor(uom.stockAvaiable/uom.perItem);
						if(uom.stockAvaiable > total) {
							if(estimate >= needQty) {
								estimate = needQty;
							}
						}
					}

					let usedTotal = parseFloat((estimate * uom.perItem).toFixed(3));

					datas.productvarmaterials[key].uoms['key_'+uom.materialdt.uom.uomid].usedTotal += usedTotal;
				});
			});

			size.accessoris.forEach(acc => {
				let needQty = size.qtycanceled;
				let key = acc.material.materialid + '.' + acc.color.value;
				Object.keys(acc.uoms).forEach(kuom => {
					let uom = acc.uoms[kuom];

					let total = uom.perItem * needQty;
					let estimate = 0;
					if(uom.perItem && uom.perItem > 0) {
						estimate = Math.floor(uom.stockAvaiable/uom.perItem);
						if(uom.stockAvaiable > total) {
							if(estimate >= needQty) {
								estimate = needQty;
							}
						}
					}

					let usedTotal = parseFloat((estimate * uom.perItem).toFixed(3));

					datas.productvarmaterials[key].uoms['key_'+uom.materialdt.uom.uomid].usedTotal += usedTotal;
				});
			})
		});

		this.setState({ datas: datas, stocks: stocks });
	}
	render() {
		const {show, onHide} = this.props;
		const {ordercd, datas} = this.state;
		const {redirect, productvarsizes, productvarmaterials} = datas;

		if(redirect) return <Redirect to={redirect} />;

		return (
			<Modal show={show} onHide={() => onHide(null)} onShow={this.onShow.bind(this)}>
				<Modal.Body>
					<div className={'form-size'}>
						<div className={'form-size-header'}>
							<div className={'header-title'}>
								Batalkan Order {ordercd}
								<span className={'close'} onClick={() => {
									onHide(null);
								}}>&times;</span>
							</div>
						</div>
						<div className={'form-size-body scrollable-y'}>
							<ul className={'nav-list'}>
								{
									productvarsizes.map((data, i) => {
										let qtycanceled = parseInt(data.qtycanceled);
										let max = parseInt(data._qtyapproved) - parseInt(data._qtycanceled === null ? 0 : data._qtycanceled);
										return (max > 0)
											? (
												<li key={`size-${i}`} className={'list-item'}>
													<div className={'d-flex justify-content-between align-items-center'}>
														<FormControlLabel
															control={
																<Checkbox
																	checked={data.checked && !data.info.isError}
																	color={'primary'}
																	onChange={() => {
																		this.calculateStock(!data.checked ? 1 : 0, i);
																		this.onChangeSize('checked', !data.checked, i);
																	}}
																	disabled={data.info.isError}
																/>
															}
															label={data.sizename}
														/>
														{
															data.checked
																? (
																	<div className={'form-number'}>
																		<i className={`fa fa-minus action-item ${data.info.isError ? 'disabled' : ''}`} onClick={() => {
																			if(!data.info.isError) this.calculateStock(qtycanceled > 1 ? qtycanceled - 1 : 1, i);
																		}} />
																		<input
																			type={'number'}
																			className={'action-input'}
																			placeholder={'0'}
																			value={qtycanceled}
																			onChange={event => this.calculateStock(parseInt(event.target.value) < max ? (parseInt(event.target.value) < 1) ? 1 : parseInt(event.target.value) : max, i)}
																			disabled={data.info.isError}
																		/>
																		<i className={`fa fa-plus action-item ${data.info.isError ? 'disabled' : ''}`} onClick={() => {
																			if(!data.info.isError) this.calculateStock(qtycanceled < max ? qtycanceled + 1 : max, i);
																		}} disabled={data.info.isError} />
																	</div>
																)
																: null
														}
													</div>
													{
														(data.info.isError)
															? (
																<small className={`info text-danger`}>{sprintf(data.info.message, data.info.args)}</small>
															) : (
																<small className={`info`}>Maksimal qty batal order {max} Pcs</small>
															)
													}
												</li>
											) : null
									})
								}
							</ul>
						</div>
						{
							Object.keys(productvarmaterials).map(materialid => {
								const material = productvarmaterials[materialid];
								return (
									<div className={'row mb-2'}>
										<div className={'col-md-12'}>
											<b>{material.material.materialname} - {material.color.label}</b>
										</div>
										{
											Object.keys(material.uoms).map((uomid) => {
												const uom = material.uoms[uomid];
												return (
													<div className={'col-md-4 col-sm-4'}>
														<label>{uom.uomname}</label>
														<input
															type={'text'}
															className={'form-control'}
															value={!isNaN(uom.usedTotal) ? parseFloat(uom.usedTotal.toFixed(3)) : 0}
															onChange={event => this.onChangeDetailUOM('usedTotal', event.target.value, materialid, uomid)}
														/>
													</div>
												);
											})
										}
									</div>
								)
							})
						}
					</div>
					<button className={'btn btn-primary btn-block'} onClick={this.onClose.bind(this)} disabled={this.isValid()}>
						Simpan
					</button>
				</Modal.Body>
			</Modal>
		);
	}
}

export default CancelOrders;
