import React from "react";
import RouteGuest from "../module/routes/route-guest";
import LoginComponent from "../app/auth/login/login";
import {Route} from "react-router";
import Auth from "../module/helpers/auth";

const routes_auth = [
	<RouteGuest exact={true} path={'/login'} component={LoginComponent} key={'auth-login'} />,
	<Route exact={true} path={"/logout"} component={() => {
		return Auth.logout(() => {
			window.location.href = '/login';
		});
	}} key={"auth-logout"} />,
];

export default routes_auth;
