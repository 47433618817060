import React from "react";
import {Link} from "react-router-dom";

class HeaderLogo extends React.Component {
	render() {
		return (
			<div className={"logo-header"} data-background-color={"blue"}>
				<Link to={"/"} className={"logo"}>
					<span className={"logo-text"}>Indarto Pratama</span>
				</Link>
				<button
					className={"navbar-toggler sidenav-toggler ml-auto"}
					type={"button"}
					data-toggle={"collapse"}
					data-target={"collapse"}
					aria-expanded={"false"}
					aria-label={"Toggle Navigation"}
				>
                    <span className={"navbar-toggler-icon"}>
                        <i className={"icon-menu"} />
                    </span>
				</button>
				<button className={"topbar-toggler more"}>
					<i className={"icon-options-vertical"} />
				</button>
				<div className={"nav-toggle"}>
					<button className={"btn btn-toggle toggle-sidebar"}>
						<i className={"icon-menu"} />
					</button>
				</div>
			</div>
		);
	}
}

export default HeaderLogo;
