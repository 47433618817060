import $ from "jquery";
import ServiceMasterMenu from "../../../services/master-menu";

const service = new ServiceMasterMenu();

export const DATATABLES = {
	serverSide: {
		url: service.datatables,
		type: 'post'
	},
	columns: [
		{title: 'No'},
		{title: 'Nama Menu', data: 'menuname'},
		{title: 'Master Menu', data: 'master.menuname'},
		{title: 'Icon', data: 'icon'},
		{title: 'Aksi'},
	],
	columnDefs: [
		{
			targets: 0,
			width: 20,
			render: (data, type, row, meta) => {
				return meta.row + meta.settings._iDisplayStart + 1;
			}
		},
		{
			targets: 1,
			render: (data, type, row, meta) => {
				let $div = $('<div>', { class: 'cell-menu' });
				$div.append($('<div>', { class: 'name' }).text(row.menuname));
				$div.append($('<span>', { class: 'link' }).text(row.link));

				return $div.get(0).outerHTML;
			}
		},
		{
			targets: 2,
			width: 100,
			render: (data, type, row, meta) => {
				return (row.master !== null) ? row.master.menuname : '';
			}
		},
		{
			targets: 4,
			width: 80,
			render: (data, type, row, meta) => {
				const $actions = $('<div>');

				const $edit = $("<button>");
				$edit.addClass("btn btn-primary btn-xs");
				$edit.attr("actions", "edit");
				$edit.attr("data-id", row.menuid);
				$edit.append($("<i>", { class: "fa fa-edit" }));

				$actions.append($edit);

				const $delete = $('<button>');
				$delete.addClass("btn btn-danger btn-xs ml-1");
				$delete.attr("actions", "delete");
				$delete.attr("data-id", row.menuid);
				$delete.append($("<i>", { class: "fa fa-trash" }));

				$actions.append($delete);

				return $actions.get(0).outerHTML;
			}
		}
	]
};
