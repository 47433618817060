import React from "react";
import {DBStyles} from "../../../module/constants";
import Header from "../../skin/header";
import Sidebar from "../../skin/sidebar";
import PageHeader from "../../skin/pageheader";
import AccountMenu from "./account-menu";

class AccountComponent extends React.Component {
	constructor() {
		super();

		this.state = {
			refreshing: false,
			title: 'Akun Saya',
			breadcrumbs: [
				{link: '#', menu: 'Profil'},
				{link: '#', menu: 'Akun Saya'}
			]
		};
	}
	render() {
		const {title, breadcrumbs} = this.state;

		return (
			<div className={DBStyles.content.wrapperIcon}>
				<Header />

				<Sidebar />

				<div className={'main-panel apps'}>
					<div className={'content'}>
						<div className={'page-inner'}>
							<PageHeader title={title} breadcrumbs={breadcrumbs} />

							<div className={'row'}>
								<div className={'col-md-3'}>
									<AccountMenu active={'account-home'} />
								</div>
								<div className={'col-md-9'}>
									<div className={'card'}>
										<div className={'card-body'}>
											<div className={'section-form'}>
												<div className={'form-header'}>
													<div className={'form-title'}>Akun Saya</div>
												</div>
												<div className={'form-body'}>

												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default AccountComponent;
