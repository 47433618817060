import {Config, Helpers} from "../module/core";
import $ from "jquery";
import axios from "axios";
import {DBMessage} from "../module/constants";

class ServiceMasterProductType {
	constructor(productid) {
		this.api = `${Config.api}/producttype/${productid}`;
		this.datatables = `${this.api}/datatables`;
	}
	select(searchValue) {
		return axios.get(`${this.api}/select2Api`, {
			params: {
				searchValue: searchValue
			}
		})
			.then(
				res => {
					return res.data;
				},
				error => {
					return {success: false, message: error}
				}
			)
			.catch(error => {
				Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, error);
			});
	}
	fetch(id) {
		$('body').addClass('fetching-data');
		return axios.get(`${this.api}/${id}`)
			.then(
				res => {
					$('body').removeClass('fetching-data');
					return res.data;
				},
				error => {
					$('body').removeClass('fetching-data');
					return {success: false, message: error};
				}
			)
			.catch(() => {
				$('body').removeClass('fetching-data');
				Config.notifyError(DBMessage.ERROR_NETWORK_TITLE, DBMessage.ERROR_NETWORK_MESSAGE);
			});
	}
	fetchStore(datas) {
		$('body').addClass('fetching-data');

		datas.userid = Helpers.sessUserid();

		return axios.post(`${this.api}`, datas)
			.then(
				res => {
					$('body').removeClass('fetching-data');
					return res.data;
				},
				error => {
					$('body').removeClass('fetching-data');
					return {success: false, message: error};
				}
			)
			.catch(() => {
				$('body').removeClass('fetching-data');
				Config.swalError(DBMessage.ERROR_NETWORK_TITLE, DBMessage.ERROR_NETWORK_MESSAGE);
			});
	}
	fetchUpdate(datas, id) {
		$('body').addClass('fetching-data');

		datas.userid = Helpers.sessUserid();

		return axios.put(`${this.api}/${id}`, datas)
			.then(
				res => {
					$('body').removeClass('fetching-data');
					return res.data;
				},
				error => {
					$('body').removeClass('fetching-data');
					return {success: false, message: error};
				}
			)
			.catch(() => {
				$('body').removeClass('fetching-data');
				Config.notifyError(DBMessage.ERROR_NETWORK_TITLE, DBMessage.ERROR_NETWORK_MESSAGE);
			});
	}
	fetchDelete(id) {
		$('body').addClass('fetching-data');
		return axios.delete(`${this.api}/${id}`)
			.then(
				res => {
					$('body').removeClass('fetching-data');
					return res.data;
				},
				error => {
					$('body').removeClass('fetching-data');
					return {success: false, message: error};
				}
			)
			.catch(() => {
				$('body').removeClass('fetching-data');
				Config.notifyError(DBMessage.ERROR_NETWORK_TITLE, DBMessage.ERROR_NETWORK_MESSAGE);
			});
	}
}

export default ServiceMasterProductType;
