import ServiceReportStock from "../../../services/report-stock";

import {DBCode} from "../../../module/constants";

import $ from 'jquery';
import ServiceMasterType from "../../../services/master-type";
import ServiceMasterMaterial from "../../../services/master-material";
import ServiceMasterWareHouse from "../../../services/master-warehouse";

const service = new ServiceReportStock();
const serviceType = new ServiceMasterType();
const serviceMaterial = new ServiceMasterMaterial();
const serviceWarehouse = new ServiceMasterWareHouse();

export const DATATABLES = {
	serverSide: {
		url: service.datatables,
		type: 'post',
		data: {
			materialid: () => $('#select2-filter-material').val(),
			warehouseid: () => $('#select2-filter-warehouse').val()
		}
	},
	columns: [
		{title: 'No'},
		{title: 'Item Code', data: 'materialdt.materialcode'},
		{title: 'Item', data: 'materialdt.material.materialname'},
		{title: 'Stock Type', data: 'type.typename'},
		{title: 'Nama Uom', data: 'uom.uomname'},
		{title: 'Nama Warehouse', data: 'warehouse.whname'},
		{title: 'Nama Size', data: 'size.sizename'},
		{title: 'Qty Onhand', data: 'qtyonhand'},
		{title: 'Qty Picked', data: 'qtypicked'},
		{title: 'Qty Reserved', data: 'qtyreserved'},
		{title: 'Qty Available', data: 'qtyavailable'},
		{title: 'Aksi'},
	],
	columnDefs: [
		{
			targets: 0,
			width: 10,
			render: (data, type, row, meta) => {
				return meta.row + meta.settings._iDisplayStart + 1;
			},
		},
		{
			targets: 2,
			render: function (data, type, row, meta) {
				let text;
				if(parseInt(row.type.typeid)===DBCode.TYPE_STOCK_RAWMATERIAL){
					text = (row.materialdt !== null) ? row.materialdt.color.colorname + ' - ' + row.materialdt.material.materialname : '';
				}else{
					text = (row.product !== null) ? row.product.productname : '';
				}

				let $div = $('<div />');
				$div.css('width', '120px');
				$div.text(text);

				return $div[0].outerHTML;
			}
		},
		{
			targets: 3,
			render: (data, type, row, meta) => {
				return row.type ? row.type.typename : '-';
			}
		},
		{
			targets: 4,
			render: (data, type, row, meta) => {
				return row.uom ? row.uom.uomname : '-';
			}
		},
		{
			targets: 5,
			render: (data, type, row, meta) => {
				return row.warehouse ? row.warehouse.whname : '-';
			}
		},
		{
			targets: 6,
			render: (data, type, row, meta) => {
				return row.size ? row.size.sizename : '-';
			}
		},
		{
			targets: 7,
			render: (data, type, row, meta) => {
				return row.qtyonhand ? parseFloat(row.qtyonhand) : 0;
			}
		},
		{
			targets: 8,
			render: (data, type, row, meta) => {
				return row.qtypicked ? parseFloat(row.qtypicked) : 0;
			}
		},
		{
			targets: 9,
			render: (data, type, row, meta) => {
				return row.qtyreserved ? parseFloat(row.qtyreserved) : 0;
			}
		},
		{
			targets: 10,
			render: (data, type, row, meta) => {
				return row.qtyavailable ? parseFloat(row.qtyavailable): 0;
			}
		},
		{
			targets: 11,
			render: (data, type, row, meta) => {
				let $wrapper = $('<div>');

				let $ButtonDetail = $('<button>', {
					type: 'button',
					class: 'btn btn-primary btn-xs',
					actions: 'detail',
					'data-id': row.stockid,
				});
				$ButtonDetail.append($('<i>', {class: 'fa fa-info-circle'}));
				$ButtonDetail.append($('<span>').text('Lihat Detail'));

				$wrapper.append($ButtonDetail);

				return $wrapper.get(0).outerHTML;
			}
		}

	]
};

export const DATATABLES_DETAIL = {
	serverSide: function(params) {
		return {
			url: service.datatablesDetail,
			type: 'post',
			data: params
		};
	},
	columns: [
		{title: 'No'},
		{title: 'Transaksi', data: 'type.typename'},
		{title: 'Nomor'},
		{title: 'Tanggal', data: 'transdate'},
		{title: 'In', data: 'qty'},
		{title: 'Out', data: 'qty'}
	],
	columnDefs: [
		{
			targets: 0,
			width: 10,
			render: (data, type, row, meta) => {
				return meta.row + meta.settings._iDisplayStart + 1;
			},
		},
		{
			targets: 2,
			render: (data, type, row, meta) => {
				let transid = parseInt(row.transid);

				if(row.adjustment !== null) {
					return row.adjustment.adjustcd;
				} else if(transid === DBCode.TYPE_TRANSACTION_ORDERMARKETING) {
					return row.order.ordercd;
				} else {
					return null;
				}
			},
		},
		{
			targets: 4,
			render: (data, type, row, meta) => {
				return row.transtype === 'in' ? parseFloat(row.qty) : null;
			}
		},
		{
			targets: 5,
			render: (data, type, row, meta) => {
				return row.transtype === 'out' ? parseFloat(row.qty) : null;
			}
		}
	]
};

export const RENDER_COMPONENT = {
	init: function() {
		const $select2Type = $('#select2-filter-type');
		$select2Type.each(function() {
			const $item = $(this);
			if($item.data('select2')) $item.select2('destroy');
			$item.select2({
				placeholder: 'Pilih tipe',
				width: '100%',
			});
		});

		const $select2Category = $('#select2-filter-category');
		$select2Category.each(function() {
			const $item = $(this);
			if($item.data('select2')) $item.select2('destroy');
			$item.select2({
				placeholder: 'Pilih produk',
				width: '100%',
				allowClear: true,
				ajax: {
					url: serviceType.select2ByCategory,
					type: 'post',
					data: {
						categoryid: $item.data('filter-categoryid'),
					},
					dataType: 'json',
				}
			});
		});

		const $select2Material = $('#select2-filter-material');
		$select2Material.each(function() {
			const $item = $(this);
			if($item.data('select2')) $item.select2('destroy');
			$item.select2({
				placeholder: 'Pilih material',
				width: '100%',
				allowClear: true,
				ajax: {
					url: serviceMaterial.select2ByType,
					type: 'post',
					data: function(params) {
						return params;
					},
					dataType: 'json',
				}
			});
		});

		const $select2Warehouse = $('#select2-filter-warehouse');
		$select2Warehouse.each(function() {
			const $item = $(this);
			if($item.data('select2')) $item.select2('destroy');
			$item.select2({
				placeholder: 'Pilih warehouse',
				width: '100%',
				allowClear: true,
				ajax: {
					url: serviceWarehouse.select2Data,
					type: 'post',
					data: function(params) {
						return params;
					},
					dataType: 'json',
				}
			});
		});
	}
};
