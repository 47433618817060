import React from "react";
import {Button, Form, Modal} from "react-bootstrap";

import ServiceMasterMenu from "../../../services/master-menu";
import ServiceMasterGroupMenu from "../../../services/master-group-menu";

import $ from 'jquery';
import './group-access.css';
import {Helpers} from "../../../module/core";
import {DBMessage} from "../../../module/constants";

class GroupAccess extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			datas: {
				groupname: null,
			},
			access: {0: []},
			typeaccess: [],
			actions: props.actions ? props.actions : 'ready',
			disabled: false,
		};

		this.menu = new ServiceMasterMenu();
		this.access = new ServiceMasterGroupMenu();
	}
	onShow() {
		const {actions, datas} = this.props;

		this.menu.fetchGroupMenu(datas.id)
			.then(
				res => {
					let datas = {};
					res.datas.forEach(data => {
						let masterid = data.masterid;
						if(datas[masterid] === undefined) {
							datas[masterid] = [];
						}

						datas[masterid].push(data);
					});

					this.setState({
						actions: actions,
						access: datas,
						typeaccess: res.typeaccess,
					});
				}
			);
	}
	onClickAccess(event) {
		const {access} = this.state;
		let $clicked = $(event.target);

		let masterid = $clicked.data('masterid');
		let index = $clicked.data('index');
		let gmindex = parseInt($clicked.attr('data-gmindex'));
		let typeid = $clicked.data('typeid');
		let typename = $clicked.data('typename');

		let data = access[masterid][index];
		if(gmindex === 0) {
			data.access.push({
				gmid: 0,
				typeid: typeid,
				typenm: typename,
				isactive: $clicked.prop('checked')
			});
		} else {
			access[masterid][index].access[gmindex - 1].isactive = $clicked.prop('checked');
		}

		this.setState({access: access});
	}
	onSubmitHandler(event) {
		const {access} = this.state;
		const props = this.props;

		event.preventDefault();

		this.setState({ disabled: true });

		this.access.fetchUpdateAccess(access)
			.then(
				res => {
					this.setState({ disabled: false });
					props.onHide();
					if(res.success) {
						Helpers.swalSuccess(DBMessage.FETCH_SUCCESS_TITLE, res.message);
					} else {
						Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, res.message);
					}
				}
			);
	}
	renderChildren(datas) {
		const {disabled, access, typeaccess} = this.state;
		let renderComponents = [];

		datas.forEach((data, i) => {
			let menuid = data.menuid;
			let children = access[menuid] !== undefined ? access[menuid] : [];

			let renderTypeAccess = [];
			typeaccess.forEach(type => {
				let check = {
					gmid: 0,
					checked: false,
					index: 0,
				};
				data.access.forEach((gmaccess, j) => {
					if(gmaccess.typeid === type.typeid) {
						check = {
							gmid: gmaccess.gmid,
							checked: gmaccess.isactive,
							index: j + 1,
						};
						return false;
					}
				});
				renderTypeAccess.push((
					<li key={`menuid-${data.menuid}-typeid=${type.typeid}`}>
						<Form.Check
							custom
							data-gmid={check.gmid}
							data-typeid={type.typeid}
							data-typename={type.typename}
							data-menuid={data.menuid}
							data-masterid={data.masterid}
							data-index={i}
							data-gmindex={check.index}
							type={'checkbox'}
							id={`custom-${type.typename}-${data.menuid}`}
							label={type.typename}
							checked={check.checked}
							onChange={this.onClickAccess.bind(this)}
							disabled={disabled}
						/>
					</li>
				));
			});

			if(children.length === 0) {
				renderComponents.push((
					<li key={`menuid-${data.menuid}`} className={"list-item"}>
						<div className={"list-content"}>
							{data.menuname}
							<ul className={"list list-access"}>{renderTypeAccess}</ul>
						</div>
					</li>
				));
			} else {
				const renderChild = this.renderChildren(access[menuid]);
				renderComponents.push((
					<li key={`menuid-${data.menuid}`}>
						<div className={"list-content"}>
							{data.menuname}
							<ul className={"list list-access"}>{renderTypeAccess}</ul>
						</div>

						<ul className={"list list-menu"}>{renderChild}</ul>
					</li>
				));
			}
		});

		return renderComponents;
	}
	render() {
		const props = this.props;
		const {disabled, datas, access, typeaccess} = this.state;

		let renderComponents = [];
		access[0].forEach((data, i) => {
			let menuid = data.menuid;
			let children = access[menuid] !== undefined ? access[menuid] : [];

			let renderTypeAccess = [];
			typeaccess.forEach(type => {
				let check = {
					gmid: 0,
					checked: false,
					index: 0,
				};
				data.access.forEach((gmaccess, j) => {
					if(gmaccess.typeid === type.typeid) {
						check = {
							gmid: gmaccess.gmid,
							checked: gmaccess.isactive,
							index: j+1,
						};
						return false;
					}
				});
				renderTypeAccess.push((
					<li key={`menuid-${data.menuid}-typeid=${type.typeid}`}>
						<Form.Check
							custom
							data-gmid={check.gmid}
							data-typeid={type.typeid}
							data-typename={type.typename}
							data-menuid={data.menuid}
							data-masterid={data.masterid}
							data-index={i}
							data-gmindex={check.index}
							type={'checkbox'}
							id={`custom-${type.typename}-${data.menuid}`}
							label={type.typename}
							checked={check.checked}
							onChange={this.onClickAccess.bind(this)}
							disabled={disabled}
						/>
					</li>
				));
			});

			if(children.length === 0) {
				renderComponents.push((
					<li key={`menuid-${data.menuid}`}>
						<div className={"list-content"}>
							{data.menuname}
							<ul className={"list list-access"}>{renderTypeAccess}</ul>
						</div>
					</li>
				));
			} else {
				const renderChild = this.renderChildren(access[menuid]);
				renderComponents.push((
					<li key={`menuid-${data.menuid}`}>
						<div className={"list-content"}>
							{data.menuname}
							<ul className={"list list-access"}>{renderTypeAccess}</ul>
						</div>
						<ul className={"list list-menu"}>{renderChild}</ul>
					</li>
				));
			}
		});

		return (
			<Modal {...props} onShow={this.onShow.bind(this)} onHide={props.onHide} size={"lg"}>
				<Modal.Header closeButton>
					<Modal.Title>Akses Menu Group {datas.groupname}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form id={"form-access"} onSubmit={this.onSubmitHandler.bind(this)}>
						<ul className={"list list-menu"}>{renderComponents}</ul>
					</form>
				</Modal.Body>
				<Modal.Footer>
					<Button type={"button"} variant={"secondary"} size={"sm"} onClick={props.onHide} disabled={disabled}>
						Batal
					</Button>
					<Button type={"submit"} variant={"primary"} size={"sm"} disabled={disabled} form={"form-access"}>
						{disabled ? 'Memproses ...' : 'Simpan'}
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default GroupAccess;
