import React from "react";
import {Button, Modal} from "react-bootstrap";
import {API_SELECT, Helpers} from "../../../module/core";
import AsyncSelect from "react-select/async";
import ServiceMasterProductType from "../../../services/master-product-type";
import {DBMessage} from "../../../module/constants";
import ServiceMasterProduct from "../../../services/master-product";


class ProductFormType extends React.Component {
	constructor(props) {
		super(props);

		const {productid} = props.datas;

		this.state = {
			id: 0,
			productid: productid,
			productname: '',
			disabled: false,
			datas: {
				type: null,
				groupsize: null,
			},
			actions: 'ready'
		};

		this.service = new ServiceMasterProductType(productid);
		this.serviceProduct = new ServiceMasterProduct();
	}
	onShow() {
		const {actions, datas} = this.props;

		if(actions === 'update-type') {
			this.setState({disabled: true, productname: datas.productname});
			this.service.fetch(datas.id)
				.then(
					res => {
						this.setState({disabled: false});
						if(res.success) {
							this.setState({
								datas: res.datas,
								id: datas.id,
								actions: actions
							});
						}
					}
				)
		} else {
			this.setState({
				datas: {
					type: null,
					groupsize: null
				},
				id: 0,
				productname: datas.productname,
				actions: actions
			});
		}
	}
	onSubmitHandler(event) {
		event.preventDefault();

		const props = this.props;
		const {datas, actions, id} = this.state;

		this.setState({disabled: true});
		if(actions === 'create-type') {
			this.service.fetchStore(datas)
				.then(
					res => {
						props.onHide();
						this.setState({disabled: false});
						if(res.success) {
							Helpers.swalSuccess(DBMessage.FETCH_SUCCESS_TITLE, res.message);
						} else {
							Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, res.message);
						}
					}
				);
		} else if(actions === 'update-type') {
			this.service.fetchUpdate(datas, id)
				.then(
					res => {
						props.onHide();
						this.setState({disabled: false});
						if(res.success) {
							Helpers.swalSuccess(DBMessage.FETCH_SUCCESS_TITLE, res.message);
						} else {
							Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, res.message);
						}
					}
				);
		}

	}
	onChangeSelect(option, select) {
		const {datas} = this.state;

		this.setState({
			datas: {
				...datas,
				[select.name]: option,
			}
		});
	}
	render() {
		const {show, onHide} = this.props;
		const {productname, disabled, datas} = this.state;
		return (
			<Modal show={show} onShow={this.onShow.bind(this)} onHide={onHide}>
				<Modal.Header closeButton>
					<Modal.Title>Form Tipe Produk</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form id={"form-product-type"} onSubmit={this.onSubmitHandler.bind(this)}>
						<div className={"form-group"}>
							<label htmlFor={"input-nama-produk"}>Nama Produk</label>
							<input
								type={"text"}
								className={"form-control"}
								value={productname}
								disabled={true}
							/>
						</div>
						<div className={"form-group"}>
							<label htmlFor={"input-tipe-produk"}>Tipe Produk</label>
							<AsyncSelect
								cacheOptions
								defaultOptions
								name={"type"}
								placeholder={'Pilih tipe produk'}
								loadOptions={API_SELECT.groupProductType}
								onChange={this.onChangeSelect.bind(this)}
								value={datas.type}
							/>
						</div>
						<div className={"form-group"}>
							<label htmlFor={"input-sizegroup-product"}>Group Size</label>
							<AsyncSelect
								cacheOptions
								defaultOptions
								name={"groupsize"}
								placeholder={'Pilih group size'}
								loadOptions={API_SELECT.groupSize}
								onChange={this.onChangeSelect.bind(this)}
								value={datas.groupsize}
							/>
						</div>
					</form>
				</Modal.Body>
				<Modal.Footer>
					<Button type={"button"} variant="secondary" size={"sm"} onClick={onHide} disabled={disabled}>
						Batal
					</Button>
					<Button type={"submit"} variant="primary" size={"sm"} form={"form-product-type"} disabled={disabled}>
						{!disabled ? 'Simpan' : 'Memproses ...'}
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default ProductFormType;
