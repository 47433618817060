import React from "react";
import {Helpers} from "../../../module/core";
import {Link} from "react-router-dom";

class AccountMenu extends React.Component{
	render() {
		const {active} = this.props;

		return (
			<div className={'card'}>
				<div className={'card-body'}>
					<div className={'card-profile'}>
						<div className={'avatar'}>
							<img src="dist/img/profile.jpg" alt="..." className="avatar-img rounded-circle" />
						</div>
						<div className={'description'}>
							<div className={'decription-title'}>{Helpers.sessFullname()}</div>
							<div className={'decription-subtitle'}>{Helpers.sessGroupName()}</div>
						</div>
					</div>
					<div className={'card-section profil'}>
						<div className={'section-title'}>Menu</div>
						<ul className={'nav-profil'}>
							{/*<li className={active === 'account-home' ? 'nav-item active' : 'nav-item'}>*/}
							{/*	<Link to={'/account'}>Akun Saya</Link>*/}
							{/*</li>*/}
							<li className={active === 'account-history' ? 'nav-item active' : 'nav-item'}>
								<Link to={'/account/history'}>Daftar Pesanan</Link>
							</li>
						</ul>
					</div>
				</div>
			</div>
		);
	}
}

export default AccountMenu;
