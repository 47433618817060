import {DBCode} from "../../../module/constants";

import ServiceMasterType from "../../../services/master-type";
import ServiceMasterWareHouse from "../../../services/master-warehouse";
import ServiceMasterMaterial from "../../../services/master-material";
import ServiceMasterUom from "../../../services/master-uom";
import ServiceTransactionStock from "../../../services/transaction-stock";

import $ from 'jquery';
import ServiceReportOrder from "../../../services/report-order";
import ServiceMasterMaterialDetail from "../../../services/master-material-detail";

require('select2/dist/js/select2.full.min');
require('select2/dist/css/select2.min.css');

const service = new ServiceTransactionStock();
const serviceType = new ServiceMasterType();
const serviceWarehouse = new ServiceMasterWareHouse();
const serviceMaterial = new ServiceMasterMaterial();
const serviceMaterialDetail = new ServiceMasterMaterialDetail();
const serviceUOM = new ServiceMasterUom();
const serviceOrder = new ServiceReportOrder();

export const DATATABLES_DATA = {
	paging: false,
	serverSide: {
		url: service.datatables,
		type: 'post',
		data: {
			typeid: DBCode.TYPE_ACCESSORIS
		}
	},
	columns: [
		{title: 'No'},
		{title: 'Nomor', data: 'adjustcd'},
		{title: 'Tanggal', data: 'adjustdate'},
		{title: 'Tipe', data: 'type.typename'},
		{title: 'Warehouse'},
		{title: 'Refno', data: 'refno'},
		{title: 'Aksi'}
	],
	columnDefs: [
		{
			targets: 0,
			width: 20,
			render: (data, type, row, meta) => {
				return meta.row + meta.settings._iDisplayStart + 1;
			},
		},
		{
			targets: 3,
			width: 20,
			render: (data, type, row, meta) => {
				const $wrapper = $('<div>');
				$wrapper.css({width: 'max-content'});
				$wrapper.text(row.type.typename);
				return $wrapper.get(0).outerHTML;
			},
		},
		{
			targets: 4,
			render: (data, type, row, meta) => {
				const $warehouse = $('<div>');
				$warehouse.css({width: 'max-content'});
				if(row.warehousefrom === null) {
					$warehouse.text(row.warehouseto.whname);
				} else if(row.warehouseto === null) {
					$warehouse.text(row.warehousefrom.whname);
				} else {
					$warehouse.text(row.warehousefrom.whname + ' - ' + row.warehouseto.whname);
				}

				return $warehouse.get(0).outerHTML;
			}
		},
		{
			targets: 5,
			render: (data, type, row, meta) => {
				if(parseInt(row.typeid) === DBCode.TYPE_CANCELED_ORDER) {
					return row.orders.ordercd;
				} else {
					return row.refno;
				}
			}
		},
		{
			targets: 6,
			width: 80,
			render: (data, type, row, meta) => {
				const $actions = $('<div>');
				$actions.css({width: 80});

				const $edit = $("<button>");
				$edit.addClass("btn btn-primary btn-xs");
				$edit.attr("actions", "edit");
				$edit.attr("data-id", row.adjustid);
				$edit.append($("<i>", { class: "fa fa-edit" }));

				$actions.append($edit);

				const $delete = $('<button>');
				$delete.addClass("btn btn-danger btn-xs ml-1");
				$delete.attr("actions", "delete");
				$delete.attr("data-id", row.adjustid);
				$delete.append($("<i>", { class: "fa fa-trash" }));

				$actions.append($delete);

				return $actions.get(0).outerHTML;
			}
		},
	]
};

export const RENDER_COMPONENTS = {
	init: function() {
		let $selectType = $('#select-tipe');
		$selectType.select2({
			placeholder: 'Pilih tipe transaksi',
			width: '100%',
			allowClear: true,
			ajax: {
				url: serviceType.select2ByCategory,
				type: 'post',
				data: function(params) {
					params.categoryid = DBCode.CATEGORY_TRANSACTION_STOCK;

					return params;
				}
			},
		});
		if($selectType.data('select-id') && $selectType.data('select-text')) {
			let id = $selectType.data('select-id');
			let text = $selectType.data('select-text');

			$selectType.append($('<option>', {value: id}).text(text));
		}

		let $selectWarehouseFrom = $('#select-warehouse-from');
		$selectWarehouseFrom.select2({
			placeholder: 'Pilih warehouse from',
			width: '100%',
			allowClear: true,
			ajax: {
				url: serviceWarehouse.select2Data,
				type: 'post',
			}
		});
		if($selectWarehouseFrom.data('select-id') && $selectWarehouseFrom.data('select-text')) {
			let id = $selectWarehouseFrom.data('select-id');
			let text = $selectWarehouseFrom.data('select-text');

			$selectWarehouseFrom.append($('<option>', {value: id}).text(text));
		}

		let $selectWarehouseTo = $('#select-warehouse-to');
		$selectWarehouseTo.select2({
			placeholder: 'Pilih warehouse to',
			width: '100%',
			allowClear: true,
			ajax: {
				url: serviceWarehouse.select2Data,
				type: 'post',
			}
		});
		if($selectWarehouseTo.data('select-id') && $selectWarehouseTo.data('select-text')) {
			let id = $selectWarehouseTo.data('select-id');
			let text = $selectWarehouseTo.data('select-text');

			$selectWarehouseTo.append($('<option>', {value: id}).text(text));
		}
		let $selectOrder = $('#select-order');
		if($selectOrder.data('select2')) $selectOrder.select2('destroy');

		$selectOrder.select2({
			placeholder: 'Pilih nomor order',
			width: '100%',
			allowClear: true,
			ajax: {
				url: serviceOrder.select2DataApproved,
				type: 'post',
			}
		});

		if($selectOrder.data('select-id') && $selectOrder.data('select-text')) {
			let id = $selectOrder.data('select-id');
			let text = $selectOrder.data('select-text');

			$selectOrder.append($('<option>', {value: id}).text(text));
		}

		RENDER_PRODUCT.$table = $('#datatableform_products');
	}
};

export const DATATABLES_PARAMS = {
	params: {
		id: 0,
	},
};

export const DATATABLES = {
	serverSide: {
		url: service.datatablesDetail,
		type: 'post',
		data: DATATABLES_PARAMS.params
	},
	columns: [
		{title: 'No'},
		{title: 'Kode', data: 'materialdt.materialcode'},
		{title: 'Produk', data: 'materialdt.material.materialname'},
		{title: 'QTY', data: 'qty'},
		{title: 'Aksi'},
	],
	columnDefs: [
		{
			targets: 0,
			width: 20,
			render: (data, type, row, meta) => {
				return meta.row + meta.settings._iDisplayStart + 1;
			},
		},
		{
			targets: 1,
			width: 20,
			render: (data, type, row, meta) => {
				const product = RENDER_PRODUCT.get(meta.row + meta.settings._iDisplayStart);
				const $wrapper = $('<div>', {class: 'row row-form my-2'});

				let $selectCode = $('<select>', {
					class: 'form-control',
					'data-index': meta.row + meta.settings._iDisplayStart,
					'data-set': 'select',
					'data-name': 'code',
					'data-select': 'product-code'
				});
				$selectCode.prop('required', true);

				if(RENDER_PRODUCT.isDisabled || product.deleted) $selectCode.attr('disabled', 'disabled');
				else $selectCode.removeAttr('disabled');

				if(product.code)
					$selectCode.append($('<option>', {value: product.code.id}).text(product.code.text));

				$wrapper.append($selectCode);

				return $wrapper.get(0).outerHTML;
			},
		},
		{
			targets: 2,
			width: 200,
			render: (data, type, row, meta) => {
				const product = RENDER_PRODUCT.get(meta.row + meta.settings._iDisplayStart);
				const $wrapper = $('<div>', {class: 'row row-form my-2'});

				const colProduct = RENDER_DETAIL_COLUMN.product(product, meta);
				colProduct.type($wrapper);
				colProduct.materialtype($wrapper);
				colProduct.product($wrapper);
				colProduct.uomAndColor($wrapper);

				return $wrapper.get(0).outerHTML;
			}
		},
		{
			targets: 3,
			width: 100,
			render: (data, type, row, meta) => {
				const product = RENDER_PRODUCT.get(meta.row + meta.settings._iDisplayStart);
				const $wrapper = $('<div>');

				RENDER_DETAIL_COLUMN.qty(product, meta).input($wrapper);

				return $wrapper.get(0).outerHTML;
			}
		},
		{
			targets: 4,
			width: 80,
			render: (data, type, row, meta) => {
				const product = RENDER_PRODUCT.get(meta.row + meta.settings._iDisplayStart);
				const $wrapper = $('<div>');

				if(product.deleted) {
					RENDER_DETAIL_COLUMN.action(product, meta).undo($wrapper);
				} else {
					RENDER_DETAIL_COLUMN.action(product, meta).delete($wrapper);
				}

				return $wrapper.get(0).outerHTML;
			}
		},
	]
};

export const RENDER_DETAIL_COLUMN = {
	product: function(product, meta) {
		return {
			type: ($wrapper) => {
				let $labelCode = $('<label>', {class: 'strong label-sm'});
				$labelCode.text('Kode');

				let $valueCode = $('<div>', {
					'data-index': meta.row + meta.settings._iDisplayStart,
					'data-name': 'code',
				});

				$wrapper.append(
					$('<div>', {class: 'col-md-6'}).append($labelCode),
					$('<div>', {class: 'col-md-6'}).append($valueCode),
				);

				let $labelType = $('<label>', {class: 'strong label-sm'});
				$labelType.text('Tipe Bahan');

				let $valueType = $('<div>', {
					'data-index': meta.row + meta.settings._iDisplayStart,
					'data-name': 'materialtype',
				});

				$wrapper.append(
					$('<div>', {class: 'col-md-6'}).append($labelType),
					$('<div>', {class: 'col-md-6'}).append($valueType),
				);

				return this;
			},
			materialtype: ($wrapper) => {
				let $labelCategory = $('<label>', {class: 'strong label-sm'});
				$labelCategory.text('Kategori');

				let $valueCategory = $('<div>', {
					'data-index': meta.row + meta.settings._iDisplayStart,
					'data-name': 'type',
				});

				$wrapper.append(
					$('<div>', {class: 'col-md-6'}).append($labelCategory),
					$('<div>', {class: 'col-md-6'}).append($valueCategory),
				);

				return this;
			},
			product: ($wrapper) => {
				let $labelProduct = $('<label>', {class: 'strong label-sm'});
				$labelProduct.text('Produk');

				let $valueProduct = $('<div>', {
					'data-index': meta.row + meta.settings._iDisplayStart,
					'data-name': 'product',
				});

				$wrapper.append(
					$('<div>', {class: 'col-md-6'}).append($labelProduct),
					$('<div>', {class: 'col-md-6'}).append($valueProduct)
				);

				return this;
			},
			uomAndColor: ($wrapper) => {
				let $labelUOM = $('<label>', {class: 'strong label-sm'});
				$labelUOM.text('UOM');

				let $valueUOM = $('<div>', {
					'data-index': meta.row + meta.settings._iDisplayStart,
					'data-name': 'materialuom',
				});
				$valueUOM.text('-');

				$wrapper.append(
					$('<div>', {class: 'col-md-6'}).append($labelUOM),
					$('<div>', {class: 'col-md-6'}).append($valueUOM),
				);

				let $labelColor = $('<label>', {class: 'strong label-sm'});
				$labelColor.text('Warna');

				let $ButtonColor = $('<div>', {
					class: 'btn btn-primary btn-xs ml-1',
					'data-index': meta.row + meta.settings._iDisplayStart,
					'data-toggle': 'choose-color',
				});
				$ButtonColor.text('Warna');

				if(RENDER_PRODUCT.isDisabled || product.deleted) $ButtonColor.attr('disabled', 'disabled');
				else $ButtonColor.removeAttr('disabled');

				if(product && product.materialcolor && product.materialcolor.groupcolor && product.materialcolor.color) {
					$ButtonColor.removeClass('btn-primary');
					$ButtonColor.css({
						backgroundColor: product.materialcolor.color.colorcode,
						borderColor: product.materialcolor.color.colorcode,
						color: '#fff',
					});
					// $('<span>').css({
					// 	display: 'block',
					// 	marginTop: 5,
					// 	fontSize: 12,
					// 	backgroundColor: '#8c8c8c',
					// 	color: '#fff',
					// 	padding: 2,
					// 	borderRadius: 2,
					// }).text('Warna: ' + product.materialcolor.color.label).insertAfter($ButtonColor.closest('.d-flex'));
					$ButtonColor.text('Ubah Warna');
				}

				$wrapper.append(
					$('<div>', {class: 'col-md-6'}).append($labelColor),
					$('<div>', {class: 'col-md-6'}).append($ButtonColor),
				);

				return this;
			}
		};
	},
	qty: (product, meta) => {
		return {
			input: ($wrapper) => {
				let $inputQTY = $('<input>', {
					type: 'text',
					class: 'form-control',
					placeholder: product && product.materialuom ? '0 ' + product.materialuom.text : '0',
					value: product.qty,
					'data-index': meta.row + meta.settings._iDisplayStart,
					'data-name': 'qty',
					'data-toggle': 'keypress',
				});
				$wrapper.append($inputQTY);

				if(RENDER_PRODUCT.isDisabled || product.deleted) $inputQTY.attr('disabled', 'disabled');
				else $inputQTY.removeAttr('disabled');
			}
		}
	},
	action: (product, meta) => {
		return {
			delete: ($wrapper) => {
				let $ButtonDelete = $('<button>', {
					type: 'button',
					class: 'btn btn-danger btn-xs ml-1',
					'data-index': meta.row + meta.settings._iDisplayStart,
					'data-toggle': 'delete',
				});
				$ButtonDelete.append($('<i>', {class: 'fa fa-trash'}));

				if(RENDER_PRODUCT.isDisabled) $ButtonDelete.attr('disabled', 'disabled');
				else $ButtonDelete.removeAttr('disabled');

				$wrapper.append($ButtonDelete);
			},
			undo: ($wrapper) => {
				const $ButtonDelete = $('<button>', {
					type: 'button',
					class: 'btn btn-secondary btn-xs ml-1',
					'data-index': meta.row + meta.settings._iDisplayStart,
					'data-toggle': 'undo',
				});
				$ButtonDelete.append($('<i>', {class: 'fa fa-undo'}));
				$ButtonDelete.append($('<span>').text('Undo'))

				if(RENDER_PRODUCT.isDisabled) $ButtonDelete.attr('disabled', 'disabled');
				else $ButtonDelete.removeAttr('disabled');

				$wrapper.append($ButtonDelete);
			}
		}
	}
};

export const RENDER_PRODUCT = {
	isDisabled: false,
	$table: $('#datatableform_products'),
	datas: [],
	deleted: 0,
	setDisabled: function(disabled) {
		RENDER_PRODUCT.isDisabled = disabled;
	},
	add: function() {
		let newData = {
			id: 0,
			code: {id: null, text: null},
			materialtype: {id: null, text: null},
			type: {id: null, text: null},
			material: {id: null, text: null},
			materialcolor: {groupcolor: null, color: null},
			materialuom: {id: null, text: null},
			qty: 1,
			deleted: false,
		};

		RENDER_PRODUCT.datas.push(newData);
		RENDER_PRODUCT.$table.dataTable().fnAddData(newData);
	},
	set: function(index, datas) {
		RENDER_PRODUCT.datas[index] = datas;
		let $rows = RENDER_PRODUCT.$table.find('tbody > tr');
		RENDER_PRODUCT.renderUpdate($rows.get(index), index);
	},
	get: function(index) {
		return index === undefined ? RENDER_PRODUCT.datas : (RENDER_PRODUCT.datas[index]) ? RENDER_PRODUCT.datas[index] : {};
	},
	remove: function(index) {
		let _index = parseInt(index) - RENDER_PRODUCT.deleted > 0 ? parseInt(index) - RENDER_PRODUCT.deleted : 0;

		let data = RENDER_PRODUCT.get(_index);
		if(data && data.id !== 0) {
			RENDER_PRODUCT.datas[_index].deleted = true;
		} else {
			RENDER_PRODUCT.datas.splice(_index, 1);
			RENDER_PRODUCT.deleted++;

			let $rows = RENDER_PRODUCT.$table.find('tbody > tr button[data-index=' + index +']').closest('tr');
			RENDER_PRODUCT.$table.dataTable().api().row($rows).remove().draw();
		}
	},
	undo: function(index) {
		RENDER_PRODUCT.datas[index].deleted = false;
	},
	init: function() {
		RENDER_PRODUCT.datas = [];
		RENDER_PRODUCT.render();
	},
	update: function() {

	},
	__renderUpdate: function(index, product, $row, $elements) {

		$elements.selectCode.empty();
		if(product.code && product.code.text)
			$elements.code.text(product.code.text.split(' - ')[0]);

		if(RENDER_PRODUCT.isDisabled || product.deleted) $elements.selectCode.attr('disabled', 'disabled');
		else $elements.selectCode.removeAttr('disabled');

		$elements.selectCode.empty();
		if(product.code)
			$elements.selectCode.append($('<option>', {value: product.code.id}).text(product.code.text));

		if(RENDER_PRODUCT.isDisabled || product.deleted) $elements.select2Stock.attr('disabled', 'disabled');
		else $elements.select2Stock.removeAttr('disabled');

		$elements.select2Stock.empty();
		if(product.materialtype)
			$elements.select2Stock.text(product.materialtype.text);

		if(RENDER_PRODUCT.isDisabled || product.deleted) $elements.select2Category.attr('disabled', 'disabled');
		else $elements.select2Category.removeAttr('disabled');

		$elements.select2Category.empty();
		if(product.type)
			$elements.select2Category.text(product.type.text);

		if(RENDER_PRODUCT.isDisabled || product.deleted) $elements.select2Product.attr('disabled', 'disabled');
		else $elements.select2Product.removeAttr('disabled');

		$elements.select2Product.empty();
		if(product.material)
			$elements.select2Product.text(product.material.text);

		if(RENDER_PRODUCT.isDisabled || product.deleted) $elements.select2UOM.attr('disabled', 'disabled');
		else $elements.select2UOM.removeAttr('disabled');

		$elements.select2UOM.empty();
		if(product.materialuom)
			$elements.select2UOM.text(product.materialuom.text);

		if(RENDER_PRODUCT.isDisabled || product.deleted) $elements.chooseColor.attr('disabled', 'disabled');
		else $elements.chooseColor.removeAttr('disabled');

		if(product && product.materialcolor && product.materialcolor.groupcolor && product.materialcolor.color) {
			$elements.chooseColor.removeClass('btn-primary');
			$elements.chooseColor.css({
				backgroundColor: product.materialcolor.color.colorcode,
				borderColor: product.materialcolor.color.colorcode,
				color: '#fff',
			});
			$elements.chooseColor.text(product.materialcolor.color.label);
		}

		if(RENDER_PRODUCT.isDisabled || product.deleted) $elements.keypress.attr('disabled', 'disabled');
		else $elements.keypress.removeAttr('disabled');

		if(product.deleted) {
			$elements.buttonDelete.attr('data-toggle', 'undo');
			$elements.buttonDelete.removeClass('btn-danger');
			$elements.buttonDelete.addClass('btn-secondary');
			$elements.buttonDelete.html($('<i>', {class: 'fa fa-undo'}));
		} else {
			$elements.buttonDelete.attr('data-toggle', 'delete');
			$elements.buttonDelete.removeClass('btn-secondary');
			$elements.buttonDelete.addClass('btn-danger');
			$elements.buttonDelete.html($('<i>', {class: 'fa fa-trash'}));
		}

	},
	renderUpdate: function(row, iDisplayIndexFull) {
		let $row = $(row);
		let $selectCode = $row.find('[data-select=product-code]');
		let $code = $row.find('[data-name=code]');
		let $stockType = $row.find('[data-name=materialtype]');
		let $productCategory = $row.find('[data-name=type]');
		let $product = $row.find('[data-name=product]');
		let $productUOM = $row.find('[data-name=materialuom]');
		let $chooseColor = $row.find('[data-toggle=choose-color]');
		let $keypress = $row.find('[data-toggle=keypress]');
		let $delete = $row.find('[data-toggle=delete]');
		let $undo = $row.find('[data-toggle=undo]');

		let data = RENDER_PRODUCT.get($stockType.data('index'));

		let $elements = {
			selectCode: $selectCode,
			code: $code,
			select2Stock: $stockType,
			select2Category: $productCategory,
			select2Product: $product,
			select2UOM: $productUOM,
			chooseColor: $chooseColor,
			keypress: $keypress,
			buttonDelete: data.deleted ? $undo : $delete,

		};

		if($selectCode.data('select2')) $selectCode.select2('destroy');
		$selectCode.select2({
			placeholder: 'Pilih kode produk',
			width: '100%',
			allowClear: true,
			ajax: {
				url: serviceMaterialDetail.select2Api,
				type: 'post',
				dataType: 'json'
			},
			minimumInputLength: 1
		});

		$selectCode.get(0).onchange = function() {
			let $change = $(this);
			let index = parseInt($change.data('index'));

			RENDER_PRODUCT.datas[index].code = {
				id: $change.val(),
				text: $change.find('option:selected').text()
			};

			serviceMaterialDetail.fetch($change.val())
				.then(
					res => {
						if(res.success) {
							RENDER_PRODUCT.datas[index].materialtype = {
								id: res.row.material.type.catid,
								text: res.row.material.type.category.catname
							};
							RENDER_PRODUCT.datas[index].type = {
								id: res.row.material.type.typeid,
								text: res.row.material.type.typename
							};
							RENDER_PRODUCT.datas[index].material = {
								id: res.row.material.materialid,
								text: res.row.material.materialname
							};
							RENDER_PRODUCT.datas[index].materialuom = {
								id: res.row.uom.uomid,
								text: res.row.uom.uomname,
							};
							RENDER_PRODUCT.datas[index].materialcolor = {
								groupcolor: {
									id: res.row.color.groupcolor.typeid,
									text: res.row.color.groupcolor.typename
								},
								color: {
									value: res.row.color.colorid,
									label: res.row.color.colorname,
									colorcode: res.row.color.colorcode,
								}
							};
							RENDER_PRODUCT.__renderUpdate(index, data, $row, $elements);
							RENDER_PRODUCT.renderUpdate(row, iDisplayIndexFull);
						}
					}
				)
		};

		if($delete.length > 0) {
			$delete.get(0).onclick = function() {
				let $clicked = $(this);
				let index = parseInt($clicked.data('index'));
				RENDER_PRODUCT.remove(index);
				RENDER_PRODUCT.__renderUpdate(index, data, $row, $elements);
				RENDER_PRODUCT.renderUpdate(row, iDisplayIndexFull);
			};
		}

		if($undo.length > 0) {
			$undo.get(0).onclick = function() {
				let $clicked = $(this);
				let index = parseInt($clicked.data('index'));
				RENDER_PRODUCT.undo(index);
				RENDER_PRODUCT.__renderUpdate(index, data, $row, $elements);
				RENDER_PRODUCT.renderUpdate(row, iDisplayIndexFull);
			};
		}

		RENDER_PRODUCT.__renderUpdate(iDisplayIndexFull, data, $row, $elements);
	},
};
