import React from "react";
import {Helpers} from "../../module/core";
import {Link} from "react-router-dom";

class SidebarUser extends React.Component {
	render() {
		return (
			<div className={"user"}>
				<div className={"avatar-sm float-left mr-2"}>
					<img src={"dist/img/profile.jpg"} alt={"profile"} className={"avatar-img rounded-circle"} />
				</div>
				<div className={"info"}>
					<Link to={"/account"}>
                        <span>
                            {Helpers.sessFullname()}
							<span className={"user-level"}>{Helpers.sessGroupName()}</span>
                        </span>
					</Link>

					<div className={"clearfix"} />

				</div>
			</div>
		);
	}
}

export default SidebarUser;
