import React from "react";
import {Link} from "react-router-dom";

import ServiceMasterGroupMenu from "../../services/master-group-menu";
import {Helpers} from "../../module/core";
import {DBCode, DBMessage} from "../../module/constants";

const localStorage = require('local-storage');

class SidebarMenu extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			menuComponents: {
				0: []
			},
			menuPositions: props.menuPositions ? props.menuPositions : []
		};

		this.service = new ServiceMasterGroupMenu();

		if(!localStorage.get(DBCode.KEY_STORAGE_MENUS)) {
			this.fetchAccess();
		} else {
			this.state.menuComponents = JSON.parse(atob(localStorage.get(DBCode.KEY_STORAGE_MENUS)));
		}
	}
	fetchAccess() {
		const {menuPositions} = this.state;

		let index = 0;
		let menus = {};
		this.service.fetchMenuAccess()
			.then(
				res => {
					if(res.success) {
						res.datas.forEach(data => {
							let parentid = data.menu.masterid ? data.menu.masterid : 0;
							if(menus[parentid] === undefined) {
								menus[parentid] = [];
								index++;
							}

							data.active = menuPositions[index] !== undefined && menuPositions[index].menu === data.menu.menuname;

							menus[parentid].push(data);
						});

						this.setState({menuComponents: menus});

						localStorage.set(DBCode.KEY_STORAGE_MENUS, btoa(JSON.stringify(menus)));
					} else {
						Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, res.message);
					}
				}
			)
	}
	sortingMenu(datas) {
		datas.forEach((data, i)=> {
			if(i < datas.length - 1) {
				let next_data = datas[i + 1];
				if(data.menu.seq > next_data.menu.seq) {
					datas[i] = next_data;
					datas[i + 1] = data;
				}
			}
		});

		return datas;
	}
	renderChildrenMenu(datas, parentid, position) {
		const {menuComponents, menuPositions} = this.state;
		datas = this.sortingMenu(datas);

		let renderComponents = [];
		datas.forEach((data, i) => {
			let menuid = data.menu.menuid;
			let icon = data.menu.icon;
			let menuname = data.menu.menuname;

			let children = menuComponents[menuid] !== undefined ? menuComponents[menuid] : [];
			let route = `${process.env.PUBLIC_URL}/${data.menu.link}`;
			let active = menuPositions[position] === data.menu.menuname ? 'active' : null;
			let show = data.active ? 'show' : null;

			if(children.length === 0) {
				renderComponents.push((
					<li key={`menu-id-${menuid}`} className={`nav-item ${active}`}>
						<Link to={route} onClick={() => {
							Helpers.sessAccessTo(parentid, i);
						}}>
							<span className={"sub-item"}>{menuname}</span>
						</Link>
					</li>
				));
			} else if(children.length > 0) {
				const renderChildren = this.renderChildrenMenu(children, menuid, position++);
				renderComponents.push((
					<li key={`menu-id-${menuid}`} className={active}>
						<a href={`#parent-id-${menuid}`} data-toggle={"collapse"}>
							<i className={icon} />
							<span className={"sub-item"}>{menuname}</span>
							<span className={"caret"} />
						</a>
						<div className={`collapse ${show}`} id={`parent-id-${menuid}`}>
							<ul className={"nav nav-collapse mb-0 pb-2"}>{renderChildren}</ul>
						</div>
					</li>
				));
			}
		});

		return renderComponents;
	}
	render() {
		const {menuComponents, menuPositions} = this.state;
		const datas = (menuComponents[0] !== undefined) ? menuComponents[0] : [];

		let renderComponents = [];

		let position = 0;
		datas.forEach((data, i) => {
			let menuid = data.menu.menuid;
			let icon = data.menu.icon;
			let menuname = data.menu.menuname;

			let children = menuComponents[menuid] !== undefined ? menuComponents[menuid] : [];
			let route = `${process.env.PUBLIC_URL}/${data.menu.link}`;
			let active = menuPositions[position] === data.menu.menuname ? 'active' : null;
			let show = data.active ? 'show' : null;

			if(children.length === 0) {
				renderComponents.push((
					<li key={`menu-id-${menuid}`} className={`nav-item ${active}`}>
						<Link to={route} onClick={() => {
							Helpers.sessAccessTo(0, i);
						}}>
							<i className={icon} />
							<span className={"sub-item"}>{menuname}</span>
						</Link>
					</li>
				));
			} else if(children.length > 0) {
				const renderChildren = this.renderChildrenMenu(children, menuid, position++);
				renderComponents.push((
					<li key={`menu-id-${menuid}`} className={`nav-item ${active}`}>
						<a href={`#parent-id-${menuid}`} data-toggle={"collapse"}>
							<i className={icon} />
							<span className={"sub-item"}>{menuname}</span>
							<span className={"caret"} />
						</a>
						<div className={`collapse ${show}`} id={`parent-id-${menuid}`}>
							<ul className={"nav nav-collapse mb-0 pb-2"}>{renderChildren}</ul>
						</div>
					</li>
				));
			}
		});

		return (
			<ul className={"nav nav-primary"}>{renderComponents}</ul>
		);
	}
}

export default SidebarMenu;
