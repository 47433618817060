import React from "react";
import {Button, Modal} from "react-bootstrap";
import {DBMessage, DBStyles, DBText} from "../../../module/constants";
import ServiceMasterVariantAccessoris from "../../../services/master-variant-accessoris";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import {API_SELECT, Helpers} from "../../../module/core";

class CopyVariantAccessoris extends React.Component {
	constructor(props) {
		super(props);

		const {productvarid, productid} = props.datas;

		this.state = {
			disabled: false,
			productvarid: productvarid,
			productid: productid,
			datas: {
				productvar: null,
				productvaracc: [],
			}
		};

		this.serviceVaracc = new ServiceMasterVariantAccessoris(productid);
	}
	onShow() {
		const {productvarid, productid} = this.props.datas;
		this.setState({
			productvarid: productvarid,
			productid: productid,
			datas: {
				productvar: null,
				productvaracc: []
			}
		});
	}
	onChangeSelect(option) {
		const {datas} = this.state;
		this.serviceVaracc.fetchAllSize(option.value)
			.then(
				res => {
					this.setState({
						datas: {
							...datas,
							productvar: option,
							productvaracc: res.sizes
						}
					});
				}
			)
	}
	renderProductSizes(details) {
		return details.map((data, i) => {
			let partname = `${data.material.type.typename} - ${data.material.materialname}`;

			if(i > 0) {
				if(details[i - 1].material.materialname === data.material.materialname) {
					partname = '';
				}
			}
			return (
				<tr>
					<td>{partname}</td>
					<td>{data.size.sizename}</td>
					<td>{parseFloat(data.pcs)}</td>
				</tr>
			);
		});
	}
	onClickCopyProcess(event) {
		event.preventDefault();

		const {productvarid, datas} = this.state;
		const {onHide} = this.props;

		this.serviceVaracc.copyDetail(datas.productvar.value, productvarid, datas.productvaracc)
			.then(
				res => {
					if(res.success) {
						Helpers.swalSuccess(DBMessage.FETCH_SUCCESS_TITLE, res.message)
							.then(() => {
								onHide();
							});
					}
				},
			)
	}
	render() {
		const props = this.props;
		const {show, onHide} = props;
		const {disabled, productid, datas} = this.state;

		return (
			<Modal size={'lg'} show={show} onHide={onHide} onShow={this.onShow.bind(this)}>
				<Modal.Header closeButton>
					<Modal.Title>Copy Data Aksesoris</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form id={'form-copy'} onSubmit={this.onClickCopyProcess.bind(this)}>
						<div className={"form-group"}>
							<label htmlFor={"select-variant"}>Varian Produk</label>
							<AsyncSelect
								cacheOptions
								defaultOptions
								name={"producttype"}
								placeholder={'Pilih tipe produk'}
								loadOptions={(inputValue, callback) => {
									API_SELECT.productVariantExcept(productid, [props.datas.productvarid], inputValue, callback);
								}}
								onChange={this.onChangeSelect.bind(this)}
								value={datas.productvar}
								isDisabled={disabled}
							/>
						</div>
					</form>
					<div className={'form-group'}>
						<div className={'table-responsive'}>
							<table className={'table table-striped table-bordered'}>
								<thead>
								<tr>
									<th>Aksesoris</th>
									<th>Size</th>
									<th>PCS</th>
								</tr>
								</thead>
								<tbody>
								{
									this.renderProductSizes(datas.productvaracc)
								}
								</tbody>
							</table>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant={"secondary"} size={"sm"} onClick={onHide}>
						<i className={DBStyles.icon.cancel} />
						<span>{DBText.button.cancel}</span>
					</Button>
					<Button type={"submit"} variant={"primary"} size={"sm"} form={'form-copy'}>
						<i className={DBStyles.icon.copy} />
						<span>Process {DBText.button.copy}</span>
					</Button>
				</Modal.Footer>
			</Modal>
		)
	}
}

export default CopyVariantAccessoris;
