import axios from 'axios';
import {Config, Helpers} from "../module/core";
import $ from "jquery";
import {DBMessage} from "../module/constants";

class ServiceMasterGroupMenu {
	constructor() {
		this.api = `${Config.api}/groupmenus`;
	}
	fetchMenuAccess() {
		const groupid = Helpers.sessGroupId();

		$('body').addClass('fetching-data');
		return axios.get(`${this.api}/access`, {
			params: {
				groupid:groupid
			}
		})
			.then(
				res => {
					$('body').removeClass('fetching-data');
					return res.data;
				},
				error => {
					$('body').addClass('fetching-data');
					return {success: false, message: error};
				}
			)
			.catch(error => {
				$('body').addClass('fetching-data');
				Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, error.toString());
				return false;
			});
	}
	fetchUpdateAccess(datas) {

		datas.userid = Helpers.sessUserid();

		$('body').addClass('fetching-data');
		return axios.put(`${this.api}/access`, datas)
			.then(
				res => {
					$('body').removeClass('fetching-data');
					return res.data;
				},
				error => {
					$('body').addClass('fetching-data');
					return {success: false, message: error};
				}
			)
			.catch(error => {
				$('body').addClass('fetching-data');
				Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, error.toString());
				return false;
			});
	}
}

export default ServiceMasterGroupMenu;
