import React from "react";
import {DBMessage, DBStyles} from "../../../module/constants";
import Header from "../../skin/header";
import Sidebar from "../../skin/sidebar";
import ServiceMasterColor from "../../../services/master-color";
import {Button} from "react-bootstrap";
import PageHeader from "../../skin/pageheader";
import Datatables from "../../components/datatables";

import {DATATABLES} from "./_config";

import $ from 'jquery';
import ColorForm from "./color-form";
import {Helpers} from "../../../module/core";

class ColorComponent extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			modal: {
				show: false,
				data: { id: 0 },
			},
			importing: false,
			actions: 'ready',
			title: 'Color',
			breadcrumbs: [
				{link: '#', menu: 'Master'},
				{link: 'master/color', menu: 'Color'}
			],
			datatables: {
				fnRowCallback: (nRow, aData, iDisplayIndex, iDisplayIndexFull) => {
					let $row = $(nRow);
					let $edit = $row.find('[actions=edit');
					$edit.click(this.onClickEdit.bind(this));
					let $delete = $row.find('[actions=delete]');
					$delete.click(this.onClickDelete.bind(this));
				}
			}
		};

		this.service = new ServiceMasterColor();
	}
	onModalClose() {
		this.setState({
			modal: {
				show: false,
				data: {},
			},
			actions: 'read',
		});
	}
	onClickCreate() {
		this.setState({
			modal: {
				show: true,
			},
			actions: 'create',
		});
	}
	onClickEdit(event) {
		let $clicked = $(event.currentTarget);
		this.setState({
			modal: {
				show: true,
				datas: {
					id: $clicked.data('id'),
				},
			},
			actions: 'edit',
		});
	}
	onClickDelete(event) {
		let $clicked = $(event.currentTarget);
		Helpers.swalConfirm(DBMessage.CONFIRM_DELETE_TITLE, DBMessage.CONFIRM_DELETE_MESSAGE)
			.then(
				result => {
					if(result.value) {
						this.service.fetchDelete($clicked.data('id'))
							.then(
								res => {
									if(res.success) {
										this.setState({
											actions: 'read'
										});
										Helpers.swalSuccess(DBMessage.FETCH_SUCCESS_TITLE, res.message);
									} else {
										Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, res.message)
									}
								}
							);
					}
				}
			);
	}
	onClickUpload() {
		const $uploadEl = $('#upload-data');
		$uploadEl.click();
		$uploadEl.change((e) => {
			let file = e.target.files[0];
			let formData = new FormData();
			formData.append('file', file);

			this.setState({importing: true});
			this.service.import(formData)
				.then(
					res => {
						if(res.success) {
							Helpers.swalSuccess(DBMessage.FETCH_SUCCESS_TITLE, res.message)
								.then(
									res => {
										this.setState({
											importing: false,
											actions: 'read',
										});
									}
								)
						}
					}
				)
		})
	}
	onClickDownload() {
		this.service.export();
	}
	render() {
		const {modal, actions, datatables, title, breadcrumbs, importing} = this.state;
		return (
			<div className={DBStyles.content.wrapper}>
				<Header />

				<Sidebar />

				<div className={"main-panel"}>
					<div className={"content"}>
						<div className={"page-inner"}>
							<PageHeader title={title} breadcrumbs={breadcrumbs} />

							<div className={"card"}>
								<div className={"card-header"}>
									<div className={"card-title"}>Data {title}</div>
									<input type={'file'} id={'upload-data'} style={{display: 'none'}} />
									<Button
										variant={"success"}
										size={"sm"}
										className={"mr-1"}
										onClick={this.onClickUpload.bind(this)}
										disabled={importing}>
										{
											importing ?
												[
													<i className={"fa fa-upload"} />,
													<span>Uploading data ...</span>
												]
												:
												[
													<i className={"fa fa-upload"} />,
													<span>Import Excel Data</span>
												]
										}
									</Button>
									<Button variant={"secondary"} size={"sm"} className={"mr-1"} onClick={this.onClickDownload.bind(this)}>
										<i className={"fa fa-download"} />
										<span>Download Excel Template</span>
									</Button>
									<Button variant={"primary"} size={"sm"} onClick={this.onClickCreate.bind(this)}>
										<i className={"fa fa-plus"} />
										<span>Buat Baru</span>
									</Button>
								</div>
								<div className={"card-body"}>
									<div className={"table-responsive"}>
										<Datatables
											actions={actions}
											columns={DATATABLES.columns}
											columnDefs={DATATABLES.columnDefs}
											serverSide={DATATABLES.serverSide}
											options={datatables}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<ColorForm show={modal.show} datas={modal.datas} actions={actions} onHide={() => { this.onModalClose() }} />
			</div>
		);
	}
}

export default ColorComponent;
