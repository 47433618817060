export const DBMessage = {
	ERROR_NETWORK_TITLE: "Gagal menghubungkan ke server",
	ERROR_NETWORK_MESSAGE: "Periksa kembali koneksi anda, pastikan terhubung dengan internet",
	LOGIN_SUCCESS_TITLE: "Login telah berhasil",
	LOGIN_FAILED_TITLE: "Gagal memproses login",
	FETCH_FAIL_TITLE: "Gagal memproses data",
	FETCH_FAIL_MESSAGE: "Terjadi kesalahan pada server",
	FETCH_SUCCESS_TITLE: "Process Berhasil",
	CONFIRM_DELETE_TITLE: "Konfirmasi Hapus Data",
	CONFIRM_DELETE_MESSAGE: "Apakah anda yakin untuk menghapus data ini?",
	CONFIRM_CHANGE_TITLE: "Konfirmasi Perubahan Data",
	CONFIRM_CHANGE_MESSAGE: "Terdapat beberapa perubahan data yang belum anda simpan. Apakah anda yakin akan merubah ini?",
	FORM_VALIDATION_TITLE: "Form tidak valid",
	FORM_VALIDATION_FILTER_VARIANT: "Tidak ditemukan produk varian yang terpilih",
	PARAMS_STOCK_INSUFFICIENT: 'Stock bahan %(materialname)s dengan warna %(colorname)s tidak mencukupi',
	PARAMS_STOCK_EXIST: 'Stock tersedia dapat menghasilkan %(estimate)s Pcs produk dengan ukuran %(sizename)s',
};

export const DBRoutes = {

};

export const DBText = {
	button: {
		create: 'Buat Baru',
		back: 'Kembali',
		next: 'Lanjutkan',
		save: 'Simpan',
		add: 'Tambah',
		cancel: 'Batal',
		delete: 'Hapus',
		processing: 'Memproses ...',
		finish: 'Selesai',
		copy: 'Copy'
	}
};

export const DBStyles = {
	content: {
		wrapper: "wrapper",
		wrapperIcon: "wrapper sidebar_minimize",
	},
	icon: {
		create: 'fa fa-plus',
		back: 'fa fa-arrow-left',
		next: 'fa fa-arrow-right',
		save: 'fa fa-save',
		add: 'fa fa-plus',
		cancel: 'fa fa-remove',
		delete: 'fa fa-trash',
		data: 'fa fa-database',
		finish: 'fa fa-check',
		copy: 'fa fa-copy',
		spinner: 'fas fa-spinner fa-spin'
	}
};

export const DBCode = {
	KEY_STORAGE_MENUS: '85%fc&66$e5b3^7&78e09&7@c)14^(08**a42^$83a&(6b@$(@!3561@2f^145)*',
	KEY_STORAGE_ACCESSTO: '@8$#16c43d@dd&@0cb@1)8$a78%^027@!9b*7(@ca^b^!a07f^19$300f4%##f@5',
	KEY_STORAGE_ORDERS: '@8$#16c43d@dd&@0cb@1)8$aasdd^027@@23b*7(@ca^b^!a07f^19$300f4%##f@5',
	KEY_PARAM_USERID: '#ff%e18(#%4%*68#&fe8cc*bb3a$(ddc^8d0@d&86*#&da(&#(#5$@e$d4b!7d^b',
	KEY_PARAM_PRODUCTID: '*a@b85e947f072&^be88f&!!cd#e98#d%@c%a0f7c4dadd)36d15!#3&$c438%f9',
	KEY_PARAM_PRODUCTTYPEID: '0075f!a7de%f^^*ac8*82!d&f!(5*(5ae2588aae8%1&$&^(8%e8d#c^6#)$)3#b',
	KEY_PARAM_PRODUCTVARID: '7#040770*@f0%^*40f%)%f1*%cb)(%@&%c9%^8d^8&d8fa22a$(9f*@89$5b%6$3',
	KEY_PARAM_ORDERID: '56&22$#24ac&)#22#c5@#$43e9b9b^@$3985%f@$%**!(@ac4#@6(*2e9d833063',
	ACCESS_VIEW: 2,
	ACCESS_CREATE: 3,
	ACCESS_UPDATE: 4,
	ACCESS_DELETE: 5,
	ACCESS_SPECIAL: 6,
	CATEGORY_GROUP_TYPE: 1,
	CATEGORY_GROUP_ACCESS: 2,
	CATEGORY_GROUP_SIZE: 3,
	CATEGORY_GROUP_COLOR: 4,
	CATEGORY_PRODUCT_TYPE: 5,
	CATEGORY_FILE_TRANS: 6,
	CATEGORY_PRODUCT_CATEGORY: 7,
	CATEGORY_STOCK_TYPE: 8,
	CATEGORY_TRANSACTION_TYPE: 9,
	CATEGORY_ACCESSORIS: 10,
	CATEGORY_MATERIAL: 11,
	CATEGORY_KAIN: 12,
	CATEGORY_TRANSACTION_STOCK: 13,
	CATEGORY_TRANS_ID: 14,
	TYPE_STOCK_RAWMATERIAL: 27,
	TYPE_CATEGORY_APPS: 17,
	TYPE_TRANSACTION_IN: 28,
	TYPE_TRANSACTION_OUT: 29,
	TYPE_ACCESSORIS: 49,
	TYPE_KAIN: 50,
	TYPE_KAIN_MATERIAL: 51,
	TYPE_ADJUSTMENT_IN: 52,
	TYPE_ADJUSTMENT_OUT: 53,
	TYPE_PRODUCTION: 54,
	TYPE_SALDO_AWAL: 57,
	TYPE_CANCELED_ORDER: 58,
	TYPE_TRANSACTION_ADJUSTMENT: 55,
	TYPE_TRANSACTION_ORDERMARKETING: 56,
	STATUS_NEEDAPPROVAL: 1,
	STATUS_APPROVED: 2,
	STATUS_CANCELED: 3,
	UOM_METER: 1,
	UOM_KG: 2,
	UOM_YARD: 4,
	UOM_PCS: 8,
	WAREHOUSE_HQ: 1,
	WAREHOUSE_PRD: 2,
};
