import React from "react";

import Dialog from "@material-ui/core/Dialog";

class Select2 extends React.Component {
	constructor(props) {
		super(props);

		const {
			maxWidth,
			refreshing,
			placeholder,
			searchable,
			loadData,
			options,
			getLabel,
			onSelectedItem,
			value,
			disabled,
			renderField,
			renderOption,
			renderEmpty,
			setWrapperClass,
			setWrapperStyle,
			setItemClass,
			setItemStyle,
		} = props;

		this.state = {
			stateItems: [],
			refreshing: refreshing ? refreshing : false,
			open: false,
			selectedItem: value ? value : null,
			searchValue: null,
			typing: false,
			typingTimeout: 0,
			options: {
				maxWidth: maxWidth ? maxWidth : 'sm',
				searchable: searchable ? searchable : false,
				placeholder: placeholder ? placeholder : 'Pilih salah satu',
				getLabel: getLabel ? getLabel : item => item.label,
				onSelectedItem: onSelectedItem ? onSelectedItem : () => {},
				options: options ? options : [],
				loadData: loadData,
				disabled: disabled ? disabled : false,
				renderField: renderField ? renderField : this.renderField,
				renderOption: renderOption ? renderOption : this.renderOption,
				renderEmpty: renderEmpty ? renderEmpty : this.renderEmpty,
				setWrapperClass: setWrapperClass ? setWrapperClass : null,
				setWrapperStyle: setWrapperStyle ? setWrapperStyle : {},
				setItemClass: setItemClass ? setItemClass : null,
				setItemStyle: setItemStyle ? setItemStyle : {},
			}
		};
	}
	renderField(selectedItem, getLabel) {
		return getLabel(selectedItem);
	}
	renderOption(item, getLabel) {
		return getLabel(item);
	}
	renderFormSearch() {
		const {searchValue} = this.state;
		return (
			<div className={'select2-material-search'}>
				<input
					type={'text'}
					className={'form-control'}
					placeholder={'Cari ...'}
					onChange={this.onSearch.bind(this)}
					value={searchValue}
				/>
			</div>
		);
	}
	renderEmpty() {
		return <i className={'select2-material-empty'}>Tidak ditemukan data</i>;
	}
	renderLoading() {
		return <span className={'loading'}>Loading ...</span>;
	}
	onClickOpen() {
		const {items} = this.props;
		const {options} = this.state;
		if(!options.disabled) {
			this.setState({
				open: true,
				searchValue: '',
				refreshing: true,
				stateItems: items && !options.loadData ? items : [],
			});

			if(options.loadData) {
				options.loadData('', (res) => {
					this.setState({
						refrehsing: false,
						stateItems: res
					});
				});
			}
		}
	}
	onClickClose() {
		this.setState({
			open: false,
		});
	}
	onSearch(event) {
		const {options} = this.state;
		const self = this;

		if (self.state.typingTimeout) {
			clearTimeout(self.state.typingTimeout);
		}

		this.setState({
			searchValue: event.target.value,
			typing: false,
			typingTimeout: setTimeout(function () {
				if(options.loadData) {
					options.loadData(self.state.searchValue, (res) => {
						self.setState({
							refrehsing: false,
							stateItems: res
						});
					});
				}
			}, 250)
		});
	}
	render() {
		const {refrehsing, open, stateItems, options} = this.state;
		const selectedItem = this.props.value ? this.props.value : null;
		options.disabled = this.props.disabled ? this.props.disabled : false;

		return [
			<div key={'select2-field'} className={`select2-field form-control ${options.disabled ? 'disabled' : ''}`} onClick={this.onClickOpen.bind(this)}>
				<div className={`select2-field-item`}>
					{
						selectedItem
							? options.renderField(selectedItem, options.getLabel)
							: <span className={'select2-field-placeholder'}>{options.placeholder}</span>
					}
				</div>
				<i className={'fa fa-caret-down'} />
			</div>,
			<Dialog key={'select2-dialog'} className={'select2-dialog'} aria-labelledby={"simple-dialog-title"} onClose={this.onClickClose.bind(this)} open={open}>
				<div className={'select2-material-header'} id={"simple-dialog-title"}>
					<div className={'select2-material-header-title'}>{options.placeholder}</div>
					<div className={'select2-material-header-close'} onClick={this.onClickClose.bind(this)}>&times;</div>
				</div>
				<div className={`select2-content ${options.maxWidth}`}>
					{
						options.searchable
							? this.renderFormSearch()
							: null
					}
					<ul className={`select2-material-list custom-scroll-1 ${options.setWrapperClass}`} style={options.setWrapperStyle}>
						{
							stateItems.map((item, i) => {
								return (
									<li key={`key-item-${i}`} className={`select2-material-item ${options.setItemClass}`} onClick={() => {
										if(!options.disabled) {
											options.onSelectedItem(item, i);

											this.setState({
												selectedItem: item,
												open: false,
											});
										}
									}} style={options.setItemStyle}>
										{options.renderOption(item, options.getLabel)}
									</li>
								);
							})
						}
						{
							!refrehsing && stateItems.length === 0
								? <li key={'key-item-empty'} className={'select2-material-item empty'}>{options.renderEmpty()}</li>
								: null
						}
						{
							refrehsing
								? <li key={'key-item-loading'} className={'select2-material-item'}>{this.renderLoading()}</li>
								: null
						}
					</ul>
				</div>
			</Dialog>
		];
	}
}

export default Select2;
