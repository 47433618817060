import React from "react";

import {Modal} from "react-bootstrap";
import {DATATABLES_DETAIL} from "./_config";
import Datatables from "../../components/datatables";

class DetailStock extends React.Component {
	constructor(props) {
		super(props);

		const {id} = props.datas;

		this.state = {
			actions: 'test',
			refreshing: false,
			id: id,
			datatables: {
				fnRowCallback: (nRow) => {
				}
			}
		};
	}
	onShow() {
		const {datas} = this.props;
		this.setState({
			id: datas.id,
		});
	}
	render() {
		const props = this.props;
		const {show, onHide, datas} = props;
		const {actions, datatables} = this.state;

		return (
			<Modal show={show} size={'lg'} onShow={this.onShow.bind(this)} onHide={onHide}>
				<Modal.Header closeButton>
					<Modal.Title>Detail Stock</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className={'table-responsive'}>
						<Datatables
							actions={actions}
							columns={DATATABLES_DETAIL.columns}
							columnDefs={DATATABLES_DETAIL.columnDefs}
							serverSide={DATATABLES_DETAIL.serverSide({stockid: datas.id})}
							options={datatables}
						/>
					</div>
				</Modal.Body>
			</Modal>
		);
	}
}

export default DetailStock;
