import React from "react";
import {Button, Modal} from "react-bootstrap";
import ServiceMasterProduct from "../../../services/master-product";
import {API_SELECT, Helpers} from "../../../module/core";
import {DBMessage} from "../../../module/constants";
import {Redirect} from "react-router";
import AsyncSelect from "react-select/async";

class ProductCreate extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			actions: 'ready',
			route: `${process.env.PUBLIC_URL}/master/product`,
			disabled: false,
			datas: {
				productcode: '',
				category: null,
				productname: '',
			},
		};

		this.service = new ServiceMasterProduct();
	}
	onChangeHandler(event) {
		const {datas} = this.state;

		this.setState({
			datas: {
				...datas,
				[event.target.name]: event.target.value
			}
		});
	}
	onChangeSelect(option, select) {
		const {datas} = this.state;

		this.setState({
			datas: {
				...datas,
				[select.name]: option
			}
		});
	}
	onSubmitHandler(event) {
		event.preventDefault();

		const props = this.props;
		const {datas, route} = this.state;

		this.setState({disabled: true});
		this.service.fetchStore(datas)
			.then(
				res => {
					props.onHide();
					this.setState({disabled: false});
					if(res.success) {
						Helpers.swalSuccess(DBMessage.FETCH_SUCCESS_TITLE, res.message)
							.then(
								result => {
									if(result.value) {
										this.setState({redirect: `${route}/edit/${res.productid}`});
									}
								}
							)
					}
				}
			)
	}
	render() {
		const props = this.props;
		const {disabled, datas, redirect} = this.state;

		if(redirect) {
			return <Redirect to={redirect} />
		}

		return (
			<Modal {...props} onHide={props.onHide}>
				<Modal.Header closeButton>
					<Modal.Title>Form Produk</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form id={"form-product"} onSubmit={this.onSubmitHandler.bind(this)}>
						<div className={"form-group"}>
							<label htmlFor={"input-productcode"}>Kode Produk</label>
							<input
								type={"text"}
								id={"input-productcode"}
								name={"productcode"}
								className={"form-control"}
								placeholder={"Masukan kode produk"}
								onChange={this.onChangeHandler.bind(this)}
								value={datas.productcode} />
						</div>
						<div className={"form-group"}>
							<label htmlFor={"select-category"}>Kategori Product</label>
							<AsyncSelect
								cacheOptions
								defaultOptions
								name={"category"}
								id={"select-category"}
								placeholder={"Pilih kategori product"}
								loadOptions={API_SELECT.groupCategoryProdyct}
								onChange={this.onChangeSelect.bind(this)}
								value={datas.category}
								isDisabled={disabled}
							/>
						</div>
						<div className={"form-group"}>
							<label htmlFor={"input-productname"}>Nama Produk</label>
							<input
								type={"text"}
								id={"input-productname"}
								name={"productname"}
								className={"form-control"}
								placeholder={"Masukan nama produk"}
								onChange={this.onChangeHandler.bind(this)}
								value={datas.productname} />
						</div>
					</form>
				</Modal.Body>
				<Modal.Footer>
					<Button type={"button"} variant="secondary" size={"sm"} onClick={props.onHide} disabled={disabled}>
						Batal
					</Button>
					<Button type={"submit"} variant="primary" size={"sm"} form={"form-product"} disabled={disabled}>
						{!disabled ? 'Simpan' : 'Memproses ...'}
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default ProductCreate;
