import ServiceMasterMaterial from "../../../services/master-material";

import $ from 'jquery';
import ServiceMasterMaterialDetail from "../../../services/master-material-detail";
import ServiceMasterType from "../../../services/master-type";
import {DBCode} from "../../../module/constants";
import ServiceMasterColor from "../../../services/master-color";
import ServiceMasterUom from "../../../services/master-uom";

const service = new ServiceMasterMaterial();
const serviceDetail = new ServiceMasterMaterialDetail();
const serviceType = new ServiceMasterType();
const serviceColor = new ServiceMasterColor();
const serviceUOM = new ServiceMasterUom();

export const DATATABLES = {
	serverSide: {
		url: service.datatables,
		type: 'post',
		data: function(params) {
			params.materialtypeid = DBCode.TYPE_KAIN;
			return params;
		}
	},
	columns: [
		{title: 'No'},
		{title: 'Nama Material', data: 'materialname'},
		{title: 'Descriptions', data: 'descriptions'},
		{title: 'Aksi'},
	],
	columnDefs: [
		{
			targets: 0,
			width: 20,
			render: (data, type, row, meta) => {
				return meta.row + meta.settings._iDisplayStart + 1;
			},
		},
		{
			targets: 3,
			width: 130,
			render: (data, type, row, meta) => {
				const $actions = $('<div>');

				const $detail = $("<button>");
				$detail.addClass("btn btn-primary btn-xs");
				$detail.attr("actions", "detail");
				$detail.attr("data-id", row.materialid);
				$detail.append($("<i>", { class: "fa fa-info-circle" }));
				$detail.append(' Detail');

				$actions.append($detail);

				const $edit = $("<button>");
				$edit.addClass("btn btn-primary btn-xs ml-1");
				$edit.attr("actions", "edit");
				$edit.attr("data-id", row.materialid);
				$edit.append($("<i>", { class: "fa fa-edit" }));

				$actions.append($edit);

				const $delete = $('<button>');
				$delete.addClass("btn btn-danger btn-xs ml-1");
				$delete.attr("actions", "delete");
				$delete.attr("data-id", row.materialid);
				$delete.append($("<i>", { class: "fa fa-trash" }));

				$actions.append($delete);

				return $actions.get(0).outerHTML;
			}
		}
	]
};

export const SELECT_COLOR = {
	elements: () => {
		return {
			$group: $('#select-groupcolor'),
			$color: $('#select-color'),
			$uom: $('#select-uom'),
		};
	},
	init: function() {
		SELECT_COLOR.groupColor();
		SELECT_COLOR.color();
		SELECT_COLOR.uom();
	},
	setSelected: function(color, uom) {
		let $html = $('<div>').css({
			display: 'flex',
			justifyContent: 'start',
			alignItems: 'center'
		}).append(
			$('<div>').css({
				width: 30,
				height: 30,
				marginRight: 5,
				backgroundColor: color.colorcode,
			}),
			$('<div>').css({
				width: 'calc(100% - 30px)'
			}).text(color.groupcolor.typename + ' - ' + color.colorname)
		);
		SELECT_COLOR.elements().$group.empty();
		SELECT_COLOR.elements().$group.append($('<option>', {value: color.groupcolor.typeid}).text(color.groupcolor.typename));
		SELECT_COLOR.elements().$color.select2().empty();
		SELECT_COLOR.elements().$color.append($('<option>', {value: color.colorid}));
		SELECT_COLOR.color({
			disabled: false,
			id: color.colorid,
			html: $html.get(0).outerHTML,
		});
		SELECT_COLOR.elements().$uom.empty();
		SELECT_COLOR.elements().$uom.append($('<option>', {value: uom.uomid}).text(uom.uomname));
	},
	groupColor: function() {
		SELECT_COLOR.elements().$group.select2({
			placeholder: 'Pilih group warna',
			width: '100%',
			allowClear: true,
			dropdownParent: $('#select-groupcolor').parent(),
			ajax: {
				url: serviceType.select2ByCategory,
				type: 'post',
				dataType: 'json',
				data: (params) => {
					params.categoryid = DBCode.CATEGORY_GROUP_COLOR;
					return params;
				}
			}
		});
	},
	color: function(selected) {
		SELECT_COLOR.elements().$color.select2({
			data: selected,
			placeholder: 'Pilih uom',
			width: '100%',
			allowClear: true,
			dropdownParent: $('#select-color').parent(),
			ajax: {
				url: serviceColor.select2Data,
				type: 'post',
				dataType: 'json',
				data: (params) => {
					params.groupid = SELECT_COLOR.elements().$group.val();
					return params;
				},
			},
			templateResult: function(data) {
				return $(data.html);
			},
			templateSelection: function(data) {
				return (selected && selected.html) ? $(selected.html) : (data) ? $(data.html) : 'Pilih uom';
			},
		});
	},
	uom: function() {
		SELECT_COLOR.elements().$uom.select2({
			placeholder: 'Pilih uom',
			width: '100%',
			allowClear: true,
			dropdownParent: $('#select-uom').parent(),
			ajax: {
				url: serviceUOM.select2Data,
				type: 'post',
				dataType: 'json',
			}
		});
	}
};

export const DETAIL_PARAMS = {
	materialid: 0,
};

export const DATATABLES_DETAIL = {
	materialdtid: 0,
	serverSide: {
		url: serviceDetail.datatables,
		type: 'post',
		data: (params) => {
			params.materialid = DETAIL_PARAMS.materialid;

			return params;
		}
	},
	columns: [
		{title: 'Gambar'},
		{title: 'Info File'},
	],
	columnDefs: [
		{
			targets: 0,
			width: 100,
			render: (data, type, row) => {
				const $wrapper = $('<div>');

				$wrapper.css({
					width: 100,
					height: 100,
					backgroundPosition: 'center',
					backgroundSize: 'contain',
					backgroundColor: '#000',
					backgroundRepeat: 'no-repeat',
				});
				if(row.files[0]) $wrapper.css({backgroundImage: `url(${row.files[0].file})`});

				return $wrapper.get(0).outerHTML;
			}
		},
		{
			targets: 1,
			render: (data, type, row) => {
				const $wrapper = $('<div>');
				$wrapper.append($('<div>', {class: ''}).text('Kode: ' + row.materialcode));
				$wrapper.append($('<div>').text('Warna : ' + row.color.colorname));

				if(row.uom) $wrapper.append($('<div>', {class: ''}).text('UOM: ' + row.uom.uomname));
				if(row.files[0] && row.files[0].filename)
					$wrapper.append($('<div>', {class: 'mb-2'}).text('Nama File: ' + row.files[0].filename));

				const $button = $('<div>');
				if(row.materialdtid === DATATABLES_DETAIL.materialdtid)
					$button.addClass('btn btn-success btn-xs mb-2 mr-1');
				else
					$button.addClass('btn btn-primary btn-xs mb-2 mr-1');

				$button.attr('data-toggle', 'edit');
				$button.attr('data-id', row.materialdtid);
				$button.append($('<i>', {class: 'fa fa-edit'}));
				$button.append(row.materialdtid === DATATABLES_DETAIL.materialdtid ? 'Selected' : ' Edit');
				$wrapper.append($button);

				const $delete = $('<div>');
				$delete.addClass('btn btn-danger btn-xs mb-2');
				$delete.attr('data-toggle', 'delete');
				$delete.attr('data-id', row.materialdtid);
				$delete.append($('<i>', {class: 'fa fa-trash'}));
				$delete.append(row.materialdtid === DATATABLES_DETAIL.materialdtid ? 'Selected' : ' Hapus');
				$wrapper.append($delete);

				return $wrapper.get(0).outerHTML;
			}
		}
	]
};