import React from "react";
import {Redirect} from "react-router";

import './login.css';
import $ from 'jquery';
import ServiceAuth from "../../../services/auth";
import {Helpers} from "../../../module/core";
import {DBMessage} from "../../../module/constants";
import Auth from "../../../module/helpers/auth";

class LoginComponent extends React.Component {
	componentDidMount() {
		$('body').addClass("login");
		$('.show-password').click(function() {
			let $password = $('[name=password]');
			if($password.attr('type') === 'text') {
				$password.attr('type', 'password');
			} else {
				$password.attr('type', 'text');
			}
		});
	}
	constructor(props) {
		super(props);

		this.state = {
			datas: {
				username: null,
				password: null
			},
			disabled: false
		};

		this.service = new ServiceAuth();
	}
	handlerChange(e) {
		const {datas} = this.state;
		this.setState({
			datas: {
				...datas,
				[e.target.name]: e.target.value
			},
			redirect: false
		});
	}
	submitHandler(e) {
		e.preventDefault();

		const {username, password} = this.state.datas;

		this.setState({ disabled: true });

		this.service.fetchLogin(username, password)
			.then(
				res => {
					this.setState({ disabled: false });

					if(res.success) {
						Helpers.swalSuccess(DBMessage.LOGIN_SUCCESS_TITLE, res.message);
						Auth.login(res.row, () => {
							this.setState({ redirect: "/" });
						});
					} else {
						Helpers.swalError(DBMessage.LOGIN_FAILED_TITLE, res.message);
					}
				},
			)
			.catch(error => {
				this.setState({ disabled: false });
			})
	}
	render() {
		const {redirect, disabled} = this.state;

		if(redirect) {
			window.location.href = redirect;
			return <Redirect to={redirect} />
		}

		return (
			<form onSubmit={event => { this.submitHandler(event); }} autoComplete={"off"}>
				<div className={"wrapper wrapper-login"}>
					<div className={"container container-login animated fadeIn"}>
						<h3 className={"text-center"}>Masuk untuk ke Dashboard</h3>
						<div className={"login-form"}>
							<div className={"form-floating-label"}>
								<input type={"text"} id={"username"} name={"username"} className={"form-control input-border-bottom"} onChange={event => { this.handlerChange(event) }} required={true}/>
								<label htmlFor="username" className={"placeholder-text"}>Nama Pengguna</label>
							</div>
							<div className={"form-floating-label"}>
								<input type={"password"} id={"password"} name={"password"} className={"form-control input-border-bottom"} onChange={event => { this.handlerChange(event) }} required={true} />
								<label htmlFor={"password"} className={"placeholder-text"}>Kata Sandi</label>
								<div className={"show-password"}>
									<i className={"icon-eye"} />
								</div>
							</div>
							<div className={"form-action mb-3"}>
								<button type={"submit"} className={"btn btn-primary btn-rounded btn-login"} disabled={disabled}>{!disabled ? 'Masuk' : 'Memproses ...'}</button>
							</div>
							<div className={"login-account"}>
								<span className={"msg"}>Copyright © 2020 Hyperdata Solusindo</span>
							</div>
						</div>
					</div>
				</div>
			</form>
		);
	}
}

export default LoginComponent;
