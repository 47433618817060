import Auth from "./helpers/auth";
import {DBCode, DBMessage} from "./constants";

import Swal from "sweetalert2";
import ServiceMasterMenu from "../services/master-menu";
import ServiceMasterCategory from "../services/master-category";
import ServiceMasterType from "../services/master-type";
import ServiceMasterProductType from "../services/master-product-type";
import ServiceMasterProductVariant from "../services/master-product-variant";
import ServiceMasterMaterial from "../services/master-material";
import ServiceMasterColor from "../services/master-color";
import ServiceMasterGroup from "../services/master-group";

import $ from "jquery";
import ServiceMasterCustomer from "../services/master-customer";
import ServiceMasterProductMaterial from "../services/master-product-material";

const localStorage = require('local-storage');

export const Config = {
	project: 'Garment Webs Application',
	api: 'https://api.indartopratama.co.id/webs',
	apiApps: 'https://api.indartopratama.co.id/apps',
	apiPublic: 'https://api.indartopratama.co.id/',
};

export const Helpers = {
	sessFullname: function () {
		return Auth.get('fullname');
	},
	sessUserid: function() {
		return Auth.get('userid');
	},
	sessGroupId: function() {
		return Auth.get('groupid');
	},
	sessGroupName: function() {
		return Auth.get('groupnm');
	},
	sessGroupTypeId: function() {
		return Auth.get('grouptypeid');
	},
	sessAccessTo: function(parentid, index) {
		localStorage.set(DBCode.KEY_STORAGE_ACCESSTO, btoa(JSON.stringify({
			parentid: parentid,
			index: index,
		})));
	},
	sessAccess: function(access) {
		const pages = ['/account'];

		if(localStorage.get(DBCode.KEY_STORAGE_MENUS)) {
			let current = window.location.href;
			let currentPath = window.location.pathname;
			let menus = JSON.parse(atob(localStorage.get(DBCode.KEY_STORAGE_MENUS)));
			let exist = false;
			pages.forEach(page => {
				if(currentPath !== '/' && currentPath.match(new RegExp(page)) === null) {
					Object.keys(menus).forEach(kmenu => {
						menus[kmenu].forEach(menu => {
							var pattern = new RegExp( window.location.origin + '/' + menu.menu.link, 'g');
							if(current.match(pattern) !== null && (
								menu.gmaccess.includes(access.toString())
								|| menu.gmaccess.includes(access)
							)) {
								exist = true;
								return false;
							}
						});
					});
				} else {
					exist = true;
					return false;
				}
			});

			return exist;
		} else {
			return true;
		}
	},
	swalConfirm: function(title, message) {
		return Swal.fire({
			title: title,
			text: message,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yakin',
			cancelButtonText: 'Batal',
		});
	},
	swalSuccess: function(title, message) {
		return Swal.fire(
			title,
			message,
			'success'
		);
	},
	swalError: function(title, message) {
		return Swal.fire(
			title,
			message,
			'error'
		);
	},
	alertClose: function() {
		$('body').removeClass('fetching-data');
		return Helpers.swalError(DBMessage.ERROR_NETWORK_TITLE, DBMessage.ERROR_NETWORK_MESSAGE)
	},
};

export const API_SELECT = {
	groupType: function(inputValue, callback) {
		const service = new ServiceMasterType();
		service.fetchByCategory(DBCode.CATEGORY_GROUP_TYPE, inputValue)
			.then(
				res => {
					callback(res.datas);
				}
			)
	},
	groupSize: function(inputValue, callback) {
		const service = new ServiceMasterType();
		service.fetchTypeId(DBCode.CATEGORY_GROUP_SIZE, inputValue)
			.then(
				res => {
					callback(res.datas);
				}
			);
	},
	groupColor: function(inputValue, callback) {
		const service = new ServiceMasterType();
		service.fetchColorId(DBCode.CATEGORY_GROUP_COLOR, inputValue)
			.then(
				res => {
					callback(res.datas);
				}
			);
	},
	groupProductType: function(inputValue, callback) {
		const service = new ServiceMasterType();
		service.fetchByCategory(DBCode.CATEGORY_PRODUCT_TYPE, inputValue)
			.then(
				res => {
					callback(res.datas);
				}
			)
	},
	groupCategoryProdyct: function(inputValue, callback) {
		const service = new ServiceMasterType();
		service.fetchByCategory(DBCode.CATEGORY_PRODUCT_CATEGORY, inputValue)
			.then(
				res => {
					callback(res.datas);
				}
			)
	},
	productType: function(productid, inputValue, callback) {
		const service = new ServiceMasterProductType(productid);
		service.select(inputValue)
			.then(
				res => {
					if(res.success) {
						callback(res.datas);
					}
				}
			)
	},
	productVariant: function(productid, inputValue, callback) {
		const service = new ServiceMasterProductVariant(productid, inputValue);
		service.select(inputValue)
			.then(
				res => {
					callback(res.datas);
				}
			)
	},
	productVariantExcept: function(productid, exceptValue, inputValue, callback) {
		const service = new ServiceMasterProductVariant(productid);
		service.selectExcept(exceptValue, inputValue)
			.then(
				res => {
					callback(res.datas);
				}
			)
	},
	productMaterial: function(productid, inputValue, callback) {
		const service = new ServiceMasterProductMaterial(productid);
		service.select(productid, inputValue)
			.then(
				res => {
					callback(res.datas);
				}
			);
	},
	menu: function(inputValue, callback) {
		const service = new ServiceMasterMenu();
		service.select(inputValue)
			.then(
				res => {
					callback(res.datas);
				}
			);
	},
	category: function(inputValue, callback) {
		const service = new ServiceMasterCategory();
		service.select(inputValue)
			.then(
				res => {
					callback(res.datas);
				}
			);
	},
	material: function(inputValue, callback) {
		const service = new ServiceMasterMaterial();
		service.select(inputValue)
			.then(
				res => {
					callback(res.datas);
				}
			)
	},
	accessorisByType: function(typeid, inputValue, callback) {
		const service = new ServiceMasterMaterial();
		service.selectByType(DBCode.TYPE_ACCESSORIS, typeid, inputValue)
			.then(
				res => {
					callback(res.datas);
				}
			)
	},
	color: function(inputValue, callback) {
		const service = new ServiceMasterColor();
		service.select(inputValue)
			.then(
				res => {
					callback(res.datas);
				}
			);
	},
	colorByGroup: function(groupid, inputValue, callback) {
		const service = new ServiceMasterColor();
		service.byGroup(groupid, inputValue)
			.then(
				res => {
					callback(res.datas);
				}
			)
	},
	colorByGroupDetail: function(from, productvarid, partname, materialid, groupid, inputValue, callback) {
		const service = new ServiceMasterColor();
		service.byGroupStock(from, productvarid, partname, materialid, groupid, inputValue)
			.then(
				res => {
					callback(res.datas);
				}
			)
	},
	colorByGroupAcc: function(materialid, groupid, inputValue, callback) {
		const service = new ServiceMasterColor();
		service.byGroupStock('accessoris', materialid, materialid, inputValue)
			.then(
				res => {
					callback(res.datas);
				}
			)
	},
	group: function(inputValue, callback) {
		const service = new ServiceMasterGroup();
		service.select(inputValue)
			.then(
				res => {
					callback(res.datas);
				}
			);
	},
	customer: function(inputValue, callback) {
		const service = new ServiceMasterCustomer();
		service.select(inputValue)
			.then(
				res => {
					callback(res.datas);
				}
			);
	},
	typeByCategory: function(catid, inputValue, callback) {
		const service = new ServiceMasterType();
		service.fetchByCategory(catid, inputValue)
			.then(
				res => {
					callback(res.datas);
				}
			);
	},
};
