import axios from 'axios';
import {Config, Helpers} from "../module/core";
import $ from "jquery";

class ServiceAppsProduct {
	constructor() {
		this.api = `${Config.apiApps}/products`;
	}
	fetchLimitProduct() {
		$('body').addClass('fetching-data');
		return axios.get(`${this.api}/limit/${this.limit}`)
			.then(
				res => {
					$('body').removeClass('fetching-data');
					return res.data;
				},
				Helpers.alertClose
			)
			.catch(Helpers.alertClose);
	}
	fetch(productid) {
		return axios.get(`${this.api}/${productid}`)
			.then(
				res => {
					return res.data;
				},
				Helpers.alertClose
			)
			.catch(Helpers.alertClose);
	}
	fetchSearch(searchValue) {
		return axios.get(`${this.api}/search/${searchValue}`)
			.then(
				res => {
					return res.data;
				},
				Helpers.alertClose
			)
			.catch(Helpers.alertClose);
	}
}

export default ServiceAppsProduct;
