import React from "react";
import {Helpers} from "../../module/core";
import {Link} from "react-router-dom";

class HeaderNavbar extends React.Component {
	render() {
		return (
			<nav className={"navbar navbar-header navbar-expand-lg"} data-background-color={"blue2"}>
				<div className={"container-fluid"}>
					<ul className={"navbar-nav topbar-nav ml-md-auto align-items-center"}>
						<li className={"nav-item dropdown hidden-caret"}>
							<a className={"dropdown-toggle profile-pic"} data-toggle={"dropdown"} href={"/"} aria-expanded={"false"}>
								<div className={"avatar-sm"}>
									<img src={"dist/img/profile.jpg"} alt={"profile.jpg"} className={"avatar-img rounded-circle"} />
								</div>
							</a>
							<ul className={"dropdown-menu dropdown-user animated fadeIn"}>
								<div className={"dropdown-user-scroll scrollbar-outer"}>
									<li>
										<div className={"user-box"}>
											<div className={"avatar-lg"}>
												<img src={"dist/img/profile.jpg"} alt={"profile.jpg"} className={"avatar-img rounded"} />
											</div>
											<div className={"u-text"}>
												<h4>{Helpers.sessFullname()}</h4>
												<p className={"text-muted"}>{Helpers.sessGroupName()}</p>
											</div>
										</div>
									</li>
									<li>
										<div className={"dropdown-divider"} />
										<Link className={"dropdown-item"} to={"/account"}>Akun Saya</Link>
										<div className={"dropdown-divider"} />
										<Link className={"dropdown-item"} to={"/logout"}>Logout</Link>
									</li>
								</div>
							</ul>
						</li>
					</ul>
				</div>
			</nav>
		);
	}
}

export default HeaderNavbar;
