import React from "react";
import {RouteAuth} from "../module/routes/route-auth";
import ProductComponent from "../app/masters/product/product";
import ProductEditComponent from "../app/masters/product/product-edit";

const routes_master_product = [
	<RouteAuth exact={true} path={"/master/product"} component={ProductComponent} key={"master-product"} />,
	<RouteAuth path={"/master/product/edit/:productid"} component={ProductEditComponent} key={"master-product-edit"} />,
];

export default routes_master_product;
