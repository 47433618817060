import React from "react";
import {Modal} from "react-bootstrap";
import {FilePond, registerPlugin} from "react-filepond";
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';

import './filepond.css';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import ServiceMasterProductVariant from "../../../services/master-product-variant";

registerPlugin(FilePondPluginImagePreview);

const sprintf = require('sprintf-js').sprintf;

class ProductFormImages extends React.Component {
	constructor(props) {
		super(props);

		const {productid} = props.datas;

		this.state = {
			files: [],
			productid: productid,
			productvarid: 0,
			productvar: null,
			actions: 'ready',
		};

		this.service = new ServiceMasterProductVariant(productid)
	}
	onShow() {
		const {datas} = this.props;

		this.setState({
			productvarid: datas.id,
			files: []
		});
		this.fetchImages();
	}
	fetchImages() {
		const {datas, actions} = this.props;

		this.service.images(datas.id)
			.then(
				res => {
					if(res.success) {
						this.setState({
							productvarid: datas.id,
							actions: actions,
							files: res.files,
							productvar: res.productvar
						});
					}
				}
			);
	}
	handleInit() {}
	onClickRevertImage(response, load) {
		const {productvarid} = this.state;
		const json = JSON.parse(response);
		this.service.fetchRevertImage(productvarid, json.fileid)
			.then(
				() => {
					load();
				}
			);
		return false;
	}
	onClickRemoveImage(file, load) {
		const {productvarid} = this.state;
		this.service.fetchRemoveImage(productvarid, file)
			.then(
				() => {
					load();
				}
			)
	}
	render() {
		const {datas, show, onHide} = this.props;
		const {files, productvar} = this.state;

		return (
			<Modal show={show} onHide={onHide} size={"lg"} onShow={this.onShow.bind(this)}>
				<Modal.Header closeButton>
					<Modal.Title><b>{productvar}</b> - Gallery</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<FilePond
						ref={ref => this.pond = ref}
						files={files}
						allowMultiple={true}
						server={{
							process: sprintf(this.service.fileupload, datas.id),
							load: `${sprintf(this.service.fileupload, datas.id)}&load=`,
							revert: (response, load) => {
								this.onClickRevertImage(response, load);
							},
							remove: this.onClickRemoveImage.bind(this),
						}}
						oninit={() => this.handleInit() }
						onupdatefiles={fileItems => {
							this.setState({
								files: fileItems.map(fileItem => fileItem.file)
							});
						}}
					/>
				</Modal.Body>
			</Modal>
		)
	}
}

export default ProductFormImages;
