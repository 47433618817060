import axios from 'axios';

import $ from 'jquery';
import {Config} from "../module/core";
import {DBMessage} from "../module/constants";

class ServiceAuth {
	constructor() {
		this.api = `${Config.api}/auth`;
	}
	fetchLogin(username, password) {
		$('body').addClass('fetching-data');
		return axios.post(`${this.api}/login`, {
			username: username,
			password: password
		}).then(
			(res) => {
				$('body').removeClass('fetching-data');
				return res.data;
			},
			(error) => {
				$('body').removeClass('fetching-data');
				return {success: false, message: error};
			}
		).catch(error => {
			$('body').removeClass('fetching-data');
			Config.notifyError(DBMessage.ERROR_NETWORK_TITLE, DBMessage.ERROR_NETWORK_MESSAGE);
		});
	}
}

export default ServiceAuth;
