import React from "react";
import {Redirect, Route} from "react-router";
import Auth from "../helpers/auth";
import {Helpers} from "../core";
import {DBCode} from "../constants";
import PageNotFound from "../../app/errors/page-not-found";

export const RouteAuth = ({component: Component, ...rest}) => {
	return (
		<Route
			{...rest}
			render={props => {
				if(Auth.isLogged()) {
					if(Helpers.sessAccess(DBCode.ACCESS_VIEW)
						/*&& Helpers.sessGroupTypeId() !== DBCode.TYPE_CATEGORY_APPS*/) {
						return <Component {...props} />
					} else {
						return <PageNotFound/>
					}
				} else {
					return <Redirect to={
						{
							pathname: '/login',
							state: {
								from: props.location
							}
						}
					} />
				}
			}
		} />
	)
};
