import React from "react";
import ServiceMasterMaterialDetail from "../../../services/master-material-detail";
import Modal from "react-bootstrap/Modal";
import {Button} from "react-bootstrap";
import $ from "jquery";
import Datatables from "../../components/datatables";
import {DATATABLES_DETAIL, DETAIL_PARAMS, SELECT_COLOR} from "./_config";
import ServiceMasterMaterial from "../../../services/master-material";
import {Helpers} from "../../../module/core";
import {DBMessage} from "../../../module/constants";

class MaterialDetail extends React.Component {

    constructor(props) {
        super(props);

        const params = props.datas;

        this.state = {
            refreshing: true,
            id: params.id,
            datas: null,
            actions: props.actions ? props.actions : 'ready',
            datatables: {
                fnRowCallback: (nRow, aData, iDisplayIndex, iDisplayIndexFull) => {
                    let $row = $(nRow);
                    let $edit = $row.find('[data-toggle=edit]');
                    $edit.click(this.onClickEdit.bind(this));
                    let $delete = $row.find('[data-toggle=delete]');
                    $delete.click(this.onClickDelete.bind(this));
                }
            }
        };
        this.defaultState = this.state;

        this.service = new ServiceMasterMaterial();
        this.serviceDetail = new ServiceMasterMaterialDetail();
    }
    resetForm() {
        $('#select-groupcolor').val(null).text(null).trigger('change');
        $('#select-color').val(null).text(null).trigger('change');
        $('#select-uom').val(null).text(null).trigger('change');
        $('[name=materialcode]').val(null);
        $('[name=filename]').val(null);
        SELECT_COLOR.init();
        this.setState({
            datas: null
        })
    }
    onShow() {
        SELECT_COLOR.init();

        const props = this.props;
        DETAIL_PARAMS.materialid = props.datas.id;

        this.setState({
            refreshing: true,
        });
        this.service.fetch(props.datas.id)
            .then(
                res => {
                    if(res.success) {
                        this.setState({
                            id: props.datas.id,
                            actions: 'read',
                            datas: {
                                materialname: res.row.materialname
                            }
                        });
                    } else {
                        props.onHide();
                    }
                }
            ).catch(() => props.onHide());
    }
    onChangeText(e) {
        const {datas} = this.state;
        this.setState({
            datas: {
                ...datas,
                [e.target.name]: e.target.value
            }
        })
    }
    onClickEdit(e) {
        let $clicked = $(e.currentTarget);
        let id = $clicked.data('id');
        this.setState({
            refreshing: true,
            actions: 'loading'
        });
        this.serviceDetail.fetch(id).then(res => {
           if(res.success) {
               this.setState({
                   refreshing: false,
                   actions: 'edit',
                   datas: {
                       materialdtid: res.row.materialdtid,
                       materialcode: res.row.materialcode,
                       filename: res.row.files && res.row.files[0] ? res.row.files[0].filename : null,
                       files: res.row.files && res.row.files[0] ? res.row.files[0].file : null,
                   }
               });
               DATATABLES_DETAIL.materialdtid = id;
               SELECT_COLOR.setSelected(res.row.color, res.row.uom);
           }
        });
    }
    onClickDelete(e) {
        let $clicked = $(e.currentTarget);
        let id = $clicked.data('id');
        Helpers.swalConfirm(DBMessage.CONFIRM_DELETE_TITLE, DBMessage.CONFIRM_DELETE_MESSAGE)
            .then(
                result => {
                    if(result.value) {
                        this.serviceDetail.delete(id)
                            .then(
                                res => {
                                    if(res.success) {
                                        this.setState({
                                            refreshing: true,
                                            actions: 'read',
                                            datas: null,
                                        });
                                        this.resetForm();
                                        Helpers.swalSuccess(DBMessage.FETCH_SUCCESS_TITLE, res.message);
                                    } else {
                                        Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, res.message)
                                    }
                                }
                            );
                    }
                }
            );
    }
    onClickUpload() {
        const {datas} = this.state;
        const $uploadEl = $('#file-color');
        $uploadEl.click();
        $uploadEl.change((e) => {
            this.setState({
                datas: {
                    ...datas,
                    files: e.target.files[0],
                    filename: e.target.files[0].name,
                }
            })
        })
    }
    onSubmit(e) {
        e.preventDefault();

        const {datas, actions, id} = this.state;
        datas.colorid = SELECT_COLOR.elements().$color.val();

        var formData = new FormData(e.target);
        formData.append('files', datas.files);
        formData.append('materialid', id);

        this.setState({
            refreshing: true,
            actions: 'loading'
        });
        if(actions === 'create') {
            this.serviceDetail.store(formData).then((res) => {
                if(res.success) {
                    this.setState({
                        actions: 'read',
                        datas: null,
                    });
                    Helpers.swalSuccess(DBMessage.FETCH_SUCCESS_TITLE, res.message);
                    this.resetForm();
                } else {
                    this.setState({
                        refreshing: false,
                        actions: 'create',
                    });
                    Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, res.message);
                }
            });
        } else {
            this.serviceDetail.update(formData, datas.materialdtid).then(res => {
                if(res.success) {
                    this.setState({
                        actions: 'read',
                        datas: null,
                    });
                    Helpers.swalSuccess(DBMessage.FETCH_SUCCESS_TITLE, res.message);
                    this.resetForm();
                } else {
                    this.setState({
                        refreshing: false,
                        actions: 'edit',
                    });
                    Helpers.swalError(DBMessage.FETCH_FAIL_MESSAGE, res.message);
                }
            })
        }
    }
    renderImage() {
        const {datas} = this.state;

        let $image = $('#image-preview');
        $image.css({
            width: '100%',
            height: 250,
            backgroundPosition: 'center',
            backgroundSize: 'contain',
            backgroundColor: '#000',
            backgroundRepeat: 'no-repeat',
        });

        if(datas && datas.files) {
            if(typeof datas.files !== 'string') {
                const Reader = new FileReader();
                Reader.readAsDataURL(datas.files);
                Reader.onloadend = function() {
                    $image.css({backgroundImage: `url(${this.result})`});
                };
            } else {
                $image.css({backgroundImage: `url(${datas.files})`});
            }
        } else {
            $image.removeAttr('style');
        }
    }
    render() {
        const props = this.props;
        const {refreshing, id, datas, actions, datatables} = this.state;

        return (
            <Modal id={'modal-material'} show={props.show} onShow={this.onShow.bind(this)} onHide={props.onHide} size={'lg'}>
                <Modal.Header closeButton>
                    <Modal.Title>Detail Material</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form id={'add-detail-material'} onSubmit={this.onSubmit.bind(this)}>
                        <div className={'row'}>
                            <div className={'col-md-5'}>
                                <div className={'form-group'}>
                                    <label htmlFor={'materialname'}>Bahan</label>
                                    <input disabled={true} className={'form-control'} value={datas && datas.materialname} />
                                </div>
                                <div className={'form-group'}>
                                    <label htmlFor={'materialcode'}>Kode Bahan</label>
                                    <input
                                        type={'text'}
                                        name={'materialcode'}
                                        className={'form-control mb-1'}
                                        placeholder={'Masukan kode bahan'}
                                        value={datas ? datas.materialcode : null}
                                        onChange={this.onChangeText.bind(this)}
                                        required={true}
                                        disabled={refreshing}
                                    />
                                </div>
                                <div className={'form-group'}>
                                    <label htmlFor={'select-groupcolor'}>Group Warna</label>
                                    <select className={'form-control'} name={'groupcolorid'} id={'select-groupcolor'} required={true} disabled={refreshing} />
                                </div>
                                <div className={'form-group'}>
                                    <label htmlFor={'select-color'}>Warna</label>
                                    <select className={'form-control'} name={'colorid'} id={'select-color'} required={true} disabled={refreshing} />
                                </div>
                                <div className={'form-group'}>
                                    <label htmlFor={'select-uom'}>UOM</label>
                                    <select className={'form-control'} name={'uomid'} id={'select-uom'} required={true} disabled={refreshing} />
                                </div>
                                <div className={'form-group'}>
                                    <label>Gambar</label>
                                    <div className={'form-upload'}>
                                        <input type={'file'} id={'file-color'} style={{display: 'none'}} />
                                        <div style={{height: 320, width: 250}} id={'image-preview'}>
                                            { this.renderImage() }
                                        </div>
                                        <input
                                            type={'text'}
                                            className={'form-control mb-1'}
                                            name={'filename'}
                                            placeholder={'Nama filename'}
                                            value={datas ? datas.filename : null}
                                            onChange={this.onChangeText.bind(this)}
                                            disabled={refreshing}
                                            readOnly={actions === 'edit'}/>
                                        <Button
                                            type={"button"}
                                            variant={"primary"}
                                            size={"sm"}
                                            className={"btn-block mr-1 mb-2"}
                                            onClick={this.onClickUpload.bind(this)}
                                            disabled={refreshing}>
                                            <span>Upload Gambar</span>
                                        </Button>
                                        {
                                            ['create', 'edit'].includes(actions)
                                                ? <div className={'d-flex mb-2'}>
                                                    <div className={'w-100 mr-1'}>
                                                        <Button
                                                            disabled={actions === 'loading'}
                                                            type={"submit"}
                                                            variant={"primary"}
                                                            size={"sm"}
                                                            className={"btn-block"}>
                                                            <span>Simpan</span>
                                                        </Button>
                                                    </div>
                                                    <div className={'w-100'}>
                                                        <Button
                                                            disabled={actions === 'loading'}
                                                            type={"button"}
                                                            variant={"danger"}
                                                            size={"sm"}
                                                            className={"btn-block"}
                                                            onClick={() => {
                                                                this.setState({
                                                                    refreshing: true,
                                                                    actions: 'standby',
                                                                });
                                                                this.resetForm();
                                                            }}>
                                                            <span>Batal</span>
                                                        </Button>
                                                    </div>
                                                </div>
                                                : null
                                        }
                                        {
                                            actions !== 'create'
                                                ? <div className={'d-flex'}>
                                                    <Button
                                                        disabled={actions === 'loading'}
                                                        type={"button"}
                                                        variant={"primary"}
                                                        size={"sm"}
                                                        className={"btn-block"}
                                                        onClick={() => {
                                                            this.resetForm();
                                                            this.setState({
                                                                refreshing: false,
                                                                actions: 'create',
                                                            })
                                                        }}>
                                                        <span>Add New</span>
                                                    </Button>
                                                </div>
                                                : null
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className={'col-md-7'}>
                                <div className={'table-responsive'}>
                                    <Datatables
                                        id={'data-material-detail'}
                                        actions={actions}
                                        serverSide={DATATABLES_DETAIL.serverSide}
                                        columns={DATATABLES_DETAIL.columns}
                                        columnDefs={DATATABLES_DETAIL.columnDefs}
                                        options={datatables}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        );
    }
}

export default MaterialDetail;