import axios from "axios";
import {Config, Helpers} from "../module/core";
import {DBMessage} from "../module/constants";
import $ from "jquery";

const sprintf = require('sprintf-js').sprintf;

class ServiceMasterProductVariant {
	constructor(productid) {
		this.api = `${Config.api}/productvar/${productid}`;
		this.datatables = `${this.api}/datatables`;
		this.fileupload = `${this.api}/fileupload/%1$s?userid=${Helpers.sessUserid()}`;
	}
	select(searchValue) {
		return axios.get(`${this.api}/select2Api`)
			.then(
				res => {
					return res.data;
				},
				error => {
					return {success: false, message: error}
				}
			)
			.catch(error => {
				Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, error);
			});
	}
	selectExcept(exceptValue, searchValue) {
		return axios.get(`${this.api}/select2Except`, {
			params: {
				except: exceptValue
			}
		})
			.then(
				res => {
					return res.data;
				},
				error => {
					return {success: false, message: error}
				}
			)
			.catch(error => {
				Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, DBMessage.FETCH_FAIL_MESSAGE);
			});
	}
	fetch(id) {
		$('body').addClass('fetching-data');
		return axios.get(`${this.api}/${id}`)
			.then(
				res => {
					$('body').removeClass('fetching-data');
					return res.data;
				},
				error => {
					$('body').removeClass('fetching-data');
					return {success: false, message: error};
				}
			)
			.catch(() => {
				$('body').removeClass('fetching-data');
				Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, DBMessage.FETCH_FAIL_MESSAGE);
			});
	}
	fetchStore(datas) {
		$('body').addClass('fetching-data');

		datas.userid = Helpers.sessUserid();

		return axios.post(`${this.api}`, datas)
			.then(
				res => {
					$('body').removeClass('fetching-data');
					return res.data;
				},
				error => {
					$('body').removeClass('fetching-data');
					return {success: false, message: error};
				}
			)
			.catch(() => {
				$('body').removeClass('fetching-data');
				Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, DBMessage.FETCH_FAIL_MESSAGE);
			});
	}
	fetchUpdate(datas, id) {
		$('body').addClass('fetching-data');

		datas.userid = Helpers.sessUserid();

		return axios.put(`${this.api}/${id}`, datas)
			.then(
				res => {
					$('body').removeClass('fetching-data');
					return res.data;
				},
				error => {
					$('body').removeClass('fetching-data');
					return {success: false, message: error};
				}
			)
			.catch(() => {
				$('body').removeClass('fetching-data');
				Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, DBMessage.FETCH_FAIL_MESSAGE);
			});
	}
	fetchDelete(id) {
		$('body').addClass('fetching-data');
		return axios.delete(`${this.api}/${id}`)
			.then(
				res => {
					$('body').removeClass('fetching-data');
					return res.data;
				},
				error => {
					$('body').removeClass('fetching-data');
					return {success: false, message: error};
				}
			)
			.catch(() => {
				$('body').removeClass('fetching-data');
				Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, DBMessage.FETCH_FAIL_MESSAGE);
			});
	}
	fetchDetail(id) {
		$('body').addClass('fetching-data');
		return axios.get(`${this.api}/${id}/detail`)
			.then(
				res => {
					$('body').removeClass('fetching-data');
					return res.data;
				},
				error => {
					$('body').removeClass('fetching-data');
					return {success: false, message: error};
				}
			)
			.catch(() => {
				$('body').removeClass('fetching-data');
				Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, DBMessage.FETCH_FAIL_MESSAGE);
			});
	}
	images(productvarid) {
		$('body').addClass('fetching-data');
		return axios.get(sprintf(this.fileupload, productvarid))
			.then(
				res => {
					$('body').removeClass('fetching-data');
					return res.data;
				},
				error => {
					$('body').removeClass('fetching-data');
					return {success: false, message: error};
				}
			)
			.catch(() => {
				$('body').removeClass('fetching-data');
				Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, DBMessage.FETCH_FAIL_MESSAGE);
			});
	}
	fetchRevertImage(productvarid, fileid) {
		$('body').addClass('fetching-data');
		return axios.delete(`${sprintf(this.fileupload, productvarid + '/revert	')}`, {
			params: {
				fileid: fileid
			}
		})
			.then(
				res => {
					$('body').removeClass('fetching-data');
					return res.data;
				},
				error => {
					$('body').removeClass('fetching-data');
					return {success: false, message: error};
				}
			)
			.catch(() => {
				$('body').removeClass('fetching-data');
				Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, DBMessage.FETCH_FAIL_MESSAGE);
			});
	}
	fetchRemoveImage(productvarid, image) {
		$('body').addClass('fetching-data');
		return axios.delete(`${sprintf(this.fileupload, productvarid + '/remove')}`, {
			params: {
				image: image
			}
		})
			.then(
				res => {
					$('body').removeClass('fetching-data');
					return res.data;
				},
				error => {
					$('body').removeClass('fetching-data');
					return {success: false, message: error};
				}
			)
			.catch(() => {
				$('body').removeClass('fetching-data');
				Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, DBMessage.FETCH_FAIL_MESSAGE);
			});
	}
}

export default ServiceMasterProductVariant;
