import React from "react";
import {DBCode, DBMessage, DBStyles} from "../../../module/constants";
import Header from "../../skin/header";
import Sidebar from "../../skin/sidebar";
import PageHeader from "../../skin/pageheader";
import Select2 from "../../components/select2";
import ServiceReportOrder from "../../../services/report-order";
import {API_SELECT, Helpers} from "../../../module/core";
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';
import OrdersConfirm from "./orders-confirm";
import {Redirect} from "react-router";
import DatePicker from "react-datepicker/es";

const sprintf = require('sprintf-js').sprintf;

class ReportOrderDetailComponent extends React.Component {
	constructor(props) {
		super(props);

		const {token} = props.match.params;
		const params = JSON.parse(atob(token));
		const userid = atob(params[DBCode.KEY_PARAM_USERID]);
		const orderid = atob(params[DBCode.KEY_PARAM_ORDERID]);
		const productid = atob(params[DBCode.KEY_PARAM_PRODUCTID]);

		this.state = {
			refreshing: true,
			title: 'Data Order',
			breadcrumbs: [
				{link: '#', menu: 'Orders'},
			],
			orderid: orderid,
			order: null,
			orders: null,
			stocks: [],
			datas: {
				indexType: null,
				indexVariant: null,
				estimatedate: new Date(),
				customer: {
					action: 'read',
					data: null
				},
				userid: userid,
				productid: productid,
			},
			options: {
				swiper: {
					pagination: {
						el: '.swiper-pagination',
						clickable: true
					}
				}
			},
			modal: {
				show: false,
			}
		};

		this.serviceOrder = new ServiceReportOrder();

		this.fetchOrder();
	}
	fetchOrder() {
		const {orderid, breadcrumbs} = this.state;
		this.serviceOrder.fetch(orderid)
			.then(
				res => {
					breadcrumbs.push({ link: '#', menu: res.order && res.order.ordercd ? res.order.ordercd : null });
					if(res.success) {
						if(res.order.status.statusid === DBCode.STATUS_APPROVED) {
							this.setState({redirect: `${window.location.pathname}/view`});
						} else {
							this.setState({
								refreshing: false,
								order: res.order,
								orders: res.orders,
								stocks: res.stocks,
								datas: {
									indexType: res.indexType,
									indexVariant: res.indexVariant,
									orderdate: res.order.orderdate,
									estimatedate: new Date(res.order.estimatedate),
									customer: {
										action: 'read',
										data: res.order.customer,
									},
									orderid: res.order.orderid,
									product: res.order.product,
									producttype: res.order.producttype,
									productmaterial: res.order.material,
									productvar: res.order.productvar,
									productvardetail: res.order.orderDetail,
									productvaracc: res.order.orderAccessoris,
									productvarsize: {
										show: false,
									},
									productvarsizes: res.order.orderSizes,
									productmaterials: res.order.orderMaterials,
								}
							})
						}
					} else {
						Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, res.message);
					}
				}
			)
	}
	fetchCalculate() {
		const {datas} = this.state;
		this.serviceOrder.fetchCalculate(datas)
			.then(
				() => {

				}
			)
	}
	isValid() {
		const {datas} = this.state;

		if(!datas.estimatedate) return true;

		if(!datas.customer.data) return true;
		else {
			if(!datas.customer.data.customername) return true;

			if(!datas.customer.data.address) return true;
		}

		if(!datas.producttype) return true;

		if(!datas.productvar) return true;

		var _disabled = datas.productvardetail.length === 0;
		datas.productvardetail.forEach((detail) => {
			if(!_disabled && (!detail.groupcolor || !detail.color)) {
				_disabled = true;
			}
		});

		datas.productvaracc.forEach((acc) => {
			if(!_disabled && (!acc.groupcolor || !acc.color)) {
				_disabled = true;
			}
		});

		if(_disabled) return true;

		var countChecked = 0;
		datas.productvarsizes.forEach(size => {
			if(size.checked) countChecked++;
		});

		if(countChecked === 0) return true;
	}
	onChangeData(name, value) {
		const {datas} = this.state;

		this.setState({
			datas: {
				...datas,
				[name]: value
			}
		});
	}
	onChangeFormCustomer(event) {
		const {datas} = this.state;
		const {customer} = datas;
		const {data} = customer;

		this.setState({
			datas: {
				...datas,
				customer: {
					...customer,
					data: {
						...data,
						[event.target.name]: event.target.value,
					}
				}
			}
		});
	}
	onChangeSize(name, value, i) {
		const {datas} = this.state;
		datas.productvarsizes[i][name] = value;

		this.setState({
			datas: datas
		})
	}
	onChangeSelectDetail(i, name, value) {
		const {datas} = this.state;
		const productvardetail = datas.productvardetail;
		if(productvardetail[i] === undefined) productvardetail[i] = {};

		productvardetail[i][name] = value;

		this.setState({
			datas: {
				...datas,
				productvardetail: productvardetail,
			}
		});
	}
	onChangeSelectAcc(i, name, value) {
		const {datas} = this.state;
		const productvaracc = datas.productvaracc;
		if(productvaracc[i] === undefined) productvaracc[i] = {};

		productvaracc[i][name] = value;

		this.setState({
			datas: {
				...datas,
				productvaracc: productvaracc,
			}
		});
	}
	onClickConfirm() {
		this.setState({
			modal: {
				show: true
			}
		});
	}
	onSubmitHandler(e) {
		e.preventDefault();
		if(this.isValid())
		this.setState({
			modal: {
				show: true
			}
		});
	}
	onModalHide() {
		this.setState({
			modal: {
				show: false
			}
		});
	}
	calculateStock(value, i) {
		const {datas, stocks} = this.state;

		if(isNaN(value)) value = 1;

		Object.keys(stocks).forEach((kStock) => {
			stocks[kStock].stockAvaiable = stocks[kStock].qtyavailable !== null
				? parseFloat(parseFloat(stocks[kStock].qtyavailable).toFixed(3))
				: 0;
			stocks[kStock].stockUsed = 0;
		});

		datas.productvardetail.forEach((detail, i) => {
			datas.productvardetail[i].needQty = 0;
			datas.productvardetail[i].usedQty = 0;
			Object.keys(datas.productvardetail[i].uoms).forEach((uom) => {
				datas.productvardetail[i].uoms[uom].needTotal = 0;
				datas.productvardetail[i].uoms[uom].usedTotal = 0;
			});
		});

		datas.productvaracc.forEach((acc, i) => {
			datas.productvaracc[i].needQty = 0;
			datas.productvaracc[i].usedQty = 0;
			Object.keys(datas.productvaracc[i].uoms).forEach((uom) => {
				datas.productvaracc[i].uoms[uom].needTotal = 0;
				datas.productvaracc[i].uoms[uom].usedTotal = 0;
			});
		});

		Object.keys(datas.productmaterials).forEach(materialid => {
			let material = datas.productmaterials[materialid];
			Object.keys(material.uoms).forEach(kuom => {
				datas.productmaterials[materialid].uoms[kuom].usedTotal = 0;
			});
		});

		let needQty = 0;

		datas.productvarsizes[i].qtyapproved = value;
		datas.productvarsizes.forEach((size) => {
			size.estimateQty = null;

			size.details.forEach((detail, j) => {
				needQty = size.qtyapproved;
				detail.stockEstimateQty = 0;

				detail.uoms.forEach((uom) => {

					const token = btoa(sprintf('%(materialdtid)s.%(uomid)s.%(warehouseid)s.%(stocktypeid)s', {
						materialdtid: uom.materialdt.materialdtid,
						uomid: uom.materialdt.uom.uomid,
						warehouseid: uom.stock.whid,
						stocktypeid: uom.stock.stocktypeid,
					}));

					uom.token = token;
					uom.stockAvaiable = stocks[token].stockAvaiable;

					let estimateQty = Math.floor(uom.stockAvaiable/uom.perItem);
					detail.stockEstimateQty += estimateQty;

					let needTotal = parseFloat((uom.perItem * needQty).toFixed(3));
					uom.needTotal = needTotal;

					let diffTotal = needTotal - uom.stockAvaiable;

					let calcEstimateQty = 0;
					if(diffTotal > 0) calcEstimateQty = Math.floor((needTotal - diffTotal)/uom.perItem);
					else calcEstimateQty = needQty;

					uom.calcEstimateQty = calcEstimateQty;

					let usedQty = 0;
					if(needTotal >= uom.stockAvaiable) usedQty = calcEstimateQty;
					else if(uom.stockAvaiable > needTotal) usedQty = needQty;

					uom.usedQty = usedQty;

					uom.usedTotal = uom.perItem * usedQty;

					uom.needQty = needQty;

					needQty -= usedQty;

					datas.productvardetail[j].usedQty += usedQty;
					datas.productvardetail[j].uoms['key_'+uom.materialdt.uom.uomid].usedTotal += uom.usedTotal;
					datas.productvardetail[j].uoms['key_'+uom.materialdt.uom.uomid].needTotal += size.qtyapproved * uom.perItem;

					stocks[token].stockAvaiable -= uom.usedTotal;
					stocks[token].stockUsed += usedQty;
				});

				datas.productvardetail[j].needQty += size.qtyapproved;

				if(size.estimateQty == null || size.estimateQty > detail.stockEstimateQty) {
					size.estimateQty = detail.stockEstimateQty;
					size.info.args.estimate = detail.stockEstimateQty;
				}

				if(detail.stockEstimateQty === 0) {
					size.info.args = {
						materialname: detail.material.materialname,
						colorname: detail.color.label,
					};
					size.info.message = DBMessage.PARAMS_STOCK_INSUFFICIENT;
				}
			});

			size.accessoris.forEach((acc, j) => {
				acc.stockEstimateQty = 0;
				needQty = size.qtyapproved;

				acc.uoms.forEach((uom) => {
					const token = btoa(sprintf('%(materialdtid)s.%(uomid)s.%(warehouseid)s.%(stocktypeid)s', {
						materialdtid: uom.materialdt.materialdtid,
						uomid: uom.materialdt.uom.uomid,
						warehouseid: uom.stock.whid,
						stocktypeid: uom.stock.stocktypeid,
					}));

					uom.token = token;
					uom.stockAvaiable = stocks[token].stockAvaiable;

					let estimateQty = Math.floor(uom.stockAvaiable/uom.perItem);
					acc.stockEstimateQty += estimateQty;

					let needTotal = parseFloat((uom.perItem * needQty).toFixed(3));
					uom.needTotal = needTotal;

					let diffTotal = needTotal - uom.stockAvaiable;

					let calcEstimateQty = 0;
					if(diffTotal > 0) calcEstimateQty = Math.floor((needTotal - diffTotal)/uom.perItem);
					else calcEstimateQty = needQty;

					uom.calcEstimateQty = calcEstimateQty;

					let usedQty = 0;
					if(needTotal >= uom.stockAvaiable) usedQty = calcEstimateQty;
					else if(uom.stockAvaiable > needTotal) usedQty = needQty;

					uom.usedQty = usedQty;

					uom.usedTotal = parseFloat((uom.perItem * calcEstimateQty).toFixed(3));

					uom.needQty = needQty;
					needQty -= usedQty;

					datas.productvaracc[j].usedQty += usedQty;
					datas.productvaracc[j].uoms['key_'+uom.materialdt.uom.uomid].usedTotal += uom.usedTotal;
					datas.productvaracc[j].uoms['key_'+uom.materialdt.uom.uomid].needTotal += size.qtyapproved * uom.perItem;

					stocks[token].stockAvaiable -= uom.usedTotal;
					stocks[token].stockUsed += usedQty;
				});

				datas.productvaracc[j].needQty += size.qtyapproved;

				if(size.estimateQty == null || size.estimateQty > acc.stockEstimateQty) {
					size.estimateQty = acc.stockEstimateQty;
					size.info.args.estimate = acc.stockEstimateQty;
				}

				if(acc.stockEstimateQty === 0) {
					size.info.args = {
						materialname: acc.material.materialname,
						colorname: acc.color.label,
					};
					size.info.message = DBMessage.PARAMS_STOCK_INSUFFICIENT;
				}
			});

			if(size.estimateQty > 0) {
				size.info.args = {
					estimate: size.estimateQty,
					sizename: size.sizename,
				};
				size.info.message = DBMessage.PARAMS_STOCK_EXIST;
			}
		});

		datas.productvarsizes.forEach(size => {
			let needQty = size.qtyapproved;
			size.details.forEach(detail => {
				let key = detail.material.materialid + '.' + detail.color.value.toString();
				Object.keys(detail.uoms).forEach(kuom => {
					let uom = detail.uoms[kuom];

					let total = uom.perItem * needQty;
					let estimate = 0;
					if(uom.perItem && uom.perItem > 0) {
						estimate = Math.floor(uom.stockAvaiable/uom.perItem);
						if(uom.stockAvaiable > total) {
							if(estimate >= needQty) {
								estimate = needQty;
							}
						}
					}

					let usedTotal = parseFloat((estimate * uom.perItem).toFixed(3));

					datas.productmaterials[key].uoms['key_'+uom.materialdt.uom.uomid].usedTotal += usedTotal;
				});
			});

			size.accessoris.forEach(acc => {
				let needQty = size.qtyapproved;
				let key = acc.material.materialid + '.' + acc.color.value.toString();
				Object.keys(acc.uoms).forEach(kuom => {
					let uom = acc.uoms[kuom];

					let total = uom.perItem * needQty;
					let estimate = 0;
					if(uom.perItem && uom.perItem > 0) {
						estimate = Math.floor(uom.stockAvaiable/uom.perItem);
						if(uom.stockAvaiable > total) {
							if(estimate >= needQty) {
								estimate = needQty;
							}
						}
					}

					let usedTotal = parseFloat((estimate * uom.perItem).toFixed(3));

					datas.productmaterials[key].uoms['key_'+uom.materialdt.uom.uomid].usedTotal += usedTotal;
				});
			})
		});

		this.setState({ datas: datas, stocks: stocks });
	}
	renderReadCustomer() {
		const {refreshing, datas} = this.state;
		return (
			<div className={'form-group'}>
				<label htmlFor={'select-customer'}>Nama Customer</label>
				<Select2
					value={datas.customer.data}
					loadData={API_SELECT.customer}
					searchable={true}
					renderOption={item => {
						return (
							<div>
								<div><b>{item.customername}</b></div>
								<div><small>{item.phone}</small></div>
								<div><small>{item.address}</small></div>
							</div>
						);
					}}
					renderField={item => {
						return (
							<div>
								<div><b>{item.customername}</b></div>
								<div><small>{item.phone}</small></div>
								<div><small>{item.address}</small></div>
							</div>
						);
					}}
					onSelectedItem={item => {
						const {datas} = this.state;
						const {customer} = datas;
						this.setState({
							datas: {
								...datas,
								customer: {
									...customer,
									data: item
								}
							}
						})
					}}
					disabled={refreshing}
				/>
			</div>
		);
	}
	renderCreateCustomer() {
		const {refreshing, datas} = this.state;
		const {customer} = datas;
		const {data} = customer;
		return [
			<div className={'form-group'} key={'form-customername'}>
				<label htmlFor={'input-customer'}>Nama Customer</label>
				<input
					name={'customername'}
					type={'text'}
					className={'form-control'}
					placeholder={'Masukan nama customer'}
					onChange={this.onChangeFormCustomer.bind(this)}
					value={data ? data.customername : ''}
					disabled={refreshing}
				/>
			</div>,
			<div className={'form-group'} key={'form-phone'}>
				<label htmlFor={'input-phone'}>Telp Customer</label>
				<input
					name={'phone'}
					type={'text'}
					className={'form-control'}
					placeholder={'Masukan phone customer'}
					onChange={this.onChangeFormCustomer.bind(this)}
					value={data ? data.phone : ''}
					disabled={refreshing}
				/>
			</div>,
			<div className={'form-group'} key={'form-address'}>
				<label htmlFor={'input-address'}>Alamat Customer</label>
				<textarea
					name={'address'}
					rows={5}
					className={'form-control'}
					placeholder={'Masukan alamat customer'}
					onChange={this.onChangeFormCustomer.bind(this)}
					value={data ? data.address : ''}
					disabled={refreshing}
				/>
			</div>,
			<div className={'form-group'} key={'form-npwp'}>
				<label htmlFor={'input-npwp'}>NPWP Customer</label>
				<input
					name={'npwp'}
					type={'text'}
					className={'form-control'}
					placeholder={'Masukan npwp customer'}
					onChange={this.onChangeFormCustomer.bind(this)}
					value={data ? data.npwp : ''}
					disabled={refreshing}
				/>
			</div>,
		];
	}
	__renderDetail(data, i) {
		const {refreshing} = this.state;
		return (
			<div className={'form-group'} key={`partname-${i}`}>
				<label>{data.partname}</label>
				<div className={'row'} id={'select-color'}>
					<div className={'col'}>
						<Select2
							searchable={true}
							loadData={API_SELECT.material}
							getLabel={item => item.label}
							onSelectedItem={item => {
								this.onChangeSelectDetail(i, 'material', item);
							}}
							value={data ? data.material : null}
							disabled={refreshing}
						/>
					</div>
					<div className={'col'}>
						<Select2
							loadData={API_SELECT.groupColor}
							getLabel={item => item.label}
							onSelectedItem={item => {
								this.onChangeSelectDetail(i, 'groupcolor', item);
								this.onChangeSelectDetail(i, 'color', null);
							}}
							value={data.groupcolor !== undefined
								? data.groupcolor
								: {
									value: data._color.groupcolorid.typeid,
									label: data._color.groupcolorid.typename
								}
							}
							disabled={refreshing}
						/>
					</div>
					<div className={'col'}>
						<Select2
							searchable={true}
							maxWidth={'lg'}
							loadData={(inputValue, callback) => {
								const {datas} = this.state;
								const detail = datas.productvardetail[i];
								API_SELECT.colorByGroup(detail && detail.groupcolor ? detail.groupcolor.value : 0, inputValue, callback);
							}}
							getLabel={item => item.label}
							onSelectedItem={item => {
								this.onChangeSelectDetail(i, 'color', item);
							}}
							value={data ? data.color : null}
							setWrapperStyle={{
								paddingLeft: 10,
								paddingRight: 5,
							}}
							setWrapperClass={'row'}
							setItemStyle={{
								padding: 0
							}}
							setItemClass={'col-sm-6'}
							renderOption={(data, getLabel) => {
								return (
									<div className={'thumb-color'} style={{
										backgroundImage: `url(${data.file && data.file[0] ? data.file[0].file : ''})`,
										backgroundRepeat: 'no-repeat',
										backgroundPosition: 'center',
										backgroundSize: 'cover',
										backgroundColor: '#f1f1f1',
									}}  >
										<span className={'thumb-title'}>{getLabel(data)}</span>
										<div className={'color'}  style={{backgroundColor: data.colorcode}}/>
									</div>
								);
							}}
							renderField={(selectedItem, getLabel) => {
								return (
									<div className={'d-flex'}>
										<div className={'thumb-color sm mr-1'} style={{backgroundColor: selectedItem.colorcode}} />
										{getLabel(selectedItem)}
									</div>
								);
							}}
							disabled={refreshing}
						/>
					</div>
				</div>
			</div>
		)
	}
	renderDetail() {
		const {datas} = this.state;
		return [
			<div className={'form-header'} key={'header-detail-product'}>
				<div className={'form-title'}>Detail Produk</div>
			</div>,
			<div className={'form-body'} style={{height: 'auto'}} key={'body-detail-product'}>
				{datas.productvardetail.map((detail, i) => this.__renderDetail(detail, i))}
				{
					datas.productvardetail.length === 0
						? this.__renderDetail({partname: 'All'}, 0)
						: null
				}
			</div>
		];
	}
	__renderAccessoris(accessoris, i) {
		const {refreshing} = this.state;
		return (
			<div className={'form-group'} key={`partname-${i}`}>
				<label htmlFor={'select-color'}>{accessoris.material.type.typename} - {accessoris.material.materialname}</label>
				<div className={'row'} id={'select-color'}>
					<div className={'col'}>
						<Select2
							searchable={true}
							loadData={API_SELECT.groupColor}
							getLabel={item => item.label}
							onSelectedItem={item => {
								this.onChangeSelectAcc(i, 'groupcolor', item);
								this.onChangeSelectAcc(i, 'color', null);
							}}
							value={accessoris.groupcolor !== undefined
								? accessoris.groupcolor
								: {
									value: accessoris._color.groupcolorid.typeid,
									label: accessoris._color.groupcolorid.typename
								}
							}
							disabled={refreshing}
						/>
					</div>
					<div className={'col'}>
						<Select2
							searchable={true}
							maxWidth={'lg'}
							loadData={(inputValue, callback) => {
								const {datas} = this.state;
								const detail = datas.productvaracc[i];
								API_SELECT.colorByGroup(detail && detail.groupcolor ? detail.groupcolor.value : 0, inputValue, callback);
							}}
							getLabel={item => item.label}
							onSelectedItem={item => {
								this.onChangeSelectAcc(i, 'color', item);
							}}
							value={accessoris ? accessoris.color : null}
							setWrapperStyle={{
								paddingLeft: 10,
								paddingRight: 5,
							}}
							setWrapperClass={'row'}
							setItemStyle={{
								padding: 0
							}}
							setItemClass={'col-sm-6'}
							renderOption={(data, getLabel) => {
								return (
									<div className={'thumb-color'} style={{
										backgroundImage: `url(${data.file && data.file[0] ? data.file[0].file : ''})`,
										backgroundRepeat: 'no-repeat',
										backgroundPosition: 'center',
										backgroundSize: 'cover',
										backgroundColor: '#f1f1f1',
									}}  >
										<span className={'thumb-title'}>{getLabel(data)}</span>
										<div className={'color'}  style={{backgroundColor: data.colorcode}}/>
									</div>
								);
							}}
							renderField={(selectedItem, getLabel) => {
								return (
									<div className={'d-flex'}>
										<div className={'thumb-color sm mr-1'} style={{backgroundColor: selectedItem.colorcode}} />
										{getLabel(selectedItem)}
									</div>
								);
							}}
							disabled={refreshing}
						/>
					</div>
				</div>
			</div>
		);
	}
	renderAccessoris() {
		const {datas} = this.state;
		return [
			<div className={'form-header'} key={'header-detail-product'}>
				<div className={'form-title'}>Aksesoris Produk</div>
			</div>,
			<div className={'form-body'} style={{height: 'auto'}} key={'body-detail-product'}>
				{datas.productvaracc.map((accessoris, i) => this.__renderAccessoris(accessoris, i))}
			</div>,
		];
	}
	renderTableSize() {
		const {refreshing, datas} = this.state;


		return datas.productvarsizes.map((size, i) => {
			if(!size.qtyapproved) size.qtyapproved = parseInt(size.qty);
			else size.qtyapproved = parseInt(size.qtyapproved);
			return (size.checked)
				? (
					<tr key={`table-size-${i}`}>
						<td>{i + 1}</td>
						<td>
							<div className={'title md'}>Ukuran {size.sizename}</div>
							<small className={'sub-title md'}>{sprintf(size.info.message, size.info.args)}</small>
						</td>
						<td>{size.qty}</td>
						<td>
							<div className={'form-number'}>
								<i className={'fa fa-minus action-item'} onClick={() => this.calculateStock(size.qtyapproved > 1 ? size.qtyapproved - 1 : 1, i)} />
								<input
									type={'text'}
									className={'action-input'}
									placeholder={'0'}
									value={size.qtyapproved}
									onChange={event => this.calculateStock(parseInt(event.target.value), i)}
									disabled={refreshing}
								/>
								<i className={'fa fa-plus action-item'} onClick={() => this.calculateStock(size.qtyapproved + 1, i)} />
							</div>
						</td>
					</tr>
				) : null;
		})
	}
	 render() {
		const {refreshing, redirect, orders, datas, modal, title, breadcrumbs, options} = this.state;
		const {swiper} = options;

		if(redirect) return <Redirect to={redirect} />;

		const images = (datas.indexType != null && datas.indexVariant != null
			&& orders.types[datas.indexType].variant[datas.indexVariant].images.length > 0)
			? orders.types[datas.indexType].variant[datas.indexVariant].images
			: [{image: orders ? orders.noImage : null}];

		return (
			<div className={DBStyles.content.wrapper}>
				<Header />

				<Sidebar />

				<div className={'main-panel'}>
					<div className={'content'}>
						<div className={'page-inner'}>
							<PageHeader title={title} breadcrumbs={breadcrumbs} />

							<form onSubmit={this.onSubmitHandler.bind(this)}>
								<div className={'card'}>
									<div className={'card-body'}>
										<div className={"row card-detail-product"}>
											<div className={"col-md-4"}>
												<Swiper {...swiper} shouldSwiperUpdate={true}>
													{
														images.map((image, img) => {
															return (
																<div key={`image-${img}`} className={"image-product"} style={{backgroundImage: `url(${image.image})`}} />
															);
														})
													}
												</Swiper>
											</div>
											<div className={'col-md-8'}>
												<div className={'section-form'}>
													<div className={'form-header'}>
														<div className={'form-title'}>Informasi Pemesanan</div>
													</div>
													<div className={'form-body'}>
														<div className={'form-body-title'}>Pesanan</div>
														<div className={'row'}>
															<div className={'col-md-6'}>
																<label htmlFor={'select-date'}>Tanggal Pesanan</label>
																<input
																	id={'select-orderdate'}
																	type={'text'}
																	className={'form-control'}
																	placeholder={'DD / MM / YYYY'}
																	disabled={true}
																	value={datas.orderdate}
																/>
															</div>
															<div className={'col-md-6'}>
																<label htmlFor={'select-orderdate'}>Estimasi Pesanan</label>
																<DatePicker
																	className={'form-control'}
																	placeholderText={'DD / MM / YYYY'}
																	selected={new Date(datas.estimatedate)}
																	dateFormat={'dd/MM/yyyy'}
																	onChange={date => {
																		this.onChangeData('estimatedate', date);
																	}}
																	required={true}
																	disabled={refreshing}
																/>
															</div>
														</div>
														<div className={'form-body-title'}>Customer</div>
														{
															datas.customer.action === 'read'
																? this.renderReadCustomer()
																: this.renderCreateCustomer()
														}
														<div className={'form-body-title'}>Tipe</div>
														<div className={'form-group'}>
															<label htmlFor={'select-producttype'}>Tipe</label>
															<Select2
																items={orders ? orders.types : []}
																getLabel={item => item.type.typename}
																value={datas.producttype ? datas.producttype : null}
																onSelectedItem={(item, i) => {

																	const {datas} = this.state;
																	this.setState({
																		datas: {
																			...datas,
																			indexType: i,
																			indexVariant: null,
																			producttype: item,
																			producttypeid: item.producttypeid,
																			productmaterialid: 0,
																			productmaterial: null,
																			productvarid: 0,
																			productvar: null,
																		}
																	});
																	this.fetchCalculate();
																}}
																disabled={refreshing}
															/>
														</div>
														<div className={'form-group'}>
															<label htmlFor={'select-productvariant'}>Variant</label>
															<Select2
																items={orders && datas.indexType !== null ? orders.types[datas.indexType].variant : []}
																getLabel={item => item.productvar}
																onSelectedItem={item => {
																	const {datas} = this.state;
																	this.setState({
																		productvarid: item.productvarid,
																		datas: {
																			...datas,
																			indexVariant: item.indexVariant,
																			productvarid: item.productvarid,
																			productvar: item,
																		}
																	});
																	this.fetchCalculate();
																}}
																value={datas.productvar}
																disabled={refreshing}
															/>
														</div>
													</div>
												</div>
											</div>
											<div className={'col-md-12'}>
												<div className={'section-form'}>
													{
														datas.producttype && datas.productvar
															? this.renderDetail()
															: null
													}
													{
														datas.producttype && datas.productvar
															? this.renderAccessoris()
															: null
													}
												</div>
											</div>
											<div className={'col-md-12'}>
												<div className={'section-form'}>
													<div className={'form-header'}>
														<div className={'form-title'}>Ukuran Produk</div>
													</div>
													<div className={'form-body'}>
														<table className={'table table-striped table-bordered small-padding'}>
															<thead>
															<tr>
																<th width={80}>No</th>
																<th>Ukuran</th>
																<th>Jumlah Pesanan</th>
																<th width={100}>Jumlah Disetujui</th>
															</tr>
															</thead>
															<tbody>
															{
																datas.productvarsizes
																	? this.renderTableSize()
																	: null
															}
															</tbody>
														</table>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className={'card-footer apps'}>
										<button
											className={'btn btn-primary btn-block'}
											onClick={this.onClickConfirm.bind(this)}
											disabled={this.isValid() || refreshing}
										>
											<i className={'fas fa-check'} />
											<span>{refreshing ? 'Sedang Memproses ...' : 'Proses Pemesanan'}</span>
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>

				<OrdersConfirm
					show={modal.show}
					datas={datas}
					onHide={this.onModalHide.bind(this)} />
			</div>
		);
	}
}

export default ReportOrderDetailComponent;
