import React from "react";
import HeaderLogo from "./header-logo";
import HeaderNavbar from "./header-navbar";

const loadjs = require('loadjs');

class Header extends React.Component {
	componentDidMount() {
		loadjs(['./dist/js/atlantis.min.js']);
	}

	render() {
		return (
			<div className={"main-header"}>
				<HeaderLogo />

				<HeaderNavbar />
			</div>
		);
	}
}

export default Header;
