import React from "react";
import {DBStyles} from "../../../module/constants";
import Header from "../../skin/header";
import Sidebar from "../../skin/sidebar";
import ServiceReportStock from "../../../services/report-stock";

import PageHeader from "../../skin/pageheader";
import Datatables from "../../components/datatables";

import {DATATABLES, RENDER_COMPONENT} from "./_config";

import $ from 'jquery';
import DetailStock from "./detail-stock";

class ReportStockComponent extends React.Component {

	componentDidMount() {
		RENDER_COMPONENT.init();
		const $selects = $('select[data-toggle=change]');
		$selects.each((i, item) => {
			const $item = $(item);
			$item.change((e) => {
				this.onClickFilter($item.data('name'), e.target.value);
			})
		});
	}

	constructor(props) {
		super(props);

		this.state = {
			filters: {
				typeid: null,
				categoryid: null,
				materialid: null,
				warehouseid: null,
			},
			modal: {
				show: false,
				datas: { id: 0 },
			},
			actions: 'ready',
			title: 'Stock',
			breadcrumbs: [
				{link: '#', menu: 'Laporan'},
				{link: 'report/stock', menu: 'Stock'}
			],
			datatables: {
				scrollX: true,
				fixedColumns: true,
				fnRowCallback: (nRow) => {
					let $row = $(nRow);
					let $detail = $row.find('[actions=detail]');
					$detail.click(this.onClickDetail.bind(this));
				}
			}
		};

		this.service = new ServiceReportStock();
	}
	onClickDetail(e) {
		let $clicked = $(e.currentTarget);
		let id = $clicked.data('id');
		this.setState({
			modal: {
				show: true,
				datas: {
					id: id,
				}
			},
			actions: 'detail-stock'
		});
	}
	onClickFilter(name, value) {
		const {filters} = this.state;
		this.setState({
			filters: {
				...filters,
				[name]: value
			},
			actions: 'read'
		});
	}
	onCloseModal() {
		this.setState({
			modal: {
				show: false,
				datas: {
					id: 0
				},
			}
		});
	}
	render() {
		const {actions, title, breadcrumbs, datatables, modal, filters} = this.state;
		return (
			<div className={DBStyles.content.wrapperIcon}>
				<Header />

				<Sidebar />

				<div className={"main-panel"}>
					<div className={"content"}>
						<div className={"page-inner"}>
							<PageHeader title={title} breadcrumbs={breadcrumbs} />

							<div className={"card"}>
								<div className={"card-header"}>
									<div className={"card-title"}>Data {title}</div>
									<div className={'card-actions'}>
										{
											(filters.warehouseid || filters.materialid)
												? (
													<button className={'btn btn-danger btn-xs mr-1'} onClick={() => {
														$('#select2-filter-material').val(null).trigger('change');
														$('#select2-filter-warehouse').val(null).trigger('change');
														this.setState({
															filters: {
																materialid: null,
																warehouseid: null
															}
														});
													}}>
														<i className={'fa fa-times'} />
														<span>Reset</span>
													</button>
												) : null
										}
										<button className={'btn btn-primary btn-xs'} onClick={() => {
											$(this.canvasFilter).toggle('fast');
										}}>
											<i className={'fa fa-filter'} />
											<span>Filter</span>
										</button>
									</div>
								</div>
								<div className={"card-body"}>
									<div className={'row'} style={{display: 'none'}} ref={ref => this.canvasFilter = ref}>
										{/*<div className={'col-md-3 form-group'}>*/}
										{/*	<label htmlFor={'select2-filter-type'}>Tipe</label>*/}
										{/*	<select*/}
										{/*		id={'select2-filter-type'}*/}
										{/*		className={'form-control'}*/}
										{/*		data-toggle={'change'}*/}
										{/*		data-name={'typeid'}>*/}
										{/*		<option value={''} />*/}
										{/*		<option value={DBCode.CATEGORY_KAIN}>Kain</option>*/}
										{/*		<option value={DBCode.CATEGORY_ACCESSORIS}>Aksessoris</option>*/}
										{/*	</select>*/}
										{/*</div>*/}
										{/*<div className={'col-md-3 form-group'}>*/}
										{/*	<label htmlFor={'select2-filter-category'}>Kategori</label>*/}
										{/*	<select*/}
										{/*		id={'select2-filter-category'}*/}
										{/*		className={'form-control'}*/}
										{/*		data-toggle={'change'}*/}
										{/*		data-name={'categoryid'}*/}
										{/*		data-filter-categoryid={filters.typeid}*/}
										{/*	/>*/}
										{/*</div>*/}
										<div className={'col-md-3 form-group'}>
											<label htmlFor={'select2-filter-material'}>Material</label>
											<select
												id={'select2-filter-material'}
												className={'form-control'}
												data-toggle={'change'}
												data-name={'materialid'}
											/>
										</div>
										{/*<div className={'col-md-3 form-group'}>*/}
										{/*	<label htmlFor={'select2-filter-groupcolor'}>Group Warna</label>*/}
										{/*	<select id={'select2-filter-groupcolor'} className={'form-control'}>Group Warna</select>*/}
										{/*</div>*/}
										{/*<div className={'col-md-3 form-group'}>*/}
										{/*	<label htmlFor={'select2-filter-color'}>Warna</label>*/}
										{/*	<select id={'select2-filter-color'} className={'form-control'}>Warna</select>*/}
										{/*</div>*/}
										{/*<div className={'col-md-3 form-group'}>*/}
										{/*	<label htmlFor={'select2-filter-uom'}>UOM</label>*/}
										{/*	<select id={'select2-filter-uom'} className={'form-control'}>UOM</select>*/}
										{/*</div>*/}
										<div className={'col-md-3 form-group'}>
											<label htmlFor={'select2-filter-warehouse'}>Warehouse</label>
											<select
												id={'select2-filter-warehouse'}
												className={'form-control'}
												data-toggle={'change'}
												data-name={'warehouseid'}
											/>
										</div>
									</div>
									<div className={"table-responsive"}>
										<Datatables
											actions={actions}
											columns={DATATABLES.columns}
											columnDefs={DATATABLES.columnDefs}
											serverSide={DATATABLES.serverSide}
											options={datatables}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<DetailStock
					show={['detail-stock'].includes(actions) && modal.show}
					datas={modal.datas}
					onHide={this.onCloseModal.bind(this)} />

			</div>
		);
	}
}

export default ReportStockComponent;
