import axios from 'axios';
import {Config, Helpers} from "../module/core";
import $ from "jquery";
import {DBCode, DBMessage} from "../module/constants";

class ServiceReportOrder {
	constructor() {
		this.api = `${Config.api}/report/orders`;
		this.datatables = `${this.api}/datatables`;

		this.select2DataApproved = `${this.api}/select2Approved`;
	}
	fetch(orderid, warehouseid = DBCode.WAREHOUSE_HQ, qty = 'qty') {
		return axios.get(`${this.api}/${orderid}`, {
			params: {
				userid: Helpers.sessUserid(),
				warehouseid: warehouseid,
				qty: qty,
			}
		})
			.then(
				res => {
					return res.data;
				},
				error => {
					$('body').removeClass('fetching-data');
					return {success: false, message: error};
				}
			)
			.catch(() => {
				$('body').removeClass('fetching-data');
				Helpers.swalError(DBMessage.ERROR_NETWORK_TITLE, DBMessage.ERROR_NETWORK_MESSAGE);
			});
	}
	fetchCalculate(datas) {
		return axios.post(`${this.api}/calculate`, datas)
			.then(
				res => {
					return res.data;
				},
				error => {
					$('body').removeClass('fetching-data');
					return {success: false, message: error};
				}
			)
			.catch(() => {
				$('body').removeClass('fetching-data');
				Helpers.swalError(DBMessage.ERROR_NETWORK_TITLE, DBMessage.ERROR_NETWORK_MESSAGE);
			});
	}
	processApprove(orderid, datas) {
		return axios.post(`${this.api}/${orderid}/approve`, datas)
			.then(
				res => {
					return res.data;
				},
				error => {
					$('body').removeClass('fetching-data');
					return {success: false, message: error};
				}
			)
			.catch(() => {
				$('body').removeClass('fetching-data');
				Helpers.swalError(DBMessage.ERROR_NETWORK_TITLE, DBMessage.ERROR_NETWORK_MESSAGE);
			});
	}
	fetchEditProcess(orderid, datas) {
		return axios.post(`${this.api}/${orderid}/edit`, datas)
			.then(
				res => {
					return res.data;
				},
				error => {
					$('body').removeClass('fetching-data');
					return {success: false, message: error};
				}
			)
			.catch(() => {
				$('body').removeClass('fetching-data');
				Helpers.swalError(DBMessage.ERROR_NETWORK_TITLE, DBMessage.ERROR_NETWORK_MESSAGE);
			});
	}
	processCancel(orderid, datas) {
		datas.userid = Helpers.sessUserid();
		return axios.post(`${this.api}/${orderid}/cancel`, datas)
			.then(
				res => {
					return res.data;
				},
				error => {
					return {success: false, message: error};
				}
			)
			.catch(() => {
				$('body').removeClass('fetching-data');
				Helpers.swalError(DBMessage.ERROR_NETWORK_TITLE, DBMessage.ERROR_NETWORK_MESSAGE);
			})
	}
}

export default ServiceReportOrder;
