import React from "react";
import ServiceMasterProductMaterial from "../../../services/master-product-material";
import {Button, Modal} from "react-bootstrap";
import AsyncSelect from "react-select/async";
import {API_SELECT, Helpers} from "../../../module/core";
import {DBMessage} from "../../../module/constants";

class ProductFormMaterial extends React.Component {
	constructor(props) {
		super(props);

		const {productid} = props.datas;

		this.state = {
			id: 0,
			productname: '',
			productid: productid,
			datas: {
				producttype: null,
				material: null,
			},
			actions: 'ready',
			disabled: false,
		};
		this.defaultData = this.state.datas;

		this.service = new ServiceMasterProductMaterial(productid);
	}
	onShow() {
		const {datas, actions, onHide} = this.props;

		if(actions === 'update-material') {
			this.setState({disabled: true, productname: datas.productname});
			this.service.fetch(datas.id)
				.then(
					res => {
						if(res.success) {
							this.setState({
								id: datas.id,
								disabled: false,
								datas: res.datas,
								actions: actions,
							});
						} else {
							this.setState({
								id: datas.id,
								disabled: false,
								datas: this.defaultData,
								actions: 'read-material',
							});
							Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, res.message)
								.then(result => {
									if(result.value) {
										onHide();
									}
								});
						}
					}
				)
		} else {
			this.setState({
				id: 0,
				productname: datas.productname,
				datas: this.defaultData,
				actions: actions
			});
		}
	}
	onChangeSelect(option, select) {
		const {datas} = this.state;
		this.setState({
			datas: {
				...datas,
				[select.name]: option
			}
		});
	}
	onSubmitHandler(event) {
		event.preventDefault();

		const {onHide} = this.props;
		const {id, datas, actions} = this.state;

		this.setState({disabled: true});
		if(actions === 'create-material') {
			this.service.fetchStore(datas)
				.then(
					res => {
						onHide();
						this.setState({disabled: false});
						if(res.success) {
							Helpers.swalSuccess(DBMessage.FETCH_SUCCESS_TITLE, res.message);
						} else {
							Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, res.message);
						}
					}
				)
		} else if(actions === 'update-material') {
			this.service.fetchUpdate(datas, id)
				.then(
					res => {
						onHide();
						this.setState({disabled: false});
						if(res.success) {
							Helpers.swalSuccess(DBMessage.FETCH_SUCCESS_TITLE, res.message);
						} else {
							Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, res.message);
						}
					}
				)
		}
	}
	render() {
		const {productid, productname, disabled, datas} = this.state;
		const {show, onHide} = this.props;
		return (
			<Modal show={show} onHide={onHide} onShow={this.onShow.bind(this)}>
				<Modal.Header closeButton>
					<Modal.Title>Form Material Produk</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form id={"form-product-material"} onSubmit={this.onSubmitHandler.bind(this)}>
						<div className={"form-group"}>
							<label htmlFor={"input-nama-produk"}>Nama Produk</label>
							<input
								type={"text"}
								className={"form-control"}
								value={productname}
								disabled={true}
							/>
						</div>
						<div className={"form-group"}>
							<label htmlFor={"select-producttype"}>Tipe Produk</label>
							<AsyncSelect
								cacheOptions
								defaultOptions
								name={"producttype"}
								id={"select-producttype"}
								placeholder={'Pilih tipe produk'}
								loadOptions={(inputValue, callback) => {
									API_SELECT.productType(productid, inputValue, callback);
								}}
								onChange={this.onChangeSelect.bind(this)}
								value={datas.producttype}
								isDisabled={disabled}
							/>
						</div>
						<div className={"form-group"}>
							<label htmlFor={"select-material"}>Material Produk</label>
							<AsyncSelect
								cacheOptions
								defaultOptions
								name={"material"}
								id={"select-material"}
								placeholder={'Pilih material produk'}
								loadOptions={API_SELECT.material}
								onChange={this.onChangeSelect.bind(this)}
								value={datas.material}
								isDisabled={disabled}
							/>
						</div>
					</form>
				</Modal.Body>
				<Modal.Footer>
					<Button type={"button"} variant="secondary" size={"sm"} onClick={onHide} disabled={disabled}>
						Batal
					</Button>
					<Button type={"submit"} variant="primary" size={"sm"} form={"form-product-material"} disabled={disabled}>
						{!disabled ? 'Simpan' : 'Memproses ...'}
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default ProductFormMaterial;
