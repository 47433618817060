import axios from 'axios';
import {Config, Helpers} from "../module/core";
import {DBMessage} from "../module/constants";

import $ from 'jquery';

class ServiceMasterUom {
	constructor() {
		this.api = `${Config.api}/uoms`;
		this.datatables = `${this.api}/datatables`;

		this.select2Data = `${this.api}/select2Data`;
	}
	fetch(id) {
		$('body').addClass('fetching-data');
		return axios.get(`${this.api}/${id}`)
			.then(
				res => {
					$('body').removeClass('fetching-data');
					return res.data;
				},
				error => {
					$('body').removeClass('fetching-data');
					return {success: false, message: error};
				}
			)
			.catch(() => {
				$('body').removeClass('fetching-data');
				Config.swalError(DBMessage.ERROR_NETWORK_TITLE, DBMessage.ERROR_NETWORK_MESSAGE);
			});
	}
	fetchStore(datas) {
		$('body').addClass('fetching-data');

		datas.userid = Helpers.sessUserid();

		return axios.post(`${this.api}`, datas)
			.then(
				res => {
					$('body').removeClass('fetching-data');
					return res.data;
				},
				error => {
					$('body').removeClass('fetching-data');
					return {success: false, message: error};
				}
			)
			.catch(() => {
				$('body').removeClass('fetching-data');
				Config.swalError(DBMessage.ERROR_NETWORK_TITLE, DBMessage.ERROR_NETWORK_MESSAGE);
			});
	}
	fetchUpdate(datas, id) {
		$('body').addClass('fetching-data');

		datas.userid = Helpers.sessUserid();

		return axios.put(`${this.api}/${id}`, datas)
			.then(
				res => {
					$('body').removeClass('fetching-data');
					return res.data;
				},
				error => {
					$('body').removeClass('fetching-data');
					return {success: false, message: error};
				}
			)
			.catch(() => {
				$('body').removeClass('fetching-data');
				Config.swalError(DBMessage.ERROR_NETWORK_TITLE, DBMessage.ERROR_NETWORK_MESSAGE);
			});
	}
	fetchDelete(id) {
		$('body').addClass('fetching-data');
		return axios.delete(`${this.api}/${id}`)
			.then(
				res => {
					$('body').removeClass('fetching-data');
					return res.data;
				},
				error => {
					$('body').removeClass('fetching-data');
					return {success: false, message: error};
				}
			)
			.catch(() => {
				$('body').removeClass('fetching-data');
				Config.swalError(DBMessage.ERROR_NETWORK_TITLE, DBMessage.ERROR_NETWORK_MESSAGE);
			});
	}
	import(formData, catid=0) {
		formData.append('userid', Helpers.sessUserid());
		return axios.post(`${this.api}/import`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
			.then(
				res => {
					return res.data;
				},
				error => {
					return {success: false, message: error}
				},
			)
			.catch(() => {
				$('body').removeClass('fetching-data');
				Config.swalError(DBMessage.ERROR_NETWORK_TITLE, DBMessage.ERROR_NETWORK_MESSAGE);
			});
	}
	export(catid=0) {
		window.open(`${this.api}/export`, '_blank');
	}
}

export default ServiceMasterUom;
