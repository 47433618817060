import React from "react";
import {Button, Modal} from "react-bootstrap";
import AsyncSelect from "react-select/async";
import {API_SELECT, Helpers} from "../../../module/core";
import ServiceMasterUsers from "../../../services/master-users";
import {DBMessage} from "../../../module/constants";

class UsersForm extends React.Component {
	constructor() {
		super();

		this.state = {
			actions: 'ready',
			disabled: false,
			datas: {
				fullname: '',
				username: '',
				password: '',
				group: null,
			}
		};
		this.defaultData = this.state.datas;

		this.service = new ServiceMasterUsers();
	}
	onShow() {
		const {actions, datas} = this.props;

		if(actions === 'edit') {
			this.setState({disabled: true});
			this.service.fetch(datas.id)
				.then(
					res => {
						if(res.success) {
							this.setState({
								disabled: false,
								datas: res.datas,
								actions: actions
							});
						} else {
							Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, res.message);
						}
					}
				);
		} else {
			this.setState({
				datas: this.defaultData,
				actions: actions,
			});
		}
	}
	onChangeHandler(event) {
		const {datas} = this.state;
		this.setState({
			datas: {
				...datas,
				[event.target.name]: event.target.value
			}
		});
	}
	onChangeSelect(option, select) {
		const {datas} = this.state;
		this.setState({
			datas: {
				...datas,
				[select.name]: option
			}
		});
	}
	onSubmit(event) {
		event.preventDefault();

		const {onHide} = this.props;
		const {actions, datas} = this.state;

		this.setState({disabled: true});
		if(actions === 'create') {
			this.service.fetchStore(datas)
				.then(
					res => {
						onHide();
						this.setState({disabled: false});
						if(res.success) {
							Helpers.swalSuccess(DBMessage.FETCH_SUCCESS_TITLE, res.message);
						} else {
							Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, res.message);
						}
					}
				)
				.catch(error => {
					this.setState({disabled: false});
				});
		} else if(actions === 'edit') {
			this.service.fetchUpdate(datas, datas.userid)
				.then(
					res => {
						onHide();
						this.setState({disabled: false});
						if(res.success) {
							Helpers.swalSuccess(DBMessage.FETCH_SUCCESS_TITLE, res.message);
						} else {
							Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, res.message);
						}
					}
				)
				.catch(error => {
					this.setState({disabled: false});
				});
		}
	}
	render() {
		const {actions, datas, disabled} = this.state;
		const {show, onHide} = this.props;
		return (
			<Modal show={show} onShow={this.onShow.bind(this)} onHide={onHide}>
				<Modal.Header>
					<Modal.Title>Form Users</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form id={"form-users"} onSubmit={this.onSubmit.bind(this)}>
						<div className={"form-group"}>
							<label htmlFor={"input-fullname"}>Nama Lengkap</label>
							<input
								type={"text"}
								id={"input-fullname"}
								name={"fullname"}
								className={"form-control"}
								placeholder={"Masukan nama lengkap"}
								onChange={this.onChangeHandler.bind(this)}
								value={datas.fullname}
								required
								disabled={disabled}
							/>
						</div>
						<div className={"form-group"}>
							<label htmlFor={"input-username"}>Nama Pengguna</label>
							<input
								type={"text"}
								id={"input-username"}
								name={"username"}
								className={"form-control"}
								placeholder={"Masukan nama pengguna"}
								onChange={this.onChangeHandler.bind(this)}
								value={datas.username}
								required
								disabled={disabled}
							/>
						</div>
						<div className={"form-group"}>
							<label htmlFor={"input-password"}>Kata Sandi</label>
							<input
								type={"password"}
								id={"input-password"}
								name={"password"}
								className={"form-control"}
								placeholder={"Masukan kata sandi"}
								onChange={this.onChangeHandler.bind(this)}
								value={datas.password}
								required={actions !== 'edit'}
								disabled={disabled}
							/>
						</div>
						<div className={"form-group"}>
							<label htmlFor={"select-group"}>Group</label>
							<AsyncSelect
								cacheOptions
								defaultOptions
								name={"group"}
								id={"select-group"}
								placeholder={'Pilih group users'}
								loadOptions={API_SELECT.group}
								onChange={this.onChangeSelect.bind(this)}
								value={datas.group}
								isDisabled={disabled}
							/>
						</div>
					</form>
				</Modal.Body>
				<Modal.Footer>
					<Button type={"button"} variant="secondary" size={"sm"} onClick={onHide} disabled={disabled}>
						Batal
					</Button>
					<Button type={"submit"} variant="primary" size={"sm"} form={"form-users"} disabled={disabled}>
						{!disabled ? 'Simpan' : 'Memproses ...'}
					</Button>
				</Modal.Footer>
			</Modal>
		)
	}
}

export default UsersForm;
