import React from 'react';
import SidebarUser from "./sidebar-user";
import SidebarMenu from "./sidebar-menu";

class Sidebar extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			menus: (props.menuPosition ? props.menuPosition : [])
		};
	}

	render() {
		const {menus} = this.state;

		return (
			<div className={"sidebar sidebar-style-2"}>
				<div className={"sidebar-wrapper scrollbar scrollbar-inner"}>
					<div className={"sidebar-content"}>

						<SidebarUser />

						<SidebarMenu menuPositions={menus} />
					</div>
				</div>
			</div>
		);
	}
}

export default Sidebar;
