import React from "react";
import Dialog from "@material-ui/core/Dialog";
import {API_SELECT} from "../../../module/core";
import Select2 from "../../components/select2";
import ServiceMasterColor from "../../../services/master-color";

import $ from 'jquery';

class ChooseColor extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			groupcolor: null,
			color: null,
			colors: [],
			searchValue: null,
			typing: false,
			typingTimeout: 0
		};

		this.serviceColor = new ServiceMasterColor();
		this.serviceColor.byGroup(0, this.state.searchValue)
			.then(
				res => {
					this.setState({
						colors: res.datas,
						groupcolor: null,
					});
				}
			)
	}
	onShow() {}
	onChangeHandler(name, value) {
		this.setState({
			[name]: value,
		});
	}
	onSearch(event) {
		const {groupcolor, searchValue} = this.state;
		const self = this;

		if (self.state.typingTimeout) {
			clearTimeout(self.state.typingTimeout);
		}

		if(!$.trim(event.target.value)) {
			this.setState({searchValue: null});
			self.serviceColor.byGroup(groupcolor ? groupcolor.value : 0, '')
				.then(
					res => {
						self.setState({
							colors: res.datas,
							groupcolor: groupcolor,
						});
					}
				)
		} else {
			self.setState({
				searchValue: event.target.value,
				typing: false,
				typingTimeout: setTimeout(function () {
					self.serviceColor.byGroup(groupcolor ? groupcolor.value : 0, searchValue)
						.then(
							res => {
								self.setState({
									colors: res.datas,
									groupcolor: groupcolor,
								});
							}
						)
				}, 250)
			});
		}
	}
	onClickedItem(item) {
		const {onHide} = this.props;
		onHide({
			groupcolor: {value: item.groupcolor.typeid, label: item.groupcolor.typename},
			color: item,
		});
	}
	render() {
		const {colors, groupcolor, searchValue} = this.state;

		const props = this.props;
		const {show, onHide} = props;
		if(show) this.onShow();

		return (
			<Dialog
				key={'select2-dialog'}
				className={'select2-dialog'}
				aria-labelledby={"simple-dialog-title"}
				open={show}
				onClose={onHide} >
				<div className={'select2-material-header'} id={"simple-dialog-title"}>
					<div className={'select2-material-header-title'}>Pilih Warna</div>
					<div className={'select2-material-header-close'} onClick={() => onHide(null)}>&times;</div>
				</div>
				<div className={`select2-content lg`} style={{overflow: 'hidden'}}>
					<div className={'row'} style={{
						padding: 10,
					}}>
						<div className={'col'}>
							<Select2
								searchable={true}
								loadData={API_SELECT.groupColor}
								getLabel={item => item.label}
								placeholder={'Pilih group warna'}
								onSelectedItem={item => {
									this.onChangeHandler('groupcolor', item);
									this.onChangeHandler('color', null);

									this.serviceColor.byGroup(item.value, searchValue)
										.then(
											res => {
												this.setState({
													colors: res.datas,
													groupcolor: item,
												});
											}
										)
								}}
								value={groupcolor}
							/>
						</div>
						<div className={'col'}>
							<div className={'select2-material-search'} style={{padding: 0}}>
								<input
									type={'text'}
									className={'form-control'}
									placeholder={'Cari ...'}
									onChange={this.onSearch.bind(this)}
									value={searchValue}
								/>
							</div>
						</div>
					</div>
					<ul className={`select2-material-list row`} style={{
						paddingLeft: 10,
						paddingRight: 5,
					}}>
						{
							colors.map((item, i) => {
								return (
									<li key={`key-item-${i}`} className={`select2-material-item col-sm-6`} onClick={() => this.onClickedItem(item)} style={{
										padding: 0
									}}>
										<div className={'thumb-color'} style={{
											backgroundImage: `url(${item.file && item.file[0] ? item.file[0].file : ''})`,
											backgroundRepeat: 'no-repeat',
											backgroundPosition: 'center',
											backgroundSize: 'cover',
											backgroundColor: '#f1f1f1',
										}}  >
											<span className={'thumb-title'}>{item.label}</span>
											<div className={'color'}  style={{backgroundColor: item.colorcode}}/>
										</div>
									</li>
								);
							})
						}
					</ul>
				</div>
			</Dialog>
		);
	}
}

export default ChooseColor;
