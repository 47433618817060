import React from "react";
import {Modal} from "react-bootstrap";
import {FormControlLabel} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import ServiceTransactionOrder from "../../../services/transaction-order";
import {Helpers} from "../../../module/core";
import {DBMessage} from "../../../module/constants";

const sprintf = require('sprintf-js').sprintf;

class OrderFormsizes extends React.Component {
	constructor(props) {
		super(props);

		const {datas} = props;

		this.state = {
			productmaterialid: datas.productmaterialid,
			productvarid: datas.productvarid,
			productvardetail: datas.productvardetail,
			productvarsizes: datas.productvarsizes,
			stocks: [],
		};

		this.serviceOrder = new ServiceTransactionOrder();
	}
	onShow() {
		const {datas} = this.props;

		const newDatas = {
			productmaterialid: datas.productmaterialid,
			productvarid: datas.productvarid,
			productvardetail: datas.productvardetail,
			productvarsizes: datas.productvarsizes,
			productvaracc: datas.productvaracc,
		};

		this.serviceOrder.fetchStock(newDatas)
			.then(
				res => {
					if(res.success) {
						newDatas.productvarsizes = res.productvarsizes;
						newDatas.stocks = res.stocks;

						this.setState(newDatas);
					} else {
						Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, res.message);
					}
				}
			);
	}
	onChangeSize(name, value, i) {
		const {productvarsizes} = this.state;
		productvarsizes[i][name] = value;

		this.setState({
			productvarsizes: productvarsizes
		});
	}
	calculateStock(value, i) {
		const {productvarsizes, stocks} = this.state;

		if(isNaN(value)) value = 1;

		Object.keys(stocks).forEach(kstock => {
			stocks[kstock].stockAvaiable = stocks[kstock].qtyavailable !== null
				? parseFloat(parseFloat(stocks[kstock].qtyavailable).toFixed(3))
				: 0;
			stocks[kstock].stockUsed = 0;
		});

		let needQty = 0;

		productvarsizes[i].qty = value;
		productvarsizes.forEach(size => {
			size.estimateQty = null;

			needQty = size.qty;
			size.details.forEach((detail, j) => {
				detail.estimateQty = 0;

				detail.uoms.forEach(uom => {

					const token = btoa(sprintf('%(materialdtid)s.%(uomid)s.%(warehouseid)s.%(stocktypeid)s', {
						materialdtid: uom.materialdt.materialdtid,
						uomid: uom.uom.uomid,
						warehouseid: uom.stock.whid,
						stocktypeid: uom.stock.stocktypeid,
					}));

					uom.token = token;
					uom.stockAvaiable = parseFloat(stocks[token].stockAvaiable);

					let estimateQty = (uom.stockAvaiable > 0) ? Math.floor(uom.stockAvaiable/uom.perItem) : 0;
					detail.estimateQty += estimateQty;

					let needTotal = parseFloat((uom.perItem * needQty).toFixed(3));
					uom.needTotal = needTotal;

					let diffTotal = needTotal - uom.stockAvaiable;

					let calcQty = 0;
					if(diffTotal > 0) calcQty = Math.floor((needTotal - diffTotal)/uom.perItem);
					else calcQty = needQty;

					uom.calcQty = calcQty;

					let usedQty = 0;
					if(needTotal >= uom.stockAvaiable) usedQty = calcQty;
					else if(uom.stockAvaiable > needTotal) usedQty = needQty;

					uom.usedQty = usedQty;

					uom.usedTotal = uom.perItem * calcQty;

					uom.needQty = needQty;

					if(stocks[token].stockAvaiable > 0)
						needQty -= usedQty;

					stocks[token].stockAvaiable -= uom.usedTotal;
					stocks[token].stockUsed += uom.usedTotal;
				});

				if(size.estimateQty == null || size.estimateQty > detail.estimateQty) {
					size.estimateQty = detail.estimateQty;
					size.info.args.estimate = detail.estimateQty;
				}

				if(detail.estimateQty === 0) {
					size.info.args = {
						materialname: detail.material.materialname,
						colorname: detail.color.label,
					};
					size.info.message = DBMessage.PARAMS_STOCK_INSUFFICIENT;
				}
			});

			needQty = size.qty;
			size.accessoris.forEach((acc, j) => {
				acc.estimateQty = 0;

				acc.uoms.forEach(uom => {
					const token = btoa(sprintf('%(materialdtid)s.%(uomid)s.%(warehouseid)s.%(stocktypeid)s', {
						materialdtid: uom.materialdt.materialdtid,
						uomid: uom.uom.uomid,
						warehouseid: uom.stock.whid,
						stocktypeid: uom.stock.stocktypeid,
					}));

					uom.token = token;
					uom.stockAvaiable = stocks[token].stockAvaiable;

					let estimateQty = Math.floor(uom.stockAvaiable/uom.perItem);
					acc.estimateQty += estimateQty;

					let needTotal = parseFloat((uom.perItem * needQty).toFixed(3));
					uom.needTotal = needTotal;

					let diffTotal = needTotal - uom.stockAvaiable;

					let calcQty = 0;
					if(diffTotal > 0) calcQty = Math.floor((needTotal - diffTotal)/uom.perItem);
					else calcQty = needQty;

					uom.calcQty = calcQty;

					let usedQty = 0;
					if(needTotal >= uom.stockAvaiable) usedQty = calcQty;
					else if(uom.stockAvaiable > needTotal) usedQty = needQty;

					uom.usedQty = usedQty;

					uom.usedTotal = parseFloat((uom.perItem * usedQty).toFixed(3));

					uom.needQty = needQty;

					if(stocks[token].stockAvaiable > 0)
						needQty -= calcQty;

					stocks[token].stockAvaiable -= uom.usedTotal;
					stocks[token].stockUsed += uom.usedTotal;
				});

				if(size.estimateQty == null || size.estimateQty > acc.estimateQty) {
					size.estimateQty = acc.estimateQty;
					size.info.args.estimate = acc.estimateQty;
				}

				if(acc.estimateQty === 0) {
					size.info.args = {
						materialname: acc.material.materialname,
						colorname: acc.color.label,
					};
					size.info.message = DBMessage.PARAMS_STOCK_INSUFFICIENT;
				}
			});

			if(size.estimateQty > 0) {
				size.info.args = {
					estimate: size.estimateQty,
					sizename: size.sizename,
				};
				size.info.message = DBMessage.PARAMS_STOCK_EXIST;
			}
		});

		this.setState({productvarsizes: productvarsizes, stocks: stocks});
	}
	render() {
		const {show, onClose} = this.props;
		const {productvarsizes} = this.state;
		return (
			<Modal size={'md'} show={show} onShow={this.onShow.bind(this)} backdrop={'static'}>
				<Modal.Body>
					<div className={'form-size'}>
						<div className={'form-size-header'}>
							<div className={'header-title'}>
								Pilih ukuran
								<span className={'close'} onClick={() => {
									onClose(null);
								}}>&times;</span>
							</div>
						</div>
						<div className={'form-size-body scrollable-y custom-scroll-1'}>
							<ul className={'nav-list'}>
								{
									productvarsizes.map((data, i) => {
										return (
											<li key={`size-${i}`} className={'list-item'}>
												<div className={'d-flex justify-content-between align-items-center'}>
													<FormControlLabel
														control={
															<Checkbox
																checked={data.checked}
																color={'primary'}
																onChange={() => {
																	this.calculateStock(!data.checked ? 1 : 0, i);
																	this.onChangeSize('checked', !data.checked, i);
																}}
																disabled={data.info.isError}
															/>
														}
														label={data.sizename}
													/>
													{
														data.checked
															? (
																<div className={'form-number'}>
																	<i className={'fa fa-minus action-item'} onClick={() => {
																		this.calculateStock(data.qty > 1 ? data.qty - 1 : 1, i);
																	}} />
																	<input
																		type={'number'}
																		className={'action-input'}
																		placeholder={'0'}
																		value={data.qty}
																		onChange={event => this.calculateStock(parseInt(event.target.value), i)}
																	/>
																	<i className={'fa fa-plus action-item'} onClick={() => {
																		this.calculateStock(data.qty + 1, i);
																	}} />
																</div>
															)
															: null
													}
												</div>
												<small className={`info ${data.info.isError ? 'text-danger' : ''}`}>{sprintf(data.info.message, data.info.args)}</small>
											</li>
										)
									})
								}
							</ul>
						</div>
					</div>
					<button className={'btn btn-primary btn-block'} onClick={() => onClose(productvarsizes)}>
						Simpan
					</button>
				</Modal.Body>
			</Modal>
		)
	}
}

export default OrderFormsizes;
