import React from "react";
import {DBCode, DBMessage, DBStyles, DBText} from "../../../module/constants";
import {DATATABLES_MATERIAL, DATATABLES_TIPE, DATATABLES_VARIANT} from "./_config";
import {API_SELECT, Helpers} from "../../../module/core";

import Header from "../../skin/header";
import Sidebar from "../../skin/sidebar";
import PageHeader from "../../skin/pageheader";
import {Button} from "react-bootstrap";

import Datatables from "../../components/datatables";
import {Link} from "react-router-dom";

import ProductFormVariant from "./product-form-variant";
import ProductFormType from "./product-form-type";

import ServiceMasterProductType from "../../../services/master-product-type";
import ServiceMasterProduct from "../../../services/master-product";
import ServiceMasterProductVariant from "../../../services/master-product-variant";

import $ from "jquery";
import VariantFormDetail from "./variant-form-detail";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import ServiceMasterProductMaterial from "../../../services/master-product-material";
import ProductFormMaterial from "./product-form-material";
import ServiceMasterVariantDetail from "../../../services/master-variant-detail";
import ProductFormImages from "./product-form-images";
import CopyVariantDetail from "./copy-variant-detail";
import Select2 from "../../components/select2";
import ServiceMasterVariantAccessoris from "../../../services/master-variant-accessoris";
import CopyVariantAccessoris from "./copy-variant-accessoris";

class ProductEditComponent extends React.Component {
	constructor(props) {
		super(props);

		const {productid} = props.match.params;

		this.state = {
			processing: false,
			route: `${process.env.PUBLIC_URL}/master/product`,
			actions: 'ready',
			disabled: false,
			title: 'Buat Produk',
			productid: productid,
			modal: {
				show: false,
				datas: {
					productid: productid,
				},
			},
			breadcrumbs: [
				{link: '#', menu: 'Master'},
				{link: '#', menu: 'Produk'},
			],
			datas: {
				productid: productid,
				productcode: '',
				category: null,
				productname: '',
			},
			variants: {
				productvarid: 0,
				details: [],
				accessoris: []
			},
			tempVariants: {
				productvarid: 0,
				details: [],
				accessoris: []
			},
			datatables: {
				fnRowCallback: (nRow, aData, iDisplayIndex, iDisplayIndexFull) => {
					let $row = $(nRow);
					let $edit = $row.find('[actions=edit');
					$edit.click(this.onClickEditType.bind(this));
					let $delete = $row.find('[actions=delete]');
					$delete.click(this.onClickDeleteType.bind(this));
				}
			},
			datatablesMaterial: {
				fnRowCallback: (nRow, aData, iDisplayIndex, iDisplayIndexFull) => {
					let $row = $(nRow);
					let $edit = $row.find('[actions=edit');
					$edit.click(this.onClickEditMaterial.bind(this));
					let $delete = $row.find('[actions=delete]');
					$delete.click(this.onClickDeleteMaterial.bind(this));
				}
			},
			datatablesVariant: {
				data: {
					producttypeid: null,
				},
				fnRowCallback: (nRow, aData, iDisplayIndex, iDisplayIndexFull) => {
					let $row = $(nRow);
					let $edit = $row.find('[actions=edit');
					$edit.click(this.onClickEditVariant.bind(this));
					let $delete = $row.find('[actions=delete]');
					$delete.click(this.onClickDeleteVariant.bind(this));
					let $inputDetail = $row.find('[actions=variant]');
					$inputDetail.click(this.onClickInputVariant.bind(this));
					let $galery = $row.find('[actions=galery]');
					$galery.click(this.onClickProductGalery.bind(this));
				}
			},
			datatablesVardetail: {

			},
		};

		this.service = new ServiceMasterProduct();
		this.serviceProductType = new ServiceMasterProductType(productid);
		this.servicePorductVariant = new ServiceMasterProductVariant(productid);
		this.servicePorductVardetail = new ServiceMasterVariantDetail(productid);
		this.serviceProductMaterial = new ServiceMasterProductMaterial(productid);
		this.serviceProductVaracc = new ServiceMasterVariantAccessoris(productid);

		this.service.fetch(this.state.datas.productid)
			.then(
				res => {
					const {breadcrumbs} = this.state;
					if(res.success) {
						breadcrumbs.push(
							{link: '#', menu: res.datas.productname},
						);
						this.setState({
							disabled: false,
							datas: res.datas,
							modal: {
								datas: res.datas,
							},
							breadcrumbs: breadcrumbs
						});
					} else {
						this.setState({disabled: false});
					}
				}
			);
	}
	onChangeHandler(event) {
		const {datas} = this.state;

		this.setState({
			datas: {
				...datas,
				[event.target.name]: event.target.value
			}
		});
	}
	onChangeSelect(option, select) {
		const {datas} = this.state;

		this.setState({
			datas: {
				...datas,
				[select.name]: option,
			}
		});
	}
	onSubmitFormProduct(event) {
		event.preventDefault();

		const {datas, breadcrumbs} = this.state;

		this.setState({disabled: true});
		this.service.fetchUpdate(datas, datas.productid)
			.then(
				res => {
					if(res.success) {
						const lastindex = Object.keys(breadcrumbs).length - 1;
						breadcrumbs[lastindex] = {link: '#', menu: datas.productname};

						this.setState({
							disabled: false,
							breadcrumbs: breadcrumbs,
							modal: {
								datas: datas
							}
						});

						Helpers.swalSuccess(DBMessage.FETCH_SUCCESS_TITLE, res.message);
					} else {
						this.setState({disabled: false});
						Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, res.message);
					}
				}
			)
	}
	onModalHide(event) {
		const {modal} = this.state;
		this.setState({
			modal: {
				...modal,
				show: false
			},
			actions: 'read',
		});
	}
	onClickProductGalery(event) {
		const {datas} = this.state.modal;
		let $clicked = $(event.currentTarget);
		this.setState({
			modal: {
				show: true,
				datas: {
					...datas,
					id: $clicked.data('id')
				},
			},
			actions: 'product-galery'
		});
	}
	onClickCreateType() {
		const {modal} = this.state;
		this.setState({
			modal: {
				...modal,
				show: true,
			},
			actions: 'create-type',
		})
	}
	onClickEditType(event) {
		const {datas} = this.state.modal;
		let $clicked = $(event.currentTarget);
		this.setState({
			modal: {
				show: true,
				datas: {
					...datas,
					id: $clicked.data('id'),
				},
			},
			actions: 'update-type'
		});
	}
	onClickDeleteType(event) {
		let $clicked = $(event.currentTarget);
		Helpers.swalConfirm(DBMessage.CONFIRM_DELETE_TITLE, DBMessage.CONFIRM_DELETE_MESSAGE)
			.then(
				result => {
					if(result.value) {
						this.serviceProductType.fetchDelete($clicked.data('id'))
							.then(
								res => {
									this.setState({
										actions: 'read-type',
									});
									if(res.success) {
										Helpers.swalSuccess(DBMessage.FETCH_SUCCESS_TITLE, res.message);
									} else {
										Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, res.message);
									}
								}
							)
					}
				}
			)
	}
	onClickCreateMaterial(event) {
		const {modal} = this.state;
		this.setState({
			modal: {
				...modal,
				show: true,
			},
			actions: 'create-material',
		});
	}
	onClickEditMaterial(event) {
		const {datas} = this.state.modal;
		let $clicked = $(event.currentTarget);
		this.setState({
			modal: {
				show: true,
				datas: {
					...datas,
					id: $clicked.data('id'),
				}
			},
			actions: 'update-material',
		})
	}
	onClickDeleteMaterial(event) {
		let $clicked = $(event.currentTarget);
		Helpers.swalConfirm(DBMessage.CONFIRM_DELETE_TITLE, DBMessage.CONFIRM_DELETE_MESSAGE)
			.then(
				result => {
					if(result.value) {
						this.serviceProductMaterial.fetchDelete($clicked.data('id'))
							.then(
								res => {
									this.setState({
										actions: 'read-type',
									});
									if(res.success) {
										Helpers.swalSuccess(DBMessage.FETCH_SUCCESS_TITLE, res.message);
									} else {
										Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, res.message);
									}
								}
							)
					}
				}
			)
	}
	onClickCreateVariant() {
		const {modal} = this.state;
		this.setState({
			modal: {
				...modal,
				show: true,
			},
			actions: 'create-variant',
		});
	}
	onClickEditVariant(event) {
		const {datas} = this.state.modal;
		let $clicked = $(event.currentTarget);
		this.setState({
			modal: {
				show: true,
				datas: {
					...datas,
					id: $clicked.data('id'),
				},
			},
			actions: 'update-variant'
		})
	}
	onClickInputVariant(event) {
		let $clicked = $(event.currentTarget);
		let value = $clicked.data('value');
		this.tabVardetail.click();

		this.servicePorductVariant.fetchDetail(value)
			.then(
				res => {
					if(res.success) {
						this.setState({
							variants: res.datas,
							tempVariants: res.datas,
						});
					}
				}
			);
	}
	onClickDeleteVariant(event) {
		let $clicked = $(event.currentTarget);
		Helpers.swalConfirm(DBMessage.CONFIRM_DELETE_TITLE, DBMessage.CONFIRM_DELETE_MESSAGE)
			.then(
				result => {
					if(result.value) {
						this.servicePorductVariant.fetchDelete($clicked.data('id'))
							.then(
								res => {
									this.setState({
										actions: 'read-variant',
									});
									if(res.success) {
										Helpers.swalSuccess(DBMessage.FETCH_SUCCESS_TITLE, res.message);
									} else {
										Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, res.message);
									}
								}
							)
					}
				}
			)
	}
	onChangeVarDetail(event) {
		const {variants} = this.state;
		const {details} = variants;
		let $element = $(event.currentTarget);
		let index = parseInt($element.data('index'));
		details[index][event.target.name] = event.target.value;

		this.setState({
			variants: {
				...variants,
				details: details
			}
		});
	}
	onChangeVarDetailSize(index, row, name, value) {
		const {variants} = this.state;
		const {details} = variants;
		details[row].sizes[index][name] = value;

		this.setState({
			variants: {
				...variants,
				details: details
			}
		});
	}
	onChangeSelectVarDetail(index, id, option, select) {
		const {variants} = this.state;
		const {details} = variants;
		details[index][select.name] = option;

		this.setState({
			variants: {
				...variants,
				details: details
			}
		});
	}
	onClickLoadSize(event) {
		const {variants} = this.state;
		const {details} = variants;
		let $clicked = $(event.currentTarget);
		let index = parseInt($clicked.data('index'));
		let data = details[index];
		this.servicePorductVardetail.fetchSize(variants.productvarid, data._partname, data._material)
			.then(
				res => {
					if(res.success) {
						details[index].sizes = res.datas;

						this.setState({
							variants: {
								...variants,
								details: details
							},
						})
					}
				}
			)
	}
	onClickLoadSizeAcc(event) {
		const {variants} = this.state;
		const {accessoris} = variants;
		let $clicked = $(event.currentTarget);
		let index = parseInt($clicked.data('index'));
		let data = accessoris[index];
		this.serviceProductVaracc.fetchSize(variants.productvarid, data._material ? data._material.value : 0)
			.then(
				res => {
					if(res.success) {
						accessoris[index].sizes = res.datas;

						this.setState({
							variants: {
								...variants,
								accessoris: accessoris
							}
						})
					}
				}
			)
	}
	onClickCreateVarDetail(event) {
		const {variants} = this.state;
		if(variants.productvarid === 0) {
			Helpers.swalError(DBMessage.FORM_VALIDATION_TITLE, DBMessage.FORM_VALIDATION_FILTER_VARIANT)
		} else {
			variants.details.unshift({
				id: 0,
				partname: '',
				color: null,
				material: null,
				sizes: []
			});
			this.setState({
				variants: variants,
				actions: 'create-vardetail'
			});
		}
	}
	onClickCopyVarDetail(event) {
		const {variants, datas} = this.state;
		const {productid} = datas;
		if(variants.productvarid === 0) {
			Helpers.swalError(DBMessage.FORM_VALIDATION_TITLE, DBMessage.FORM_VALIDATION_FILTER_VARIANT)
		} else {
			const {modal} = this.state;
			const {datas} = modal;
			this.setState({
				modal: {
					...modal,
					show: true,
					datas: {
						...datas,
						productvarid: variants.productvarid,
						productid: productid
					}
				},
				actions: 'copy-vardetail',
			})
		}
	}
	onClickDeleteVarDetail(event) {
		const {variants} = this.state;
		const {details} = variants;
		let $clicked = $(event.currentTarget);
		let index = $clicked.data('index');
		let data = details[index];
		details[index].processing = true;
		details[index].action = 'delete';
		this.setState({
			variants: {
				...variants,
				details: details,
			}
		});
		Helpers.swalConfirm(DBMessage.CONFIRM_DELETE_TITLE, DBMessage.CONFIRM_DELETE_MESSAGE)
			.then(
				res => {
					if(res.value) {
						if(parseInt($clicked.data('id')) !== 0) {
							this.servicePorductVardetail.fetchDeleteByParname(variants.productvarid, data.partname, data.material)
								.then(
									res => {
										if(res.success) {
											let index = $clicked.data('index');
											variants.details.splice(index, 1);

											this.setState({
												variants: variants
											});

											Helpers.swalSuccess(DBMessage.FETCH_SUCCESS_TITLE, res.message)
												.then(res => {
													if(res.value) {
														this.servicePorductVariant.fetchDetail(variants.productvarid)
															.then(
																res => {
																	if(res.success) {
																		this.setState({
																			variants: res.datas
																		});
																	}
																}
															)
													}
												})
										}
									}
								)
						} else {
							let index = $clicked.data('index');
							variants.details.splice(index, 1);

							this.setState({
								variants: variants
							});
						}
					} else {
						details[index].processing = false;
						details[index].action = 'read';
						this.setState({
							variants: variants
						});
					}
				}
			)
	}
	onClickCopyVarAcc() {
		const {variants, datas} = this.state;
		const {productid} = datas;
		if(variants.productvarid === 0) {
			Helpers.swalError(DBMessage.FORM_VALIDATION_TITLE, DBMessage.FORM_VALIDATION_FILTER_VARIANT)
		} else {
			const {modal} = this.state;
			const {datas} = modal;
			this.setState({
				modal: {
					...modal,
					show: true,
					datas: {
						...datas,
						productvarid: variants.productvarid,
						productid: productid
					}
				},
				actions: 'copy-varacc',
			})
		}
	}
	saveDetailVarianAcc(event) {
		const {variants} = this.state;
		const {accessoris} = variants;
		let $clicked = $(event.currentTarget);
		let index = $clicked.data('index');
		accessoris[index].processing = true;
		this.setState({
			variants: {
				...variants,
				accessoris: accessoris
			}
		});

		this.serviceProductVaracc.fetchStore(variants.productvarid, accessoris[index])
			.then(
				res => {
					if(res.success) {
						Helpers.swalSuccess(DBMessage.FETCH_SUCCESS_TITLE, res.message)
							.then(res => {
								if(res.value) {
									this.servicePorductVariant.fetchDetail(variants.productvarid)
										.then(
											res => {
												if(res.success) {
													this.setState({
														variants: res.datas
													});
												}
											}
										)
								}
							})
					}
				}
			)
	}
	onClickSaveAllAcc() {
		const {variants} = this.state;
		this.setState({
			processing: true
		});
		this.serviceProductVaracc.fetchStoreAll(variants.productvarid, variants.accessoris)
			.then(
				res => {
					if(res.success) {
						Helpers.swalSuccess(DBMessage.FETCH_SUCCESS_TITLE, res.message)
							.then(res => {
								this.servicePorductVariant.fetchDetail(variants.productvarid)
									.then(
										res => {
											if(res.success) {
												this.setState({
													processing: false,
													variants: res.datas
												});
											}
										}
									)
							})
					} else {
						Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, res.message)
							.then(() => {
								this.setState({
									processing: false,
								});
							});
					}
				}
			).catch(() => Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, DBMessage.FETCH_FAIL_MESSAGE).then(() => {
				this.setState({
					processing: false
				});
			}));
	}
	onClickCreateVarAcc() {
		const {variants} = this.state;
		if(variants.productvarid === 0) {
			Helpers.swalError(DBMessage.FORM_VALIDATION_TITLE, DBMessage.FORM_VALIDATION_FILTER_VARIANT)
		} else {
			variants.accessoris.unshift({
				id: 0,
				accessoris: null,
				material: null,
				sizes: []
			});
			this.setState({
				variants: variants,
				actions: 'create-varacc'
			});
		}
	}
	onClickDeleteVarAcc(event) {
		const {variants} = this.state;
		const {accessoris} = variants;
		let $clicked = $(event.currentTarget);
		let index = $clicked.data('index');
		let data = accessoris[index];
		accessoris[index].processing = true;
		this.setState({
			variants: {
				...variants,
				accessoris: accessoris
			}
		});
		Helpers.swalConfirm(DBMessage.CONFIRM_DELETE_TITLE, DBMessage.CONFIRM_DELETE_MESSAGE)
			.then(
				res => {
					if(res.value) {
						if(parseInt($clicked.data('id')) !== 0) {
							this.serviceProductVaracc.fetchDeleteByAccessoris(variants.productvarid, data.material.value)
								.then(
									res => {
										if(res.success) {
											let index = $clicked.data('index');
											variants.details.splice(index, 1);

											this.setState({
												variants: variants
											});

											Helpers.swalSuccess(DBMessage.FETCH_SUCCESS_TITLE, res.message)
												.then(res => {
													if(res.value) {
														this.servicePorductVariant.fetchDetail(variants.productvarid)
															.then(
																res => {
																	if(res.success) {
																		this.setState({
																			variants: res.datas
																		});
																	}
																}
															)
													}
												})
										}
									}
								)
						} else {
							let index = $clicked.data('index');
							variants.details.splice(index, 1);

							this.setState({
								variants: variants
							});
						}
					}
				}
			)
	}
	onChangeSelectVarAcc(index, id, option, select) {
		const {variants} = this.state;
		const {accessoris} = variants;
		accessoris[index][select.name] = option;

		this.setState({
			variants: {
				...variants,
				accessoris: accessoris
			}
		});
	}
	onChangeVarDetailSizeAcc(index, row, name, value) {
		const {variants} = this.state;
		const {accessoris} = variants;
		accessoris[row].sizes[index][name] = value;

		this.setState({
			variants: {
				...variants,
				accessoris: accessoris
			}
		});
	}
	onSubmitFilterVariant(event) {
		event.preventDefault();
		const {variants, tempVariants} = this.state;
		if(JSON.stringify(tempVariants) !== JSON.stringify(variants)) {
			Helpers.swalConfirm(DBMessage.CONFIRM_CHANGE_TITLE, DBMessage.CONFIRM_CHANGE_MESSAGE)
				.then(
					res => {
						if(res.value) {
							this.servicePorductVariant.fetchDetail(variants.productvarid)
								.then(
									res => {
										if(res.success) {
											this.setState({
												variants: res.datas,
												tempVariants: res.datas,
											});
										}
									}
								)
						}
					}
				);
		} else {
			this.servicePorductVariant.fetchDetail(variants.productvarid)
				.then(
					res => {
						if(res.success) {
							this.setState({
								variants: res.datas,
								tempVariants: res.datas,
							});
						}
					}
				)
		}
	}
	saveDetailVarian(event) {
		const {variants} = this.state;
		const {details} = variants;
		let $clicked = $(event.currentTarget);
		let index = $clicked.data('index');
		details[index].processing = true;
		details[index].action = 'save';
		this.setState({
			variants: {
				...variants,
				details: details,
			}
		});
		this.servicePorductVardetail.fetchStore(variants.productvarid, details[index])
			.then(
				res => {
					if(res.success) {
						Helpers.swalSuccess(DBMessage.FETCH_SUCCESS_TITLE, res.message)
							.then(res => {
								if(res.value) {
									this.servicePorductVariant.fetchDetail(variants.productvarid)
										.then(
											res => {
												if(res.success) {
													this.setState({
														variants: res.datas
													});
												} else {
													Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, res.message);
												}
											}
										)
								}
							})
					}
				}
			)
	}
	onClickSaveAll(event) {
		const {variants} = this.state;
		this.setState({
			processing: true
		});
		this.servicePorductVardetail.fetchStoreAll(variants.productvarid, variants.details)
			.then(
				res => {
					if(res.success) {
						Helpers.swalSuccess(DBMessage.FETCH_SUCCESS_TITLE, res.message)
							.then(res => {
								this.servicePorductVariant.fetchDetail(variants.productvarid)
									.then(
										res => {
											if(res.success) {
												this.setState({
													processing: false,
													variants: res.datas
												});
											} else {
												Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, res.message)
													.then(() => {
														this.setState({
															processing: false,
														});
													});
											}
										}
									)
							})
					} else {
						Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, res.message)
							.then(() => {
								this.setState({
									processing: false,
								});
							});
					}
				}
			).catch(() => Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, DBMessage.FETCH_FAIL_MESSAGE).then(() => {
				this.setState({
					processing: false
				});
			}));
	}
	renderVariants() {
		const {variants, processing} = this.state;
		const {details} = variants;

		if(details[0] !== undefined && details.length > 0) {
			return (
				details.map((detail, i) => {
					return (
						<div className={"form-variant-detail"} key={`data-${i}`}>
							<div className={"row"}>
								<div className={"action-size-variant card-actions col-md-12"}>
									<Button
										variant={"danger"}
										size={"xs"}
										data-id={detail.id}
										data-index={i}
										onClick={this.onClickDeleteVarDetail.bind(this)}
										disabled={detail.processing || processing}>
										{
											detail.processing || processing
												? (<i className={DBStyles.icon.spinner} />)
												: (<i className={DBStyles.icon.delete} />)
										}
									</Button>
									<Button
										variant={"primary"}
										size={"xs"}
										className={"ml-1"}
										data-index={i}
										onClick={this.saveDetailVarian.bind(this)}
										disabled={detail.processing || processing}>
										{
											detail.processing || processing
												? (<i className={DBStyles.icon.spinner} />)
												: (<i className={DBStyles.icon.save} />)
										}
									</Button>
								</div>
								<div className={"col-sm-12 col-md-6"}>
									<div className={"form-group"}>
										<label htmlFor={"input-prartname"}>Nama Part</label>
										<input
											type={"text"}
											id={"input-partname"}
											name={"partname"}
											className={"form-control"}
											placeholder={"Masukan nama part"}
											value={detail.partname}
											data-id={detail.id}
											data-index={i}
											onChange={this.onChangeVarDetail.bind(this)}
											disabled={detail.processing || processing}/>
									</div>
								</div>
								<div className={"col-sm-12 col-md-6"}>
									<div className={"form-group"}>
										<label htmlFor={"select-material"}>Material</label>
										<AsyncSelect
											id={"input-variant"}
											cacheOptions
											defaultOptions
											name={"material"}
											placeholder={'Pilih material'}
											loadOptions={(inputValue, callback) => {
												API_SELECT.material(inputValue, callback);
											}}
											value={detail.material}
											data-id={detail.id}
											data-index={i}
											onChange={(option, select) => {
												this.onChangeSelectVarDetail(i, detail.id, option, select);
											}}
											isDisabled={detail.processing || processing}/>
									</div>
								</div>
								<div className={"action-size-variant card-actions col-md-12"}>
									<span
										className={"nav-action"}
									  	data-toggle={"collapse"}
										data-target={`#detail-size-${i}`}
										data-id={detail.id}
										data-index={i}
										onClick={this.onClickLoadSize.bind(this)}>
										Tampilkan detail ukuran
									</span>
								</div>
								<div className={"detail-size-variant col-md-12 collapse"} id={`detail-size-${i}`}>
									<div className={"table-responsive form-group"}>
										<table
											className={"table table-striped table-bordered small-padding"}>
											<thead>
											<tr>
												<th width={20}>No</th>
												<th>Size</th>
												<th width={100}>Meter</th>
												<th width={100}>KG</th>
												<th width={100}>Yard</th>
											</tr>
											</thead>
											<tbody>
											{
												detail.sizes.map((size, j) => {
													return (
														<tr key={`table-${i}-row-${j}`}>
															<td>{j + 1}</td>
															<td>{size.sizename}</td>
															<td>
																<input
																	type={"number"}
																	id={"input-partname"}
																	name={"partname"}
																	className={"form-control input-table input-number"}
																	placeholder={"0 Meter"}
																	value={size.meter}
																	onChange={(event) => {
																		this.onChangeVarDetailSize(j, i,'meter', event.target.value);
																	}}
																	disabled={detail.processing || processing}/>
															</td>
															<td>
																<input
																	type={"number"}
																	id={"input-partname"}
																	name={"partname"}
																	className={"form-control input-table input-number"}
																	placeholder={"0 KG"}
																	value={size.kg}
																	data-id={size.productvarsizeid}
																	data-index={j}
																	data-row={i}
																	onChange={(event) => {
																		this.onChangeVarDetailSize(j, i,'kg', event.target.value);
																	}}
																	disabled={detail.processing || processing}/>
															</td>
															<td>
																<input
																	type={"number"}
																	id={"input-partname"}
																	name={"partname"}
																	className={"form-control input-table input-number"}
																	placeholder={"0 Yard"}
																	value={size.yard}
																	onChange={(event) => {
																		this.onChangeVarDetailSize(j, i,'yard', event.target.value);
																	}}
																	disabled={detail.processing || processing}/>
															</td>
														</tr>
													);
												})
											}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					);
				})
			);
		} else {
			return (
				<div className={"form-variant-detail text-center"}>
					<h5 className={"label-notfound"}>Tidak ada data</h5>
				</div>
			);
		}
	}
	renderAccessoris() {
		const {variants, processing} = this.state;
		const {accessoris} = variants;

		if(accessoris && accessoris[0] !== undefined && accessoris.length > 0) {
			return accessoris.map((dataAcc, i) => {
				let acc = accessoris[i];
				return (
					<div className={"form-variant-detail"} key={`data-${i}`}>
						<div className={"row"}>
							<div className={"action-size-variant card-actions col-md-12"}>
								<Button
									variant={"danger"}
									size={"xs"}
									data-id={acc.id}
									data-index={i}
									onClick={this.onClickDeleteVarAcc.bind(this)}
									disabled={acc.processing || processing}>
									<i className={DBStyles.icon.delete}/>
								</Button>
								<Button
									variant={"primary"}
									size={"xs"}
									className={"ml-1"}
									data-index={i}
									onClick={this.saveDetailVarianAcc.bind(this)}
									disabled={acc.processing || processing}>
									<i className={DBStyles.icon.save}/>
								</Button>
							</div>
							<div className={"col-sm-12 col-md-6"}>
								<div className={"form-group"}>
									<label htmlFor={"input-prartname"}>Aksesoris</label>
									<AsyncSelect
										id={"select-aksesoris"}
										cacheOptions
										defaultOptions
										name={"accessoris"}
										placeholder={'Pilih aksesoris'}
										loadOptions={(inputValue, callback) => {
											API_SELECT.typeByCategory(DBCode.CATEGORY_ACCESSORIS, inputValue, callback);
										}}
										value={acc.accessoris}
										data-id={acc.id}
										data-index={i}
										onChange={(option, select) => {
											this.onChangeSelectVarAcc(i, acc.id, option, select);
											this.onChangeSelectVarAcc(i, acc.id, null, {name: 'material'});
										}}
										isDisabled={acc.processing || processing}/>
								</div>
							</div>
							<div className={"col-sm-12 col-md-6"}>
								<div className={"form-group"}>
									<label htmlFor={"input-prartname"}>Bahan</label>
									<Select2
										searchable={true}
										loadData={(inputValue, callback) => {
											const {variants} = this.state;
											const {accessoris} = variants;
											let acc = accessoris[i];
											API_SELECT.accessorisByType(acc.accessoris ? acc.accessoris.value : 0, inputValue, callback);
										}}
										getLabel={item => item.label}
										onSelectedItem={item => {
											this.onChangeSelectVarAcc(i, acc.id, item, {name: 'material'});
										}}
										value={acc.material}
										disabled={acc.processing || processing}
									/>
								</div>
							</div>
							<div className={"action-size-variant card-actions col-md-12"}>
									<span
										className={"nav-action"}
										data-toggle={"collapse"}
										data-target={`#accessoris-size-${i}`}
										data-id={acc.id}
										data-index={i}
										onClick={this.onClickLoadSizeAcc.bind(this)}>
										Tampilkan detail ukuran
									</span>
							</div>
							<div className={"detail-size-variant col-md-12 collapse"} id={`accessoris-size-${i}`}>
								<div className={"table-responsive form-group"}>
									<table
										className={"table table-striped table-bordered small-padding"}>
										<thead>
										<tr>
											<th width={20}>No</th>
											<th>Size</th>
											<th width={100}>PCS</th>
										</tr>
										</thead>
										<tbody>
										{
											acc.sizes.map((size, j) => {
												return (
													<tr key={`table-${i}-row-${j}`}>
														<td>{j + 1}</td>
														<td>{size.sizename}</td>
														<td>
															<input
																type={"number"}
																id={"input-partname"}
																name={"qty"}
																className={"form-control input-table input-number"}
																placeholder={"0 PCS"}
																value={size.pcs}
																data-id={size.id}
																data-index={j}
																data-row={i}
																onChange={(event) => {
																	this.onChangeVarDetailSizeAcc(j, i,'pcs', event.target.value);
																}}
																disabled={acc.processing || processing}/>
														</td>
													</tr>
												);
											})
										}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				);
			});
		} else {
			return (
				<div className={"form-variant-detail text-center"}>
					<h5 className={"label-notfound"}>Tidak ada data</h5>
				</div>
			);
		}
	}
	render() {
		const {
			processing,
			productid,
			disabled,
			route,
			actions,
			modal,
			title,
			breadcrumbs,
			datatables,
			datatablesVariant,
			datatablesMaterial,
			datas,
			variants,
			tempVariants
		} = this.state;

		return (
			<div className={DBStyles.content.wrapper}>
				<Header />

				<Sidebar />

				<div className={"main-panel"}>
					<div className={"content"}>
						<div className={"page-inner"}>

							<PageHeader title={title} breadcrumbs={breadcrumbs} />

							<div className={"card"}>
								<div className={"card-header with-tabs"}>
									<div
										id={"v-pills-tab-product"}
										className={"nav flex-row nav-pills nav-secondary nav-pills-no-bd nav-style-1"}
										role={"tablist"}
										aria-orientation={"vertical"}>
										<a
											href={"#tab-pane-info"}
											className={"nav-link active"}
											id={"v-pill-tab-info"}
											data-toggle={"pill"}
											role={"tab"}
											aria-controls={"v-pill-tab-info"}
											aria-selected={"true"}
											ref={element => this.tabInfo = element}>
											<span>Informasi Produk</span>
										</a>
										<a
											href={"#tab-pane-type"}
											className={"nav-link"}
											id={"v-pill-tab-type"}
											data-toggle={"pill"}
											role={"tab"}
											aria-controls={"v-pill-tab-type"}
											aria-selected={"true"}
											ref={element => this.tabType = element}>
											<span>Tipe Produk</span>
										</a>
										<a
											href={"#tab-pane-material"}
											className={"nav-link d-none"}
											id={"v-pill-tab-material"}
											data-toggle={"pill"}
											role={"tab"}
											aria-controls={"v-pill-tab-material"}
											aria-selected={"false"}
											ref={element => this.tabMaterial = element}>
											<span>Material Produk</span>
										</a>
										<a
											href={"#tab-pane-variant"}
											className={"nav-link"}
											id={"v-pill-tab-variant"}
											data-toggle={"pill"}
											role={"tab"}
											aria-controls={"v-pill-tab-type"}
											aria-selected={"true"}
											ref={element => this.tabVariant = element}>
											<span>Varian Produk</span>
										</a>
										<a
											href={"#tab-pane-part"}
											className={"nav-link"}
											id={"v-pill-tab-part"}
											data-toggle={"pill"}
											role={"tab"}
											aria-controls={"v-pill-tab-part"}
											aria-selected={"false"}
											ref={element => this.tabVardetail = element}>
											<span>Detail Varian</span>
										</a>
									</div>
								</div>
								<div className={"card-body"}>
									<div className={"tab-content"} id={"v-pills-tab-product-content"}>
										<div className={"tab-pane fade show active"} id={"tab-pane-info"} role={"tabpanel"} aria-labelledby={"v-pill-tab-info"}>
											<div className={"card-section"}>
												<div className={"card-section-body"}>
													<form id={"form-edit-product"} onSubmit={this.onSubmitFormProduct.bind(this)}>
														<div className={"row"}>
															<div className={"col-sm-12 col-md-6"}>
																<div className={"form-group"}>
																	<label htmlFor={"input-productcd"}>Kode Produk</label>
																	<input
																		type={"text"}
																		id={"input-productcd"}
																		name={"productcode"}
																		className={"form-control"}
																		placeholder={"Masukan kode produk"}
																		onChange={this.onChangeHandler.bind(this)}
																		value={datas.productcode}
																		disabled={disabled} />
																</div>
															</div>
															<div className={"col-sm-12 col-md-6"}>
																<div className={"form-group"}>
																	<label htmlFor={"select-category"}>Kategori Produk</label>
																	<AsyncSelect
																		cacheOptions
																		defaultOptions
																		name={"category"}
																		id={"select-category"}
																		placeholder={"Pilih kategori product"}
																		loadOptions={API_SELECT.groupCategoryProdyct}
																		onChange={this.onChangeSelect.bind(this)}
																		value={datas.category}
																		isDisabled={disabled}
																	/>
																</div>
															</div>
															<div className={"col-sm-12 col-md-6"}>
																<div className={"form-group"}>
																	<label htmlFor={"input-productname"}>Nama Produk</label>
																	<input
																		type={"text"}
																		id={"input-productname"}
																		name={"productname"}
																		className={"form-control"}
																		placeholder={"Masukan nama produk"}
																		onChange={this.onChangeHandler.bind(this)}
																		value={datas.productname}
																		disabled={disabled} />
																</div>
															</div>
														</div>
													</form>
												</div>
												<div className={"card-section-footer"}>
													<Link to={route}>
														<Button className={"mr-1"} variant={"danger"} size={"sm"} disabled={disabled}>
															<i className={DBStyles.icon.data} />
															<span>{DBText.button.back} ke Data Produk</span>
														</Button>
													</Link>
													<Button className={"mr-1"} type={"submit"} form={"form-edit-product"} variant={"primary"} size={"sm"} disabled={disabled}>
														<i className={DBStyles.icon.save} />
														<span>{disabled ? DBText.button.processing : DBText.button.save}</span>
													</Button>
													<Button type={"button"} variant={"primary"} size={"sm"} onClick={() => {
														this.tabType.click();
													}}>
														<span className={"ml-0 mr-1"}>{DBText.button.next}</span>
														<i className={DBStyles.icon.next} />
													</Button>
												</div>
											</div>
										</div>
										<div className={"tab-pane fade"} id={"tab-pane-type"} role={"tabpanel"} aria-labelledby={"v-pill-tab=type"}>
											<div className={"card-section"}>
												<div className={"card-section-header"}>
													<h3 className={"h3"}>Data Tipe</h3>
													<div className={"card-actions"}>
														<Button variant={"primary"} size={"sm"} onClick={this.onClickCreateType.bind(this)}>
															<i className={DBStyles.icon.add} />
															<span>{DBText.button.add}</span>
														</Button>
													</div>
												</div>
												<div className={"card-section-body"}>
													<div className={"table-responsive"}>
														<Datatables
															actions={actions}
															target={'type'}
															columns={DATATABLES_TIPE.columns}
															columnDefs={DATATABLES_TIPE.columnDefs}
															serverSide={{
																url: this.serviceProductType.datatables,
																type: 'post',
															}}
															options={datatables}
														/>
													</div>
												</div>
												<div className={"card-section-footer"}>
													<Link to={route}>
														<Button className={"mr-1"} variant={"danger"} size={"sm"} disabled={disabled}>
															<i className={DBStyles.icon.data} />
															<span>{DBText.button.back} ke Data Produk</span>
														</Button>
													</Link>
													<Button className={"mr-1"} type={"button"} variant={"primary"} size={"sm"} onClick={() => {this.tabInfo.click()}}>
														<i className={DBStyles.icon.back} />
														<span>{DBText.button.back}</span>
													</Button>
													<Button type={"button"} variant={"primary"} size={"sm"} onClick={() => {this.tabMaterial.click()}}>
														<span className={"ml-0 mr-1"}>{DBText.button.next}</span>
														<i className={DBStyles.icon.next} />
													</Button>
												</div>
											</div>
										</div>
										<div className={"tab-pane fade"} id={"tab-pane-material"} role={"tabpanel"} aria-labelledby={"v-pill-tab-material"}>
											<div className={"card-section"}>
												<div className={"card-section-header"}>
													<h3 className={"h3"}>Data Material</h3>
													<div className={"card-actions"}>
														<Button variant={"primary"} size={"sm"} onClick={this.onClickCreateMaterial.bind(this)}>
															<i className={DBStyles.icon.add} />
															<span>{DBText.button.add}</span>
														</Button>
													</div>
												</div>
												<div className={"card-section-body"}>
													<div className={"table-responsive"}>
														<Datatables
															actions={actions}
															target={'type'}
															columns={DATATABLES_MATERIAL.columns}
															columnDefs={DATATABLES_MATERIAL.columnDefs}
															serverSide={{
																url: this.serviceProductMaterial.datatables,
																type: 'post',
															}}
															options={datatablesMaterial}
														/>
													</div>
												</div>
												<div className={"card-section-footer"}>
													<Link to={route}>
														<Button className={"mr-1"} variant={"danger"} size={"sm"} disabled={disabled}>
															<i className={DBStyles.icon.data} />
															<span>{DBText.button.back} ke Data Produk</span>
														</Button>
													</Link>
													<Button className={"mr-1"} type={"button"} variant={"primary"} size={"sm"} onClick={() => {this.tabType.click()}}>
														<i className={DBStyles.icon.back} />
														<span>{DBText.button.back}</span>
													</Button>
													<Button type={"button"} variant={"primary"} size={"sm"} onClick={() => { this.tabVariant.click(); }}>
														<span className={"ml-0 mr-1"}>{DBText.button.next}</span>
														<i className={DBStyles.icon.next} />
													</Button>
												</div>
											</div>
										</div>
										<div className={"tab-pane fade"} id={"tab-pane-variant"} role={"tabpanel"} aria-labelledby={"v-pill-tab-variant"}>
											<div className={"card-section"}>
												<div className={"card-section-header"}>
													<h3 className={"h3"}>Data Varian</h3>
													<div className={"card-actions"}>
														<Button variant={"primary"} size={"sm"}
																onClick={this.onClickCreateVariant.bind(this)}>
															<i className={DBStyles.icon.add}/>
															<span>{DBText.button.add}</span>
														</Button>
													</div>
												</div>
												<div className={"card-section-body"}>
													<div className={"table-responsive"}>
														<Datatables
															actions={actions}
															target={'varian'}
															columns={DATATABLES_VARIANT.columns}
															columnDefs={DATATABLES_VARIANT.columnDefs}
															serverSide={{
																url: this.servicePorductVariant.datatables,
																type: 'post',
																data: function (d) {
																	d.producttypeid = datatablesVariant.data.producttypeid
																}
															}}
															options={datatablesVariant}
														/>
													</div>
												</div>
												<div className={"card-section-footer"}>
													<Link to={route}>
														<Button className={"mr-1"} variant={"danger"} size={"sm"} disabled={disabled}>
															<i className={DBStyles.icon.data} />
															<span>{DBText.button.back} ke Data Produk</span>
														</Button>
													</Link>
													<Button className={"mr-1"} type={"button"} variant={"primary"} size={"sm"} onClick={() => {this.tabMaterial.click()}}>
														<i className={DBStyles.icon.back} />
														<span>{DBText.button.back}</span>
													</Button>
													<Button type={"button"} variant={"primary"} size={"sm"} onClick={() => { this.tabVardetail.click(); }}>
														<span className={"ml-0 mr-1"}>{DBText.button.next}</span>
														<i className={DBStyles.icon.next} />
													</Button>
												</div>
											</div>
										</div>
										<div className={"tab-pane fade"} id={"tab-pane-part"} role={"tabpanel"} aria-labelledby={"v-pill-tab-part"}>
											<div className={"card-section"}>
												<div className={"card-section-header"}>
													<h3 className={"h3"}>Data Detail</h3>
												</div>
												<div className={"card-section-body"}>
													<div className={"row"}>
														<div className={"col-md-6"}>
															<form id={"form-varian-detail"} onSubmit={this.onSubmitFilterVariant.bind(this)}>
																<div className={"form-group"}>
																	<div className={"d-flex"}>
																		<div className={"w-100 mr-1"}>
																			<AsyncSelect
																				id={"input-variant"}
																				cacheOptions
																				defaultOptions
																				name={"productvarid"}
																				placeholder={'Pilih varian produk untuk menampilkan detail'}
																				loadOptions={(inputValue, callback) => {
																					API_SELECT.productVariant(productid, inputValue, callback);
																				}}
																				onChange={(option , select) => {
																					const {variants} = this.state;
																					this.setState({
																						variants: {
																							...variants,
																							[select.name]: option.value,
																							productvar: option
																						}
																					});
																				}}
																				value={variants.productvar}
																				isDisabled={processing}
																			/>
																		</div>
																		<Button type={"submit"} variant={"primary"} size={"sm"} form={"form-varian-detail"} disabled={processing}>
																			Tampilkan
																		</Button>
																	</div>
																</div>
															</form>
														</div>
														<div className={"col-md-6"}>
															<div className={"form-group"}>
																<div className={"d-flex justify-content-end"}>

																</div>
															</div>
														</div>
													</div>
													<ul className="nav nav-tabs">
														<li className="nav-item">
															<a href={'#pane-part'} data-toggle={'pill'} data-target={'#pane-part'} className={'nav-link active'}>Data Part</a>
														</li>
														<li className="nav-item">
															<a href={'#pane-accessoris'} data-toggle={'pill'} data-target={'#pane-accessoris'} className={'nav-link'}>Data Aksesoris</a>
														</li>
													</ul>
													<div className={'tab-content'}>
														<div className={'tab-pane active'} id={'pane-part'}>
															<div className={"d-flex justify-content-end my-2"}>
																<Button variant={"primary"} size={"sm"} className={"ml-1"} onClick={this.onClickCopyVarDetail.bind(this)} disabled={processing}>
																	<i className={DBStyles.icon.copy} />
																	<span>{DBText.button.copy} Ukuran</span>
																</Button>
																<Button variant={"primary"} size={"sm"} className={"ml-1"} onClick={this.onClickCreateVarDetail.bind(this)} disabled={processing}>
																	<i className={DBStyles.icon.add}/>
																	<span>{DBText.button.add} Part</span>
																</Button>
																<Button variant={"primary"} size={"sm"} className={"ml-1"} onClick={this.onClickSaveAll.bind(this)} disabled={processing}>
																	<i className={DBStyles.icon.save}/>
																	<span>{DBText.button.save} Semua</span>
																</Button>
															</div>
															{this.renderVariants()}
														</div>
														<div className={'tab-pane'} id={'pane-accessoris'}>
															<div className={"d-flex justify-content-end my-2"}>
																<Button variant={"primary"} size={"sm"} className={"ml-1"} onClick={this.onClickCopyVarAcc.bind(this)} disabled={processing}>
																	<i className={DBStyles.icon.copy} />
																	<span>{DBText.button.copy} Aksesoris</span>
																</Button>
																<Button variant={"primary"} size={"sm"} className={"ml-1"} onClick={this.onClickCreateVarAcc.bind(this)} disabled={processing}>
																	<i className={DBStyles.icon.add}/>
																	<span>{DBText.button.add} Aksesoris</span>
																</Button>
																<Button variant={"primary"} size={"sm"} className={"ml-1"} onClick={this.onClickSaveAllAcc.bind(this)} disabled={processing}>
																	<i className={DBStyles.icon.save}/>
																	<span>{DBText.button.save} Semua</span>
																</Button>
															</div>
															{this.renderAccessoris()}
														</div>
													</div>
												</div>
												<div className={"card-section-footer"}>
													<Button className={"mr-1"} type={"button"} variant={"primary"} size={"sm"} onClick={() => {this.tabVariant.click()}}>
														<i className={DBStyles.icon.back} />
														<span>{DBText.button.back}</span>
													</Button>
													<Link to={route}>
														<Button className={"mr-1"} variant={"danger"} size={"sm"} disabled={disabled}>
															<i className={DBStyles.icon.data} />
															<span>{DBText.button.back} ke Data Produk</span>
														</Button>
													</Link>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<ProductFormType
								show={['create-type', 'update-type'].includes(actions) && modal.show}
								actions={actions}
								datas={modal.datas}
								onHide={this.onModalHide.bind(this)}/>

							<ProductFormMaterial
								show={['create-material', 'update-material'].includes(actions) && modal.show}
								actions={actions}
								datas={modal.datas}
								onHide={this.onModalHide.bind(this)} />

							<ProductFormVariant
								show={['create-variant', 'update-variant'].includes(actions) && modal.show}
								actions={actions}
								datas={modal.datas}
								onHide={this.onModalHide.bind(this)} />

							<VariantFormDetail
								show={['create-vardetail', 'update-vardetail'].includes(actions) && modal.show}
								actions={actions}
								datas={modal.datas}
								onHide={this.onModalHide.bind(this)} />

							<ProductFormImages
								show={['product-galery'].includes(actions) && modal.show}
								actions={actions}
								datas={modal.datas}
								onHide={this.onModalHide.bind(this)} />

							<CopyVariantDetail
								show={['copy-vardetail'].includes(actions) && modal.show}
								actions={actions}
								datas={modal.datas}
								onHide={
									event => {
										this.onModalHide(event);
										$('[form=form-varian-detail]').click();
									}
								} />

							<CopyVariantAccessoris
								show={['copy-varacc'].includes(actions) && modal.show}
								actions={actions}
								datas={modal.datas}
								onHide={
									event => {
										this.onModalHide(event);
										$('[form=form-varian-detail]').click();
									}
								} />
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default ProductEditComponent;
