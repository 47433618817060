import React from "react";
import {Button, Modal} from "react-bootstrap";
import {API_SELECT, Helpers} from "../../../module/core";
import {DBCode, DBMessage} from "../../../module/constants";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import ServiceMasterMaterial from "../../../services/master-material";

class AccessorisForm extends React.Component {
	constructor() {
		super();

		this.state = {
			id: 0,
			actions: 'ready',
			refreshing: false,
			disabled: false,
			datas: {
				materialtypeid: DBCode.TYPE_ACCESSORIS,
				type: null,
				materialname: '',
				descriptions: '',
			}
		};
		this.defaultDatas = this.state.datas;

		this.service = new ServiceMasterMaterial();
	}
	onShow() {
		const {actions, datas} = this.props;

		if(actions === 'edit') {
			this.setState({disabled: true});
			this.service.fetch(datas.id)
				.then(
					res => {
						if(res.success) {
							this.setState({
								id: datas.id,
								disabled: false,
								actions: 'edit',
								datas: res.row,
							})
						} else {
							this.setState({disabled: false});
						}
					}
				)
		} else {
			this.setState({
				disabled: false,
				actions: actions,
				datas: this.defaultDatas
			})
		}
	}
	onChangeHandler(event) {
		const {datas} = this.state;
		this.setState({
			datas: {
				...datas,
				[event.target.name]: event.target.value,
			}
		});
	}
	onChangeSelect(option, select) {
		const {datas} = this.state;
		this.setState({
			datas: {
				...datas,
				[select.name]: option
			}
		});
	}
	onSubmitHandler(e) {
		e.preventDefault();

		const {onHide} = this.props;
		const {id, datas, actions} = this.state;

		this.setState({disabled: true});
		if(actions === 'create') {
			datas.materialtypeid = DBCode.TYPE_ACCESSORIS;
			this.service.fetchStore(datas)
				.then(
					res => {
						this.setState({disabled: false});
						if(res.success) {
							Helpers.swalSuccess(DBMessage.FETCH_SUCCESS_TITLE, res.message)
								.then(() => {
									this.setState({disabled: false});
									onHide();
								})
						} else {
							Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, res.message);
						}
					}
				)
		} else {
			this.service.fetchUpdate(datas, id)
				.then(
					res => {
						this.setState({disabled: false});
						if(res.success) {
							Helpers.swalSuccess(DBMessage.FETCH_SUCCESS_TITLE, res.message)
								.then(() => {
									this.setState({disabled: false});
									onHide();
								})
						} else {
							Helpers.swalError(DBMessage.FETCH_FAIL_TITLE, res.message);
						}
					}
				)
		}
	}
	render() {
		const props = this.props;
		const {disabled, datas} = this.state;

		return (
			<Modal {...props} show={props.show} onShow={this.onShow.bind(this)}>
				<Modal.Header closeButton>
					<Modal.Title>Form Aksesoris</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form id={'form-group'} onSubmit={this.onSubmitHandler.bind(this)}>
						<div className={'form-group'}>
							<label htmlFor={'input-category'}>Kategori Aksesoris</label>
							<AsyncSelect
								value={datas.type}
								cacheOptions
								loadOptions={(inputValue, callback) => {
									API_SELECT.typeByCategory(DBCode.CATEGORY_ACCESSORIS, inputValue, callback);
								}}
								defaultOptions
								placeholder={'Pilih kategori aksesoris'}
								name={"type"}
								onChange={this.onChangeSelect.bind(this)}
								required={true}
							/>
						</div>
						<div className={'form-group'}>
							<label htmlFor={'input-accessoris'}>Nama Aksesoris</label>
							<input
								type={'text'}
								className={'form-control'}
								name={'materialname'}
								placeholder={'Masukan nama aksesoris'}
								value={datas.materialname}
								onChange={this.onChangeHandler.bind(this)}
								required={true} />
						</div>
						<div className={'form-group'}>
							<label htmlFor={'input-description'}>Deskripsi</label>
							<textarea
								className={'form-control'}
								rows={5}
								name={'descriptions'}
								placeholder={'Masukan deskripsi'}
								onChange={this.onChangeHandler.bind(this)}
								value={datas.descriptions} />
						</div>
					</form>
				</Modal.Body>
				<Modal.Footer>
					<Button type={"button"} variant="secondary" size={"sm"} onClick={props.onHide} disabled={disabled}>
						Batal
					</Button>
					<Button type={"submit"} variant="primary" size={"sm"} form={"form-group"} disabled={disabled}>
						{!disabled ? 'Simpan' : 'Memproses ...'}
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default AccessorisForm;
