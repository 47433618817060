import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import PageNotFound from "./app/errors/page-not-found";

import routes_default from "./routes/defaults";
import routes_auth from "./routes/auth";
import routes_master_groups from "./routes/master-groups";
import routes_master_uom from "./routes/master-uom";
import routes_master_category from "./routes/master-category";
import routes_master_type from "./routes/master-type";
import routes_master_menus from "./routes/master-menus";
import routes_master_size from "./routes/master-size";
import routes_master_material from "./routes/master-material";
import routes_master_color from "./routes/master-color";
import routes_master_product from "./routes/master-product";
import routes_master_users from "./routes/master-users";
import routes_master_warehouse from "./routes/master-warehouse";
import routes_report_stock from "./routes/report-stock";
import routes_master_customer from "./routes/master-customer";
import {routes_transaction_order} from "./routes/transaction-order";
import routes_account from "./routes/account";
import routes_report_orders from "./routes/report-orders";
import routes_transaction_approval from "./routes/transaction-approval";
import routes_master_accessoris from "./routes/master-accessoris";
import routes_inventory_stock from "./routes/inventory-stock";

ReactDOM.render(
	(
		<BrowserRouter>
			<Switch>
				{routes_default}

				{routes_auth}

				{routes_account}

				{routes_master_groups}
				{routes_master_menus}
				{routes_master_uom}
				{routes_master_category}
				{routes_master_type}
				{routes_master_size}
				{routes_master_material}
				{routes_master_color}
				{routes_master_product}
				{routes_master_users}
				{routes_master_warehouse}
				{routes_master_customer}
				{routes_master_accessoris}

				{routes_report_stock}
				{routes_report_orders}

				{routes_transaction_order}
				{routes_transaction_approval}

				{routes_inventory_stock}

				<Route exact={true} path={"*"} component={PageNotFound} key={'page-not-found'} />
			</Switch>
		</BrowserRouter>
	),
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
